import chainlink from './chainlink.png'
import cosmos from './cosmos.png'
import etherium from './etherium.png'
import polygon from './polygon.png'
import xdc from './xdc.png'
import solana from './image-312.png'
import avalanche from './image-338.png'

export default {
  chainlink,
  cosmos,
  etherium,
  polygon,
  xdc,
  solana,
  avalanche
}