import React from 'react'
import PropTypes from "prop-types"

const SwitchCheckbox = ({
  // eslint-disable-next-line react/prop-types
  keyIndex,
  value,
  onChange,
  switchText,
  isGrayText,
  isSmallSpace,
  isRemoveLine,
  defaultChecked,
  isLeftSwitch,
  isDisabled,
}) => {
  return (
    <div key={keyIndex} className={`create__line create__line-kyc ${isLeftSwitch ? "create__line_left" : ""} ${isRemoveLine ? "remove__line" : ""} ${isGrayText ? "create__line-gray" : ""} ${isSmallSpace ? "create__line-small-space" : ""} ${isDisabled ? "create__line-disabled" : ""}`}>
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label className="switch">
        <input
          type="checkbox"
          className="switch__input"
          checked={defaultChecked}
          onChange={onChange}
          value={value}
        />

        <div className="switch__inner">
          <div className="switch__box">{' '}</div>
        </div>

        {switchText && (
          <div className="create__info">
            {switchText}
          </div>
        )}
      </label>
    </div>
  )
}

SwitchCheckbox.propTypes = {
  keyIndex: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  switchText: PropTypes.element,
  isGrayText: PropTypes.bool,
  isSmallSpace: PropTypes.bool,
  isRemoveLine: PropTypes.bool,
  defaultChecked: PropTypes.bool,
  isLeftSwitch: PropTypes.bool,
  isDisabled: PropTypes.bool,
}

export default SwitchCheckbox
