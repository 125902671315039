import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import {
  Dialog,
} from '@material-ui/core'
import TenantCard from './TenantCard'
import Icon from '../Icon'
import ModalInnerMaterialDialog from '../ModalInnerMaterialDialog'

const TenantGrid = ({
  tenantList,
  total,
  navigation,
  page,
  rowsPerPage,
  setPage,
}) => {
  const { t } = useTranslation()
  const [openModalVideo, setOpenModalVideo] = useState(false)
  const [isClickedModalVideo, setIsClickedModalVideo] = useState(null)

  const handleOpenModal = (name) => {
    setIsClickedModalVideo(tenantList.findIndex((x) => x.company.name === name))
    setOpenModalVideo(true)
  }
  const handleCloseModal = () => {
    setOpenModalVideo(false)
    isClickedModalVideo([])
  }

  const totalPage = Math.ceil(total / rowsPerPage)
  const isValidPage = (targetPage) => (targetPage >= 0 && targetPage < totalPage)
  const goToPage = (targetPage) => () => {
    if (!isValidPage(targetPage)) {
      return
    }

    setPage(targetPage)
  }

  return (
    <div className="discover__list explore__list">
      <div className="discover__slider explore_slider js-slider-discover js-slider-resize">
        {tenantList && tenantList.map((tenant, index) => (tenant.error
          ? (
            <div key={tenant.host} className="card card-explore" aria-hidden="true">
              <TenantCard
                error
                thumbUrl={`https://via.placeholder.com/650/000000/FFFFFF/?text=${encodeURIComponent('Cannot get data')}`}
                title={t(tenant.host)}
              />
            </div>
          ) : (
            <>
              <div key={tenant.host} className="card card-explore">
                <TenantCard
                  videoUrl={tenant.loginYoutubeVideo}
                  thumbUrl={tenant.logoPath}
                  title={t(tenant.company.name)}
                  isPrivate={tenant.privateDeal}
                  totalCommited={+tenant.totalCommited}
                  price={tenant.price}
                  currency={tenant.currency}
                  tokenName={tenant.tokenName}
                  projectUri={tenant.projectUri}
                  host={tenant.host}
                  latestUsers={tenant.latestUsers}
                  handleOpenModal={handleOpenModal}
                />
              </div>

              {tenant.loginYoutubeVideo && (
                <Dialog
                  open={openModalVideo && index === isClickedModalVideo}
                  className="investhub-modal investhub-modal__m"
                  onClose={handleCloseModal}
                >
                  <ModalInnerMaterialDialog
                    modalTitle={tenant.company.name}
                    modalOnClickClose={handleCloseModal}
                  >
                    <div className="field field__video">
                      <div className="video__item video__item-9-16">
                        <iframe
                          src={tenant.loginYoutubeVideo}
                          title="video"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                          frameBorder="0"
                        />
                      </div>
                    </div>
                  </ModalInnerMaterialDialog>

                </Dialog>
              )}
            </>
          )
        ))}
        {navigation && totalPage > 1 && (
          <div className="explore__pagination">
            <button
              className="subscription__btn explore__pagination_button explore__pagination_prev"
              onClick={goToPage(0)}
              disabled={page === 0}
              type="button"
            >
              <Icon
                name="arrow-prev"
                className={classNames('arrow-prev')}
                size="20"
              />
            </button>

            {isValidPage(page - 2) && (
              <button
                type="button"
                className="subscription__btn explore__pagination_button"
                onClick={goToPage(page - 2)}
              >
                {page - 1}
              </button>
            )}
            {isValidPage(page - 1) && (
              <button
                type="button"
                className="subscription__btn explore__pagination_button"
                onClick={goToPage(page - 1)}
              >
                {page}
              </button>
            )}
            <button
              type="button"
              className="subscription__btn explore__pagination_button"
              disabled
            >
              {page + 1}
            </button>
            {isValidPage(page + 1) && (
              <button
                type="button"
                className="subscription__btn explore__pagination_button"
                onClick={goToPage(page + 1)}
              >
                {page + 2}
              </button>
            )}
            {isValidPage(page + 2) && (
              <button
                type="button"
                className="subscription__btn explore__pagination_button"
                onClick={goToPage(page + 2)}
              >
                {page + 3}
              </button>
            )}
            <button
              type="button"
              className="subscription__btn explore__pagination_button explore__pagination_next"
              onClick={goToPage(totalPage - 1)}
              disabled={page === totalPage - 1}
            >
              <Icon
                name="arrow-next"
                className={classNames('arrow-next')}
                size="20"
              />
            </button>
          </div>
        )}
      </div>
    </div>
  )
}

TenantGrid.propTypes = {
  tenantList: PropTypes.array.isRequired,
  total: PropTypes.number,
  navigation: PropTypes.bool,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  setPage: PropTypes.func,
}

export default TenantGrid
