import React from 'react'
import PropTypes from 'prop-types'
import { Router } from 'react-router-dom'
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts'
import { ApolloProvider } from '@apollo/react-hooks'
import { CookiesProvider } from 'react-cookie'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import dictionary from './lang'
import { getLocale } from './utils/lang'
import Root from './Root'
import history from './historyStore'
import ScrollToTop from './components/ScrollToTop'

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: dictionary.en,
      },
      de: {
        translation: dictionary.de,
      },
      it: {
        translation: dictionary.it,
      },
    },
    lng: getLocale(),
    fallbackLng: 'en',
  })

const App = ({ apolloClient }) => (
  <>
    <CookiesProvider>
      <Router history={history}>
        <ApolloProvider client={apolloClient}>
          <ScrollToTop />
          <Root />
        </ApolloProvider>
      </Router>
      <ToastsContainer position={ToastsContainerPosition.TOP_RIGHT} store={ToastsStore} />
    </CookiesProvider>
  </>
)

App.propTypes = {
  apolloClient: PropTypes.object.isRequired,
}

export default App
