import gql from 'graphql-tag'

export const GET_INVOICES = gql`
  query getInvoices($tenantId: ID!) {
    getInvoices(tenantId: $tenantId) {
      invoiceId
      amount
      currency
      status
      invoiceUrl
      createdAt
    }
  }
`;

export const GET_ADMIN_INVOICES = gql`
  query getAdminInvoices($tenantId: ID!) {
    getAdminInvoices(tenantId: $tenantId) {
      invoiceId
      amount
      currency
      status
      invoiceUrl
      createdAt
    }
  }
`;
