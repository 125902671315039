import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useLazyQuery } from '@apollo/react-hooks'
import { useTranslation } from 'react-i18next'

import {
  CircularProgress,
  FormControlLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'

import { GET_SINGLE_TENANT_META_INFO } from '../queriesAndMutations'
import { stringToSlug } from '../utils'

const ProjectPageForm = ({
  id,
  data,
  onSubmit,
  isSubmitLoading,
}) => {
  const { t } = useTranslation()
  const [
    getSingleTenantMetaInfo,
    {
      data: { getSingleTenantMetaInfo: tenantData } = {},
      loading: getSingleTenantMetaInfoLoading,
    },
  ] = useLazyQuery(GET_SINGLE_TENANT_META_INFO)
  const [values, setValues] = useState({
    title: data ? data.title : '',
    uri: data ? data.uri : '',
    tenant: data ? data.tenant : '',
    status: data ? data.status : 'public',
  })
  const setField = (fieldName) => ({ target: { value } }) => {
    setValues({
      ...values,
      [fieldName]: value,
    })
  }
  const triggerGenerateUri = () => {
    if (values.uri === '' && values.title) {
      setValues({
        ...values,
        uri: stringToSlug(values.title),
      })
    }
  }
  const isValidSubdomain = (subDomain) => true || !!subDomain.match(/^[a-z0-9-_]+\.[a-z0-9-_]+\.[a-z0-9-_]+$/)
  const fetchTenantData = () => {
    getSingleTenantMetaInfo({
      variables: {
        host: values.tenant,
      },
    })
  }
  const submitForm = () => {
    onSubmit(values)
  }

  useEffect(() => {
    if (tenantData) {
      setValues({
        ...values,
        title: values.title || tenantData.setting.company.name,
        uri: values.uri || stringToSlug(tenantData.setting.company.name),
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenantData])

  return (
    <>
      <div className="upload__item">
        <div className="upload__fieldset details__fieldset">
          <TextField
            required
            className="investhub__field field field-has__button"
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            label={t('Tenant domain')}
            value={values.tenant}
            onChange={setField('tenant')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {getSingleTenantMetaInfoLoading ? (
                    <CircularProgress />
                  ) : (
                    <button
                      type="button"
                      className="button button-small"
                      startIcon={<CloudDownloadIcon />}
                      disabled={!values.tenant || !isValidSubdomain(values.tenant)}
                      onClick={fetchTenantData}
                    >
                      {t('Fetch from tenant')}
                    </button>
                  )}
                </InputAdornment>
              ),
            }}
          />

          <TextField
            className="investhub__field field"
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            required
            label={t('Title')}
            value={values.title}
            onChange={setField('title')}
            onBlur={triggerGenerateUri}
          />

          <TextField
            className="investhub__field field field-has__url"
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            required
            label={t('URI')}
            value={values.uri}
            onChange={setField('uri')}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {`${window.location.origin}/projects/`}
                </InputAdornment>
              ),
            }}
          />

          <div className="field">
            <div className="field__label">{t('Status')}</div>

            <RadioGroup
              row
              aria-label="status"
              name="status"
              value={values.status}
              onChange={setField('status')}
              className="field__radio"
            >
              <FormControlLabel value="public" control={<Radio />} label={t('Public')} />
              <FormControlLabel value="unlisted" control={<Radio />} label={t('Unlisted')} />
            </RadioGroup>
          </div>
        </div>
      </div>

      <div className="upload__foot details__foot">
        <div className="details__btns details__btns_clear">
          {isSubmitLoading && (
            <CircularProgress />
          )}
          {!isSubmitLoading && (
            <button type="button" className="button details__button" onClick={submitForm}>
              {id ? t('Update') : t('Create')}
            </button>
          )}
        </div>
      </div>
    </>
  )
}

ProjectPageForm.propTypes = {
  id: PropTypes.number,
  data: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  isSubmitLoading: PropTypes.bool,
}

export default ProjectPageForm
