import gql from 'graphql-tag'

// eslint-disable-next-line import/prefer-default-export
export const CREATE_PROJECT_PAGE = gql `
  mutation createProjectPage($input: ProjectPageInput!) {
    createProjectPage(input: $input)
  }
`

export const UPDATE_PROJECT_PAGE = gql `
  mutation updateProjectPage($id: ID!, $input: ProjectPageInput!) {
    updateProjectPage(id: $id, input: $input)
  }
`
export const DELETE_PROJECT_PAGE = gql `
  mutation deleteProjectPage($id: ID!) {
    deleteProjectPage(id: $id)
  }
`
