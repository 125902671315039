import React, { useState } from 'react'
import classNames from 'classnames'
import { useQuery } from '@apollo/react-hooks'

import {
  CircularProgress,
  TextField,
  MenuItem,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import {
  GET_TENANT_META_INFO,
} from '../../queriesAndMutations'
import TenantGrid from '../common/TenantGrid'
import CustomNavLink from '../common/CustomNavLink'

const ExploreDeals = () => {
  const { t } = useTranslation()
  const [filter, setFilter] = useState({
    type: 'all',
  })
  const setFilterField = (field, value) => () => {
    setFilter({
      ...filter,
      [field]: value,
    })
  }

  const setFilterFieldDropdown = (field) => (event) => {
    setFilter({
      ...filter,
      [field]: event.target.value,
    })
  }
  const { data: { getTenantMetaInfo: data } = {}, loading } = useQuery(GET_TENANT_META_INFO, {
    variables: {
      input: {
        page: 0,
        rowsPerPage: 4,
        filter: {
          ...filter,
          type: filter.type === 'all' ? '' : filter.type,
        },
      },
    },
  })
  const filterTypeOptions = [
    { value: 'all', label: 'All' },
    { value: 'private', label: 'Private' },
    { value: 'public', label: 'Public' },
    { value: 'security', label: 'Security Token' },
    { value: 'utility', label: 'Utility Token' },
  ]

  return (
    <section className="section-bg discover explore">
      <div className="discover__center center">
        <h2 className="discover__title h2">{t('Explore')}</h2>

        <div className="discover__top explore__top">
          <div className="discover__nav explore__nav">
            {filterTypeOptions.map((option) => (
              <button
                key={option.value}
                className={classNames(
                  filter.type === option.value
                    ? ['discover__link', 'active']
                    : ['discover__link'],
                )}
                onClick={setFilterField('type', option.value)}
                type="button"
              >
                {t(option.label)}
              </button>
            ))}
          </div>

          <div className="tablet-show">
            <TextField
              select
              className="select investhub-select"
              onChange={setFilterFieldDropdown('type')}
              value={filter.type}
            >
              {filterTypeOptions.map((option) => (
                <MenuItem
                  key={option.value}
                  className={classNames(
                    filter.type === option.value
                      ? ['discover__link', 'active']
                      : ['discover__link'],
                  )}
                  value={option.value}
                >
                  {t(option.label)}
                </MenuItem>
              ))}
            </TextField>
          </div>
          <CustomNavLink
            to="/explore"
            className="discover__filter explore__filter"
          >
            {t('View All')}
          </CustomNavLink>
        </div>

        {loading && (
          <div className="discover__list">
            <CircularProgress />
          </div>
        )}

        {data && data.tenants && (
          <TenantGrid tenantList={data.tenants} />
        )}
      </div>
    </section>
  )
}

export default ExploreDeals
