import React from 'react'

import {
  List,
  ListItem,
  ListItemText,
  Container,
} from '@material-ui/core'

import { NavLink } from 'react-router-dom'
import LayersIcon from '@material-ui/icons/Layers'
import PeopleIcon from '@material-ui/icons/People'
import SettingsIcon from '@material-ui/icons/Settings'
import DashboardIcon from '@material-ui/icons/Dashboard'
import LocalOfferIcon from '@material-ui/icons/LocalOffer'
import NotInterestedIcon from '@material-ui/icons/NotInterested'
import StorageIcon from '@material-ui/icons/Storage'
import WebIcon from '@material-ui/icons/Web'
import { useTranslation } from 'react-i18next'

const AdminNavigation = () => {
  const { t } = useTranslation()
  const menuList = [
    {
      label: 'Dashboard',
      url: '/admin/dashboard',
      icon: <DashboardIcon />,
    },
    {
      label: 'Users',
      url: '/admin/users',
      icon: <PeopleIcon />,
    },
    {
      label: 'Tenants',
      url: '/admin/tenants',
      icon: <LayersIcon />,
    },
    {
      label: 'Pages',
      url: '/admin/pages/projects',
      icon: <WebIcon />,
    },
    {
      label: 'Prices',
      url: '/admin/prices',
      icon: <LocalOfferIcon />,
    },
    {
      label: 'Blacklist',
      url: '/admin/blacklist',
      icon: <NotInterestedIcon />,
    },
    {
      label: 'Settings',
      url: '/admin/settings',
      icon: <SettingsIcon />,
    },
    {
      label: 'Storage',
      url: '/admin/storage',
      icon: <StorageIcon />,
    },
  ]

  return (
    <>
      <div className="site-main__dashboard-nav">
        <Container>
          <List>
            {menuList.map((menuItem) => (
              <li key={menuItem.label}>
                <ListItem
                  alignItems="flex-start"
                  component={NavLink}
                  to={menuItem.url}
                >
                  <ListItemText>{t(menuItem.label)}</ListItemText>
                </ListItem>
              </li>
            ))}
          </List>
        </Container>
      </div>
    </>
  )
}

export default AdminNavigation
