import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Icon from '../Icon'

const TablePaginationActions = ({
  count,
  page,
  rowsPerPage,
  onPageChange,
}) => {
  const goToFirstPage = (event) => {
    onPageChange(event, 0)
  }
  const goToPrevPage = (event) => {
    if (page > 0) {
      onPageChange(event, page - 1)
    }
  }
  const gotoNextPage = (event) => {
    if (page < Math.ceil(count / rowsPerPage) - 1) {
      onPageChange(event, page + 1)
    }
  }
  const goToLastPage = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
  }
  const goToPage = (event, nextPage) => {
    if (page !== nextPage) {
      onPageChange(event, nextPage)
    }
  }
  const adjacentPages = [page - 2, page - 1, page, page + 1, page + 2].filter((adjacentPage) => (
    adjacentPage >= 0 && adjacentPage <= Math.max(0, Math.ceil(count / rowsPerPage) - 1)
  ))

  return (
    <div className="explore__pagination">
      <button
        type="button"
        className="subscription__btn explore__pagination_button explore__pagination_prev display-none"
        onClick={goToFirstPage}
        disabled={page === 0}
        aria-label="first page"
      >
        <Icon
          name="arrow-prev"
          className={classNames('arrow-prev')}
          size="20"
        />
      </button>

      <button
        type="button"
        className="subscription__btn explore__pagination_button explore__pagination_prev"
        onClick={goToPrevPage}
        disabled={page === 0}
        aria-label="previous page"
      >
        <Icon
          name="arrow-prev"
          className={classNames('arrow-prev')}
          size="20"
        />
      </button>

      {adjacentPages.map((adjacentPage) => (
        <button
          type="button"
          key={adjacentPage}
          onClick={(event) => goToPage(event, adjacentPage)}
          className="subscription__btn explore__pagination_button"
        >
          {adjacentPage + 1}
        </button>
      ))}
      <button
        type="button"
        className="subscription__btn explore__pagination_button explore__pagination_next"
        onClick={gotoNextPage}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <Icon
          name="arrow-next"
          className={classNames('arrow-next')}
          size="20"
        />
      </button>

      <button
        type="button"
        className="subscription__btn explore__pagination_button explore__pagination_next display-none"
        onClick={goToLastPage}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        <Icon
          name="arrow-next"
          className={classNames('arrow-next')}
          size="20"
        />
      </button>
    </div>
  )
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
}

export default TablePaginationActions
