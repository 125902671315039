import React, { useState, useEffect, useCallback } from 'react'
import propTypes from 'prop-types'
import { useQuery, useMutation } from '@apollo/react-hooks'
import qrcode from 'qrcode'
import { useTranslation } from 'react-i18next'
import {
  CircularProgress,
  Stepper,
  Step,
  StepLabel,
  TextField,
} from '@material-ui/core'
import { NEW_2FA_SECRET, SET_2FA_SECRET } from '../../../queriesAndMutations'
import { useMe } from '../../../myHooks'

const SetupTwoFactorAuth = ({
  onClose,
  onComplete,
}) => {
  const { t } = useTranslation()
  const [twoFaSecretUrl, setTwoFaSecretUrl] = useState('')
  const [twoFaToken, setTwoFaToken] = useState('')
  const [step, setStep] = useState(0)

  const { data: { me } = {} } = useMe()
  const { data: { new2FaSecret } = {} } = useQuery(NEW_2FA_SECRET)
  const [set2FaSecret, set2FaSecretStatus] = useMutation(SET_2FA_SECRET)

  const secretToUrl = useCallback(
    (secret) => `otpauth://totp/${encodeURIComponent('InvestHub')}:${encodeURIComponent(me.email)}?secret=${secret}`,
    [me],
  )

  const onSubmitToken = async (event) => {
    event.preventDefault()
    try {
      await set2FaSecret({ variables: { secret: new2FaSecret, token: twoFaToken } })
      setStep(3)
    } catch (error) {}
  }

  const renderFirstStep = () => {
    const onNext = () => setStep(1)

    return (
      <>
        <div className="popup__info">
          {t('Please scan the QR Code below with any authenticator app on your phone that supports the standard QR Code security, eg Google authenticator, Microsoft authenticator, Authy')}
        </div>

        <div className="text-center">
          <img src={twoFaSecretUrl} alt="" />
        </div>

        <div className="popup__btns">
          <button
            type="button"
            className="button-stroke popup__button"
            onClick={onClose}
          >
            {t('Cancel')}
          </button>

          <button
            type="button"
            className="button popup__button js-popup-close"
            onClick={onNext}
          >
            {t('Next step')}
          </button>
        </div>
      </>
    )
  }

  const renderSecondStep = () => {
    const onBack = () => setStep(0)

    return (
      <>
        <form onSubmit={onSubmitToken} className="details__fieldset">
          <TextField
            className="xid-field"
            label={t('Please enter your 2fa code')}
            value={twoFaToken}
            onChange={({ target: { value } }) => setTwoFaToken(value)}
            margin="normal"
            variant="standard"
          />

          <div className="popup__btns">
            <button
              type="button"
              className="button-stroke popup__button"
              onClick={onBack}
            >
              {t('Previous step')}
            </button>
            {set2FaSecretStatus.loading && (
              <CircularProgress />
            )}
            {!set2FaSecretStatus.loading && (
              <button type="submit" className="button popup__button js-popup-close">{t('Next step')}</button>
            )}
          </div>
        </form>
      </>
    )
  }

  const renderSuccess = () => (
    <>
      <div className="popup__info">
        {t('Success! 2FA was successfully enabled!')}
      </div>

      <div className="popup__btns">
        <button type="button" onClick={onComplete} className="button js-popup-close">{t('Close')}</button>
      </div>
    </>
  )

  useEffect(() => {
    if (new2FaSecret) {
      qrcode.toDataURL(secretToUrl(new2FaSecret)).then((qrCode) => {
        setTwoFaSecretUrl(qrCode)
      })
    }
  }, [new2FaSecret, secretToUrl])

  return (
    <>
      <Stepper activeStep={step} className="stepper__wrapper">
        <Step key={0} className="stepper__item">
          <StepLabel>{t('Setting up 2FA')}</StepLabel>
        </Step>
        <Step key={1} className="stepper__item">
          <StepLabel>{t('Confirm 2FA')}</StepLabel>
        </Step>
        <Step key={2} className="stepper__item">
          <StepLabel>{t('Success')}</StepLabel>
        </Step>
      </Stepper>
      <>
        {step === 0 && renderFirstStep()}
        {step === 1 && renderSecondStep()}
        {step === 3 && renderSuccess()}
      </>
    </>
  )
}

SetupTwoFactorAuth.propTypes = {
  onClose: propTypes.func.isRequired,
  onComplete: propTypes.func,
}

export default SetupTwoFactorAuth
