import gql from 'graphql-tag'

// eslint-disable-next-line import/prefer-default-export
export const GET_BLACKLIST = gql`
    query getBlacklist {
        getBlacklist {
            id
            firstName
            lastName
            dateOfBirth
            nationality
            passportNumber
            companyName
            comment
            createdAt
            updatedAt
        }
    }
`;
