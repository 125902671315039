import gql from 'graphql-tag'

// eslint-disable-next-line import/prefer-default-export
export const GET_ADMIN_PROJECT_PAGE_LIST = gql `
  query getAdminProjectPageList($search: String, $page: Int!, $rowsPerPage: Int!) {
    getAdminProjectPageList(search: $search, page: $page, rowsPerPage: $rowsPerPage) {
      pages {
        id
        title
        tenant
        uri
        status
      }
      count
    }
  }
`
export const GET_ADMIN_PROJECT_PAGE = gql `
  query getAdminProjectPage($id: ID!) {
    getAdminProjectPage(id: $id) {
      id
      title
      tenant
      uri
      status
    }
  }
`
export const GET_PROJECT_PAGE = gql `
  query getProjectPage($uri: String!) {
    getProjectPage(uri: $uri)
  }
`
