import gql from 'graphql-tag'

// eslint-disable-next-line import/prefer-default-export
export const CREATE_OPPORTUNITY_COMMITMENT = gql `
  mutation createOpportunityCommitment($input: OpportunityCommitmentInput!) {
    createOpportunityCommitment(input: $input)
  }
`

export const COMPLETE_OPPORTUNITY_COMMITMENT = gql `
  mutation completeOpportunityCommitment($id: Int!) {
    completeOpportunityCommitment(id: $id)
  }
`