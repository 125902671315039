import React, { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/react-hooks'
import {
  CircularProgress,
  FormGroup,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { toaster } from '../utils'
import {
  SAVE_STORAGE,
  GET_STORAGE,
} from '../queriesAndMutations'
import Upload from '../components/common/Upload'
import ControlAdminBreadcrumbs from '../components/ControlAdminBreadcrumbs'

const defaultSettings = {
  sourceOfFunds: null,
}

const fieldLabels = {
  sourceOfFunds: 'Source of Funds',
}

const AdminStorage = () => {
  const { t } = useTranslation()
  const { data, loading, refetch } = useQuery(GET_STORAGE)
  const [saveStorage, saveStorageStatus] = useMutation(SAVE_STORAGE)
  const [settings, setSettings] = useState(defaultSettings)
  const setFile = (fileKey) => (file) => {
    setSettings({
      ...settings,
      [fileKey]: file,
    })
  }
  const getUploadFile = (fileObject) => (fileObject ? fileObject.file : null)
  const triggerSaveStorage = () => {
    const inputData = Object.keys(settings).reduce((all, fileKey) => ({
      ...all,
      [fileKey]: settings[fileKey]?.file ? getUploadFile(settings[fileKey]) : settings[fileKey],
    }), {})

    saveStorage({
      variables: {
        input: inputData,
      },
    }).then(() => {
      toaster.success('Storage saved')
      refetch()
    }).catch(() => {})
  }

  useEffect(() => {
    if (data && data.getStorage) {
      setSettings(Object.keys(defaultSettings).reduce((all, fileKey) => ({
        ...all,
        [fileKey]: data.getStorage[fileKey] === undefined ? defaultSettings[fileKey] : data.getStorage[fileKey],
      }), {}))
    }
  }, [data])

  return (
    <>
      <ControlAdminBreadcrumbs>
        <li className="breadcrumbs__item">
          <NavLink to="/admin/dashboard" className="breadcrumbs__link">{t('Admin Dashboard')}</NavLink>
        </li>
        <li className="breadcrumbs__item">
          {t('Storage')}
        </li>
      </ControlAdminBreadcrumbs>

      <div className="section-pt80 faq admin-settings">
        <div className="faq__center admin-settings__center center">
          <div className="faq__top admin-settings__top">
            <div className="faq__title admin-settings__title h2">{t('Storage')}</div>
          </div>

          <div className="faq__row admin-settings__row">
            <div className="faq__col admin-settings__col">
              {loading && (
                <CircularProgress />
              )}

              {!loading && (
                <>
                  <div className="upload__item">
                    <div className="upload__fieldset details__fieldset">
                      <div className="investhub-upload__row">
                        {Object.keys(defaultSettings).map((fileKey) => (
                          <div className="card investhub-upload__card" key={fileKey}>
                            <div className="investhub__field field field-upload">
                              <div className="field__label">
                                {t(fieldLabels[fileKey])}
                              </div>

                              <div className="field-upload__wrapper">
                                <FormGroup>
                                  <Upload
                                    preview
                                    accept="application/pdf,image/*"
                                    state={settings[fileKey]}
                                    setState={setFile(fileKey)}
                                  />
                                </FormGroup>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>

                  <div className="upload__foot details__foot">
                    <div className="details__btns details__btns_clear">
                      {saveStorageStatus.loading ? (
                        <CircularProgress />
                      ) : (
                        <button type="button" className="button details__button" onClick={triggerSaveStorage}>{t('Update')}</button>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AdminStorage
