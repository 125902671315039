import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const ShortenText = ({
  text,
  v2,
  classInfo,
}) => {
  return (
    <div className={classNames('shorten__text-wrap', classInfo)}>
      <div className={classNames('shorten__text', v2 ? 'v2' : '')}>
        {text}
      </div>
      <div className="full__text">
        {' '}
        {text}
      </div>
    </div>
  )
}

ShortenText.propTypes = {
  text: PropTypes.string,
  v2: PropTypes.bool,
  classInfo: PropTypes.string,
}

export default ShortenText
