import ethereum from './ethereum.svg'
import polygon from './polygon.svg'
import polygonDark from './polygon-dark.svg'
import solana from './solana.svg'
import xinfin from './xinfin.svg'
import xinfinDark from './xinfin-dark.svg'
import chainlink from './chainlink.svg'
import javascript from './js.png'
import reactimage from './react.svg'
import intercom from './intercom.svg'
import googleTagManager from './google-tag-manager.svg'
import twilio from './twilio.svg'
import electronicIdentification from './electronic-identification.jpg'
import electronicIdentificationDark from './electronic-identification-dakr.png'
import onfido from './onfido.svg'
import IDNow from './idnow.svg'
import IDNowDark from './idnow-dark.svg'
import aws from './aws.svg'
import awsDark from './aws-dark.svg'
import sepa from './sepa.svg'
import sepaDark from './sepa-dark.svg'
import crypto from './crypto.svg'
import wyre from './wyre.svg'
import wyreDark from './wyre-dark.svg'
import equanimity from './equanimity.svg'
import cashlink from './cashlink.svg'
import cashlinkDark from './cashlink-dark.svg'
import fireblocksWhite from './fireblocks-white.svg'
import fireblocksDark from './fireblocks-dark.svg'
import facetec from './facetec.svg'
import facetecDark from './facetec-dark.svg'
import kompany from './kompany.svg'
import volt from './volt.svg'
import carta from './carta.svg'
import cartaDark from './carta-dark.svg'
import elliptic from './elliptic.svg'
import ellipticDark from './elliptic-dark.svg'
import refinitiv from './refinitiv.svg'
import refinitivDark from './refinitiv-dark.svg'
import accounting from './accounting.svg'
import accountingDark from './accounting-dark.svg'
import tangany from './tangany.png'
import acronis from './acronis.svg'
import acronisDark from './acronis-dark.svg'
import cosmos from './cosmos.svg'
import cosmosDark from './cosmos-dark.svg'

export default {
  polygon,
  polygonDark,
  xinfin,
  xinfinDark,
  cosmos,
  cosmosDark,
  aws,
  awsDark,
  sepa,
  sepaDark,
  wyre,
  wyreDark,
  cashlink,
  cashlinkDark,
  acronis,
  acronisDark,
  carta,
  cartaDark,
  elliptic,
  ellipticDark,
  refinitiv,
  refinitivDark,
  accounting,
  accountingDark,
  electronicIdentification,
  electronicIdentificationDark,
  facetec,
  facetecDark,
  IDNow,
  IDNowDark,
  
  ethereum,
  solana,
  chainlink,
  intercom,
  javascript,
  reactimage,
  googleTagManager,

  twilio,
  onfido,
  crypto,
  equanimity,
  fireblocksWhite,
  fireblocksDark,

  tangany,
  volt,
  kompany,
}