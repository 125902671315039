import React, { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import {
  Dialog,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { formatPrintNumber, capitalize, toaster } from '../../utils'
import Icon from '../Icon'
import ModalInnerMaterialDialog from '../ModalInnerMaterialDialog'
import Transaction from '../Transaction'

const TenantCard = ({
  videoUrl,
  thumbUrl,
  title,
  isPrivate,
  price,
  currency,
  totalCommited,
  tokenName,
  projectUri,
  host,
  error,
  handleOpenModal,
  showConsent,
  tenant,
}) => {
  const { t } = useTranslation()
  const [openModalConsent, setOpenModalConsent] = useState(false)
  const [allowSyncData, setAllowSyncData] = useState(false)
  const minimum = tenant && tenant.enforce_token_minimum_at_kyc === 'true' && tenant.minimum_investment_amount && tenant.token_type !== 'NONE'
    ? tenant.minimum_investment_amount : ''
  const [values, setValues] = useState({
    action: '',
    token_amount: minimum,
    amount: minimum ? Math.round(minimum * tenant.price) : '',
    currency: 'eur',
    selectedTransferType: '',
    transaction: {},
  })
  const [openModalPayment, setOpenModalPayment] = useState(false)

  const redirectPath = `/redirect-to-tenant?host=${encodeURIComponent(host)}`

  const currencyList = [...new Set([tenant ? tenant.currency : ''])]

  useEffect(() => {
    if (values.amount !== '' && values.currency) {
      const tokenAmount = values.amount / (values.currency === 'eur' ? tenant.price : tenant.priceInBaseCurrency)
      setValues({ ...values, token_amount: Math.floor(tokenAmount) })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.amount, values.currency])

  const onRedirectToTenant = () => {
    if (allowSyncData) {
      window.open(`${redirectPath}&allowSyncData=true`, "_blank")
    } else {
      window.open(redirectPath, "_blank")
    }
  }

  const onClickButtonRedirect = () => {
    if (
      showConsent
      && tenant.allow_port_kyc_and_create_transaction_from_master_tenant === 'true'
      && !tenant.kycExisted
      && tenant.masterKycApproved
    ) {
      setOpenModalConsent(true)
    } else {
      onRedirectToTenant()
    }
  }

  const onCloseModalConsent = () => {
    setOpenModalConsent(false)
    setAllowSyncData(false)
  }

  const onClickContinue = () => {
    if (values.action === 'redirect_only') {
      setAllowSyncData(false)
      onRedirectToTenant()
      return
    }

    if (!allowSyncData) {
      toaster.error(t('Need agree that COMPANYNAME can access your KYC Data.', {
        COMPANYNAME: host,
      }))
      return
    }

    if (values.action === 'redirect_and_sync_kyc') {
      onRedirectToTenant()
      return
    }

    if (!values.amount) {
      toaster.error(t('Invest amount is required'))
      return
    }

    if (minimum && values.amount < Math.round(minimum * tenant.price)) {
      toaster.error(`${t('Token amount must not be under')} ${Math.round(minimum * tenant.price)}`)
      return
    }
    setOpenModalConsent(false)
    setOpenModalPayment(true)
  }

  return (
    <>
      <Dialog
        open={openModalConsent}
        className="investhub-modal investhub-modal__m"
        onClose={onCloseModalConsent}
      >
        <ModalInnerMaterialDialog
          modalTitle={t('Tenant detail')}
          modalOnClickClose={onCloseModalConsent}
        >
          <div className="space space__8">{' '}</div>
          <div className="settings__wrapper outer">
            <div className="settings__list">
              <div className="settings__item">
                <div className="upload__fieldset details__fieldset">
                  <div className="field">
                    <div className="field__label">{t('Choose action you want:')}</div>
                    <RadioGroup
                      aria-label="action"
                      name="action"
                      className="field__radio"
                      value={values.action}
                      onChange={({ target: { value } }) => setValues({ ...values, action: value })}
                    >
                      <FormControlLabel
                        value="redirect_only"
                        label={t('Redirect to tenant only')}
                        control={<Radio />}
                      />
                      <FormControlLabel
                        value="redirect_and_sync_kyc"
                        label={t('Redirect to tenant and sync KYC data')}
                        control={<Radio />}
                      />
                      <FormControlLabel
                        value="redirect_make_transaction_and_sync_kyc"
                        label={t('Redirect to tenant and sync KYC data with transaction')}
                        control={<Radio />}
                      />
                    </RadioGroup>
                  </div>
                </div>

                <div className="upload__fieldset settings__fieldset">
                  { values.action && values.action !== 'redirect_only' && (
                    <div className="create__line">
                      <div className="create__info popup__info">
                        { t('I agree that COMPANYNAME can access my KYC Data.', {
                          COMPANYNAME: host,
                        }) }
                      </div>

                      {/* eslint-disable-next-line */}
                      <label className="switch">
                        <input
                          type="checkbox"
                          className="switch__input"
                          value="allow-access-companyname"
                          checked={allowSyncData}
                          onChange={() => setAllowSyncData(!allowSyncData)}
                        />
                        <div className="switch__inner">
                          <div className="switch__box">{' '}</div>
                        </div>
                      </label>
                    </div>
                  )}

                  { allowSyncData && values.action === 'redirect_make_transaction_and_sync_kyc' && (
                    <>
                      <div className="create__info popup__info">
                        { t('How much do you want to invest?') }
                      </div>

                      <div className="upload__fieldset details__fieldset mb-6">
                        <div className="field">
                          <div className="field__label">{t('Choose Currency')}</div>

                          <RadioGroup
                            row
                            aria-label="currency"
                            name="currency"
                            value={values.currency}
                            onChange={({ target: { value } }) => setValues({ ...values, currency: value })}
                            className="field__radio"
                          >
                            {currencyList.map((name) => (
                              <FormControlLabel
                                key={name}
                                value={name}
                                control={<Radio color="primary" />}
                                label={name.toUpperCase()}
                              />
                            ))}
                          </RadioGroup>
                        </div>

                        <TextField
                          className="investhub__field field"
                          margin="normal"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          required
                          label={t('Amount')}
                          value={values.amount}
                          onChange={({ target: { value } }) => setValues({ ...values, amount: value })}
                        />
                      </div>
                    </>
                  )}
                </div>

                <div className="settings__button settings__buttons d__flex flex__row flex__wrap flex__justify_end">
                  {values.action && (
                    <button
                      onClick={onClickContinue}
                      disabled={values.action !== 'redirect_only' && !allowSyncData}
                      className="button"
                      type="button"
                    >
                      {values.action === 'redirect_make_transaction_and_sync_kyc' ? t("Submit") : t("Redirect to tenant")}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </ModalInnerMaterialDialog>

      </Dialog>

      <Transaction
        values={values}
        setValues={setValues}
        openModalPayment={openModalPayment}
        setOpenModalPayment={setOpenModalPayment}
        tenant={tenant}
      />
      <div className="card__preview card-explore__preview">
        {videoUrl ? (
          <iframe
            className=""
            src={videoUrl}
            title="video"
            type="text/html"
            frameBorder="0"
          />
        ) : (
          <img src={thumbUrl} alt="" className="" />
        )}

        <div className="card__control card-explore__control">
          {videoUrl ? (
            <button
              type="button"
              className="button-small card__button js-popup-open card-explore__button"
              style={{ bottom: projectUri ? '114px' : '64px' }}
              onClick={() => handleOpenModal(title)}
            >
              View Video
              <Icon
                name="play"
                className={classNames('play')}
                size="20"
              />
            </button>
          ) : ''}
          {projectUri && (
            <NavLink
              to={`/projects/${projectUri}`}
              style={{ bottom: '64px' }}
              className="button-small card__button js-popup-open card-explore__button"
            >
              View details
              <Icon
                name="more"
                className={classNames('more')}
                size="20"
              />
            </NavLink>
          )}
          {host && (
            <>
              {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events */}
              <button
                type="button"
                onClick={onClickButtonRedirect}
                className="button-small card__button js-popup-open card-explore__button"
              >
                Check more
                <Icon
                  name="scatter-up"
                  className={classNames('scatter-up')}
                  size="20"
                />
              </button>
            </>
          )}
        </div>
      </div>

      <div className="card__link card-explore__link">
        <div className="card__body card-explore__body">
          <div className="card__line card-explore__line">
            <div className="card__title card-explore__title">{title}</div>

            {!error && (
              <div className="card__price card-explore__status">
                {isPrivate ? 'Private' : 'Public'}
              </div>
            )}
          </div>
        </div>

        <div className="card__foot card-explore__foot">
          <div className="card__status card-explore__status">
            <Icon
              name="candlesticks-up"
              className={classNames('candlesticks-up')}
              size="20"
            />
            {!error && (
              isPrivate
                ? (
                  <>
                    Total committed:
                    {' '}
                    {totalCommited ? `${formatPrintNumber(totalCommited)} CHF` : 'undisclosed'}
                  </>
                )
                : (
                  <span>
                    {formatPrintNumber(price)}
                    {' '}
                    {currency}
                    Euro/
                    {capitalize(tokenName.toLowerCase())}
                    {' '}
                    Token
                  </span>
                )
            )}
          </div>
        </div>
      </div>
    </>
  )
}

TenantCard.propTypes = {
  videoUrl: PropTypes.string,
  thumbUrl: PropTypes.string,
  title: PropTypes.string.isRequired,
  isPrivate: PropTypes.bool,
  price: PropTypes.number,
  currency: PropTypes.string,
  totalCommited: PropTypes.number,
  tokenName: PropTypes.string,
  projectUri: PropTypes.string,
  host: PropTypes.string,
  error: PropTypes.bool,
  handleOpenModal: PropTypes.func,
  showConsent: PropTypes.bool,
  tenant: PropTypes.object,
}

export default TenantCard
