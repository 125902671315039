import React from 'react'
import PropTypes from 'prop-types'
import {
  TextField,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const MailSetting = ({
  settings,
  setSubSettingField,
}) => {
  const { t } = useTranslation()

  return (
    <div id="setting-mail" className="upload__item">
      <div className="upload__category">{t('SMTP Setting')}</div>

      <div className="upload__fieldset details__fieldset">
        <div className="field__group field__group-3">
          <TextField
            className="investhub__field field"
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            label={t('SMTP host')}
            value={settings.mailgun.host}
            onChange={setSubSettingField('mailgun', 'host')}
          />

          <TextField
            className="investhub__field field"
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            label={t('SMTP port')}
            value={settings.mailgun.port}
            onChange={setSubSettingField('mailgun', 'port')}
          />

          <TextField
            className="investhub__field field"
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            label={t('SMTP user')}
            value={settings.mailgun.user}
            onChange={setSubSettingField('mailgun', 'user')}
          />
        </div>

        <div className="field__group field__group-3">
          <TextField
            className="investhub__field field"
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            type="password"
            label={t('SMTP password')}
            value={settings.mailgun.pass}
            onChange={setSubSettingField('mailgun', 'pass')}
          />

          <TextField
            className="investhub__field field"
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            label={t('Mail from')}
            value={settings.mailgun.from}
            onChange={setSubSettingField('mailgun', 'from')}
          />

          <TextField
            className="investhub__field field"
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            label={t('Technician email')}
            value={settings.mailgun.technicianEmail}
            onChange={setSubSettingField('mailgun', 'technicianEmail')}
          />
        </div>

        <div className="field__group field__group-3">
          <TextField
            className="investhub__field field"
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            label={t('BCC email')}
            value={settings.mailgun.bccEmail}
            onChange={setSubSettingField('mailgun', 'bccEmail')}
          />
        </div>
      </div>
    </div>
  )
}

MailSetting.propTypes = {
  settings: PropTypes.object.isRequired,
  setSubSettingField: PropTypes.func.isRequired,
}

export default MailSetting
