import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import '../../styles/investhub/styles.scss'
import '../../styles/v2/style.scss'
import Logo from '../Header/Logo'
// import SearchForm from '../Header/SearchForm'
import SiteFooter from '../SiteFooter/SiteFooter'

const PageProject = ({ children }) => {
  const { t } = useTranslation()
  const [rwdVisibleValues, setRwdVisibleValues] = useState({
    isRwdVisibleActive: false,
  })

  const onRwdVisibleActive = () => {
    setRwdVisibleValues({ ...rwdVisibleValues, isRwdVisibleActive: !rwdVisibleValues.isRwdVisibleActive })
  }

  return (
    <div className="outer v2 admin">
      <div className="header js-header">
        <div className="header__center center">
          <Logo />

          <div className={`header__wrapper js-header-wrapper ${rwdVisibleValues.isRwdVisibleActive ? 'visible' : ''} `}>

            {/* <SearchForm /> */}
          </div>

          <NavLink
            to="/"
            className="button-small header__button header__upload"
            activeClassName="active"
            exact
          >
            {t('Home')}
          </NavLink>

          <button
            type="button"
            className={`header__burger js-header-burger ${rwdVisibleValues.isRwdVisibleActive ? 'active' : ''}`}
            onClick={onRwdVisibleActive}
          >
            {' '}
          </button>
        </div>
      </div>

      <div className="outer__inner">
        {children}
      </div>

      <SiteFooter />
    </div>
  )
}

export default PageProject
