import config from '../config'
// eslint-disable-next-line import/prefer-default-export
export const getFileUrl = (filename) => `${config.fileEndpoint}/${filename}`

export const getExternalFileUrl = (filename) => `${getFileUrl(filename)}?origin=${window.location.origin}`

export const getOriginalFileExtension = (filename) => {
  const regexp = /.+\.([a-zA-Z]{3,4})$/i
  const result = regexp.exec(filename)
  return (result || [])[1]
}

export const generateUuid = () => 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
  // eslint-disable-next-line no-bitwise
  const r = Math.random() * 16 | 0
  // eslint-disable-next-line no-bitwise
  const v = c === 'x' ? r : ((r & 0x3) | 0x8)
  return v.toString(16)
})
