import React, { useState } from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import {
  Box,
  CircularProgress,
  TextField,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import {
  RESTORE_CHECK,
  RESTORE_CONFIRM,
} from '../queriesAndMutations'

const RestorePasswordForm = () => {
  const { t } = useTranslation()
  const token = (new URLSearchParams(window.location.search)).get('token')
  const [values, setValues] = useState({
    token,
    password: '',
    passwordConfirm: '',
  })
  const { data: { restoreCheck: isTokenValid } = {}, loading: checking } = useQuery(RESTORE_CHECK, {
    variables: {
      input: {
        token,
      },
    },
  })
  const [restoreConfirm, restoreConfirmStatus] = useMutation(RESTORE_CONFIRM)
  const sendRestoreConfirmRequest = (event) => {
    event.preventDefault()
    restoreConfirm({
      variables: {
        input: {
          token,
          password: values.password,
          passwordConfirm: values.passwordConfirm,
        },
      },
    }).then(() => {
      setTimeout(() => {
        window.location.href = `${window.location.origin}/login`
      }, 2000)
    }).catch(() => {})
  }

  if (checking) {
    return (
      <Box display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    )
  }

  if (!isTokenValid) {
    return (
      <div className="text-error text-center">
        {t('Invalid link')}
      </div>
    )
  }

  if (restoreConfirmStatus?.data?.restoreConfirm) {
    return (
      <div className="text-success text-center">
        {t('Password reset successfully. Redirecting...')}
      </div>
    )
  }

  return (
    <>
      <form onSubmit={sendRestoreConfirmRequest} className="variants__list signinup__form">
        <div className="variants__item signinup__inner">
          <div className="details__fieldset">
            <TextField
              required
              className="investhub__field field"
              label={t('Password')}
              type="password"
              placeholder={t('Enter Password')}
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              value={values.email}
              onChange={({ target: { value } }) => setValues({ ...values, password: value })}
            />

            <TextField
              required
              className="investhub__field field"
              type="password"
              placeholder={t('Confirm password')}
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              value={values.passwordConfirm}
              onChange={({ target: { value } }) => setValues({ ...values, passwordConfirm: value })}
            />
          </div>

          <div className="details__btns signinup__btns">
            {restoreConfirmStatus.loading
              ? (
                <CircularProgress />
              ) : (
                <button
                  className="button details__button signinup__button"
                  type="submit"
                >
                  {t('Reset password')}
                </button>
              )}
          </div>
        </div>
      </form>
    </>
  )
}

export default RestorePasswordForm
