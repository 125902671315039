import React, { useState } from 'react'
import {
  Dialog,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import UserTenantTable from '../components/Dashboard/UserTenantTable'
import ModalInnerMaterialDialog from '../components/ModalInnerMaterialDialog'

const Dashboard = () => {
  const { t } = useTranslation()
  const [openSetupDialog, setOpenSetupDialog] = useState(false)

  return (
    <>
      <div className="section main">
        <div className="main__center center">
          {/* Main Head */}
          <div className="main__head">
            <h1 className="main__title h3">{t('Scaleable & Secure Biometric Access')}</h1>
            <p className="main__text">{t('From access-control for highly secured premises to blockchain authentication zk-SNARKs (Zero-Knowledge Succinct Non-Interactive Argument of Knowledge).')}</p>
            <button
              className="button-stroke main__button"
              type="button"
              onClick={() => {
                window.location = '/tenant-easy-setup'
              }}
            >
              {t('Add new tenant')}
            </button>
          </div>

          {/* Main Wrapper */}
          <div className="main__wrapper">
            <UserTenantTable />
          </div>
        </div>
      </div>

      <Dialog
        open={openSetupDialog}
        onClose={() => setOpenSetupDialog(false)}
        className="investhub-modal investhub-modal__m"
      >
        <ModalInnerMaterialDialog
          modalTitle="New Tenant"
          modalOnClickClose={() => setOpenSetupDialog(false)}
        >
          <div className="variants__list onboarding-best__list flex__row onboarding-best__list-v2">
            <div className="variants__item onboarding-best__item text-center">
              <div className="variants__preview">
                <img src="https://via.placeholder.com/650/000000/FFFFFF/?text=investhub" alt="Onboarding 1" />
              </div>

              <div className="variants__info onboarding-best__info">
                {t('Easy Setup')}
                <br />
                {t('(4 Steps - 60 seconds)')}
              </div>

              <a
                href="/tenant-easy-setup"
                type="button"
                className="button-stroke variants__button admin-settings-page__button"
              >
                {t('Choose')}
              </a>
            </div>

            <div className="variants__item onboarding-best__item text-center">
              <div className="variants__preview">
                <img src="https://via.placeholder.com/650/000000/FFFFFF/?text=investhub" alt="Onboarding 1" />
              </div>

              <div className="variants__info onboarding-best__info">
                {t('Professional Setup')}
                <br />
                {t('(12 Steps - up to 5 minutes)')}
              </div>

              <a
                href="/tenant-professional-setup"
                type="button"
                className="button-stroke variants__button admin-settings-page__button"
              >
                {t('Choose')}
              </a>
            </div>
          </div>
        </ModalInnerMaterialDialog>
      </Dialog>
    </>
  )
}

export default Dashboard
