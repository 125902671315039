import React, { useState } from "react"
import PropTypes from "prop-types"
import { useTranslation, Trans } from "react-i18next"
import {
  Dialog,
  RadioGroup,
  FormControlLabel,
  Radio,
  CircularProgress,
} from "@material-ui/core"
import { isFiat, formatPrintNumber } from "../../utils"
import InformationCard from "../InformationCard"
import ModalInnerMaterialDialog from "../ModalInnerMaterialDialog"
import SwitchCheckbox from "../SwitchCheckbox"

export const PAYMENT_TYPES = {
  BANK_TRANSFER: "BANK_TRANSFER",
  PAYPAL: "PAYPAL",
  CRYPTO: "CRYPTO",
  WALLET: "WALLET",
  VOLT: "VOLT",
  SENDWYRE: "SENDWYRE",
  CRYPTODOTCOM: "CRYPTO.COM",
  STRIPE: "STRIPE",
}

export const PROSPECTUS_OR_ISSUING = {
  PROSPECTUS: 'PROSPECTUS',
  ISSUING: 'ISSUING',
  TOKEN_SALE_AGREEMENT: 'TOKEN_SALE_AGREEMENT',
  TOKEN_OFFERING_MEMORANDUM: 'TOKEN_OFFERING_MEMORANDUM',
  SAFE: 'SAFE',
}

const PaymentMethodDialog = ({
  values,
  setValues,
  setOpenModalPayment,
  openModalPayment,
  tenant,
  disabledBtn,
  onPay,
  createTransactionStatus,
}) => {
  const { t } = useTranslation()
  const bankDetails = (tenant || {}).bank || {}
  const [confirms, setConfirms] = useState({
    agreeWithSubscriptionLink: false,
    legalDocumentAgreement: false,
    agreeWithConditions: false,
    confirmBeforeSubscription: false,
  })

  const handleCheckboxChange = (name) => (event) => {
    setConfirms({ ...confirms, [name]: event.target.checked })
  }

  const agreementMapping = {
    [PROSPECTUS_OR_ISSUING.TOKEN_SALE_AGREEMENT]: {
      text: t('Token sale agreement'),
      url: tenant && tenant.token_sale_agreement_url,
    },
    [PROSPECTUS_OR_ISSUING.PROSPECTUS]: {
      text: t('Prospectus'),
      url: tenant && tenant.prospectus_url,
    },
    [PROSPECTUS_OR_ISSUING.ISSUING]: {
      text: t('Issuing guidelines'),
      url: tenant && tenant.issuing_guidelines,
    },
    [PROSPECTUS_OR_ISSUING.TOKEN_OFFERING_MEMORANDUM]: {
      text: t('Token Offering Memorandum'),
      url: tenant && tenant.memorandum_url,
    },
    [PROSPECTUS_OR_ISSUING.SAFE]: {
      text: t('Token Safe'),
      url: tenant && tenant.token_safe_url,
    },
  }

  return (
    <Dialog
      className="investhub-modal investhub-modal__l"
      open={openModalPayment}
      onClose={() => setOpenModalPayment(false)}
    >
      <ModalInnerMaterialDialog
        modalOnClickClose={() => setOpenModalPayment(false)}
        modalTitle={t("Purchase Tokens and Make Payment")}
      >
        <div className="description__row flex__row flex__wrap outer">
          <div className="refund__content w-full">
            <p className="lead">
              {t("To receive")}
              <strong>
                {formatPrintNumber(values.token_amount)}
                {" "}
                {tenant && tenant.tokenSymbol}
              </strong>
              {" "}
              {t("you are required to pay")}
              {" "}
              <strong>
                {/* {publicSettings && publicSettings.enable_kyc_discount_level
                  ? calculateDiscount(
                      getTokenValueInAnything(values.currency, values.amount)
                    )
                      .toFixed(2)
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  : formatPrintNumber(
                      getTokenValueInAnything(values.currency, values.amount)
                    )}
                  {" "} */}
                {formatPrintNumber(values.amount)}
                {" "}
                {values.currency.toUpperCase()}
              </strong>
            </p>
            <p>
              {t("You can choose any of following payment methods to make your payment.")}
            </p>
            <p>
              {t("Your token balance will reflect after payment. Token transfers to your account will be confirmed by email.")}
            </p>
          </div>

          <div className="space space-32"> </div>
          <div className="settings__wrapper w-full">
            <div className="upload__fieldset details__fieldset mb-6">
              <div className="field w-full">
                <div className="field__label">
                  {t("Select payment method")}
                  :
                </div>

                <RadioGroup
                  row
                  aria-label="selectedTransferType"
                  name="selectedTransferType"
                  className="field__radio"
                  value={values.selectedTransferType}
                  onChange={(event) => (
                    setValues({
                      ...values,
                      selectedTransferType: event.target.value,
                    })
                  )}
                >
                  {isFiat(values.currency) ? (
                    <>
                      <FormControlLabel
                        value={PAYMENT_TYPES.BANK_TRANSFER}
                        label={t("Bank Transfer (4-5 days)")}
                        control={<Radio />}
                      />
                      <span className="space space__16"> </span>
                      <InformationCard color="card__information-primary">
                        <>
                          {t("Bank transfer to BANK_NAME with SEPA/SWIFT in BASE_CURRENCY (4-5 days) processing time. Fee: FEE_VALUE - minimum FEE_MINIMUM BASE_CURRENCY, maximum FEE_MAXIMUM BASE_CURRENCY",
                            {
                              nsSeparator: "|",
                              BANK_NAME: bankDetails.bank_name
                                ? bankDetails.bank_name
                                : "Bank Frick",
                              FEE_VALUE: bankDetails.fee ? bankDetails.fee : "",
                              FEE_MINIMUM: bankDetails.minimum_fee
                                ? bankDetails.minimum_fee
                                : "",
                              FEE_MAXIMUM: bankDetails.maximum_fee
                                ? bankDetails.maximum_fee
                                : "",
                              BASE_CURRENCY: tenant && tenant.base_currency
                                ? tenant.base_currency.toUpperCase()
                                : "",
                            })}

                          <br />
                          <br />

                          {t(
                            "When instructing your wire transfer please be sure to set fees to “our” as any fees charged by your bank or correspondent banks may reduce the net amount we receive and that we can credit towards your YOURTOKENNAME purchase.",
                            {
                              nsSeparator: "|",
                              YOURTOKENNAME: tenant && tenant.token_name
                                ? tenant.token_name
                                : "",
                            },
                          )}
                        </>
                      </InformationCard>

                      {/* {renderOptionPaymentWithVolt()} */}
                    </>
                  ) : (
                    <>
                      {/* <RadioTheme
                        nameGroup="pay-transfer"
                        value={PAYMENT_TYPES.CRYPTO}
                        onChange={handleSelectedTransferType()}
                        defaultChecked={values.selectedTransferType === PAYMENT_TYPES.CRYPTO}
                        radioText={
                          <>
                            { t('Pay With Crypto') }
                            <img
                              src={payCryptoManual}
                              className={classNames('pay-check-img crypto', styles.module__image_icon)}
                              alt="pay-logo"
                            />
                          </>
                        }
                      />
                      <span className="space space__16">{' '}</span>
                      <InformationCard color="card__information-primary">
                        { t('Crypto transfer in BASE_CURRENCY (20-60 seconds) processing time. Fee: FEE_VALUE - minimum FEE_MINIMUM BASE_CURRENCY, maximum FEE_MAXIMUM BASE_CURRENCY', {
                          nsSeparator: '|',
                          FEE_VALUE: publicSettings.bank.fee ? publicSettings.bank.fee : '',
                          FEE_MINIMUM: publicSettings.bank.minimum_fee ? publicSettings.bank.minimum_fee : '',
                          FEE_MAXIMUM: publicSettings.bank.maximum_fee ? publicSettings.bank.maximum_fee : '',
                          BASE_CURRENCY: publicSettings.base_currency ? publicSettings.base_currency.toUpperCase() : '',
                        })}
                      </InformationCard> */}
                    </>
                  )}
                  {/* {publicSettings && publicSettings.wallet_service === "sendwyre" && renderOptionPaymentWithSendWyre()}
                  {publicSettings && publicSettings.wallet_service === "wallet_engine" && renderOptionPaymentViaWallet()}
                  {publicSettings && publicSettings.enable_stripe && renderOptionPaymentWithStripe()}
                  {renderOptionPaymentWithCryptoDotCom()} */}
                </RadioGroup>
              </div>

              {tenant && tenant.enable_subscription_agreement_link === 'true' && (
                <div className="field w-full w-full__switch">
                  <SwitchCheckbox
                    isLeftSwitch
                    isRemoveLine
                    defaultChecked={confirms.agreeWithSubscriptionLink}
                    value="rememberMe"
                    onChange={handleCheckboxChange("agreeWithSubscriptionLink")}
                    switchText={(
                      <>
                        {tenant && !!tenant.subscription_agreement_link && (
                          <label htmlFor="token-purchase-agreement">
                            I acknowledge and agree to the
                            <a
                              href={tenant.subscription_agreement_link}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {" "}
                              Subscription Agreement
                            </a>
                          </label>
                        )}
                      </>
                    )}
                  />
                </div>
              )}

              {tenant && tenant.prospectus_or_issuing ===
                PROSPECTUS_OR_ISSUING.TOKEN_OFFERING_MEMORANDUM && (
                <div className="field w-full w-full__switch">
                  <SwitchCheckbox
                    isLeftSwitch
                    isRemoveLine
                    value="rememberMe"
                    defaultChecked={confirms.legalDocumentAgreement}
                    onChange={handleCheckboxChange("legalDocumentAgreement")}
                    switchText={(
                      <>
                        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                        <label>
                          {t("I hereby acknowledge receipt of the")}
                          {" "}
                          <a href={tenant.memorandum_url} target="_blank" rel="noreferrer">
                            {t("Token Offering Memorandum.")}
                          </a>
                        </label>
                      </>
                    )}
                  />
                </div>
              )}

              {/* Not german translation ready */}
              {tenant && tenant.prospectus_or_issuing ===
                PROSPECTUS_OR_ISSUING.PROSPECTUS && (
                <div className="field w-full w-full__switch">
                  <SwitchCheckbox
                    isLeftSwitch
                    isRemoveLine
                    value="rememberProspectus"
                    defaultChecked={confirms.legalDocumentAgreement}
                    onChange={handleCheckboxChange("legalDocumentAgreement")}
                    switchText={(
                      <>
                        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                        <label>
                          {t("I hereby acknowledge receipt of the")}
                          {" "}
                          <a href={tenant.prospectus_url} target="_blank" rel="noreferrer">
                            {t("Prospectus")}
                          </a>
                        </label>
                      </>
                    )}
                  />
                </div>
              )}

              {tenant && tenant.prospectus_or_issuing ===
                PROSPECTUS_OR_ISSUING.ISSUING && (
                <div className="field w-full w-full__switch">
                  <SwitchCheckbox
                    isLeftSwitch
                    isRemoveLine
                    value="rememberIssuing"
                    defaultChecked={confirms.legalDocumentAgreement}
                    onChange={handleCheckboxChange("legalDocumentAgreement")}
                    switchText={(
                      <>
                        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                        <label>
                          {t("I hereby acknowledge receipt of the")}
                          {" "}
                          <a href={tenant.issuing_guidelines} target="_blank" rel="noreferrer">
                            {t("Issuing guidelines.")}
                          </a>
                        </label>
                      </>
                    )}
                  />
                </div>
              )}

              {tenant && tenant.prospectus_or_issuing ===
                PROSPECTUS_OR_ISSUING.TOKEN_SALE_AGREEMENT && (
                <div className="field w-full w-full__switch">
                  <SwitchCheckbox
                    isLeftSwitch
                    isRemoveLine
                    defaultChecked={confirms.legalDocumentAgreement}
                    value="rememberMe"
                    onChange={handleCheckboxChange("legalDocumentAgreement")}
                    switchText={(
                      <>
                        {tenant && !!tenant.token_sale_agreement_url && (
                          <label htmlFor="token-purchase-agreement">
                            {t("I acknowledge and agree to the")}
                            {" "}
                            <a
                              href={tenant.token_sale_agreement_url}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {t("Token sale agreement")}
                            </a>
                          </label>
                        )}
                      </>
                    )}
                  />
                </div>
              )}

              {tenant && tenant.prospectus_or_issuing === PROSPECTUS_OR_ISSUING.SAFE && (
                <div className="field w-full w-full__switch">
                  <SwitchCheckbox
                    isLeftSwitch
                    isRemoveLine
                    defaultChecked={confirms.legalDocumentAgreement}
                    value="rememberMe"
                    onChange={handleCheckboxChange("legalDocumentAgreement")}
                    switchText={(
                      <>
                        {tenant && !!tenant.token_safe_url && (
                          <label htmlFor="token-purchase-agreement">
                            {t("I hereby acknowledge receipt of the")}
                            {" "}
                            <a href={tenant.token_safe_url} target="_blank" rel="noreferrer">
                              {t("Token Safe")}
                            </a>
                          </label>
                        )}
                      </>
                    )}
                  />
                </div>
              )}

              {/* Right of Withdrawal */}
              <div className="field w-full w-full__switch">
                <SwitchCheckbox
                  isLeftSwitch
                  isRemoveLine
                  value="right-of-withdrawal"
                  defaultChecked={confirms.agreeWithConditions}
                  onChange={handleCheckboxChange("agreeWithConditions")}
                  switchText={(
                    <>
                      <label htmlFor="token-purchase-agreement">
                        {t("I confirm that I have been informed about the")}
                        {" "}
                        <a href="/right-of-withdrawal" target="_blank" rel="noreferrer">
                          {t("Right of Withdrawal.")}
                        </a>
                      </label>
                    </>
                  )}
                />
              </div>

              {/* Confirm before subscription */}
              <div className="field w-full w-full__switch nospace__bottom">
                <SwitchCheckbox
                  isLeftSwitch
                  isRemoveLine
                  value="confirmBeforeSubscription"
                  defaultChecked={confirms.confirmBeforeSubscription}
                  onChange={handleCheckboxChange("confirmBeforeSubscription")}
                  switchText={tenant && (
                    <>
                      <label htmlFor="token-purchase-agreement">
                        <Trans
                          i18nKey={t(
                            "I hereby confirm that, before subscribing, I have read the TOKENOFFERINGMEMORANDUM in full and I agree with the content of the TOKENOFFERINGMEMORANDUM, and in particular that",
                            {
                              TOKENOFFERINGMEMORANDUM:
                                agreementMapping[tenant.prospectus_or_issuing]
                                  .text,
                            },
                          )}
                          t={t}
                          components={{
                            tokenOfferingLink: (
                              <a
                                href={
                                  agreementMapping[tenant.prospectus_or_issuing].url
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {" "}
                              </a>
                            ),
                          }}
                        />
                        {": "}
                      </label>
                    </>
                  )}
                />
              </div>

              <div className="field">
                <div className="space space-24"> </div>
                <InformationCard color="card__information-primary" title="">
                  <ul>
                    <li>{t("I accept the subscription applications.")}</li>
                    <li>
                      {t("I have duly noted the sales restrictions listed and hereby affirm that these restrictions will be complied with.")}
                    </li>
                    <li>
                      {t("I have taken note of the risks and their possible effects and hereby accept them.")}
                    </li>
                  </ul>
                </InformationCard>
              </div>
            </div>

            <div className="space space-24"> </div>

            <div className="settings__button settings__buttons d__flex flex__row flex__wrap flex__justify_between nospace__top">
              <button
                disabled={
                  confirms.legalDocumentAgreement === false ||
                  confirms.agreeWithConditions === false ||
                  confirms.confirmBeforeSubscription === false ||
                  disabledBtn
                }
                onClick={onPay}
                type="button"
                className="button"
              >
                {t("Buy Tokens Now")}
              </button>
            </div>

            {createTransactionStatus.loading ? <CircularProgress /> : ""}
          </div>
        </div>
      </ModalInnerMaterialDialog>
    </Dialog>
  )
}

PaymentMethodDialog.propTypes = {
  values: PropTypes.object,
  setValues: PropTypes.func,
  setOpenModalPayment: PropTypes.func,
  openModalPayment: PropTypes.bool,
  tenant: PropTypes.object,
  disabledBtn: PropTypes.bool,
  onPay: PropTypes.func,
  createTransactionStatus: PropTypes.object,
}

export default PaymentMethodDialog
