import React from 'react'
import { NavLink } from 'react-router-dom'
import LayersIcon from '@material-ui/icons/Layers'
import { useTranslation } from 'react-i18next'
import ControlAdminBreadcrumbs from '../components/ControlAdminBreadcrumbs'

const AdminPage = () => {
  const { t } = useTranslation()
  return (
    <>
      <ControlAdminBreadcrumbs>
        <li className="breadcrumbs__item">
          <NavLink to="/admin/dashboard" className="breadcrumbs__link">{t('Admin Dashboard')}</NavLink>
        </li>
        <li className="breadcrumbs__item">
          {t('Choose Project')}
        </li>
      </ControlAdminBreadcrumbs>

      <div className="section-pt80 variants admin-settings">
        <div className="variants__center center admin-settings-page">
          <div className="variants__top admin-settings-page__top">
            <div className="variants__title h2 admin-settings-page__title">{t('Choose Project')}</div>
          </div>

          <div className="variants__list admin-settings-page__list">
            <div className="variants__item admin-settings-page__item">
              <div className="variants__preview admin-settings-page__preview">
                <LayersIcon />
              </div>

              <NavLink to="/admin/pages/projects" className="button-stroke variants__button admin-settings-page__button">
                {t('Projects')}
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AdminPage
