import React from 'react'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ControlBreadcrumbs from '../components/ControlBreadcrumbs'

const InvestorOnBoarding = () => {
  const { t } = useTranslation()

  return (
    <>
      <ControlBreadcrumbs>
        <li className="breadcrumbs__item">
          <NavLink to="/" className="breadcrumbs__link">{t('Home')}</NavLink>
        </li>
        <li className="breadcrumbs__item">
          <NavLink to="/" className="breadcrumbs__link">{t('Explore')}</NavLink>
        </li>
        <li className="breadcrumbs__item">
          {t('Create')}
        </li>
      </ControlBreadcrumbs>

      <div className="section-pt80 faq investor-onboarding">
        <div className="discover__center investor-onboarding__center center">
          <div className="faq__top investor-onboarding__top h2">
            {t('No Investor Onboarding')}
          </div>
        </div>
      </div>
    </>
  )
}

export default InvestorOnBoarding
