import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import Theme from './components/Theme'
import SiteWrap from './components/PageWrapper'
import PageWrapper from './components/Page'
import PageWrapperProject from './components/PageProject'
import PageDashboard from './components/PageDashboard'
import PageAdminDashboard from './components/PageAdminDashboard'
import ProtectedZone from './components/routes/ProtectedZone'
import AuthRoute from './components/routes/AuthRoute'
import NotFound from './pages/NotFound'
import AuthNotFound from './pages/AuthNotFound'
import { USER_RIGHT_TYPES } from './constants/user'
// import Home from './pages/Home'
import Dashboard from './pages/Dashboard'
import Pricing from './pages/Pricing'
import Login from './pages/Login'
import SignUp from './pages/SignUp'
import RestorePassword from './pages/RestorePassword'
import RestorePasswordConfirm from './pages/RestorePasswordConfirm'
import Policy from './pages/Policy'
import Tos from './pages/TOS'
import SignUpConfirm from './pages/SignUpConfirm'
import CheckoutStatus from './pages/CheckoutStatus'
import CheckoutStatusDummy from './pages/CheckoutStatusDummy'
import AdminDashboard from './pages/AdminDashboard'
import AdminTenant from './pages/AdminTenant'
import AdminSetting from './pages/AdminSetting'
import AdminPrice from './pages/AdminPrice'
import AdminUser from './pages/AdminUser'
import ConnectWallet from './pages/ConnectWallet'
import OnBoarding from './pages/Onboarding'
import OnBoardingBest from './pages/OnBoardingBest'
import InvestorOnBoarding from './pages/InvestorOnBoarding'
import Explore from './pages/Explore'
import AdminBlacklist from './pages/AdminBlacklist'
import AdminStorage from './pages/AdminStorage'
import TenantSubscribe from './pages/TenantSubscribe'
import TenantSetupDns from './pages/TenantSetupDns'
import AdminRedirect from './pages/AdminRedirect'
import AdminPage from './pages/AdminPage'
import AdminProjectPage from './pages/AdminProjectPage'
import AdminCreateProjectPage from './pages/AdminCreateProjectPage'
import AdminEditProjectPage from './pages/AdminEditProjectPage'
import Project from './pages/Project'
import SsoRequest from './pages/SsoRequest'
import TenantDetails from './pages/TenantDetails'
// import TenantDetailsDummy from './pages/TenantDetailsDummy'
// import UserKycInfo from './pages/UserKycInfo'
import AdminTenantDetails from './pages/AdminTenantDetails'
import TenantUpgrade from './pages/TenantUpgrade'
import TenantRedirect from './pages/TenantRedirect'
import TenantEasySetup from './pages/TenantEasySetup'
import TenantProfessionalSetup from './pages/TenantProfessionalSetup'
import ProfileName from './pages/ProfileName'
import Learn from './pages/Learn'
import LearnDetail from './pages/LearnDetail'
import MyProfile from './pages/MyProfile'
import SsoAuth from './pages/SsoAuth'
import PersonalisedOpportunities from './pages/PersonalisedOpportunities'
import Issuer from './pages/Issuer'
// XID Page Wrapper
import XIDPageSimpleWrapper from './components/XIDPageSimpleWrapper/XIDPageSimpleWrapper'

export default () => (
  <Theme>
    <Switch>
      <Route
        exact
        path="/issuer"
        render={() => (
          <SiteWrap>
            <Issuer />
          </SiteWrap>
        )}
      />

      <Route
        exact
        path="/checkout-status-dummy"
        render={() => (
          <PageWrapper>
            <CheckoutStatusDummy />
          </PageWrapper>
        )}
      />

      <Route
        exact
        path="/learn"
        render={() => (
          <SiteWrap
            classWrapper="outer v2"
          >
            <Learn />
          </SiteWrap>
        )}
      />

      <Route
        exact
        path="/learn/:learnId"
        render={({ match }) => (
          <PageWrapper>
            <LearnDetail match={match} />
          </PageWrapper>
        )}
      />

      <Route
        exact
        path="/pricing"
        render={() => (
          <SiteWrap
            classWrapper="outer v2 pricing"
          >
            <Pricing />
          </SiteWrap>
        )}
      />

      <AuthRoute
        exact
        path="/login"
        render={() => (
          <XIDPageSimpleWrapper>
            <Login />
          </XIDPageSimpleWrapper>
        )}
      />

      <AuthRoute
        exact
        path="/signup"
        render={() => (
          <XIDPageSimpleWrapper>
            <SignUp />
          </XIDPageSimpleWrapper>
        )}
      />

      <AuthRoute
        exact
        path="/signup/confirm"
        render={() => (
          <XIDPageSimpleWrapper>
            <SignUpConfirm />
          </XIDPageSimpleWrapper>
        )}
      />

      <AuthRoute
        exact
        path="/restore-password"
        render={() => (
          <SiteWrap
            classWrapper="outer v2"
          >
            <RestorePassword />
          </SiteWrap>
        )}
      />
      <AuthRoute
        exact
        path="/restore-password/confirm"
        render={() => (
          <PageWrapper>
            <RestorePasswordConfirm />
          </PageWrapper>
        )}
      />
      <Route
        exact
        path="/privacy-policy"
        render={() => (
          <PageWrapper>
            <Policy />
          </PageWrapper>
        )}
      />
      <Route
        exact
        path="/tos"
        render={() => (
          <PageWrapper>
            <Tos />
          </PageWrapper>
        )}
      />
      <Route
        exact
        path="/not-found"
        render={() => (
          <PageWrapper>
            <NotFound />
          </PageWrapper>
        )}
      />
      <Route
        exact
        path="/connect-wallet"
        render={() => (
          <PageWrapper>
            <ConnectWallet />
          </PageWrapper>
        )}
      />
      <Route
        exact
        path="/onboarding"
        render={() => (
          <PageWrapper>
            <OnBoarding />
          </PageWrapper>
        )}
      />
      <Route
        exact
        path="/explore"
        render={() => (
          <SiteWrap
            classWrapper="outer v2"
          >
            <Explore />
          </SiteWrap>
        )}
      />
      <Route
        exact
        path="/onboarding-best"
        render={() => (
          <PageWrapper>
            <OnBoardingBest />
          </PageWrapper>
        )}
      />
      <Route
        exact
        path="/investor-onboarding"
        render={() => (
          <PageWrapper>
            <InvestorOnBoarding />
          </PageWrapper>
        )}
      />
      <Route
        exact
        path="/sso/auth"
        render={() => (
          <PageWrapper>
            <SsoAuth />
          </PageWrapper>
        )}
      />
      <Route
        exact
        path="/projects/:projectUri"
        render={({ match }) => (
          <PageWrapperProject>
            <Project match={match} />
          </PageWrapperProject>
        )}
      />
      <Route
        exact
        path="/sso/request"
        render={() => (
          <PageWrapperProject>
            <SsoRequest />
          </PageWrapperProject>
        )}
      />
      <Route
        exact
        path="/redirect-to-tenant"
        render={() => (
          <PageWrapperProject>
            <TenantRedirect />
          </PageWrapperProject>
        )}
      />

      <ProtectedZone
        path={[
          '/',
          '/dashboard',
          '/dashboard/*',
          '/my-profile',
          '/my-account/kyc',
          '/payment/stripe/:status',
          '/tenant-easy-setup',
          '/tenant-professional-setup',
          '/profile-name',
          '/personalised_opportunities',
        ]}
        minRights={USER_RIGHT_TYPES.COMMON}
      >
        <Route
          exact
          path="/"
          render={() => (
            <Redirect to="/dashboard" />
          )}
        />

        <Route
          exact
          path="/dashboard"
          render={() => (
            <PageDashboard>
              <Dashboard />
            </PageDashboard>
          )}
        />

        <Route
          exact
          path="/my-profile"
          render={() => (
            <PageDashboard>
              <MyProfile />
            </PageDashboard>
          )}
        />

        <Route
          exact
          path="/profile-name"
          render={() => (
            <PageDashboard>
              <ProfileName />
            </PageDashboard>
          )}
        />

        <Route
          exact
          path="/tenant-easy-setup"
          render={() => (
            <PageDashboard>
              <TenantEasySetup />
            </PageDashboard>
          )}
        />

        <Route
          exact
          path="/tenant-professional-setup"
          render={() => (
            <PageDashboard>
              <TenantProfessionalSetup />
            </PageDashboard>
          )}
        />

        <Route
          exact
          path="/dashboard/tenants/:tenantId"
          render={({ match }) => (
            <PageDashboard>
              <TenantDetails match={match} />
            </PageDashboard>
          )}
        />
        <Route
          exact
          path="/dashboard/tenants/:tenantId/subscribe"
          render={({ match }) => (
            <PageDashboard>
              <TenantSubscribe match={match} />
            </PageDashboard>
          )}
        />
        <Route
          exact
          path="/dashboard/tenants/:tenantId/setup-dns"
          render={({ match }) => (
            <PageDashboard>
              <TenantSetupDns match={match} />
            </PageDashboard>
          )}
        />

        <Route
          exact
          path="/dashboard/tenants/:tenantId/upgrade"
          render={({ match }) => (
            <PageDashboard>
              <TenantUpgrade match={match} />
            </PageDashboard>
          )}
        />
        <Route
          exact
          path="/payment/stripe/:status"
          render={({ match }) => (
            <XIDPageSimpleWrapper>
              <CheckoutStatus match={match} />
            </XIDPageSimpleWrapper>
          )}
        />
        <Route
          exact
          path="/my-account/kyc"
          render={() => (
            // <PageDashboard>
            //   <UserKycInfo />
            // </PageDashboard>
            <Redirect to="/dashboard" />
          )}
        />
        <Route
          exact
          path="/personalised_opportunities"
          render={() => (
            <PageDashboard>
              <PersonalisedOpportunities />
            </PageDashboard>
          )}
        />
        <Route
          exact
          path="*"
          render={() => (
            <PageDashboard>
              <AuthNotFound />
            </PageDashboard>
          )}
        />
      </ProtectedZone>

      <ProtectedZone path={['/admin', '/admin/*']} minRights={USER_RIGHT_TYPES.ADMIN}>
        <Route exact path="/admin" component={AdminRedirect} />
        <Route
          exact
          path="/admin/dashboard"
          render={() => (
            <PageAdminDashboard>
              <AdminDashboard />
            </PageAdminDashboard>
          )}
        />

        <Route
          exact
          path="/admin/tenants"
          render={() => (
            <PageAdminDashboard>
              <AdminTenant />
            </PageAdminDashboard>
          )}
        />

        <Route
          exact
          path="/admin/tenants/:tenantId"
          render={({ match }) => (
            <PageAdminDashboard>
              <AdminTenantDetails match={match} />
            </PageAdminDashboard>
          )}
        />

        <Route
          exact
          path="/admin/pages"
          render={() => (
            <PageAdminDashboard>
              <AdminPage />
            </PageAdminDashboard>
          )}
        />

        <Route
          exact
          path="/admin/pages/projects"
          render={() => (
            <PageAdminDashboard>
              <AdminProjectPage />
            </PageAdminDashboard>
          )}
        />
        <Route
          exact
          path="/admin/pages/projects/create"
          render={() => (
            <PageAdminDashboard>
              <AdminCreateProjectPage />
            </PageAdminDashboard>
          )}
        />
        <Route exact path="/admin/pages/projects/:projectId/edit" component={AdminEditProjectPage} />

        <Route
          exact
          path="/admin/settings"
          render={() => (
            <PageAdminDashboard>
              <AdminSetting />
            </PageAdminDashboard>
          )}
        />
        <Route
          exact
          path="/admin/users"
          render={() => (
            <PageAdminDashboard>
              <AdminUser />
            </PageAdminDashboard>
          )}
        />

        <Route
          exact
          path="/admin/prices"
          render={() => (
            <PageAdminDashboard>
              <AdminPrice />
            </PageAdminDashboard>
          )}
        />

        <Route
          exact
          path="/admin/blacklist"
          render={() => (
            <PageAdminDashboard>
              <AdminBlacklist />
            </PageAdminDashboard>
          )}

        />
        <Route
          exact
          path="/admin/storage"
          render={() => (
            <PageAdminDashboard>
              <AdminStorage />
            </PageAdminDashboard>
          )}
        />

        <Route
          exact
          path="*"
          render={() => (
            <PageDashboard>
              <AuthNotFound />
            </PageDashboard>
          )}
        />
      </ProtectedZone>

      <Route
        exact
        path="*"
        render={() => (
          <PageDashboard>
            <NotFound />
          </PageDashboard>
        )}
      />
    </Switch>
  </Theme>
)
