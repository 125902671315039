import gql from 'graphql-tag'

export const GET_ADMIN_USER = gql`
  query getAdminUser($page: Int!, $rowsPerPage: Int!){
    getAdminUser(page: $page, rowsPerPage: $rowsPerPage) {
      users {
        id
        email
        isActive
        createdAt
      }
      count
    }
  }
`;
