import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const LearnCard = ({
  title,
  datePublished,
  idDetail,
  idArticle,
}) => {
  const { t } = useTranslation()

  return (
    <div className="variants__item investhub-learn__item" id={`learn-${idArticle}`}>
      <h3 className="user__name investhub-blog__title">
        {title}
      </h3>
      <span className="investhub-blog__date">
        {moment(datePublished).format('ll')}
      </span>

      <Link
        to={`/learn/${idDetail}`}
        className="button-stroke variants__button"
      >
        {t('View more detail')}
      </Link>
    </div>
  )
}

LearnCard.propTypes = {
  title: PropTypes.string.isRequired,
  datePublished: PropTypes.string,
  idDetail: PropTypes.string,
  idArticle: PropTypes.number,
}

export default LearnCard
