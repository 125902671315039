// eslint-disable-next-line import/prefer-default-export
export const USER_RIGHT_TYPES = {
  COMMON: 'COMMON',
  COMPLIANCE_OFFICER: 'COMPLIANCE_OFFICER',
  ADMIN: 'ADMIN',
  GENERAL_ADMIN: 'GENERAL_ADMIN',
}

export const USER_RIGHTS_WEIGHT = {
  [USER_RIGHT_TYPES.COMMON]: 1,
  [USER_RIGHT_TYPES.COMPLIANCE_OFFICER]: 2,
  [USER_RIGHT_TYPES.ADMIN]: 3,
  [USER_RIGHT_TYPES.GENERAL_ADMIN]: 4,
}

export const USER_STATUS_TYPES = {
  DELETED: 'DELETED',
  BLOCKED: 'BLOCKED',
  ACTIVE: 'ACTIVE',
}

export const USER_KYC_STATUS_TYPES = {
  NOT_SUBMITTED: 'NOT_SUBMITTED',
  PENDING: 'PENDING',
  REJECTED: 'REJECTED',
  PASSED: 'PASSED',
}

export const USER_VIDEO_IDENT_STATUS = {
  NOT_NEEDED: 'NOT_NEEDED',
  PENDING: 'PENDING',
  PASSED: 'PASSED',
  DENIED: 'DENIED',
}
