import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
// import SiteHeader from './SiteHeader/SiteHeader'
// import SiteFooter from './SiteFooter/SiteFooter'
import '../styles/investhub/styles.scss'
import '../styles/v2/style.scss'

const PageWrapper = ({
  classWrapper,
  children,
}) => {
  return (
    <div className={classNames(
      'pagewrap',
      classWrapper,
    )}
    >
      {/* <SiteHeader /> */}

      <div className="content" id="content">
        {children}
      </div>

      {/* <SiteFooter /> */}
    </div>
  )
}

PageWrapper.propTypes = {
  classWrapper: PropTypes.string,
}

export default PageWrapper
