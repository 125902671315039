import React from 'react'
import PropTypes from 'prop-types'

import Box from '@material-ui/core/Box'

const ValidationMessage = ({
  error,
}) => (error
  ? (
    <Box>
      <p className="text-error error-message">{ error }</p>
    </Box>
  )
  : null
)

ValidationMessage.propTypes = {
  error: PropTypes.string.isRequired,
}

export default ValidationMessage
