import React from 'react'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const CheckoutStatusDummy = () => {
  const { t } = useTranslation()

  return (
    <div className="section result successfully-subscribed">
      <div className="result__center center">
        <div className="result__wrap">
          {/* Credit to https://codepen.io/Hellwinkel/pen/KKaNNKb */}
          <div className="successfully-subscribed__icon">
            <div className="successfully-subscribed__wrapper">
              <div className="successfully-subscribed__background">
                <svg viewBox="0 0 65 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7 25L27.3077 44L58.5 7" stroke="white" strokeWidth="13" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>
            </div>
            <div className="successfully-subscribed__shadow">{' '}</div>
          </div>
          <div className="result__title h2">
            {t('You\'ve been successfully subscribed for your tenant')}
          </div>

          <NavLink to="#" className="button successfully-subscribed__button">
            {t('Back to dashboard')}
          </NavLink>
        </div>
      </div>
    </div>
  )
}

export default CheckoutStatusDummy
