import React from 'react'
import { NavLink } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import { useTranslation } from 'react-i18next'
import ControlDashboardBreadcrumbs from '../components/ControlDashboardBreadcrumbs'
import Icon from '../components/Icon'

const menuItem = [
  {
    icon: 'user',
    text: 'Users',
    path: '/admin/users',
  },
  {
    icon: 'store',
    text: 'Tenants',
    path: '/admin/tenants',
  },
  {
    icon: 'report',
    text: 'Project pages',
    path: '/admin/pages/projects',
  },
  {
    icon: 'coin',
    text: 'Prices',
    path: '/admin/prices',
  },
  {
    icon: 'circle-close',
    text: 'Global blacklist',
    path: '/admin/blacklist',
  },
  {
    icon: 'setting',
    text: 'System settings',
    path: '/admin/settings',
  },
  {
    icon: 'download',
    text: 'Storage',
    path: '/admin/storage',
  },
]

const AdminDashboard = () => {
  const { t } = useTranslation()

  return (
    <>
      <ControlDashboardBreadcrumbs>
        <li className="breadcrumbs__item">
          {t('Admin Dashboard')}
        </li>
      </ControlDashboardBreadcrumbs>

      <div className="section-pt80 faq admin">
        <div className="faq__center admin__center center">
          <div className="faq__top admin__top">
            <div className="faq__title admin__title h2">{t('Welcome to Dashboard')}</div>
          </div>

          <Grid container spacing={3}>
            {menuItem.map((item) => (
              <Grid item xs={12} sm={6} md={4}>
                <NavLink to={item.path} className="button-stroke button-tile">
                  <span className="tile__img">
                    <Icon name={item.icon} size={48} />
                  </span>
                  <span className="tile__text">{t(item.text)}</span>
                </NavLink>
              </Grid>
            ))}
          </Grid>
        </div>
      </div>
    </>
  )
}

export default AdminDashboard
