import React from 'react'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ControlBreadcrumbs from '../components/ControlBreadcrumbs'

const OnBoardingBest = () => {
  const { t } = useTranslation()

  return (
    <>
      <ControlBreadcrumbs>
        <li className="breadcrumbs__item">
          <NavLink to="/" className="breadcrumbs__link">{t('Home')}</NavLink>
        </li>
        <li className="breadcrumbs__item">
          {t('Create')}
        </li>
      </ControlBreadcrumbs>

      <div className="section-pt80 variants onboarding-best">
        <div className="variants__center center onboarding-best__center">
          <div className="variants__top onboarding-best__top">
            <div className="variants__title h2 onboarding-best__title">{t('What type describes your project best?')}</div>
          </div>

          <div className="variants__list onboarding-best__list flex__row">
            <div className="variants__item onboarding-best__item">
              <div className="variants__preview">
                <img src="https://via.placeholder.com/650/000000/FFFFFF/?text=investhub" alt="Onboarding 1" />
              </div>

              <div className="variants__info onboarding-best__info">
                {t('You are offering equity and would require the investor to signup and subscribe to the offering.')}
              </div>

              <a href="/" className="button-stroke variants__button onboarding-best__button">{t('Choose')}</a>
            </div>

            <div className="variants__item onboarding-best__item">
              <div className="variants__preview">
                <img src="https://via.placeholder.com/650/000000/FFFFFF/?text=investhub" alt="Onboarding 1" />
              </div>

              <div className="variants__info onboarding-best__info">
                {t('You are offering equity and would require the investor to signup and subscribe to the offering.')}
              </div>

              <a href="/" className="button-stroke variants__button onboarding-best__button">{t('Choose')}</a>
            </div>

            <div className="variants__item onboarding-best__item">
              <div className="variants__preview">
                <img src="https://via.placeholder.com/650/000000/FFFFFF/?text=investhub" alt="Onboarding 1" />
              </div>

              <div className="variants__info onboarding-best__info">
                {t('You are offering equity and would require the investor to signup and subscribe to the offering.')}
              </div>

              <a href="/" className="button-stroke variants__button onboarding-best__button">{t('Choose')}</a>
            </div>

            <div className="variants__item onboarding-best__item">
              <div className="variants__preview">
                <img src="https://via.placeholder.com/650/000000/FFFFFF/?text=investhub" alt="Onboarding 1" />
              </div>

              <div className="variants__info onboarding-best__info">
                {t('You are offering equity and would require the investor to signup and subscribe to the offering.')}
              </div>

              <a href="/" className="button-stroke variants__button onboarding-best__button">{t('Choose')}</a>
            </div>

          </div>
        </div>
      </div>
    </>
  )
}

export default OnBoardingBest
