import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { Tabs, Tab } from '@material-ui/core'

import ShortenText from '../../ShortenText'

import styles from './styles.module.scss'

function TabPanel(props) {
  const {
    children,
    value,
    index,
    ...other
  } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`managemonitorregister-tabpanel-${index}`}
      aria-labelledby={`managemonitorregister-tab-${index}`}
      {...other}
    >
      {value === index && (
        <>
          {children}
        </>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

function a11yProps(index) {
  return {
    id: `managemonitorregister-tab-${index}`,
    'aria-controls': `managemonitorregister-tabpanel-${index}`,
  }
}

const ManageMonitorRegister = () => {
  const { t } = useTranslation()

  const [tabValue, setTabValue] = useState(0)

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue)
  }

  const tabContentManagement = () => {
    return (
      <>
        <h2 className={classNames("h3 text-center", styles.managemonitorregister__title)}>
          {t('Compact overview')}
        </h2>

        <div className={styles.managemonitorregister__text}>
          {t('We learned from onboarding over 3000 Investors with smaller tickets, how to enable the Issuer to handle it as easy as possible.')}
        </div>

        <div className={styles.table}>
          <div className={styles.table__row}>
            <div className={styles.table__col}>
              <div className={styles.table__header__title}>
                {t('Email')}
              </div>
            </div>

            <div className={styles.table__col}>
              <div className={styles.table__header__title}>
                {t('KYC ID')}
              </div>
            </div>

            <div className={styles.table__col}>
              <div className={styles.table__header__title}>
                {t('Receiving Address')}
              </div>
            </div>

            <div className={styles.table__col}>
              <div className={styles.table__header__title}>
                {t('Sending Address')}
              </div>
            </div>

            <div className={styles.table__col}>
              <div className={styles.table__header__title}>
                {t('Contribution')}
              </div>
            </div>

            <div className={styles.table__col}>
              <div className={styles.table__header__title}>
                {t('Source')}
              </div>
            </div>

            <div className={styles.table__col}>
              <div className={styles.table__header__title}>
                {t('CHF amount')}
              </div>
            </div>

            <div className={styles.table__col}>
              <div className={styles.table__header__title}>
                {t('Submitted At')}
              </div>
            </div>

            <div className={styles.table__col}>
              <div className={styles.table__header__title}>
                {t('Status')}
              </div>
            </div>

            <div className={styles.table__col}>
              <div className={styles.table__header__title}>
                {t('Liveness Check (Onfido)')}
              </div>
            </div>

            <div className={styles.table__col}>
              <div className={styles.table__header__title}>
                {t('Liveness Check (IDnow)')}
              </div>
            </div>
          </div>

          <div className={styles.table__row}>
            <div className={styles.table__col}>
              <div className={styles.table__label}>
                {t('Email')}
              </div>
              <ShortenText text={t('rizwanaritonang@gmail.com')} classInfo={styles.shorten} />
            </div>

            <div className={styles.table__col}>
              <div className={styles.table__label}>
                {t('KYC ID')}
              </div>

              {t('233')}
            </div>

            <div className={styles.table__col}>
              <div className={styles.table__label}>
                {t('Receiving Address')}
              </div>
              <ShortenText text={t('0xd1424124124rkashasdfa1231asfasf')} />
              <br />
              <small>{t('(Tangany)')}</small>
            </div>

            <div className={styles.table__col}>
              <div className={styles.table__label}>
                {t('Sending Address')}
              </div>
              <ShortenText text={t('0xd1424124124rkashasdfa1231asfasf')} />
            </div>

            <div className={styles.table__col}>
              <div className={styles.table__label}>
                {t('Contribution')}
              </div>
              {t('USDC 500')}
            </div>

            <div className={styles.table__col}>
              <div className={styles.table__label}>
                {t('Source')}
              </div>
              {t('Organic')}
            </div>

            <div className={styles.table__col}>
              <div className={styles.table__label}>
                {t('CHF amount')}
              </div>
              {t('500')}
            </div>

            <div className={classNames(styles.table__col, styles.table__col_date)}>
              <div className={styles.table__label}>
                {t('Submitted At')}
              </div>
              {t('Fri Sep 23 2022')}
              <br />
              {t('11:35:35 am')}
            </div>

            <div className={styles.table__col}>
              <div className={styles.table__label}>
                {t('Status')}
              </div>
              <div className={classNames(styles.table__status, styles.status__yellow)}>
                {t('Pending')}
              </div>
            </div>

            <div className={styles.table__col}>
              <div className={styles.table__label}>
                {t('Liveness Check (Onfido)')}
              </div>
              <div className={classNames(styles.table__status, styles.status__white)}>
                {t('Not Submitted')}
              </div>
            </div>

            <div className={styles.table__col}>
              <div className={styles.table__label}>
                {t('Liveness Check (IDnow)')}
              </div>
              <div className={classNames(styles.table__status, styles.status__white)}>
                {t('Not Required')}
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  const tabContentMonitoring = () => {
    return (
      <>
        <h2 className={classNames("h3 text-center", styles.managemonitorregister__title)}>
          {t('Monitoring overview')}
        </h2>
      </>
    )
  }

  const tabContentRegister = () => {
    return (
      <>
        <h2 className={classNames("h3 text-center", styles.managemonitorregister__title)}>
          {t('Register overview')}
        </h2>
      </>
    )
  }

  return (
    <div className={classNames("section", styles.managemonitorregister)}>
      <div className={classNames("center", styles.managemonitorregister__center)}>
        <Tabs
          aria-label="managemonitorregister-tab"
          className={classNames('investhub-tab', styles.managemonitorregister__tab_header)}
          centered
          value={tabValue}
          onChange={handleTabChange}
        >
          <Tab label={t('Management')} {...a11yProps(0)} />
          <Tab label={t('Monitoring')} {...a11yProps(1)} />
          <Tab label={t('Token Register')} {...a11yProps(2)} />
        </Tabs>

        <TabPanel value={tabValue} index={0}>
          {tabContentManagement()}
        </TabPanel>

        <TabPanel value={tabValue} index={1}>
          {tabContentMonitoring()}
        </TabPanel>

        <TabPanel value={tabValue} index={2}>
          {tabContentRegister()}
        </TabPanel>
      </div>
    </div>
  )
}

export default ManageMonitorRegister
