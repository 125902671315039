export const supportedLanguages = {
  en: {
    code: 'en',
    label: 'English',
    lcid: 'en_GB',
    flag: 'https://flagcdn.com/w80/gb.png',
    flagIcon: 'https://flagcdn.com/w40/gb.png',
  },
  de: {
    code: 'de',
    label: 'German',
    lcid: 'de_DE',
    flag: 'https://flagcdn.com/w80/de.png',
    flagIcon: 'https://flagcdn.com/w40/de.png',
  },
  it: {
    code: 'it',
    label: 'Italy',
    lcid: 'it_IT',
    flag: 'https://flagcdn.com/w80/it.png',
    flagIcon: 'https://flagcdn.com/w40/it.png',
  },
}

export const getLocale = () => {
  const locale = localStorage.getItem('lang') || 'en'

  return supportedLanguages[locale] ? locale : 'en'
}

export const setLocale = (lang) => localStorage.setItem('lang', lang)

export const getCurrentFlagIcon = () => supportedLanguages[getLocale()].flagIcon
export const getCurrentLang = () => supportedLanguages[getLocale()].label
export const getCurrentLocalLcid = () => supportedLanguages[getLocale()].lcid

export default {
  supportedLanguages,
  getLocale,
  setLocale,
  getCurrentFlagIcon,
  getCurrentLang,
}
