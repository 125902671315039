import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import qs from 'qs'
import moment from 'moment'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useTranslation } from 'react-i18next'
import ReactHtmlParser from 'react-html-parser'
import MarkDownIt from 'markdown-it'

import ArticleAPI from '../config/ArticleAPI'
import { toaster } from '../utils'

const md = MarkDownIt({ html: true })

const LearnDetail = ({ match }) => {
  const { t } = useTranslation()
  const articleSlug = match.params.learnId
  const [article, setArticle] = useState(null)
  const [hasError, setHasError] = useState(false)
  const generateRandom = () => ((Array(16).fill(0).map(() => (Math.floor(Math.random() * 9)))).join(''))

  useEffect(() => {
    ArticleAPI.getBySlug(
      articleSlug,
      qs.stringify({
        populate: {
          InformationViewColumn: {
            populate: '*',
          },
          InformationViewGrid: {
            populate: '*',
          },
          InformationMainContent: '*',
        },
      }),
    ).then(({ data: { data } } = { data: {} }) => {
      if (data) {
        setArticle({
          title: data.attributes.Title,
          caption: data.attributes.Caption,
          excerpt: data.attributes.Excerpt,
          viewColumn: data.attributes.InformationViewColumn.map((column) => ({
            title: column.Title,
            content: column.Content,
            isImageInLeft: column.ImageInLeft,
            image: column.Image.data.attributes.url,
          })),
          viewGrid: data.attributes.InformationViewGrid.map((column) => ({
            title: column.Title,
            content: column.Content,
            image: column.Image.data.attributes.url,
          })),
          mainContent: data.attributes.InformationMainContent.Content,
          publishedAt: data.publishedAt,
        })
      } else {
        setHasError(true)
        toaster.error('Article not found')
      }
    }).catch((error) => {
      setHasError(true)
      toaster.error(error.message)
    })
  }, [articleSlug])

  if (hasError) {
    return <></>
  }

  if (!article) {
    return <CircularProgress />
  }

  return (
    <>
      <div className="section main learn">

        <div className="main__center center learn__center">
          <div className="main__head learn__head">
            <div className="main__stage learn__stage">
              {t('Article')}
            </div>
            <h1 className="main__title h3 learn__title">
              {t(article.title)}
            </h1>
            <div className="details__info learn__info">
              {t(article.caption)}
            </div>
          </div>
        </div>

        <div className="main__content learn__content">
          <div className="main__center center learn__center">
            <div className="learn__inner">
              <div className="item__user learn-author">
                <div className="item__avatar learn-author__avatar">
                  <img src="https://via.placeholder.com/48/000000/FFFFFF/" alt="Author logo" />
                </div>

                <div className="item__description learn-author__details">
                  <div className="item__name learn-author__name">
                    {t('posted by')}
                    {' '}
                    {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events */}
                    <a href="#">{t('Investhub Team')}</a>
                  </div>
                  <div className="item__position learn-author__date">
                    {moment(article.publishedAt).format('LL')}
                    {' '}
                    -
                    {' '}
                    {t('5 min read')}
                  </div>
                </div>
              </div>

              <div className="faq__content learn-content__intro">
                {article.excerpt}
              </div>

              {article.viewColumn.map((column) => (
                <div
                  className={classNames([
                    'section learn-content__section learn-content__column description no-border__top no-border__bottom',
                    column.isImageInLeft ? 'learn-content__right' : 'learn-content__left',
                  ])}
                  key={generateRandom()}
                >
                  <div className="description__center center learn-content__center">
                    <div className="description__wrap learn-content__wrap">
                      <div className="catalog__title learn-content__title">{column.title}</div>

                      <div className="details__info learn-content__text">
                        { ReactHtmlParser(md.render(column.content)) }
                      </div>
                    </div>

                    <div className="description__gallery learn-content__gallery">
                      <div className="description__preview learn-content__preview">
                        <img src={column.image} alt={column.title} />
                      </div>
                    </div>
                  </div>
                </div>
              ))}

              <hr className="learn-content__line" />

              {article.viewGrid.length > 0 && (
                <>
                  <div className="section learn-content__section learn-content__box description no-border__top no-border__bottom">
                    <div className="learn-content__boxrow">
                      {article.viewGrid.map((card) => (
                        <div
                          className="learn-content__boxinner"
                          key={generateRandom()}
                        >
                          <div className="description__gallery learn-content__gallery">
                            <div className="description__preview learn-content__preview">
                              <img src={card.image} alt={card.title} />
                            </div>
                          </div>

                          <div className="description__wrap learn-content__wrap">
                            <div className="catalog__title learn-content__title">{card.title}</div>
                            <div className="details__info learn-content__text">
                              {card.content}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>

                  <hr className="learn-content__line" />
                </>
              )}

              <div className="catalog__title learn-content__title">Sample full page</div>

              <div className="details__info learn-content__text">
                <div>
                  { ReactHtmlParser(md.render(article ? article.mainContent : '')) }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

LearnDetail.propTypes = {
  match: PropTypes.object,
}

export default LearnDetail
