import arkane from './arkane.jpg'
import authereum from './authereum.jpg'
import coinbasewallet from './coinbasewallet.jpg'
import dapper from './dapper.jpg'
import formatic from './formatic.jpg'
import kaikas from './kaikas.jpg'
import metamask from './metamask.jpg'
import walletconnect from './walletconnect.jpg'

export default {
  arkane,
  authereum,
  coinbasewallet,
  dapper,
  formatic,
  kaikas,
  metamask,
  walletconnect
}
