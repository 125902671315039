import React from 'react'
import '../../styles/investhub/styles.scss'
import '../../styles/v2/style.scss'
// import PageFooter from '../Footer'
import SiteFooter from '../SiteFooter/SiteFooter'
import AppAdminBar from '../common/AppAdminBar'

const PageAdminDashboard = ({ children }) => (
  <div className="outer v2 pagewrap admin">
    <AppAdminBar />
    <div className="outer__inner">
      {children}
    </div>

    <SiteFooter />
  </div>
)

export default PageAdminDashboard
