import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import SignUpForm from '../components/SignUpForm'
import XIDLogo from '../media/xid/xid-logo.png'

const SignUp = () => {
  const { t } = useTranslation()
  const [showRegistrationCompleteStatus, setShowRegistrationCompleteStatus] = useState(false)
  const showRegistrationComplete = () => {
    setShowRegistrationCompleteStatus(true)
  }

  return (
    <>
      <div className="entry__logo">
        <img src={XIDLogo} alt="XID App" />
      </div>
      { showRegistrationCompleteStatus ? (
        <>
          <div className="entry__title h3">
            {t('Verify your signup process')}
          </div>
          <div className="entry__top">
            <div className="entry__text">
              {t('You received an email, please confirm this email and continue from there.')}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="entry__title h3">
            {t('Please Sign Up')}
          </div>

          <div className="entry__top">
            <div className="entry__text">
              {t('Once you are signed up, you can start creating tenants.')}
            </div>
          </div>

          <SignUpForm onRegistrationComplete={showRegistrationComplete} />
        </>
      )}
    </>
  )
}

export default SignUp
