import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import uuid from 'react-uuid'
import PropTypes from 'prop-types'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { useTranslation } from 'react-i18next'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from '@material-ui/core'

import {
  GET_TENANT,
  DROP_OWN_TENANT,
} from '../queriesAndMutations'
import { formatDate } from '../utils'
import CustomLoader from '../components/Loader'
import TenantStatusChip from '../components/common/TenantStatusChip'
import TenantLogItem from '../components/TenantDetails/TenantLogItem'
// import TenantServiceUsageItem from '../components/TenantDetails/TenantServiceUsageItem'
import TenantInvoiceItem from '../components/TenantDetails/TenantInvoiceItem'
import ControlDashboardBreadcrumbs from '../components/ControlDashboardBreadcrumbs'

const TenantDetails = ({ match }) => {
  const { t } = useTranslation()
  const tenantId = +match.params.tenantId
  const [twoFaToken, setTwoFaToken] = useState('')
  const [isDropTenantDialogOpen, setIsDropTenantDialogOpen] = useState(false)
  const [dropTenantResult, setDropTenantResult] = useState({})
  const [isDropTenantStatusDialogOpen, setIsDropTenantStatusDialogOpen] = useState(false)
  const [dropOwnTenant, dropOwnTenantStatus] = useMutation(DROP_OWN_TENANT)
  const { data: { getTenant: data } = {}, loading } = useQuery(GET_TENANT, {
    variables: {
      id: tenantId,
    },
  })
  const latestSubscription = data && data.Subscription
    ? [...data.Subscription].sort((subA, subB) => subB.id - subA.id)[0]
    : null
  const dropSelectedTenant = () => {
    setIsDropTenantDialogOpen(false)
    setIsDropTenantStatusDialogOpen(true)
    dropOwnTenant({
      variables: {
        tenantId: data.id,
        twoFaToken,
      },
    }).then((response) => {
      setDropTenantResult(response.data.dropOwnTenant)
    }).catch(() => {})
  }
  const renderDropTenantResult = () => (
    <ul>
      {dropTenantResult && dropTenantResult.status ? Object.keys(dropTenantResult.status).map((key) => (
        <li
          key={key}
          className={`text-${dropTenantResult.status[key].type}`}
        >
          {dropTenantResult.status[key].message}
        </li>
      )) : (
        <li>{t('Something went wrong. Please try again later.')}</li>
      )}
    </ul>
  )
  const closeDropTenantStatusDialog = () => {
    setIsDropTenantStatusDialogOpen(false)
    window.location.href = `${window.location.origin}/dashboard`
  }

  const tenantDetailsNav = ['Change Log', 'Billings']
  const [activeDetailNav, setActiveDetailNav] = useState(tenantDetailsNav[0])

  return (
    <>
      <ControlDashboardBreadcrumbs>
        <li className="breadcrumbs__item">
          <NavLink to="/dashboard" className="breadcrumbs__link">{t('Dashboard')}</NavLink>
        </li>
        <li className="breadcrumbs__item">
          {t('Tenant Details')}
          {!loading && data && (
            <>
              :
              {' '}
              {data.host}
            </>
          )}
        </li>
      </ControlDashboardBreadcrumbs>

      <div className="section-pt80 faq tenant-details">
        <div className="faq__center tenant-details__center center">
          <div className="faq__top tenant-details__top tenant-details__top-full">
            <div className="faq__stage tenant-details__pretitle">
              {t('Tenant Details')}
            </div>

            {!loading && data && (
              <div className="faq__title tenant-details__title h2">
                {t(data.host)}
              </div>
            )}
          </div>
        </div>

        <div className="activity__center tenant-activity__center center">
          <div className="activity__row tenant-activity__row">
            {loading && (
              <div className="faq__row tenant-details__row">
                <CustomLoader />
              </div>
            )}

            {!loading && data && (
              <>
                <div className="activity__wrapper no-padding__top">
                  <div className="block mt__32">{' '}</div>
                  <div className="activity__nav">
                    {tenantDetailsNav.map((x) => (
                      <button
                        key={uuid()}
                        type="button"
                        className={`activity__link ${x === activeDetailNav ? 'active' : ''} `}
                        onClick={() => setActiveDetailNav(x)}
                      >
                        {x}
                      </button>
                    ))}
                  </div>

                  <div className="activity__contents">
                    {activeDetailNav === tenantDetailsNav[0] && (
                      <>
                        <div className="upload__list no-margin__bottom no-padding__bottom border-none">
                          <div className="activity__info">{t('Change Log')}</div>
                          <div className="mb__24">{' '}</div>

                          <TenantLogItem tenantId={tenantId} />
                        </div>
                      </>
                    )}

                    {activeDetailNav === tenantDetailsNav[1] && (
                      <>
                        <div className="upload__list no-margin__bottom no-padding__bottom border-none">
                          <div className="activity__info">{t('Subscription')}</div>
                          <div className="mb__24">{' '}</div>

                          {!latestSubscription && (
                            <div className="activity__item change-log__item">
                              <div className="activity__details">
                                <div className="activity__description">
                                  {t('No information')}
                                </div>
                              </div>
                            </div>
                          )}

                          {latestSubscription && (
                            <div className="activity__item change-log__item">
                              <div className="activity__details change-log__details">
                                <div className="activity__description change-log__title">
                                  <div className="field__label mt__8 mb__4">
                                    {t('License')}
                                  </div>
                                  {latestSubscription ? (latestSubscription?.SubscriptionPlan?.title || t('Deprecated license')) : t('No license') }
                                </div>

                                <div className="activity__date change-log__details">
                                  <div className="field__label mt__8 mb__4">
                                    {t('Start from')}
                                  </div>
                                  {' '}
                                  {formatDate(+latestSubscription.createdAt)}
                                </div>

                                <div className="activity__date change-log__details">
                                  <div className="field__label mt__8 mb__4">
                                    {t('Next invoice')}
                                  </div>
                                  {' '}
                                  {formatDate(+latestSubscription.expiredDate)}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>

                        <div className="mb__24">{' '}</div>
                        <div className="mb__24">{' '}</div>
                        <div className="upload__list no-margin__bottom no-padding__bottom border-none">
                          <div className="activity__info">{t('Invoices')}</div>
                          <div className="mb__24">{' '}</div>

                          <TenantInvoiceItem tenantId={tenantId} />
                        </div>
                      </>
                    )}
                  </div>
                </div>

                <div className="activity__filters">
                  <div className="upload__list">
                    <div className="field__label">{t('Tenant Info')}</div>
                    <div className="mb-32">{' '}</div>

                    <div className="item__head">
                      <div className="item__description">
                        <div className="item__info">
                          {t('Host')}
                          {' '}

                          <span>
                            {t(data.host)}
                          </span>
                        </div>
                        <div className="block mb__16">{' '}</div>

                        <div className="item__info">
                          {t('License')}
                          {' '}

                          <span>
                            {latestSubscription ? (latestSubscription?.SubscriptionPlan?.title || t('Deprecated license')) : t('No license') }
                          </span>
                        </div>
                        <div className="block mb__16">{' '}</div>

                        <div className="item__info">
                          {t('Status')}
                          {' '}
                          <TenantStatusChip status={data.status} />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="upload__list no-margin__bottom no-padding__bottom border-none">
                    <div className="field__label">{t('Administrator Action')}</div>
                    <div className="mb__32">{' '}</div>

                    <div className="item__head">
                      <div className="item__description">

                        <div className="item__info">
                          {t('Upgrade your tenant')}

                          {latestSubscription?.SubscriptionPlan?.plan === 'PREMIUM_PLAN' && (
                            <>
                              <br />
                              <div className="block mb__8">{' '}</div>
                              <span>{t('You are having the highest plan.')}</span>
                            </>
                          )}
                          {latestSubscription?.SubscriptionPlan?.plan !== 'PREMIUM_PLAN' && (
                            <>
                              <br />
                              <div className="block mb__8">{' '}</div>
                              <NavLink
                                disabled={data.isStatic}
                                className="button button-small w-full"
                                to={`/dashboard/tenants/${data.id}/upgrade`}
                                onClick={() => window.scrollTo(0, 0)}
                              >
                                {t('Upgrade')}
                              </NavLink>
                            </>
                          )}
                        </div>
                        <div className="block mb__16">{' '}</div>

                        <div className="item__info">
                          {t('Drop tenant')}
                          <br />
                          <div className="block mb__8">{' '}</div>
                          <button
                            type="button"
                            disabled={data.isStatic}
                            className="button error button-small w-full"
                            onClick={() => setIsDropTenantDialogOpen(true)}
                          >
                            {t('Drop')}
                          </button>
                          {data.isStatic && (
                            <>
                              {t('This is a static tenant. It cannot be dropped')}
                            </>
                          )}
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      <Dialog
        open={isDropTenantDialogOpen}
        onClose={() => setIsDropTenantDialogOpen(false)}
      >
        <DialogTitle id="alert-dialog-title">{t('Drop tenant')}</DialogTitle>
        <DialogContent>
          {data && (
            <div>
              <p>
                {t('Do you really want to drop tenant')}
                {' '}
                <strong>{data.host}</strong>
                ?
              </p>
              <div className="text-error">
                <p>
                  <strong>
                    {t('Notice')}
                    :
                  </strong>
                </p>
                <p>
                  -
                  {' '}
                  {t('This action cannot be undone.')}
                </p>
                <p>
                  -
                  {' '}
                  {t('Dropping announcement will be sent to emails of all admins and compliance officers of the tenant.')}
                </p>
              </div>
              <hr />
              <TextField
                label={t('Enter 2FA code for confirmation')}
                required
                value={twoFaToken}
                onChange={({ target: { value } }) => setTwoFaToken(value)}
                className="investhub__field field w-full"
                margin="normal"
              />
            </div>
          )}
        </DialogContent>
        <DialogActions>
          {dropOwnTenantStatus.loading ? (
            <CircularProgress />
          ) : (
            <>
              <Button variant="contained" onClick={() => setIsDropTenantDialogOpen(false)}>Cancel</Button>
              <Button
                variant="contained"
                className="MuiButton-containedError"
                onClick={dropSelectedTenant}
              >
                {t('Drop')}
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>

      <Dialog
        open={isDropTenantStatusDialogOpen}
        onClose={closeDropTenantStatusDialog}
      >
        <DialogTitle id="alert-dialog-title">{t('Drop tenant status')}</DialogTitle>
        <DialogContent>
          {dropOwnTenantStatus.loading ? (
            <div>
              <p>{t('Dropping tenant...')}</p>
              <CircularProgress />
            </div>
          ) : (
            renderDropTenantResult()
          )}
        </DialogContent>
        <DialogActions>
          {!dropOwnTenantStatus.loading && (
            <>
              <Button
                variant="contained"
                color="primary"
                onClick={closeDropTenantStatusDialog}
              >
                {t('Close')}
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </>
  )
}

TenantDetails.propTypes = {
  match: PropTypes.object,
}

export default TenantDetails
