import React from 'react'
import { NavLink } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import classNames from 'classnames'

import moment from 'moment-timezone'
import {
  CircularProgress,
  Tooltip,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import {
  GET_USER_TENANT,
} from '../../queriesAndMutations'
import { TENANT_STATUS } from '../../constants/tenant'

import TenantStatusChip from '../common/TenantStatusChip'
import Icon from '../Icon'

const UserTenantTable = () => {
  const { t } = useTranslation()
  const { data, loading } = useQuery(GET_USER_TENANT)
  const allTenants = data ? data.getUserTenant : []
  const formatDate = (timestamp) => moment(+timestamp).tz('Europe/Vaduz').format('YYYY-MM-DD HH:mm z')

  return (
    <>
      <div className="xid-table__wrapper">
        <div className="xid-table__inner">
          <div className="xid-table__row">
            <div className="xid-table__col">
              {t('Domain')}
            </div>
            <div className="xid-table__col">
              {t('Created At')}
            </div>
            <div className="xid-table__col">
              {t('Status')}
            </div>
            <div className="xid-table__col">
              {t('Action')}
            </div>
          </div>

          { loading && (
            <div className="xid-table__row xid-table__row-empty">
              <div className="investhub-table__col">
                <CircularProgress />
              </div>
            </div>
          )}

          { allTenants.map((tenant) => (
            <div className="xid-table__row" key={tenant.id}>
              <div className="xid-table__col">
                <div className="xid-table__label">{t('Domain')}</div>
                {tenant.host}
              </div>
              <div className="xid-table__col">
                <div className="xid-table__label">{t('Created At')}</div>
                {formatDate(tenant.createdAt)}
              </div>
              <div className="xid-table__col">
                <div className="xid-table__label">{t('Status')}</div>
                <TenantStatusChip status={tenant.status} />
              </div>
              <div className="xid-table__col">
                <div className="xid-table__label">{t('Action')}</div>

                <div className="button-group">
                  {tenant.status === TENANT_STATUS.AWAIT_PURCHASE && (
                    <Tooltip title={t('Subscribe')}>
                      <NavLink
                        to={`/dashboard/tenants/${tenant.id}/subscribe`}
                        className="button-stroke button-icon xid-table__btn"
                      >
                        <Icon name="add-square" className={classNames('add-square')} size={24} />
                      </NavLink>
                    </Tooltip>
                  )}
                  {tenant.status === TENANT_STATUS.AWAIT_DNS_SETUP && (
                    <Tooltip title={t('Setup DNS')}>
                      <NavLink
                        to={`/dashboard/tenants/${tenant.id}/setup-dns`}
                        className="button-stroke button-icon xid-table__btn"
                      >
                        <Icon name="globe" className={classNames('globe')} size={24} />
                      </NavLink>
                    </Tooltip>
                  )}
                  <Tooltip title={t('Report')}>
                    <NavLink
                      to={`/dashboard/tenants/${tenant.id}`}
                      className="button-stroke button-icon xid-table__btn"
                    >
                      <Icon name="report" className={classNames('report')} size={24} />
                    </NavLink>
                  </Tooltip>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default UserTenantTable
