import React from 'react'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ControlBreadcrumbs from '../components/ControlBreadcrumbs'

const Policy = () => {
  const { t } = useTranslation()

  return (
    <>
      <ControlBreadcrumbs>
        <li className="breadcrumbs__item">
          <NavLink to="/" className="breadcrumbs__link">{t('Home')}</NavLink>
        </li>
        <li className="breadcrumbs__item">
          {t('Privacy Policy')}
        </li>
      </ControlBreadcrumbs>

      <div className="section-pt80 details single-page">
        <div className="details__center single-page__center center">
          <div className="details__top single-page__top">
            <h1 className="details__title single-page__title h2">
              {t('Privacy Policy')}
            </h1>
          </div>

          <div className="details__row single-page__row">
            <div className="details__col single-page__col_full">
              <div className="details__info single-page__info">
                <p>
                  {t('It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using &lsquo;Content here, content here&rsquo;, making it look like readable English.')}
                </p>

                <p>
                  {t('Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for &lsquo;lorem ipsum&rsquo; will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).')}
                </p>

                <p>
                  {t('All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.')}
                </p>

                <ul className="list-number">
                  <li>
                    <strong>
                      1.
                      {' '}
                      {t('Determination of personal information of users')}
                    </strong>
                    <ul>
                      <li>
                        1.1.
                        {' '}
                        {t('If you are going to use a passage of Lorem Ipsum')}
                        :
                        <ul>
                          <li>
                            1.1.1 All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary,
                            making this the first true generator on the Internet.
                          </li>
                          <li>
                            1.1.2 It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures,
                            to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from
                            repetition, injected humour, or non-characteristic words etc.
                          </li>
                        </ul>
                      </li>

                      <li>
                        1.2. There are many variations of passages of Lorem Ipsum available, but the majority have suffered
                        alteration in some form, by injected humour, or randomised words which don&lsquo;t look even slightly believable.
                      </li>

                      <li>
                        1.3. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text,
                        and a search for &lsquo;lorem ipsum&rsquo; will uncover many web sites still in their infancy.
                        Various versions have evolved over the years, sometimes by accident, sometimes on purpose
                        (injected humour and the like).
                      </li>
                    </ul>
                  </li>

                  <li>
                    <strong>2. Reasons for collecting and processing user personal information</strong>

                    <ul>
                      <li>
                        1.1. If you are going to use a passage of Lorem Ipsum:
                        <ul>
                          <li>
                            1.1.1 All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary,
                            making this the first true generator on the Internet.
                          </li>
                          <li>
                            1.1.2 It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures,
                            to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free
                            from repetition, injected humour, or non-characteristic words etc.
                          </li>
                        </ul>
                      </li>

                      <li>
                        1.2. There are many variations of passages of Lorem Ipsum available, but the majority have suffered
                        alteration in some form, by injected humour, or randomised words which don&apos;t look even slightly believable.
                      </li>

                      <li>
                        1.3. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text,
                        and a search for &lsquo;lorem ipsum&rsquo; will uncover many web sites still in their infancy.
                        Various versions have evolved over the years, sometimes by accident, sometimes on purpose
                        (injected humour and the like).
                      </li>
                    </ul>
                  </li>
                </ul>
                <p>
                  All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first
                  true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model
                  sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always
                  free from repetition, injected humour, or non-characteristic words etc.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Policy
