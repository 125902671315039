import React from 'react'
import PricingLists from '../components/Pricing/ListPricing'
import PricingFaq from '../components/Pricing/Faq'
import ExploreDeals from '../components/Home/ExploreDeals'

const Pricing = () => (
  <>
    <PricingLists />

    <PricingFaq />

    <ExploreDeals />
  </>
)

export default Pricing
