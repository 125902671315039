import React, { useState, useEffect } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { omit } from 'lodash'
import classNames from 'classnames'
import { NavLink } from 'react-router-dom'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import {
  Dialog,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  InputAdornment,
  Checkbox,
  CircularProgress,
  MenuItem,
} from '@material-ui/core'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { useTranslation, Trans } from 'react-i18next'
import {
  CHECK_SUBDOMAIN_AVAILABILITY,
  CHECK_DOMAIN_AVAILABILITY,
  REGISTER_TENANT,
} from '../queriesAndMutations'
import { useMe, usePublicSettings } from '../myHooks'
import { toaster } from '../utils'
import Icon from '../components/Icon'
import Upload from '../components/common/Upload'
import ValidationMessage from '../components/common/ValidationMessage'
import SetupTwoFactorAuth from '../components/MyProfile/TwoFactorAuth/SetupTwoFactorAuth'
import ModalInnerMaterialDialog from '../components/ModalInnerMaterialDialog'

const TenantProfessionalSetup = () => {
  const { t } = useTranslation()
  const { data: { me: user }, refetch } = useMe()
  const [checkSubdomain, checkSubdomainStatus] = useMutation(CHECK_SUBDOMAIN_AVAILABILITY)
  const [checkDomain, checkDomainStatus] = useMutation(CHECK_DOMAIN_AVAILABILITY)
  const [registerTenant, registerTenantStatus] = useMutation(REGISTER_TENANT)
  const { data } = usePublicSettings()
  const tenantBaseDomain = (data && data.publicSettings && data.publicSettings.cloudflare && data.publicSettings.cloudflare.baseDomain) || ''
  const prefixOwnDomain = window.location.host === 'dev.investhub.io' ? 'test-' : ''
  const [selectedCurrency, setSelectedCurrency] = useState('eur')
  const [is2FaDialogOpened, setIs2FaDialogOpened] = useState(false)
  const [values, setValues] = useState({
    subdomainType: '',
    ownSubdomain: '',
    customDomain: localStorage.getItem('desiredUrl') || '',
    tokenType: '',
    baseCurrency: 'eur',
    minimumInvestment: 1,
    acceptedCurrencies: ['eur', 'usd', 'chf', 'eth', 'usdc'],
    tokenName: '',
    tokenSymbol: '',
    blockchainType: 'MATIC_SIDE_CHAIN',
    acceptedWallets: {
      eth: true,
      ixo_id: true,
      cosmos: true,
      btc: true,
      other: true,
    },
    paperType: '',
    durationFrom: '2000-01-01',
    personType: '',
    acknowledgementAgree: false,
    kycLevels: {
      1: {
        minInvestAmount: 0,
        maxInvestAmount: 5000,

        cryptoAcceptTaxNumber: false,
        cryptoAcceptTaxOffice: false,
        cryptoAcceptDescriptionOfFunds: false,
        cryptoAcceptUploadPhotoProofDocuments: false,
        cryptoAcceptUploadProofResidence: false,

        fiatAcceptTaxNumber: false,
        fiatAcceptTaxOffice: false,
        fiatAcceptDescriptionOfFunds: false,
        fiatAcceptUploadPhotoProofDocuments: false,
        fiatAcceptUploadProofResidence: false,
      },
      2: {
        minInvestAmount: 5001,
        maxInvestAmount: 25000,
      },
      3: {
        minInvestAmount: 25001,
        maxInvestAmount: 50000,
      },
      4: {
        minInvestAmount: 50001,
        maxInvestAmount: 100000,
      },
      5: {
        minInvestAmount: 100001,
        maxInvestAmount: 500000,
      },
    },
    availableLevels: 5,
    companyEmail: '',
    companyName: localStorage.getItem('companyName') || '',
    api: {
      mailgun: {
        enable: true,
        settingFrom: 'investhubMailgun',
        host: '',
        port: '',
        user: '',
        password: '',
        from: '',
        fromForComment: '',
        fromDuplicate: '',
        emailSubjectPrefix: '',
        kycApprovalEmail: '',
        newUserRegistration: '',
        emailVideoIdentFailTwice: '',
      },
      videoIdent: {
        enable: true,
        service: 'onfido',
      },
      walletService: {
        enable: false,
        service: '',
      },
    },
    bank: ['eur', 'usd', 'chf'].reduce((all, currency) => ({
      ...all,
      [currency]: {
        accountName: '',
        accountNumber: '',
        bankName: '',
        bankAddress: '',
        routingNumber: '',
        iban: '',
        swiftBic: '',
      },
    }), {}),
    hasWalletAddress: 'yes',
    walletType: 'eth',
    walletAddress: '',
    saleApi: {
      url: '',
      key: '',
    },
    brand: {
      logo: null,
      dataroomGraphic: null,
      sourceOfFund: null,
      sampleWithdrawalForm: null,
    },
  })
  const [stepErrors, setStepErrors] = useState({})
  const [stepProfessionalSetup, setStepProfessionalSetup] = useState(1)
  const [hasManualUpdateTokenSymbol, setHasManualUpdateTokenSymbol] = useState(false)

  useEffect(() => {
    if (user) {
      setValues({
        ...values,
        api: {
          ...values.api,
          mailgun: {
            ...values.api.mailgun,
            from: user.email,
            fromForComment: user.email,
            fromDuplicate: user.email,
            kycApprovalEmail: user.email,
            emailVideoIdentFailTwice: user.email,
            newUserRegistration: user.email,
          },
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  const setTextField = (fieldName) => ({ target: { value } }) => {
    setValues({
      ...values,
      [fieldName]: value,
    })
  }

  const generateTokenSymbol = (tokenName) => {
    if (!tokenName) {
      return ''
    }

    const words = tokenName.replace(/[^a-zA-Z0-9]/g, ' ')
      .replace(/\s{2,}/g, ' ')
      .trim()
      .split(' ')

    if (words.length === 0) {
      return ''
    }

    if (words.length === 1) {
      return words[0].substr(0, 3).toUpperCase()
    }

    return words.map((word) => word.substr(0, 1)).join('').substr(0, 3).toUpperCase()
  }

  const setTokenName = ({ target: { value } }) => {
    if (hasManualUpdateTokenSymbol) {
      setValues({
        ...values,
        tokenName: value,
      })
    } else {
      setValues({
        ...values,
        tokenName: value,
        tokenSymbol: generateTokenSymbol(value),
      })
    }
  }

  const setTokenSymbol = ({ target: { value } }) => {
    setValues({
      ...values,
      tokenSymbol: value,
    })
    setHasManualUpdateTokenSymbol(true)
  }

  const setNumberField = (fieldName) => ({ target: { value } }) => {
    setValues({
      ...values,
      [fieldName]: +value,
    })
  }

  const setButtonSelectField = (fieldName, value) => () => {
    setValues({
      ...values,
      [fieldName]: value,
    })
  }

  const setCheckBox = (field) => ({ target: { value, checked } }) => {
    if (checked) {
      setValues({
        ...values,
        [field]: [
          ...values[field],
          value,
        ],
      })
    } else {
      setValues({
        ...values,
        [field]: values[field].filter((c) => c !== value),
      })
    }
  }

  const setAcceptedWallets = (currency) => ({ target: { checked } }) => {
    setValues({
      ...values,
      acceptedWallets: {
        ...values.acceptedWallets,
        [currency]: checked,
      },
    })
  }

  const setDateField = (field) => (date) => {
    if (date) {
      const year = date.getFullYear()
      const month = date.getMonth() + 1
      const day = date.getDate()
      const value = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`

      setValues({ ...values, [field]: value })
    }
  }

  const setLevelField = (level, fieldName) => ({ target: { value } }) => {
    setValues({
      ...values,
      kycLevels: Object.keys(values.kycLevels).reduce((allLevels, lvl) => ({
        ...allLevels,
        [lvl]: +lvl === level
          ? {
            ...values.kycLevels[lvl],
            [fieldName]: +value,
          }
          : values.kycLevels[lvl],
      }), {}),
    })
  }

  const setEnableService = (service, isEnabled) => () => {
    setValues({
      ...values,
      api: {
        ...values.api,
        [service]: {
          ...values.api[service],
          enable: isEnabled,
        },
      },
    })
  }
  const setApiField = (service, fieldName) => ({ target: { value } }) => {
    setValues({
      ...values,
      api: {
        ...values.api,
        [service]: {
          ...values.api[service],
          [fieldName]: value,
        },
      },
    })
  }
  const setSaleApiField = (fieldName) => ({ target: { value } }) => {
    setValues({
      ...values,
      saleApi: {
        ...values.saleApi,
        [fieldName]: value,
      },
    })
  }

  const setLevelCheckbox = (level, fieldName) => ({ target: { checked } }) => {
    setValues({
      ...values,
      kycLevels: Object.keys(values.kycLevels).reduce((allLevels, lvl) => ({
        ...allLevels,
        [lvl]: +lvl === level
          ? {
            ...values.kycLevels[lvl],
            [fieldName]: checked,
          }
          : values.kycLevels[lvl],
      }), {}),
    })
  }

  const setCurrency = ({ target: { value } }) => {
    setSelectedCurrency(value)
  }

  const setBankField = (fieldName) => ({ target: { value } }) => {
    setValues({
      ...values,
      bank: {
        ...values.bank,
        [selectedCurrency]: {
          ...values.bank[selectedCurrency],
          [fieldName]: value,
        },
      },
    })
  }

  const setBrandField = (fieldName) => (value) => {
    setValues({
      ...values,
      brand: {
        ...values.brand,
        [fieldName]: value,
      },
    })
  }

  const validateDomain = () => {
    const errors = {}
    if (!values.subdomainType) {
      errors.subdomainType = 'You need to select a type of domain.'
    } else if (values.subdomainType === 'ownSubdomain' && !values.ownSubdomain) {
      errors.ownSubdomain = 'You need to provide your subdomain.'
    } else if (values.subdomainType === 'investhubSubdomain') {
      if (!values.customDomain) {
        errors.customDomain = 'You need to select a subdomain.'
      }
    }

    setStepErrors({
      ...stepErrors,
      domain: errors,
    })

    return Object.keys(errors).length === 0
  }

  const validateTokenType = () => {
    const errors = {}
    if (!values.tokenType) {
      errors.tokenType = 'You need to select a token type.'
    }

    setStepErrors({
      ...stepErrors,
      tokenType: errors,
    })

    return Object.keys(errors).length === 0
  }

  const validateCurrency = () => {
    const errors = {}
    if (!values.baseCurrency) {
      errors.baseCurrency = 'You need to select a base currency.'
    }

    if (+values.minimumInvestment <= 0) {
      errors.minimumInvestment = 'Minimum investment must be greater than 0.'
    }

    if (values.acceptedCurrencies.length === 0) {
      errors.acceptedCurrencies = 'You must select at least one accepted currency.'
    }

    setStepErrors({
      ...stepErrors,
      currency: errors,
    })

    return Object.keys(errors).length === 0
  }

  const validateBlockchain = () => {
    const errors = {}
    if (!values.tokenName) {
      errors.tokenName = 'Token name must not be empty.'
    }

    if (!values.tokenSymbol) {
      errors.tokenSymbol = 'Token symbol must not be empty.'
    }

    if (!values.blockchainType) {
      errors.blockchainType = 'You must select a blockchain type.'
    }

    if (Object.values(values.acceptedWallets).reduce((all, isAccepted) => all || isAccepted, false) === false) {
      errors.acceptedWallets = 'You must select at least one accepted wallet.'
    }

    setStepErrors({
      ...stepErrors,
      blockchain: errors,
    })

    return Object.keys(errors).length === 0
  }

  const validatePaper = () => {
    const errors = {}

    if (!values.paperType) {
      errors.paperType = 'You must select a paperType type.'
    }

    if (!values.durationFrom || (new Date(values.durationFrom)).toString() === 'Invalid Date') {
      errors.durationFrom = 'Invalid Date.'
    }

    setStepErrors({
      ...stepErrors,
      paper: errors,
    })

    return Object.keys(errors).length === 0
  }

  const validateService = () => {
    const errors = {}

    if (!values.companyEmail) {
      errors.companyEmail = 'Company email is required.'
    }

    if (!values.companyName) {
      errors.companyName = 'Company name is required.'
    }

    if (values.api.videoIdent.enable && !values.api.videoIdent.service) {
      errors.videoIdent = 'Please select a video ident service.'
    }

    if (values.api.walletService.enable && !values.api.walletService.service) {
      errors.walletService = 'Please select a video ident service.'
    }

    if (values.api.mailgun.enable) {
      if (!values.api.mailgun.settingFrom) {
        errors.mailgun = 'Please select a mailgun setting.'
      } else if (values.api.mailgun.settingFrom === 'ownMailgun') {
        if (!values.api.mailgun.host) {
          errors.mailgunHost = 'This field is required.'
        }
        if (!values.api.mailgun.port) {
          errors.mailgunPort = 'This field is required.'
        }
        if (!values.api.mailgun.user) {
          errors.mailgunUser = 'This field is required.'
        }
        if (!values.api.mailgun.password) {
          errors.mailgunPassword = 'This field is required.'
        }
        if (!values.api.mailgun.from) {
          errors.mailgunFrom = 'This field is required.'
        }
        if (!values.api.mailgun.fromForComment) {
          errors.mailgunFromForComment = 'This field is required.'
        }
        if (!values.api.mailgun.fromDuplicate) {
          errors.mailgunFromDuplicate = 'This field is required.'
        }
        if (!values.api.mailgun.kycApprovalEmail) {
          errors.mailgunKycApprovalEmail = 'This field is required.'
        }
        if (!values.api.mailgun.emailSubjectPrefix) {
          errors.mailgunEmailSubjectPrefix = 'This field is required.'
        }
        if (!values.api.mailgun.emailVideoIdentFailTwice) {
          errors.mailgunEmailVideoIdentFailTwice = 'This field is required.'
        }
      }
    }

    setStepErrors({
      ...stepErrors,
      service: errors,
    })

    return Object.keys(errors).length === 0
  }

  const validateWallet = () => {
    const errors = {}

    if (values.hasWalletAddress === 'yes' && !values.walletAddress) {
      errors.walletAddress = 'This field is required.'
    }

    setStepErrors({
      ...stepErrors,
      wallet: errors,
    })

    return Object.keys(errors).length === 0
  }

  const validateStep = (step) => {
    switch (step) {
      case 1:
        return validateDomain()

      case 2:
        return validateTokenType()

      case 3:
        return validateCurrency()

      case 4:
        return validateBlockchain()

      case 5:
        return validatePaper()

      case 7:
        return validateService()

      case 9:
        return validateWallet()

      default:
        return true
    }
  }

  const stepListProfessionalSetup = [
    'Setup Domain',
    'Financial Instrument',
    'Financials',
    'Blockchain',
    'Duration',
    'KYC Levels',
    'APIs',
    'Banking',
    'Smart Contract Address',
    'Referral Traffic',
    'Logo Upload',
    '2-factor Authentication',
  ]

  const prevStepProfessionalSetup = () => {
    if (stepProfessionalSetup > 1) {
      setStepProfessionalSetup(stepProfessionalSetup - 1)
    }
  }

  const nextStepProfessionalSetup = () => {
    const formViewPort = document.getElementById('tenant_register_form')

    if (stepProfessionalSetup < 12) {
      if (validateStep(stepProfessionalSetup)) {
        setStepProfessionalSetup(stepProfessionalSetup + 1)
        window.scrollBy(0, formViewPort.getClientRects()[0].y - 100)
      } else {
        setTimeout(() => {
          const firstErrorMessage = formViewPort.querySelector('.error-message')

          if (firstErrorMessage) {
            window.scrollBy(0, firstErrorMessage.getClientRects()[0].y - 100)
          }
        }, 0)
      }
    } else if (validateStep(stepProfessionalSetup)) {
      const getUploadFile = (fileObject) => (fileObject ? fileObject.file : null)

      registerTenant({
        variables: {
          input: {
            ...omit(values, [
              'blockchainType',
              'durationTo',
              'personType',
              'acknowledgementAgree',
              'availableLevels',
              'brand',
            ]),
            logo: getUploadFile(values.brand.logo),
            dataroomGraphic: getUploadFile(values.brand.dataroomGraphic),
            sourceOfFund: getUploadFile(values.brand.sourceOfFund),
            sampleWithdrawalForm: getUploadFile(values.brand.sampleWithdrawalForm),
          },
        },
      }).then(() => {
        window.location.href = `${window.location.origin}/dashboard`
      }).catch(() => {})
    }
  }
  const getErrorText = (step, field) => (stepErrors && stepErrors[step] && stepErrors[step][field]) || ''

  const verifyDomain = () => {
    checkDomain({
      variables: {
        host: `${prefixOwnDomain}${values.ownSubdomain}`,
      },
    }).then((response) => {
      if (response.data.checkDomainAvailability) {
        toaster.success(`Domain "${prefixOwnDomain}${values.ownSubdomain}" is available`)
      } else {
        toaster.error(`Domain "${prefixOwnDomain}${values.ownSubdomain}" is not available`)
      }
    }).catch(() => {})
  }

  const checkSubdomainAvailable = () => {
    checkSubdomain({
      variables: {
        host: values.customDomain,
      },
    }).then((response) => {
      if (response.data.checkSubdomainAvailability) {
        toaster.success(`Domain "${values.customDomain}${tenantBaseDomain}" is available`)
      } else {
        toaster.error(`Domain "${values.customDomain}${tenantBaseDomain}" is not available`)
      }
    }).catch(() => {})
  }

  const addLevel = () => {
    setValues({
      ...values,
      availableLevels: values.availableLevels < 5 ? values.availableLevels + 1 : values.availableLevels,
    })
  }
  const removeLevel = () => {
    setValues({
      ...values,
      availableLevels: values.availableLevels > 1 ? values.availableLevels - 1 : values.availableLevels,
    })
  }

  return (
    <>
      <div className="section-pt80 faq new-tenant">
        <div className="faq__center center new-tenant__center">
          <div className="faq__top new-tenant__center">
            <div className="faq__stage">{t('12 steps - up to 5 minutes')}</div>
            <div className="faq__title h2 new-tenant__title">{t('Professional Setup')}</div>
            <div className="faq__info new-tenant__info">{t('Join Stacks community now to get free updates and also alot of freebies are waiting for you or')}</div>
          </div>

          <div className="faq__row new-tenant__row">
            <div className="faq__col new-tenant__col">
              <div className="new-tenant__stepper-nav tablet-hide mobile-hide">
                <Stepper
                  activeStep={stepProfessionalSetup - 1}
                  orientation="vertical"
                >
                  { stepListProfessionalSetup.map((label) => (
                    <Step key={label}>
                      <StepLabel>{t(label)}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </div>
            </div>

            <div className="faq__col new-tenant__col" id="tenant_register_form">
              <div className="new-tenant__stepper-content">
                {stepProfessionalSetup === 1 && (
                  <>
                    <div className="upload__item">
                      <div className="upload__category">{t('Setup domain')}</div>

                      <div className="upload__fieldset details__fieldset">
                        <div className="field field__video">
                          <div className="video__item video__item-9-16">
                            <iframe
                              src="https://www.youtube.com/embed/6eUCbY5oIuU"
                              title={t('YouTube video player')}
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                              allowFullScreen
                              frameBorder="0"
                            />
                          </div>
                        </div>

                        <div className="field">
                          <div className="field__label">{t('Do you want to have your own Subdomain?')}</div>

                          <RadioGroup
                            row
                            aria-label="subdomaintype"
                            name="subdomaintype"
                            className="field__radio"
                            value={values.subdomainType}
                            onChange={setTextField('subdomainType')}
                          >
                            <FormControlLabel
                              value="ownSubdomain"
                              label={t('Yes - I have my own Subdomain')}
                              control={<Radio />}
                            />
                            <FormControlLabel
                              value="investhubSubdomain"
                              label={t('No - Continue with TENANTBASEDOMAIN subdomain and verify my own Domain later.')}
                              control={<Radio />}
                            />
                          </RadioGroup>

                          <ValidationMessage error={getErrorText('domain', 'subdomainType')} />
                        </div>

                        {values.subdomainType === 'ownSubdomain' && (
                          <>
                            <TextField
                              value={values.ownSubdomain}
                              onChange={setTextField('ownSubdomain')}
                              className="investhub__field field field-has__button"
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              label={t('Cname')}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    {checkDomainStatus.loading && (
                                      <CircularProgress />
                                    )}
                                    {!checkDomainStatus.loading && (
                                      <button
                                        type="button"
                                        className="button button-small"
                                        onClick={verifyDomain}
                                      >
                                        {t('Verify')}
                                      </button>
                                    )}
                                  </InputAdornment>
                                ),
                              }}
                            />

                            <ValidationMessage error={getErrorText('domain', 'ownSubdomain')} />

                            <div className="field">
                              <div className="box-note box-note__orange">
                                {t('Please setup a CNAME DNS record point to the address app.investhub.io (without proxy enabled. We recommend to use CloudFlare). If you wish to set up your subdomain, please make sure to follow the video.')}
                              </div>
                            </div>
                          </>
                        )}

                        {values.subdomainType === 'investhubSubdomain' && (
                          <>
                            <TextField
                              value={values.customDomain}
                              onChange={setTextField('customDomain')}
                              className="investhub__field field field-has__button"
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              label={t('Cname')}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <span className="button-stroke button-small button-transparent">
                                      {tenantBaseDomain}
                                    </span>
                                  </InputAdornment>
                                ),
                              }}
                            />

                            <ValidationMessage error={getErrorText('domain', 'customDomain')} />

                            <div className="field">
                              {checkSubdomainStatus.loading && (
                                <CircularProgress />
                              )}
                              {!checkSubdomainStatus.loading && (
                                <button
                                  className="button-stroke"
                                  type="button"
                                  onClick={checkSubdomainAvailable}
                                >
                                  {t('Check Availability')}
                                </button>
                              )}
                            </div>

                            <div className="field">
                              <div className="box-note box-note__orange">
                                {t('You can also set up your cname later in case you want to simply start with a subdomain so you can start configuring your KYC SaaS right away.')}
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </>
                )}

                {stepProfessionalSetup === 2 && (
                  <div className="upload__item">
                    <div className="upload__category">{t('Financial Instrument')}</div>

                    <div className="upload__fieldset details__fieldset">
                      <div className="field field__video">
                        <div className="video__item video__item-9-16">
                          <iframe
                            src="https://www.youtube.com/embed/d-fz5JoE_I4"
                            title={t('YouTube video player')}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            frameBorder="0"
                          />
                        </div>
                      </div>

                      <div className="field">
                        <div className="field__label">
                          {t('Please tell us what financial instrument you are going to be issuing')}
                          :
                        </div>

                        <ValidationMessage error={getErrorText('tokenType', 'tokenType')} />

                        <div className="investhub-table__wrapper">
                          <div className="investhub-table__box investhub-table__box-styled new-tenant__table">
                            <div className="investhub-table__row">{' '}</div>
                            <div className="investhub-table__row new-tenant__row-table">
                              <div className="investhub-table__col">
                                <strong>{t('EQUITY')}</strong>
                                {t('Equity - can tokenize your existing or newly issued equity.')}
                              </div>

                              <div className="investhub-table__col">
                                <button
                                  type="button"
                                  className={`${values.tokenType === 'EQUITY' ? 'button' : 'button-stroke'} button-small`}
                                  onClick={setButtonSelectField('tokenType', 'EQUITY')}
                                >
                                  {values.tokenType === 'EQUITY' ? t('Selected') : t('Select')}
                                </button>
                              </div>
                            </div>

                            <div className="investhub-table__row new-tenant__row-table">
                              <div className="investhub-table__col">
                                <strong>{t('BOND')}</strong>
                                {t('Bond - can tokenize a complete bond including interest payments and initial bond principal.')}
                              </div>

                              <div className="investhub-table__col">
                                <button
                                  type="button"
                                  className={`${values.tokenType === 'BOND' ? 'button' : 'button-stroke'} button-small`}
                                  onClick={setButtonSelectField('tokenType', 'BOND')}
                                >
                                  {values.tokenType === 'BOND' ? t('Selected') : t('Select')}
                                </button>
                              </div>
                            </div>

                            <div className="investhub-table__row new-tenant__row-table">
                              <div className="investhub-table__col">
                                <strong>{t('UTILITY')}</strong>
                                {t('Utility - can issue a utility token with or without whitelisting feature.')}
                              </div>

                              <div className="investhub-table__col">
                                <button
                                  type="button"
                                  className={`${values.tokenType === 'UTILITY' ? 'button' : 'button-stroke'} button-small`}
                                  onClick={setButtonSelectField('tokenType', 'UTILITY')}
                                >
                                  {values.tokenType === 'UTILITY' ? t('Selected') : t('Select')}
                                </button>
                              </div>
                            </div>

                            <div className="investhub-table__row new-tenant__row-table">
                              <div className="investhub-table__col">
                                <strong>{t('NONE')}</strong>
                                {t('None - can be used for simple invest onboarding without any token function.')}
                              </div>

                              <div className="investhub-table__col">
                                <button
                                  type="button"
                                  className={`${values.tokenType === 'NONE' ? 'button' : 'button-stroke'} button-small`}
                                  onClick={setButtonSelectField('tokenType', 'NONE')}
                                >
                                  {values.tokenType === 'NONE' ? t('Selected') : t('Select')}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="field single-page">
                        <p>
                          {t('The difference between each token type is primarily which token contract has to be deployed as the smart contract for an equity offering is different than compared to a bond or a utility token.')}
                        </p>
                        <p>
                          {t('If you wish to not have any token-related parameters in your KYC SaaS you can simply choose &quot;None&quot; which enables you to use the KYC SaaS as a simple onboarding tool for your investors, either for equity or tokens.')}
                        </p>
                        <p>
                          {t('Many of our customers also use this in connection with our Referral feature, where you can assign certain investors to certain projects so you can use for example 1 system for several thousand investors with just separating them through their referral source.')}
                        </p>
                      </div>
                    </div>
                  </div>
                )}

                {stepProfessionalSetup === 3 && (
                  <div className="upload__item">
                    <div className="upload__category">Financials</div>

                    <div className="upload__fieldset details__fieldset">
                      <div className="field field__video">
                        <div className="video__item video__item-9-16">
                          <iframe
                            src="https://www.youtube.com/embed/TtQykxBfnM8"
                            title={t('YouTube video player')}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            className="video-tenant"
                            allowFullScreen
                            frameBorder="0"
                          />
                        </div>
                      </div>

                      <div className="field">
                        <div className="field__label">{t('Whats your base currency?')}</div>

                        <TextField
                          select
                          required
                          className="select investhub-select select-trans"
                          value={values.baseCurrency}
                          onChange={setTextField('baseCurrency')}
                        >
                          {['eur', 'usd', 'chf'].map((currency) => (
                            <MenuItem value={currency} key={currency}>{currency.toUpperCase()}</MenuItem>
                          ))}
                        </TextField>

                        <ValidationMessage error={getErrorText('currency', 'baseCurrency')} />
                      </div>

                      <TextField
                        className="investhub__field field"
                        label={t('Whats your minimum investment?')}
                        margin="normal"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        type="number"
                        step={1}
                        value={values.minimumInvestment}
                        onChange={setNumberField('minimumInvestment')}
                      />
                      <ValidationMessage error={getErrorText('currency', 'minimumInvestment')} />

                      <div className="field">
                        <div className="field__label">
                          {t('Which currencies do you want that investors can invest?')}
                        </div>

                        <div className="field__checkbox-wrapper field__checkbox-vertical">
                          {['eur', 'usd', 'chf', 'eth', 'usdc'].map((currency) => (
                            <FormControlLabel
                              key={currency}
                              className="field field__checkbox field__checkbox-group no-margin__left"
                              label={currency.toUpperCase()}
                              control={(
                                <Checkbox
                                  color="primary"
                                  value={currency}
                                  checked={values.acceptedCurrencies.includes(currency)}
                                  onChange={setCheckBox('acceptedCurrencies')}
                                />
                              )}
                            />
                          ))}
                        </div>

                        <ValidationMessage error={getErrorText('currency', 'acceptedCurrencies')} />
                      </div>
                    </div>
                  </div>
                )}

                {stepProfessionalSetup === 4 && (
                  <div className="upload__item">
                    <div className="upload__category">{t('Blockchain')}</div>

                    <div className="upload__fieldset details__fieldset">
                      <div className="field field__video">
                        <div className="video__item video__item-9-16">
                          <iframe
                            src="https://www.youtube.com/embed/478n1h7k6tM"
                            title={t('YouTube video player')}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            className="video-tenant"
                            allowFullScreen
                            frameBorder="0"
                          />
                        </div>
                      </div>

                      <div className="field__group">
                        <TextField
                          className="investhub__field field"
                          margin="normal"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          step={1}
                          label={t('Whats your token name?')}
                          value={values.tokenName}
                          onChange={setTokenName}
                          error={!!getErrorText('blockchain', 'tokenName')}
                          helperText={getErrorText('blockchain', 'tokenName')}
                        />

                        <TextField
                          className="investhub__field field"
                          margin="normal"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          step={1}
                          label={t('Your Token Symbol?')}
                          value={values.tokenSymbol}
                          onChange={setTokenSymbol}
                          error={!!getErrorText('blockchain', 'tokenSymbol')}
                          helperText={getErrorText('blockchain', 'tokenSymbol')}
                        />
                      </div>

                      <div className="field">
                        <div className="field__label">{t('Which crypto wallets will you accepted from investors?')}</div>

                        <div className="field__checkbox-wrapper field__checkbox-vertical">
                          <FormControlLabel
                            className="field field__checkbox field__checkbox-group no-margin__left"
                            label="ETH"
                            control={(
                              <Checkbox
                                color="primary"
                                value="eth"
                                checked={values.acceptedWallets.eth}
                                onChange={setAcceptedWallets('eth')}
                              />
                            )}
                          />

                          <FormControlLabel
                            className="field field__checkbox field__checkbox-group no-margin__left"
                            label={t('ixo Account ID')}
                            control={(
                              <Checkbox
                                color="primary"
                                value="ixo_id"
                                checked={values.acceptedWallets.ixo_id}
                                onChange={setAcceptedWallets('ixo_id')}
                              />
                            )}
                          />

                          <FormControlLabel
                            className="field field__checkbox field__checkbox-group no-margin__left"
                            label={t('ixo Account Address')}
                            control={(
                              <Checkbox
                                color="primary"
                                value="cosmos"
                                checked={values.acceptedWallets.cosmos}
                                onChange={setAcceptedWallets('cosmos')}
                              />
                            )}
                          />

                          <FormControlLabel
                            className="field field__checkbox field__checkbox-group no-margin__left"
                            label="BTC"
                            control={(
                              <Checkbox
                                color="primary"
                                value="btc"
                                checked={values.acceptedWallets.btc}
                                onChange={setAcceptedWallets('btc')}
                              />
                            )}
                          />

                          <FormControlLabel
                            className="field field__checkbox field__checkbox-group no-margin__left"
                            label={t('Others')}
                            control={(
                              <Checkbox
                                color="primary"
                                value="other"
                                checked={values.acceptedWallets.other}
                                onChange={setAcceptedWallets('other')}
                              />
                            )}
                          />
                        </div>

                        <ValidationMessage error={getErrorText('blockchain', 'acceptedWallets')} />
                      </div>

                      <div className="field">
                        <div className="field__label">{t('Which Blockchain should be used for the financial instrument deployed?')}</div>

                        <ValidationMessage error={getErrorText('blockchain', 'blockchainType')} />

                        <div className="investhub-table__wrapper">
                          <div className="investhub-table__box investhub-table__box-styled new-tenant__table">
                            <div className="investhub-table__row">{' '}</div>
                            <div className="investhub-table__row new-tenant__row-table">
                              <div className="investhub-table__col">
                                <strong>{t('Ethereum Blockchain')}</strong>
                                {t('Ethereum is the community-run technology powering the cryptocurrency ether (ETH) and thousands of decentralized applications')}
                              </div>

                              <div className="investhub-table__col">
                                <button
                                  type="button"
                                  className={`${values.blockchainType === 'ETHEREUM' ? 'button' : 'button-stroke'} button-small`}
                                  onClick={setButtonSelectField('blockchainType', 'ETHEREUM')}
                                >
                                  {values.blockchainType === 'ETHEREUM' ? t('Selected') : t('Select')}
                                </button>
                              </div>
                            </div>

                            <div className="investhub-table__row new-tenant__row-table">
                              <div className="investhub-table__col">
                                <strong>{t('Stellar Blockchain')}</strong>
                                {t('Stellar, or Stellar Lumens, is an open source, decentralized protocol for digital currency to fiat money low-cost transfers which allows cross-border transactions between any pair of currencies.')}
                              </div>

                              <div className="investhub-table__col">
                                <button
                                  type="button"
                                  className={`${values.blockchainType === 'STELLAR' ? 'button' : 'button-stroke'} button-small`}
                                  onClick={setButtonSelectField('blockchainType', 'STELLAR')}
                                >
                                  {values.blockchainType === 'STELLAR' ? t('Selected') : t('Select')}
                                </button>
                              </div>
                            </div>

                            <div className="investhub-table__row new-tenant__row-table">
                              <div className="investhub-table__col">
                                <strong>{t('Polygon Blockchain')}</strong>
                                {t('Polygon is a protocol and a framework for building and connecting Ethereum-compatible blockchain networks. Aggregating scalable solutions on Ethereum supporting a multi-chain Ethereum ecosystem.')}
                              </div>

                              <div className="investhub-table__col">
                                <button
                                  type="button"
                                  className={`${values.blockchainType === 'MATIC_SIDE_CHAIN' ? 'button' : 'button-stroke'} button-small`}
                                  onClick={setButtonSelectField('blockchainType', 'MATIC_SIDE_CHAIN')}
                                >
                                  {values.blockchainType === 'MATIC_SIDE_CHAIN' ? t('Selected') : t('Select')}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {stepProfessionalSetup === 5 && (
                  <div className="upload__item">
                    <div className="upload__category">{t('Duration')}</div>

                    <div className="upload__fieldset details__fieldset">
                      <div className="field field__video">
                        <div className="video__item video__item-9-16">
                          <iframe
                            width="560"
                            height="257"
                            src="https://www.youtube.com/embed/dJBkVAnnKOw"
                            title={t('YouTube video player')}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            className="video-tenant"
                          />
                        </div>
                      </div>

                      <div className="field">
                        <div className="field__label">{t('Which document are you using, for your investors to display the deal terms of the token? This is used through the subscription process of the Investor.')}</div>

                        <ValidationMessage error={getErrorText('paper', 'paperType')} />

                        <div className="investhub-table__wrapper">
                          <div className="investhub-table__box investhub-table__box-styled new-tenant__table">
                            <div className="investhub-table__row">{' '}</div>

                            <div className="investhub-table__row new-tenant__row-table">
                              <div className="investhub-table__col">
                                <strong>{t('Prospectus')}</strong>
                                {t('If you have an approved prospectus please choose this option.')}
                              </div>

                              <div className="investhub-table__col">
                                <button
                                  type="button"
                                  className={`${values.paperType === 'PROSPECTUS' ? 'button' : 'button-stroke'} button-small`}
                                  onClick={setButtonSelectField('paperType', 'PROSPECTUS')}
                                >
                                  {values.paperType === 'PROSPECTUS' ? t('Selected') : t('Select')}
                                </button>
                              </div>
                            </div>

                            <div className="investhub-table__row new-tenant__row-table">
                              <div className="investhub-table__col">
                                <strong>{t('Issuing Guidelines')}</strong>
                                {t('This is ideal if you are in a non prospectus requirement. Its usually showing all deal terms on one single page.')}
                              </div>

                              <div className="investhub-table__col">
                                <button
                                  type="button"
                                  className={`${values.paperType === 'ISSUING' ? 'button' : 'button-stroke'} button-small`}
                                  onClick={setButtonSelectField('paperType', 'ISSUING')}
                                >
                                  {values.paperType === 'ISSUING' ? t('Selected') : t('Select')}
                                </button>
                              </div>
                            </div>

                            <div className="investhub-table__row new-tenant__row-table">
                              <div className="investhub-table__col">
                                <strong>{t('Token Sale Agreement')}</strong>
                                {t('In case you are in a private sale this is your perfect option.')}
                              </div>

                              <div className="investhub-table__col">
                                <button
                                  type="button"
                                  className={`${values.paperType === 'TOKEN_SALE_AGREEMENT' ? 'button' : 'button-stroke'} button-small`}
                                  onClick={setButtonSelectField('paperType', 'TOKEN_SALE_AGREEMENT')}
                                >
                                  {values.paperType === 'TOKEN_SALE_AGREEMENT' ? t('Selected') : t('Select')}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="investhub__field field investhub-date">
                        <div className="field__label">{t('Date - The date your document is issued.')}</div>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="dd.MM.yyyy"
                            value={values.durationFrom}
                            onChange={setDateField('durationFrom')}
                            openTo="year"
                            views={['year', 'month', 'date']}
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                    </div>
                  </div>
                )}

                {stepProfessionalSetup === 6 && (
                  <>
                    <div className="upload__item">
                      <div className="upload__category">{t('KYC Levels')}</div>

                      <div className="upload__fieldset details__fieldset">
                        <div className="field field__video">
                          <div className="video__item video__item-9-16">
                            <iframe
                              width="560"
                              height="257"
                              src="https://www.youtube.com/embed/nSHMBGM1Clw"
                              title={t('YouTube video player')}
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                              className="video-tenant"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="upload__item">
                      <div className="upload__category">{t('Level 1')}</div>

                      <div className="upload__fieldset details__fieldset">
                        <div className="field__group">
                          <TextField
                            className="investhub__field field field-has__symbol"
                            margin="normal"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            label={t('Minimum invest amount')}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  $
                                </InputAdornment>
                              ),
                            }}
                            value={values.kycLevels[1].minInvestAmount}
                            onChange={setLevelField(1, 'minInvestAmount')}
                          />

                          <TextField
                            className="investhub__field field field-has__symbol"
                            margin="normal"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            label={t('Maximum invest amount')}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  $
                                </InputAdornment>
                              ),
                            }}
                            value={values.kycLevels[1].maxInvestAmount}
                            onChange={setLevelField(1, 'maxInvestAmount')}
                          />
                        </div>

                        <div className="field">
                          <div className="field__label">{t('Crypto')}</div>
                          <div className="field__checkbox-wrapper field__checkbox-vertical">
                            <FormControlLabel
                              className="field field__checkbox field__checkbox-group no-margin__left"
                              label={t('Accept tax number')}
                              control={(
                                <Checkbox
                                  color="primary"
                                  value="cryptoAcceptTaxNumber"
                                  checked={values.kycLevels[1].cryptoAcceptTaxNumber}
                                  onChange={setLevelCheckbox(1, 'cryptoAcceptTaxNumber')}
                                />
                              )}
                            />

                            <FormControlLabel
                              className="field field__checkbox field__checkbox-group no-margin__left"
                              label={t('Accept tax office')}
                              control={(
                                <Checkbox
                                  color="primary"
                                  value="cryptoAcceptTaxOffice"
                                  checked={values.kycLevels[1].cryptoAcceptTaxOffice}
                                  onChange={setLevelCheckbox(1, 'cryptoAcceptTaxOffice')}
                                />
                              )}
                            />

                            <FormControlLabel
                              className="field field__checkbox field__checkbox-group no-margin__left"
                              label={t('Accept description of funds')}
                              control={(
                                <Checkbox
                                  color="primary"
                                  value="cryptoAcceptDescriptionOfFunds"
                                  checked={values.kycLevels[1].cryptoAcceptDescriptionOfFunds}
                                  onChange={setLevelCheckbox(1, 'cryptoAcceptDescriptionOfFunds')}
                                />
                              )}
                            />

                            <FormControlLabel
                              className="field field__checkbox field__checkbox-group no-margin__left"
                              label={t('Accept upload photo proof documents')}
                              control={(
                                <Checkbox
                                  color="primary"
                                  value="cryptoAcceptUploadPhotoProofDocuments"
                                  checked={values.kycLevels[1].cryptoAcceptUploadPhotoProofDocuments}
                                  onChange={setLevelCheckbox(1, 'cryptoAcceptUploadPhotoProofDocuments')}
                                />
                              )}
                            />

                            <FormControlLabel
                              className="field field__checkbox field__checkbox-group no-margin__left"
                              label={t('Accept upload proof residence')}
                              control={(
                                <Checkbox
                                  color="primary"
                                  value="cryptoAcceptUploadProofResidence"
                                  checked={values.kycLevels[1].cryptoAcceptUploadProofResidence}
                                  onChange={setLevelCheckbox(1, 'cryptoAcceptUploadProofResidence')}
                                />
                              )}
                            />
                          </div>
                        </div>

                        <div className="field">
                          <div className="field__label">Fiat</div>
                          <div className="field__checkbox-wrapper field__checkbox-vertical">
                            <FormControlLabel
                              className="field field__checkbox field__checkbox-group no-margin__left"
                              label={t('Accept tax number')}
                              control={(
                                <Checkbox
                                  color="primary"
                                  value="fiatAcceptTaxNumber"
                                  checked={values.kycLevels[1].fiatAcceptTaxNumber}
                                  onChange={setLevelCheckbox(1, 'fiatAcceptTaxNumber')}
                                />
                              )}
                            />

                            <FormControlLabel
                              className="field field__checkbox field__checkbox-group no-margin__left"
                              label={t('Accept tax office')}
                              control={(
                                <Checkbox
                                  color="primary"
                                  value="fiatAcceptTaxOffice"
                                  checked={values.kycLevels[1].fiatAcceptTaxOffice}
                                  onChange={setLevelCheckbox(1, 'fiatAcceptTaxOffice')}
                                />
                              )}
                            />

                            <FormControlLabel
                              className="field field__checkbox field__checkbox-group no-margin__left"
                              label={t('Accept description of funds')}
                              control={(
                                <Checkbox
                                  color="primary"
                                  value="fiatAcceptDescriptionOfFunds"
                                  checked={values.kycLevels[1].fiatAcceptDescriptionOfFunds}
                                  onChange={setLevelCheckbox(1, 'fiatAcceptDescriptionOfFunds')}
                                />
                              )}
                            />

                            <FormControlLabel
                              className="field field__checkbox field__checkbox-group no-margin__left"
                              label={t('Accept upload photo proof documents')}
                              control={(
                                <Checkbox
                                  color="primary"
                                  value="fiatAcceptUploadPhotoProofDocuments"
                                  checked={values.kycLevels[1].fiatAcceptUploadPhotoProofDocuments}
                                  onChange={setLevelCheckbox(1, 'fiatAcceptUploadPhotoProofDocuments')}
                                />
                              )}
                            />

                            <FormControlLabel
                              className="field field__checkbox field__checkbox-group no-margin__left"
                              label={t('Accept upload proof residence')}
                              control={(
                                <Checkbox
                                  color="primary"
                                  value="fiatAcceptUploadProofResidence"
                                  checked={values.kycLevels[1].fiatAcceptUploadProofResidence}
                                  onChange={setLevelCheckbox(1, 'fiatAcceptUploadProofResidence')}
                                />
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    { values.availableLevels >= 2 && (
                      <div className="upload__item no-margin__bottom">
                        <div className="upload__category">{t('Level 2')}</div>

                        <div className="upload__fieldset details__fieldset">
                          <div className="field__group">
                            <TextField
                              className="investhub__field field field-has__symbol"
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              label={t('Minimum invest amount')}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    $
                                  </InputAdornment>
                                ),
                              }}
                              value={values.kycLevels[2].minInvestAmount}
                              onChange={setLevelField(2, 'minInvestAmount')}
                            />

                            <TextField
                              className="investhub__field field field-has__symbol"
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              label={t('Maximum invest amount')}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    $
                                  </InputAdornment>
                                ),
                              }}
                              value={values.kycLevels[2].maxInvestAmount}
                              onChange={setLevelField(2, 'maxInvestAmount')}
                            />
                          </div>
                        </div>
                      </div>
                    )}

                    { values.availableLevels >= 3 && (
                      <div className="upload__item no-margin__bottom">
                        <div className="upload__category">{t('Level 3')}</div>

                        <div className="upload__fieldset details__fieldset">
                          <div className="field__group">
                            <TextField
                              className="investhub__field field field-has__symbol"
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              label={t('Minimum invest amount')}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    $
                                  </InputAdornment>
                                ),
                              }}
                              value={values.kycLevels[3].minInvestAmount}
                              onChange={setLevelField(3, 'minInvestAmount')}
                            />

                            <TextField
                              className="investhub__field field field-has__symbol"
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              label={t('Maximum invest amount')}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    $
                                  </InputAdornment>
                                ),
                              }}
                              value={values.kycLevels[3].maxInvestAmount}
                              onChange={setLevelField(3, 'maxInvestAmount')}
                            />
                          </div>
                        </div>
                      </div>
                    )}

                    { values.availableLevels >= 4 && (
                      <div className="upload__item no-margin__bottom">
                        <div className="upload__category">{t('Level 4')}</div>

                        <div className="upload__fieldset details__fieldset">
                          <div className="field__group">
                            <TextField
                              className="investhub__field field field-has__symbol"
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              label={t('Minimum invest amount')}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    $
                                  </InputAdornment>
                                ),
                              }}
                              value={values.kycLevels[4].minInvestAmount}
                              onChange={setLevelField(4, 'minInvestAmount')}
                            />

                            <TextField
                              className="investhub__field field field-has__symbol"
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              label={t('Maximum invest amount')}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    $
                                  </InputAdornment>
                                ),
                              }}
                              value={values.kycLevels[4].maxInvestAmount}
                              onChange={setLevelField(4, 'maxInvestAmount')}
                            />
                          </div>
                        </div>
                      </div>
                    )}

                    { values.availableLevels >= 5 && (
                      <div className="upload__item no-margin__bottom">
                        <div className="upload__category">{t('Level 5')}</div>

                        <div className="upload__fieldset details__fieldset">
                          <div className="field__group">
                            <TextField
                              className="investhub__field field field-has__symbol"
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              label={t('Minimum invest amount')}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    $
                                  </InputAdornment>
                                ),
                              }}
                              value={values.kycLevels[5].minInvestAmount}
                              onChange={setLevelField(5, 'minInvestAmount')}
                            />

                            <TextField
                              className="investhub__field field field-has__symbol"
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              label={t('Maximum invest amount')}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    $
                                  </InputAdornment>
                                ),
                              }}
                              value={values.kycLevels[5].maxInvestAmount}
                              onChange={setLevelField(5, 'maxInvestAmount')}
                            />
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="upload__item">
                      <div className="upload__fieldset details__fieldset no-margin__top">
                        <div className="field__group field__group-inline field__group-addremoval">
                          <div className="field no-margin__bottom">
                            <button
                              type="button"
                              className="preview__clear no-margin__top"
                              disabled={values.availableLevels >= 5}
                              onClick={addLevel}
                            >
                              <Icon
                                name="plus-circle"
                                className={classNames('plus-circle')}
                                size="20"
                              />
                              {t('Add Level')}
                            </button>
                          </div>

                          <div className="field no-margin__bottom">
                            <button
                              type="button"
                              className="preview__clear preview__clear-red no-margin__top"
                              disabled={values.availableLevels <= 1}
                              onClick={removeLevel}
                            >
                              <Icon
                                name="close-circle"
                                className={classNames('close-circle')}
                                size="20"
                              />
                              {t('Removal level')}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {stepProfessionalSetup === 7 && (
                  <>
                    <div className="upload__item">
                      <div className="upload__category">
                        {t('APIs')}
                      </div>

                      <div className="upload__fieldset details__fieldset">
                        <div className="field__group">
                          <TextField
                            className="investhub__field field"
                            margin="normal"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            step={1}
                            label={t('Please let us know your company email')}
                            value={values.companyEmail}
                            onChange={setTextField('companyEmail')}
                            error={!!getErrorText('service', 'companyEmail')}
                            helperText={getErrorText('service', 'companyEmail')}
                          />

                          <TextField
                            className="investhub__field field"
                            margin="normal"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            step={1}
                            label={t('Your company name')}
                            value={values.companyName}
                            onChange={setTextField('companyName')}
                            error={!!getErrorText('service', 'companyName')}
                            helperText={getErrorText('service', 'companyName')}
                          />
                        </div>

                        <div className="field">
                          <div className="field__label">{t('Select')}</div>
                          <div className="investhub-table__wrapper">
                            <div className="investhub-table__box investhub-table__box-styled new-tenant__table">
                              <div className="investhub-table__row">{' '}</div>

                              <div className="investhub-table__row new-tenant__row-table">
                                <div className="investhub-table__col">
                                  <strong>{t('Liveness check')}</strong>
                                  {t('We currently have Onfido integrated also for Watchlist Enhanced checks. More to come with further updates.')}
                                </div>

                                <div className="investhub-table__col">
                                  <button
                                    type="button"
                                    className={`${values.api.videoIdent.enable ? 'button' : 'button-stroke'} button-small`}
                                    onClick={setEnableService('videoIdent', !values.api.videoIdent.enable)}
                                  >
                                    {values.api.videoIdent.enable ? t('Selected') : t('Select')}
                                  </button>
                                </div>
                              </div>

                              {values.api.videoIdent.enable && (
                                <div className="investhub-table__row new-tenant__row-table">
                                  <div className="investhub-table__col">
                                    <div className="field">
                                      <div className="field__label">{t('Select liveness check service')}</div>
                                      <RadioGroup
                                        row
                                        aria-label="status"
                                        name="status"
                                        className="field__radio"
                                        value={values.api.videoIdent.service}
                                        onChange={setApiField('videoIdent', 'service')}
                                      >
                                        <FormControlLabel value="onfido" control={<Radio />} label="Onfido" />
                                      </RadioGroup>
                                    </div>
                                  </div>
                                </div>
                              )}

                              <div className="investhub-table__row new-tenant__row-table">
                                <div className="investhub-table__col">
                                  <strong>{t('Mailgun')}</strong>
                                  {t('The Leading Email Delivery Service for Businesses Around the World')}
                                </div>

                                <div className="investhub-table__col">
                                  <button
                                    type="button"
                                    className={`${values.api.mailgun.enable ? 'button' : 'button-stroke'} button-small`}
                                    onClick={setEnableService('mailgun', !values.api.mailgun.enable)}
                                  >
                                    {values.api.mailgun.enable ? t('Selected') : t('Select')}
                                  </button>
                                </div>
                              </div>

                              <div className="investhub-table__row new-tenant__row-table new-tenant__row-note">
                                <div className="investhub-table__col">
                                  <div className="box-note box-note__orange">
                                    {t('To use your own mail servers, please set up mailgun and fill in the details here - please note that this will work once you added your cname. Otherwise you can do this later as well under settings. If you skip this setup, emails are sent via no-reply@investhub.io ')}
                                  </div>
                                </div>
                              </div>

                              { values.api.mailgun.enable && (
                                <>
                                  <div className="investhub-table__row new-tenant__row-table">
                                    <div className="investhub-table__col">
                                      <div className="field">
                                        <RadioGroup
                                          row
                                          aria-label="status"
                                          name="status"
                                          className="field__radio"
                                          value={values.api.mailgun.settingFrom}
                                          onChange={setApiField('mailgun', 'settingFrom')}
                                        >
                                          <FormControlLabel value="ownMailgun" control={<Radio />} label={t('Use my own Mailgun')} />
                                          <FormControlLabel value="investhubMailgun" control={<Radio />} label={t('Use investhub.io Mailgun')} />
                                        </RadioGroup>
                                      </div>
                                    </div>
                                  </div>

                                  { values.api.mailgun.settingFrom === 'ownMailgun' && (
                                    <div className="investhub-table__row new-tenant__row-table">
                                      <div className="investhub-table__col">
                                        <div className="field__group">
                                          <TextField
                                            className="investhub__field field"
                                            margin="normal"
                                            InputLabelProps={{
                                              shrink: true,
                                            }}
                                            step={1}
                                            label={t('MailGun Host')}
                                            value={values.api.mailgun.host}
                                            onChange={setApiField('mailgun', 'host')}
                                            error={!!getErrorText('service', 'mailgunHost')}
                                            helperText={getErrorText('service', 'mailgunHost')}
                                          />

                                          <TextField
                                            className="investhub__field field"
                                            margin="normal"
                                            InputLabelProps={{
                                              shrink: true,
                                            }}
                                            step={1}
                                            label={t('MailGun Port')}
                                            value={values.api.mailgun.port}
                                            onChange={setApiField('mailgun', 'port')}
                                            error={!!getErrorText('service', 'mailgunPort')}
                                            helperText={getErrorText('service', 'mailgunPort')}
                                          />

                                          <TextField
                                            className="investhub__field field"
                                            margin="normal"
                                            InputLabelProps={{
                                              shrink: true,
                                            }}
                                            step={1}
                                            label="MailGun User"
                                            value={values.api.mailgun.user}
                                            onChange={setApiField('mailgun', 'user')}
                                            error={!!getErrorText('service', 'mailgunUser')}
                                            helperText={getErrorText('service', 'mailgunUser')}
                                          />

                                          <TextField
                                            className="investhub__field field"
                                            margin="normal"
                                            InputLabelProps={{
                                              shrink: true,
                                            }}
                                            step={1}
                                            label={t('MailGun Password')}
                                            value={values.api.mailgun.password}
                                            onChange={setApiField('mailgun', 'password')}
                                            error={!!getErrorText('service', 'mailgunPassword')}
                                            helperText={getErrorText('service', 'mailgunPassword')}
                                          />

                                          <TextField
                                            className="investhub__field field"
                                            margin="normal"
                                            InputLabelProps={{
                                              shrink: true,
                                            }}
                                            step={1}
                                            label={t('From')}
                                            value={values.api.mailgun.from}
                                            onChange={setApiField('mailgun', 'from')}
                                            error={!!getErrorText('service', 'mailgunFrom')}
                                            helperText={getErrorText('service', 'mailgunFrom')}
                                          />

                                          <TextField
                                            className="investhub__field field"
                                            margin="normal"
                                            InputLabelProps={{
                                              shrink: true,
                                            }}
                                            step={1}
                                            label={t('From for comment')}
                                            value={values.api.mailgun.fromForComment}
                                            onChange={setApiField('mailgun', 'fromForComment')}
                                            error={!!getErrorText('service', 'mailgunFromForComment')}
                                            helperText={getErrorText('service', 'mailgunFromForComment')}
                                          />

                                          <TextField
                                            className="investhub__field field"
                                            margin="normal"
                                            InputLabelProps={{
                                              shrink: true,
                                            }}
                                            step={1}
                                            label={t('From duplicate')}
                                            value={values.api.mailgun.fromDuplicate}
                                            onChange={setApiField('mailgun', 'fromDuplicate')}
                                            error={!!getErrorText('service', 'mailgunFromDuplicate')}
                                            helperText={getErrorText('service', 'mailgunFromDuplicate')}
                                          />

                                          <TextField
                                            className="investhub__field field"
                                            margin="normal"
                                            InputLabelProps={{
                                              shrink: true,
                                            }}
                                            step={1}
                                            label={t('KYC approval email')}
                                            value={values.api.mailgun.kycApprovalEmail}
                                            onChange={setApiField('mailgun', 'kycApprovalEmail')}
                                            error={!!getErrorText('service', 'mailgunKycApprovalEmail')}
                                            helperText={getErrorText('service', 'mailgunKycApprovalEmail')}
                                          />

                                          <TextField
                                            className="investhub__field field"
                                            margin="normal"
                                            InputLabelProps={{
                                              shrink: true,
                                            }}
                                            step={1}
                                            label={t('Email subject prefix')}
                                            value={values.api.mailgun.emailSubjectPrefix}
                                            onChange={setApiField('mailgun', 'emailSubjectPrefix')}
                                            error={!!getErrorText('service', 'mailgunEmailSubjectPrefix')}
                                            helperText={getErrorText('service', 'mailgunEmailSubjectPrefix')}
                                          />

                                          <TextField
                                            className="investhub__field field"
                                            margin="normal"
                                            InputLabelProps={{
                                              shrink: true,
                                            }}
                                            step={1}
                                            label={t('Email video indentification failed twice')}
                                            value={values.api.mailgun.emailVideoIdentFailTwice}
                                            onChange={setApiField('mailgun', 'emailVideoIdentFailTwice')}
                                            error={!!getErrorText('service', 'mailgunEmailVideoIdentFailTwice')}
                                            helperText={getErrorText('service', 'mailgunEmailVideoIdentFailTwice')}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </>
                              )}

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {stepProfessionalSetup === 8 && (
                  <div className="upload__item">
                    <div className="upload__category">{t('Banking')}</div>

                    <div className="upload__fieldset details__fieldset">
                      <div className="field">
                        <RadioGroup
                          row
                          aria-label="status"
                          name="status"
                          className="field__radio"
                          value={selectedCurrency}
                          onChange={setCurrency}
                        >
                          { ['eur', 'usd', 'chf'].map((currency) => (
                            <FormControlLabel
                              key={currency}
                              value={currency}
                              control={<Radio />}
                              label={currency.toUpperCase()}
                            />
                          ))}
                        </RadioGroup>
                      </div>

                      <div className="field__group">
                        <TextField
                          className="investhub__field field"
                          margin="normal"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          label={t('Account name')}
                          value={values.bank[selectedCurrency].accountName}
                          onChange={setBankField('accountName')}
                        />

                        <TextField
                          className="investhub__field field"
                          margin="normal"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          label={t('Account number')}
                          value={values.bank[selectedCurrency].accountNumber}
                          onChange={setBankField('accountNumber')}
                        />
                      </div>

                      <div className="field__group">
                        <TextField
                          className="investhub__field field"
                          margin="normal"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          label={t('Bank name')}
                          value={values.bank[selectedCurrency].bankName}
                          onChange={setBankField('bankName')}
                        />

                        <TextField
                          className="investhub__field field"
                          margin="normal"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          label={t('Bank address')}
                          value={values.bank[selectedCurrency].bankAddress}
                          onChange={setBankField('bankAddress')}
                        />
                      </div>

                      <div className="field__group">
                        <TextField
                          className="investhub__field field"
                          margin="normal"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          label={t('Routing number')}
                          value={values.bank[selectedCurrency].routingNumber}
                          onChange={setBankField('routingNumber')}
                        />

                        <TextField
                          className="investhub__field field"
                          margin="normal"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          label={t('IBAN')}
                          value={values.bank[selectedCurrency].swiftBic}
                          onChange={setBankField('swiftBic')}
                        />
                      </div>

                      <TextField
                        className="investhub__field field"
                        margin="normal"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Swift/BIC"
                      />
                    </div>
                  </div>
                )}

                {stepProfessionalSetup === 9 && (
                  <div className="upload__item">
                    <div className="upload__category">
                      {t('Smart Contract Address')}
                    </div>

                    <div className="upload__fieldset details__fieldset">
                      <div className="field">
                        <div className="field__label">{t('Do you have your smart contract address already deployed where Investors can send an ERC-20 issued Stablecoin like USDC?')}</div>

                        <RadioGroup
                          row
                          aria-label="smart_contract_address"
                          name="smart_contract_address"
                          className="field__radio"
                          value={values.hasWalletAddress}
                          onChange={setTextField('hasWalletAddress')}
                        >
                          <FormControlLabel value="yes" control={<Radio />} label={t('Yes')} />
                          <FormControlLabel value="no" control={<Radio />} label={t('No')} />
                        </RadioGroup>
                      </div>

                      <div className="field__group">
                        <div className="field">
                          <div className="field__label">{t('Wallet type')}</div>

                          <TextField
                            select
                            className="select investhub-select select-trans"
                            required={values.hasWalletAddress === 'yes'}
                            disabled={values.hasWalletAddress !== 'yes'}
                            value={values.walletType}
                            onChange={setTextField('walletType')}
                            error={!!getErrorText('wallet', 'walletType')}
                            helperText={getErrorText('wallet', 'walletType')}
                          >
                            <MenuItem value="eth">ETH</MenuItem>
                            <MenuItem value="usdc">USDC</MenuItem>
                          </TextField>
                        </div>

                        <TextField
                          className="investhub__field field field-has__button"
                          margin="normal"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          label={t('Address')}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <button
                                  type="button"
                                  className="button button-small"
                                >
                                  {t('Add')}
                                </button>
                              </InputAdornment>
                            ),
                          }}
                          required={values.hasWalletAddress === 'yes'}
                          disabled={values.hasWalletAddress !== 'yes'}
                          value={values.walletAddress}
                          onChange={setTextField('walletAddress')}
                          error={!!getErrorText('wallet', 'walletAddress')}
                          helperText={getErrorText('wallet', 'walletAddress')}
                        />
                      </div>
                    </div>
                  </div>
                )}

                {stepProfessionalSetup === 10 && (
                  <>
                    <div className="upload__item no-margin__bottom">
                      <div className="upload__category">
                        {t('Referral Traffic')}
                      </div>
                      <div className="upload__note">
                        <Trans
                          i18nKey={t('In case you have referral traffic channels, please refer to our documentation how to use external traffic and enable them a single sign on - the API is also feeding back the referral partner with information as per our documentation. Please note that your platform can only accept traffic from whitelisted URLs. You can always change them through the settings.')}
                          t={t}
                          components={{
                            documentLink: <NavLink to="/" />,
                          }}
                        />
                      </div>

                      <div className="upload__fieldset details__fieldset">
                        <div className="field__group">
                          <TextField
                            className="investhub__field field"
                            margin="normal"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            label={t('API url')}
                            value={values.saleApi.url}
                            onChange={setSaleApiField('url')}
                          />

                          <TextField
                            className="investhub__field field"
                            margin="normal"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            label={t('API Key')}
                            value={values.saleApi.key}
                            onChange={setSaleApiField('key')}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="upload__item">
                      <div className="upload__fieldset details__fieldset no-margin__top">
                        <div className="field__group field__group-inline">
                          <div className="field no-margin__bottom">
                            <button
                              type="button"
                              className="preview__clear no-margin__top"
                            >
                              <Icon
                                name="plus-circle"
                                className={classNames('plus-circle')}
                                size="20"
                              />
                              {t('Update')}
                            </button>
                          </div>

                          <div className="field no-margin__bottom">
                            <button
                              type="button"
                              className="preview__clear preview__clear-red no-margin__top"
                            >
                              <Icon
                                name="close-circle"
                                className={classNames('close-circle')}
                                size="20"
                              />
                              {t('Removal level')}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {stepProfessionalSetup === 11 && (
                  <>
                    <div className="upload__item">
                      <div className="upload__category">
                        {t('Please upload the following graphics')}
                        :
                      </div>

                      <div className="upload__fieldset details__fieldset">
                        <div className="field__group">
                          <div className="investhub__field field field-upload">
                            <div className="field__label">{t('Logo')}</div>

                            <div className="field-upload__wrapper">
                              <Upload
                                preview
                                accept="image/*"
                                fieldFormat={t('PNG, GIF, JPEG or WEBP. Max 1Gb.')}
                                state={values.brand.logo}
                                setState={setBrandField('logo')}
                              />
                            </div>
                          </div>

                          <div className="investhub__field field field-upload">
                            <div className="field__label">{t('Dataroom Graphic')}</div>

                            <div className="field-upload__wrapper">
                              <Upload
                                preview
                                accept="image/*"
                                fieldFormat={t('PNG, GIF, JPEG or WEBP. Max 1Gb.')}
                                state={values.brand.dataroomGraphic}
                                setState={setBrandField('dataroomGraphic')}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="field__group">
                          <div className="investhub__field field field-upload">
                            <div className="field__label">{t('Source of fund')}</div>

                            <div className="field-upload__wrapper">
                              <Upload
                                preview
                                accept="image/*"
                                fieldFormat={t('PNG, GIF, JPEG or WEBP. Max 1Gb.')}
                                state={values.brand.sourceOfFund}
                                setState={setBrandField('sourceOfFund')}
                              />
                            </div>
                            <div className="details__note field-upload__note">
                              {t('Download it here if you want to use ours')}
                              {' '}
                              <NavLink to="/">{t('Download')}</NavLink>
                            </div>
                          </div>

                          <div className="investhub__field field field-upload">
                            <div className="field__label">{t('Sample withdrawal form')}</div>

                            <div className="field-upload__wrapper">
                              <Upload
                                preview
                                accept="image/*"
                                fieldFormat={t('PNG, GIF, JPEG or WEBP. Max 1Gb.')}
                                state={values.brand.sampleWithdrawalForm}
                                setState={setBrandField('sampleWithdrawalForm')}
                              />
                            </div>

                            <div className="details__note field-upload__note">
                              {t('Download it here if you want to use ours')}
                              {' '}
                              <NavLink to="/">{t('Download')}</NavLink>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {stepProfessionalSetup === 12 && (
                  <>
                    <div className="upload__item">
                      <div className="upload__category">
                        {t('Two-Factor Verification')}
                        {' '}
                        {user.isTwoFaEnabled && (
                          <div className="status status-green">{t('Enabled')}</div>
                        )}
                        {!user.isTwoFaEnabled && (
                          <div className="status status-red">{t('Disabled')}</div>
                        )}
                      </div>
                      <div className="upload__note">
                        {!user.isTwoFaEnabled && (
                          <>
                            {t('Please set up 2FA.')}
                            <br />
                            {t('Alternatively you can skip this step and do it later through the profile on the top right. Please note that this is required to make changes to any admin setting and save it.')}
                          </>
                        )}
                        {user.isTwoFaEnabled && (
                          <>
                            {t('Two-Factor Verification has been set up.')}
                          </>
                        )}
                      </div>

                      <div className="upload__fieldset details__fieldset">
                        <div className="new-tenant__2fa">
                          <div className="new-tenant__2fa-text">
                            {t('Two-factor authentication is a method used for protection of your web account. When it is activated you are required to enter not only your password, but also an activation code. This code will be issued to you by a mobile application like Google Authenticator app. Even if anyone would find out your password 2FA would still protect your account from unauthorized access. Security is our top priority at all times.')}
                          </div>

                          {!user.isTwoFaEnabled && (
                            <button type="button" className="button button-small" onClick={() => setIs2FaDialogOpened(true)}>
                              {t('Enable 2FA')}
                            </button>
                          )}
                        </div>
                      </div>
                    </div>

                    <Dialog
                      open={is2FaDialogOpened}
                      onClose={() => setIs2FaDialogOpened(false)}
                      className="investhub-modal investhub-modal__m"
                    >
                      <ModalInnerMaterialDialog>
                        <div>
                          <SetupTwoFactorAuth
                            onClose={() => setIs2FaDialogOpened(false)}
                            onComplete={() => {
                              refetch()
                              setIs2FaDialogOpened(false)
                            }}
                          />
                        </div>
                      </ModalInnerMaterialDialog>
                    </Dialog>
                  </>
                )}

                <div className="upload__foot details__foot new-tenant__foot">
                  <div className="details__btns details__btns_flex">
                    { stepProfessionalSetup > 1 ? (
                      <button type="button" className="button-stroke details__button" onClick={prevStepProfessionalSetup}>
                        {t('Back')}
                      </button>
                    ) : (
                      <></>
                    )}

                    { stepProfessionalSetup > 1 ? (
                      <div className="new-tenant__stepper-nav new-tenant__stepper-mobile desktop-hide tablet-show">
                        <Stepper
                          activeStep={stepProfessionalSetup - 1}
                          orientation="vertical"
                        >
                          { stepListProfessionalSetup.map((label) => (
                            <Step key={label}>
                              <StepLabel>{t(label)}</StepLabel>
                            </Step>
                          ))}
                        </Stepper>
                      </div>
                    ) : (
                      <></>
                    )}

                    { stepProfessionalSetup < 12 && (
                      <button type="button" className="button details__button" onClick={nextStepProfessionalSetup}>
                        {t('Next')}
                      </button>
                    )}
                    { stepProfessionalSetup >= 12 && registerTenantStatus.loading && (
                      <CircularProgress />
                    )}
                    { stepProfessionalSetup >= 12 && !registerTenantStatus.loading && (
                      <button type="button" className="button details__button" onClick={nextStepProfessionalSetup}>
                        {t('Complete')}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default TenantProfessionalSetup
