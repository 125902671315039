import React from 'react'
import classNames from 'classnames'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import styles from './styles.module.scss'

const Cta = () => {
  const { t } = useTranslation()

  return (
    <div className={classNames('section', styles.cta)}>
      <div className={classNames('center', styles.cta__center)}>
        <div className={styles.cta__list}>
          <div className={styles.cta__item}>
            <div className={styles.cta__item_inner}>
              <h2 className={classNames('h3', styles.cta__item_title)}>
                {t('Better for your Company.')}
              </h2>

              <ul className={styles.cta__item_list}>
                <li>
                  {t('Trust over 3800 whitelisted existing early adopters')}
                </li>
                <li>
                  {t('Instant capital allocation')}
                </li>
                <li>
                  {t('Be innovative together with your Investors')}
                </li>
                <li>
                  {t('Built in Compliance & Tokenisation layer')}
                </li>
              </ul>

              <NavLink to="/signup" className={classNames('button-white', styles.cta__item_button)}>
                {t('Create an Opportunity')}
              </NavLink>
            </div>
          </div>

          <div className={styles.cta__item}>
            <div className={styles.cta__item_inner}>
              <h2 className={classNames('h2', styles.cta__item_title)}>
                {t('Better for Investors.')}
              </h2>

              <ul className={styles.cta__item_list}>
                <li>
                  {t('One wallet to access all opportunities')}
                </li>
                <li>
                  {t('One KYC for all projects')}
                </li>
                <li>
                  {t('Regulatory compliance')}
                </li>
                <li>
                  {t('Transparent fees')}
                </li>
                <li>
                  {t('Access to private deals')}
                </li>
              </ul>

              <NavLink to="/signup" className={classNames('button-white', styles.cta__item_button)}>
                {t('Create an Opportunity')}
              </NavLink>
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}

export default Cta
