import gql from 'graphql-tag'

export const UPGRADE_SUBSCRIPTION = gql`
  mutation upgradeSubscription($tenantId: ID!, $planId: String!, $voucherCode: String) {
    upgradeSubscription(tenantId: $tenantId, planId: $planId, voucherCode: $voucherCode)
  }
`;

export const RENEW_SUBSCRIPTION = gql`
  mutation renewSubscription($tenantId: ID!) {
    renewSubscription(tenantId: $tenantId)
  }
`;
