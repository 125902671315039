import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import {
  TextField,
} from '@material-ui/core'

import Upload from '../common/Upload'

const BrandSetting = ({
  settings,
  setSubSettingField,
  setSubSettingFile,
}) => {
  const { t } = useTranslation()

  return (
    <div id="setting-mail" className="upload__item">
      <div className="upload__category">{t('Brand Setting')}</div>

      <div className="upload__fieldset details__fieldset">
        <TextField
          className="investhub__field field"
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          label={t('Site title')}
          value={settings.brand.siteTitle}
          onChange={setSubSettingField('brand', 'siteTitle')}
        />

        <div className="investhub__field field field-upload">
          <div className="field__label">
            {t('Site Logo')}
          </div>

          <div className="field-upload__wrapper">
            <Upload
              preview
              accept="image/*"
              state={settings.brand.logo}
              setState={setSubSettingFile('brand', 'logo')}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

BrandSetting.propTypes = {
  settings: PropTypes.object.isRequired,
  setSubSettingField: PropTypes.func.isRequired,
  setSubSettingFile: PropTypes.func.isRequired,
}

export default BrandSetting
