import React from 'react'
import PropTypes from 'prop-types'
import {
  TextField,
  InputAdornment,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const MediumSetting = ({
  settings,
  setSubSettingField,
}) => {
  const { t } = useTranslation()

  return (
    <div id="setting-medium" className="upload__item">
      <div className="upload__category">{t('Medium Setting')}</div>

      <div className="upload__fieldset details__fieldset">
        <TextField
          className="investhub__field field"
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          type="text"
          label={t('Medium user name')}
          value={settings.medium.username}
          onChange={setSubSettingField('medium', 'username')}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">@</InputAdornment>
            ),
          }}
        />
      </div>
    </div>
  )
}

MediumSetting.propTypes = {
  settings: PropTypes.object.isRequired,
  setSubSettingField: PropTypes.func.isRequired,
}

export default MediumSetting
