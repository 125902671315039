import React from 'react'
import i18n from 'i18next'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import * as languageHelper from '../../../utils/lang'

import Styles from './styles.module.scss'

const TranslationRwd = () => {
  const { t } = useTranslation()

  return (
    <div className={`header__nav ${Styles.translation_rwd}`}>
      <div className={`footer__head ${Styles.translation_rwd__head}`}>
        {t('Choose Translation')}
      </div>

      {Object.values(languageHelper.supportedLanguages).map((lang) => (
        <NavLink
          to="#"
          key={lang.code}
          className={`header__link site-translation__rwd ${Styles.translation_rwd__link} ${Styles.translation_rwd__switch} ${languageHelper.getLocale() === lang.code ? 'has-active' : ''}`}
          onClick={() => { languageHelper.setLocale(lang.code); i18n.changeLanguage(lang.code) }}
        >
          { lang.label }
        </NavLink>
      ))}
    </div>
  )
}

export default TranslationRwd
