import React from 'react'
import PropTypes from 'prop-types'
import { Range } from 'react-range'
import { useTranslation } from 'react-i18next'

const PriceRange = ({
  rangeValues,
  setRangeValues,
  rangeStep = 40,
  rangeMin = 10,
  rangeMax = 500,
}) => {
  const { t } = useTranslation()
  const RANGESTEP = rangeStep === undefined ? 40 : rangeStep
  const RANGEMIN = rangeMin === undefined ? 10 : rangeMin
  const RANGEMAX = rangeMax === undefined ? 500 : rangeMax

  return (
    <div className="range">
      <div className="range__label">{t('Price range')}</div>

      <Range
        values={rangeValues}
        step={RANGESTEP}
        min={RANGEMIN}
        max={RANGEMAX}
        /* eslint-disable no-shadow */
        onChange={(rangeValues) => setRangeValues(rangeValues)}
        renderTrack={({ props, children }) => (
          /* eslint-disable react/prop-types,jsx-a11y/no-static-element-interactions */
          <div
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            style={{
              ...props.style,
              height: '36px',
              display: 'flex',
              width: '100%',
            }}
          >
            <div
              ref={props.ref}
              style={{
                height: '8px',
                width: '100%',
                borderRadius: '4px',
                background: '#E6E8EC',
                alignSelf: 'center',
              }}
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({ props }) => (
          <div
            {...props}
            style={{
              ...props.style,
              height: '24px',
              width: '24px',
              borderRadius: '50%',
              backgroundColor: '#3772FF',
              border: '4px solid #FCFCFD',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                position: 'absolute',
                top: '-33px',
                color: '#fff',
                fontWeight: '600',
                fontSize: '14px',
                lineHeight: '18px',
                fontFamily: 'Poppins',
                padding: '4px 8px',
                borderRadius: '8px',
                backgroundColor: '#141416',
              }}
            >
              {rangeValues[0].toFixed(1)}
            </div>
          </div>
        )}
      />
      <div className="range__indicators">
        <div className="range__text">10 Euro</div>
        <div className="range__text">500 Euro</div>
      </div>
    </div>
  )
}

PriceRange.propTypes = {
  rangeValues: PropTypes.array.isRequired,
  setRangeValues: PropTypes.func.isRequired,
  rangeStep: PropTypes.number,
  rangeMin: PropTypes.number,
  rangeMax: PropTypes.number,
}

export default PriceRange
