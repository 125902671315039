import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  TextField,
  InputAdornment,
} from '@material-ui/core'
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import { useTranslation } from 'react-i18next'

const StripeSetting = ({
  settings,
  setSubSettingField,
}) => {
  const { t } = useTranslation()
  const [showSecretKey, setShowSecretKey] = useState(false)
  const [showWebhookKey, setShowWebhookKey] = useState(false)

  return (
    <div id="setting-stripe" className="upload__item">
      <div className="upload__category">{t('Stripe Setting')}</div>

      <div className="upload__fieldset details__fieldset">
        <div className="field__group field__group-3">
          <TextField
            className="investhub__field field"
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            label={t('Public key')}
            value={settings.stripe.publicKey}
            onChange={setSubSettingField('stripe', 'publicKey')}
          />

          <div className="investhub__field field field-has__icon">
            <div className="field__label">
              {t('Secret key')}
            </div>
            <TextField
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              type={showSecretKey ? 'text' : 'password'}
              value={settings.stripe.secretKey}
              onChange={setSubSettingField('stripe', 'secretKey')}
              InputProps={{
                endAdornment: (
                  <InputAdornment className="clickable" position="end" onClick={() => setShowSecretKey(!showSecretKey)}>
                    {showSecretKey ? (<VisibilityOffIcon />) : (<VisibilityIcon />)}
                  </InputAdornment>
                ),
              }}
            />
          </div>

          <div className="investhub__field field field-has__icon">
            <div className="field__label">
              {t('Webhook secret key')}
            </div>

            <TextField
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              type={showWebhookKey ? 'text' : 'password'}
              value={settings.stripe.webhookSecretKey}
              onChange={setSubSettingField('stripe', 'webhookSecretKey')}
              InputProps={{
                endAdornment: (
                  <InputAdornment className="clickable" position="end" onClick={() => setShowWebhookKey(!showWebhookKey)}>
                    {showWebhookKey ? (<VisibilityOffIcon />) : (<VisibilityIcon />)}
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

StripeSetting.propTypes = {
  settings: PropTypes.object.isRequired,
  setSubSettingField: PropTypes.func.isRequired,
}

export default StripeSetting
