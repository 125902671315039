import gql from 'graphql-tag'

export const ME = gql`
  query me {
    me {
      id
      email
      fullname
      rights
      isTwoFaEnabled
    }
  }
`;

export const RESTORE_CHECK = gql`
  query restoreCheck($input: RestoreCheckInput) {
    restoreCheck(input: $input)
  }
`;

export const NEW_2FA_SECRET = gql`
  query new2FaSecret {
    new2FaSecret
  }
`;


export const IS_2FA_NEEDED = gql`
  query is2FaNeeded($email: String) {
    is2FaNeeded(email: $email)
  }
`;
