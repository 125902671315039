import React from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { animated } from 'react-spring'
import { use3dEffect } from 'use-3d-effect'

import ContentImage from '../../../media/images/explanationFeature/explanationFeature'
import styles from './styles.module.scss'

const ContentOne = () => {
  const { t } = useTranslation()

  const ref = React.useRef(null)
  const { style, ...mouseHandlers } = use3dEffect(ref)

  const ref3 = React.useRef(null)
  const { style3, ...mouseHandlers3 } = use3dEffect(ref3)

  return (
    <>
      <div className={classNames('section', styles.content, styles.content__right, styles.content__space, styles.content__nospacetop)}>
        <div className={classNames('center', styles.content__center)}>

          <div className={styles.content__image}>
            <animated.div
              ref={ref}
              style={{
                ...style,
              }}
              {...mouseHandlers}
            >
              <img src={ContentImage.modularEngine} alt={t('Modular engine, like a Swiss Knife.')} />
            </animated.div>
          </div>

          <div className={styles.content__description}>
            <h2 className={classNames("h3", styles.content__title)}>
              {t('Modular engine, like a Swiss Knife.')}
            </h2>

            <div className={styles.content__text}>
              <p>
                {t('One of our Community Contributors is a registered Token Generator in Liechtenstein, many other jurisdictions to follow. Only the best for Remote Identification.')}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className={classNames('section', styles.content, styles.content__left, styles.content__nospacebottom)}>
        <div className={classNames('center', styles.content__center)}>

          <div className={styles.content__image}>
            <animated.div
              ref={ref3}
              style={{
                ...style3,
              }}
              {...mouseHandlers3}
            >
              <img src={ContentImage.diligenceData} alt={t('Due Dilligence data exportable')} />
            </animated.div>
          </div>

          <div className={styles.content__description}>
            <h2 className={classNames("h3", styles.content__title)}>
              {t('Due Dilligence data exportable')}
            </h2>

            <div className={styles.content__text}>
              <p>
                {t('Your compliance officer should not waste time with the due dilligence act, downloadable or pushable via API to your local Datacenter.')}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ContentOne
