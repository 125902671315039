import gql from 'graphql-tag'

export const GET_VOUCHER_CODE = gql`
  query getVoucherCode ($id: String){
    getVoucherCode (id: $id){
      id
      object
      percent_off
    }
  }
`;
