import React, { useEffect, useState } from 'react'
import ArticleAPI from '../../config/ArticleAPI'
import LearnCard from './Card'
import Loader from '../Loader'

const List = () => {
  const [learns, setLearns] = useState([])

  useEffect(() => {
    ArticleAPI.find().then((res) => {
      <>
        {/* eslint-disable-next-line */}
        {console.log(res)}
        {/* eslint-disable-next-line */}
        {setLearns(res)}
      </>
    })
  }, [])

  return (
    <div className="variants__list investhub-learn__list">
      {
        learns.length === 0
          ? (
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
              <Loader />
            </div>
          )
          : learns.data.map((learn) => (
            <LearnCard
              key={learn.id}
              title={learn.attributes.Title}
              datePublished={learn.attributes.publishedAt}
              idArticle={learn.id}
              // idDetail={learn.id}
              idDetail={learn.attributes.Slug}
            />
          ))
      }
    </div>
  )
}

export default List
