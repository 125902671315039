import React from 'react'
import PropTypes from 'prop-types'
import {
  TextField,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const CloudflareSetting = ({
  settings,
  setSubSettingField,
  setSubSettingCheckbox,
}) => {
  const { t } = useTranslation()

  return (
    <div id="setting-cloudflare" className="upload__item">
      <div className="upload__category">{t('CloudFlare Setting')}</div>

      <div className="upload__fieldset details__fieldset">
        <FormControlLabel
          className="field field__checkbox no-margin__left"
          label={t('Enable service')}
          control={(
            <Checkbox
              checked={!!settings.cloudflare.enable}
              onChange={setSubSettingCheckbox('cloudflare', 'enable')}
              color="primary"
            />
          )}
        />

        <div className="field__group field__group-3">
          <TextField
            className="investhub__field field"
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            label={t('Auth email')}
            value={settings.cloudflare.authEmail}
            onChange={setSubSettingField('cloudflare', 'authEmail')}
          />

          <TextField
            className="investhub__field field"
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            label={t('Zone ID')}
            value={settings.cloudflare.zoneId}
            onChange={setSubSettingField('cloudflare', 'zoneId')}
          />

          <TextField
            className="investhub__field field"
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            type="password"
            label={t('Auth key')}
            value={settings.cloudflare.authKey}
            onChange={setSubSettingField('cloudflare', 'authKey')}
          />
        </div>

        <div className="field__group field__group-3">
          <TextField
            className="investhub__field field"
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            type="password"
            label={t('API Token')}
            value={settings.cloudflare.apiToken}
            onChange={setSubSettingField('cloudflare', 'apiToken')}
          />

          <TextField
            className="investhub__field field"
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            label={t('Base domain')}
            value={settings.cloudflare.baseDomain}
            onChange={setSubSettingField('cloudflare', 'baseDomain')}
          />

          <TextField
            className="investhub__field field"
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            label={t('Load Balancer Host')}
            value={settings.cloudflare.loadbalancerHost}
            onChange={setSubSettingField('cloudflare', 'loadbalancerHost')}
          />
        </div>
      </div>
    </div>
  )
}

CloudflareSetting.propTypes = {
  settings: PropTypes.object.isRequired,
  setSubSettingField: PropTypes.func.isRequired,
  setSubSettingCheckbox: PropTypes.func.isRequired,
}

export default CloudflareSetting
