import React from 'react'
// import classNames from 'classnames'
import PropTypes from 'prop-types'
// import Icon from '../Icon'

const ModalInnerMaterialDialog = ({
  modalTitle,
  modalOnClickClose,
  children,
}) => (
  <div className="popup js-popup xid-modal__inner">
    <div className="popup__item" style={{ display: 'block' }}>
      {modalTitle && (
        <div className="popup__title h4">
          {modalTitle}
        </div>
      )}

      {children}

      <button
        type="button"
        className="mfp-close"
        onClick={modalOnClickClose}
      >
        x
      </button>
    </div>
  </div>
)

ModalInnerMaterialDialog.propTypes = {
  modalTitle: PropTypes.string,
  modalOnClickClose: PropTypes.func,
}

export default ModalInnerMaterialDialog
