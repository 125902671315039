import gql from 'graphql-tag'

export const REGISTRATION = gql`
  mutation registration($input: RegistrationInput) {
    registration(input: $input) {
      email
      fullname
    }
  }
`;

export const REGISTRATION_CONFIRM = gql`
  mutation registrationConfirm($input: RegistrationConfirmInput) {
    registrationConfirm(input: $input) {
      accessToken
      refreshToken
    }
  }
`;

export const LOGIN = gql`
  mutation login($input: LoginInput) {
    login(input: $input) {
      accessToken
      refreshToken
    }
  }
`;

export const RESTORE = gql`
  mutation restore($input: RestoreInput) {
    restore(input: $input)
  }
`;

export const RESTORE_CONFIRM = gql`
  mutation restoreConfirm($input: RestoreConfirmInput) {
    restoreConfirm(input: $input) {
      accessToken
      refreshToken
    }
  }
`;

export const SET_2FA_SECRET = gql`
    mutation set2FaSecret($secret: String, $token: String) {
      set2FaSecret(secret: $secret, token: $token)
    }
`;

export const DISABLE_2FA = gql`
    mutation disable2Fa($token: String) {
      disable2Fa(token: $token)
    }
`;

export const WALLET_SIGN_IN = gql`
  mutation walletSignIn($input: WalletSignInInput) {
    walletSignIn(input: $input) {
      accessToken
      refreshToken
    }
  }
`;
