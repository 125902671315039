import React, { useState } from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import moment from 'moment-timezone'
import { useTranslation } from 'react-i18next'

import {
  CircularProgress,
  Dialog,

  TablePagination,
} from '@material-ui/core'

import {
  GET_ADMIN_TENANT,
  MARK_AS_SETUP_COMPLETED,
} from '../../queriesAndMutations'
import { TENANT_STATUS } from '../../constants/tenant'
import { capitalize, toaster } from '../../utils'

import TablePaginationActions from '../common/TablePaginationActions'
import CustomNavLink from '../common/CustomNavLink'
import ModalInnerMaterialDialog from '../ModalInnerMaterialDialog'

const AdminTenantTable = () => {
  const { t } = useTranslation()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(+localStorage.getItem('admin-tenant_table-rows_per_page') || 5)
  const { data, refetch, loading } = useQuery(GET_ADMIN_TENANT, {
    variables: {
      page,
      rowsPerPage,
    },
  })
  const [markTenantAsSetupCompleted, markTenantAsSetupCompletedStatus] = useMutation(MARK_AS_SETUP_COMPLETED)
  const allTenants = data ? data.getAdminTenant.tenants : []
  const tenantCount = data ? data.getAdminTenant.count : 0
  const formatDate = (timestamp) => moment(+timestamp).tz('Europe/Vaduz').format('YYYY-MM-DD HH:mm z')
  const renderStatus = (status) => {
    let label = ''
    let className
    switch (status) {
      case TENANT_STATUS.AWAIT_PURCHASE:
        label = 'Subscription awaiting'
        className = 'status-stroke-black'
        break
      case TENANT_STATUS.PROCESSING:
        label = 'Need processing'
        className = 'status-stroke-orange'
        break
      case TENANT_STATUS.RUNNING:
        label = 'Running'
        className = 'status-stroke-green'
        break
      default:
        label = capitalize(status.replace('_', ' ').toLowerCase())
    }

    return <span className={className}>{t(label)}</span>
  }
  const [selectedTenant, setSelectedTenant] = useState(null)
  const [isMarkAsCompletedDialogOpen, setIsMarkAsCompletedDialogOpen] = useState(false)
  const showMarkAsCompletedDialog = (tenant) => {
    setSelectedTenant(tenant)
    setIsMarkAsCompletedDialogOpen(true)
  }
  const updateSelectedTenantStatusToCompleted = () => {
    markTenantAsSetupCompleted({
      variables: {
        id: selectedTenant.id,
      },
    }).then((response) => {
      if (response.data.markTenantAsSetupCompleted.success) {
        toaster.success(t('Tenant status update successfully'))
        refetch()
        setIsMarkAsCompletedDialogOpen(false)
      } else {
        toaster.error(t('Tenant status update failed. RESPONSE_TENANT', { RESPONSE_TENANT: response.data.markTenantAsSetupCompleted.message }))
      }
    }).catch(() => {})
  }
  const changePage = (event, nextPage) => {
    setPage(nextPage)
  }
  const changeRowsPerPage = (event) => {
    localStorage.getItem('admin-tenant_table-rows_per_page', +event.target.value)
    setPage(0)
    setRowsPerPage(+event.target.value)
  }

  return (
    <>
      <div className="investhub-table__wrapper">
        <div className="investhub-table__box investhub-table__box-styled">
          <div className="investhub-table__row">
            <div className="investhub-table__col">{t('ID')}</div>
            <div className="investhub-table__col">{t('Schema')}</div>
            <div className="investhub-table__col">{t('User')}</div>
            <div className="investhub-table__col">{t('Domain')}</div>
            <div className="investhub-table__col">{t('Created At')}</div>
            <div className="investhub-table__col">{t('Status')}</div>
            <div className="investhub-table__col">{t('Action')}</div>
          </div>

          { loading && (
            <div className="investhub-table__row investhub-table__row-empty">
              <div className="investhub-table__col">
                <CircularProgress />
              </div>
            </div>
          )}

          { allTenants.map((tenant) => (
            <div className="investhub-table__row" key={tenant.id}>
              <div className="investhub-table__col">
                <div className="investhub-table__label">{t('ID')}</div>
                {t(tenant.id)}
              </div>
              <div className="investhub-table__col">
                <div className="investhub-table__label">{t('Schema')}</div>
                {t(tenant.schema)}
              </div>
              {tenant.User ? (
                <div className="investhub-table__col">
                  <div className="investhub-table__label">
                    {t('User')}
                  </div>
                  {t(tenant.User.fullname)}
                  <br />
                  {t(tenant.User.email)}
                </div>
              ) : (
                <div className="investhub-table__col">{t('Unidentified')}</div>
              )}
              <div className="investhub-table__col">
                <div className="investhub-table__label">{t('Domain')}</div>
                {t(tenant.host)}
              </div>
              <div className="investhub-table__col">
                <div className="investhub-table__label">{t('Created At')}</div>
                {formatDate(tenant.createdAt)}
              </div>
              <div className="investhub-table__col">
                <div className="investhub-table__label">{t('Status')}</div>
                {renderStatus(tenant.status)}
              </div>
              <div className="investhub-table__col">
                <div className="investhub-table__label">{t('Action')}</div>
                <div className="investhub-table__btns">
                  { tenant.status === TENANT_STATUS.PROCESSING && (
                    <button
                      type="button"
                      className="button-stroke button-small investhub-table__btn"
                      onClick={() => showMarkAsCompletedDialog(tenant)}
                    >
                      {t('Mark as live')}
                    </button>
                  )}
                  <CustomNavLink
                    className="button-stroke button-small investhub-table__btn"
                    to={`/admin/tenants/${tenant.id}`}
                  >
                    {t('Detail')}
                  </CustomNavLink>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="investhub-table__pagination">
          <TablePagination
            colSpan={6}
            rowsPerPageOptions={[5, 10, 20]}
            count={tenantCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={changePage}
            onRowsPerPageChange={changeRowsPerPage}
            SelectProps={{
              inputProps: { 'aria-label': 'rows per page' },
              native: true,
            }}
            ActionsComponent={TablePaginationActions}
            component="div"
          />
        </div>
      </div>

      <Dialog
        open={isMarkAsCompletedDialogOpen}
        onClose={() => setIsMarkAsCompletedDialogOpen(false)}
        className="investhub-modal investhub-modal__m"
      >
        <ModalInnerMaterialDialog
          modalTitle="Subscription plan"
          modalOnClickClose={() => { setIsMarkAsCompletedDialogOpen(false) }}
        >
          {selectedTenant && (
            <div className="upload__item">
              <div className="field">
                <div className="variants__info">
                  {t('Do you want to update for the status of tenant SELECTEDTENANT to setup completed?', { SELECTEDTENANT: selectedTenant.host })}
                </div>
              </div>
            </div>
          )}

          <div className="upload__foot details__foot">
            <div className="details__btns details__btns_clear">
              {markTenantAsSetupCompletedStatus.loading ? (
                <CircularProgress />
              ) : (
                <>
                  <button
                    type="button"
                    onClick={() => setIsMarkAsCompletedDialogOpen(false)}
                    className="button-stroke button-small"
                  >
                    {t('Cancel')}
                  </button>
                  <button
                    type="button"
                    onClick={updateSelectedTenantStatusToCompleted}
                    className="button-pink button-small"
                  >
                    {t('Confirm')}
                  </button>
                </>
              )}
            </div>
          </div>

        </ModalInnerMaterialDialog>
      </Dialog>
    </>
  )
}

export default AdminTenantTable
