import React from 'react'
import '../../styles/xid/app.scss'

// XID Page Wrapper for creating SignIn

const XIDPageSimpleWrapper = ({
  children,
}) => {
  return (
    <div className="page page__simple">
      <div className="entry">
        <div className="entry__wrapper">
          {children}
        </div>
      </div>
    </div>
  )
}

export default XIDPageSimpleWrapper
