import React from 'react'
import { useTranslation } from 'react-i18next'

const SiteFooter = () => {
  const { t } = useTranslation()

  return (
    <footer className="footer">
      <div className="center footer__center">
        <div className="footer__foot">
          <div className="footer__copyright">
            {t("Copyright © 2023 XID Technology. All rights reserved")}
          </div>
        </div>
      </div>
    </footer>
  )
}

export default SiteFooter
