import React, { useState } from 'react'
import classNames from 'classnames'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import {
  Tooltip,
  Dialog,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import Upload from '../components/common/Upload'
import Icon from '../components/Icon'
import ModalInnerMaterialDialog from '../components/ModalInnerMaterialDialog'

const itemFilters = [
  {
    link: '/',
    name: 'Collections',
  },
  {
    link: '/',
    name: 'Created',
  },
  {
    link: '/',
    name: 'Likes',
  },
  {
    link: '/',
    name: 'Following',
  },
  {
    link: '/',
    name: 'Followers',
  },
]

const ProfileName = () => {
  const { t } = useTranslation()
  const [copiedWallet, setCopiedWallet] = useState()
  const [openEditDialog, setOpenEditDialog] = useState(false)

  return (
    <div className="profile">
      <div className="profile__head js-profile-head">
        <div className="profile__center center">
          <div className="profile__btns">
            <button
              type="button"
              className="button-stroke button-small profile__buttons"
              onClick={() => setOpenEditDialog(true)}
            >
              <span>{t('Edit cover photo')}</span>

              <Icon
                name="edit"
                className={classNames('edit')}
                size="20"
              />
            </button>

            <button
              type="button"
              className="button-stroke button-small profile__buttons"
              onClick={() => setOpenEditDialog(true)}
            >
              <span>{t('Edit profile')}</span>

              <Icon
                name="image"
                className={classNames('image')}
                size="20"
              />
            </button>
          </div>
        </div>
      </div>

      <div className="profile__body">
        <div className="profile__center center">
          <div className="user">
            <div className="user__avatar">
              <img src="https://via.placeholder.com/160/000/fff" alt="Avatar" />
            </div>

            <div className="user__name">Enrico cole</div>

            <CopyToClipboard
              text="1IidGZIO6fxSWdls15g7X60TSBcBXUxntxlMYqICA9hBdeRKPeHjd31fwIjyp038JgobMdwNDl9Kll7NZjyi9j1c"
              onCopy={() => setCopiedWallet('1IidGZIO6fxSWdls15g7X60TSBcBXUxntxlMYqICA9hBdeRKPeHjd31fwIjyp038JgobMdwNDl9Kll7NZjyi9j1c')}
            >
              <Tooltip
                placement="top"
                title={
                  copiedWallet === '1IidGZIO6fxSWdls15g7X60TSBcBXUxntxlMYqICA9hBdeRKPeHjd31fwIjyp038JgobMdwNDl9Kll7NZjyi9j1c'
                    ? 'This was copied'
                    : 'Copy to clipboard'
                }
              >
                <div className="user__code">
                  <div className="user__number">
                    1IidGZIO6fxSWdls15g7X60TSBcBXUxntxlMYqICA9hBdeRKPeHjd31fwIjyp038JgobMdwNDl9Kll7NZjyi9j1c
                  </div>

                  <button className="user__copy" type="button">
                    <Icon
                      name="copy"
                      className={classNames('copy')}
                      size="20"
                    />
                  </button>
                </div>
              </Tooltip>
            </CopyToClipboard>

            <div className="user__info">
              A wholesome farm owner in Montana. Upcoming gallery solo show in Germany
            </div>

            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events */}
            <a href="#" className="user__site" target="_blank">
              https://ui8.net
            </a>

            <div className="user__control">
              <div className="user__btns">
                <button className="button-stroke button-small user__button js-user-follow active" type="button">
                  <span>Follow</span>
                  <span>Unfollow</span>
                </button>

                <button className="button-circle-stroke button-small user__button js-user-share" type="button">
                  <Icon
                    name="share"
                    className={classNames('share')}
                    size="20"
                  />
                </button>

                <button className="button-circle-stroke button-small user__button js-user-share" type="button">
                  <Icon
                    name="report"
                    className={classNames('report')}
                    size="20"
                  />
                </button>
              </div>
            </div>

            <div className="user__socials">
              {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events */}
              <a href="#" className="user__social">
                <Icon
                  name="twitter"
                  className={classNames('twitter')}
                  size="20"
                />
              </a>

              {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events */}
              <a href="#" className="user__social">
                <Icon
                  name="instagram"
                  className={classNames('instagram')}
                  size="20"
                />
              </a>

              {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events */}
              <a href="#" className="user__social">
                <Icon
                  name="facebook"
                  className={classNames('facebook')}
                  size="20"
                />
              </a>
            </div>

            <div className="user__note">
              Member since Mar 15, 2021
            </div>
          </div>

          <div className="profile__wrapper js-tabs">
            <div className="profile__nav">
              {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events */}
              <a href="#" className="profile__link js-tabs-link active">
                On Sale
              </a>
              {itemFilters.map((x) => (
                <>
                  {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events */}
                  <a href={x.link} className="profile__link js-tabs-link">
                    {x.name}
                  </a>
                </>
              ))}
            </div>

            <div className="profile__container">
              <div className="footer__head">Should hide for a moment</div>
            </div>
          </div>
        </div>
      </div>

      <Dialog
        open={openEditDialog}
        onClose={() => setOpenEditDialog(false)}
        className="investhub-modal investhub-modal__m"
      >
        <ModalInnerMaterialDialog
          modalTitle="Edit cover photo"
          modalOnClickClose={() => setOpenEditDialog(false)}
        >
          <Upload
            preview
            accept="image/*"
            fieldFormat="PNG, JPEG, or WEBP. Max 1Gb"
          />
        </ModalInnerMaterialDialog>
      </Dialog>
    </div>
  )
}

export default ProfileName
