import React, { useState } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { NavLink } from 'react-router-dom'
import {
  FormControlLabel,
  Checkbox,
  TextField,
  CircularProgress,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { RESTORE } from '../queriesAndMutations'

const RestorePasswordForm = () => {
  const { t } = useTranslation()
  const [values, setValues] = useState({
    email: '',
    agree: false,
  })
  const [restorePassword, restorePasswordStatus] = useMutation(RESTORE)
  const sendRestoreRequest = (event) => {
    event.preventDefault()
    restorePassword({
      variables: {
        input: {
          email: values.email,
        },
      },
    }).catch(() => {})
  }

  if (restorePasswordStatus?.data?.restore) {
    return (
      <div className="text-success text-center">
        {t('Reset password link has been send to your email address.')}
      </div>
    )
  }

  return (
    <>
      <form onSubmit={sendRestoreRequest} className="variants__list signinup__form">
        <div className="variants__item signinup__inner">
          <div className="details__fieldset">
            <TextField
              required
              className="investhub__field field"
              label={t('Email')}
              placeholder={t('Enter Email Address')}
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              value={values.email}
              onChange={({ target: { value } }) => setValues({ ...values, email: value })}
            />

            <div className="field__group">
              <FormControlLabel
                className="field field__full field__checkbox"
                label={(
                  <>
                    {t('I agree to Investhub IOs')}
                    <NavLink to="/tos" target="_blank">{t('Terms & Condition')}</NavLink>
                  </>
                )}
                checked={values.agree}
                onChange={({ target: { checked } }) => setValues({ ...values, agree: checked })}
                control={(
                  <Checkbox
                    values="yes"
                    color="primary"
                  />
                )}
              />
            </div>
          </div>

          <div className="details__btns signinup__btns">
            {restorePasswordStatus.loading
              ? (
                <CircularProgress />
              ) : (
                <button
                  className="button details__button signinup__button"
                  type="submit"
                  fullWidth
                  disabled={!values.agree}
                >
                  {t('Send Now')}
                </button>
              )}
          </div>
        </div>
      </form>
    </>
  )
}

export default RestorePasswordForm
