import React, { useEffect } from 'react'
import { Redirect, NavLink } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { useTranslation } from 'react-i18next'
import {
  CircularProgress,
} from '@material-ui/core'

import { useMe } from '../myHooks'
import {
  CHECK_VALID_TENANT,
  REQUEST_SSO_TOKEN,
} from '../queriesAndMutations'

import LoginForm from '../components/LoginForm'

const SsoRequest = () => {
  const { t } = useTranslation()
  const query = new URLSearchParams(window.location.search)
  const redirectUrl = query.get('redirect')
  const allowSyncData = query.get('allowSyncData')
  let host

  try {
    host = (new URL(redirectUrl)).host
  } catch (error) {
    host = ''
  }
  const {
    data: { checkValidTenant } = false,
    loading: checkValidTenantLoading,
  } = useQuery(CHECK_VALID_TENANT, {
    variables: { host },
  })
  const { data: { me } = {}, loading: getUserLoading } = useMe()
  const [requestSsoToken, requestSsoTokenStatus] = useMutation(REQUEST_SSO_TOKEN)
  const submitSsoTokenRequest = () => {
    requestSsoToken({
      variables: { host },
    }).then(({ data: { requestSsoToken: token } }) => {
      window.location.href = `${redirectUrl}${redirectUrl.indexOf('?') > -1 ? '&' : '?'}sso_token=${token}&allowSyncData=${allowSyncData}`
    }).catch(() => {})
  }

  useEffect(() => {
    if (me) {
      submitSsoTokenRequest()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me])

  if (!host) {
    return <Redirect to="/not-found" />
  }

  return (
    <>
      <div className="section-pt80 variants signinup">
        <div className="variants__center signinup__center center">
          {checkValidTenantLoading && (
            <div className="variants__top signinup__top">
              <CircularProgress />
              <div className="variants__info signinup__info">
                {t('Checking tenant...')}
              </div>
            </div>
          )}

          {!checkValidTenantLoading && !checkValidTenant && (
            <div className="variants__top signinup__top">
              <div className="variants__info signinup__info">
                <p className="text-error mb-6 text-lg">{t('Invalid tenants')}</p>
                <NavLink className="button" to="/">{t('Back to home')}</NavLink>
              </div>
            </div>
          )}

          {checkValidTenant && (
            <>
              {getUserLoading && (
                <div className="variants__top signinup__top">
                  <CircularProgress />
                  <div className="variants__info siginup__info">{t('Getting user info...')}</div>
                </div>
              )}

              {requestSsoTokenStatus.loading && (
                <div className="variants__top signinup__top">
                  <CircularProgress />
                  <div className="variants__info signunp__info">{t('Request access credential...')}</div>
                </div>
              )}

              {!getUserLoading && !me && (
                <>
                  <div className="variants__top signinup__top">
                    <div className="variants__title h2 signinup__title">
                      {t('Please Sign In')}
                    </div>
                    <div className="variants__info signinup__info">
                      {t('Signin to manage your account')}
                    </div>
                  </div>

                  <LoginForm onSuccess={submitSsoTokenRequest} />
                </>
              )}
            </>
          )}

        </div>
      </div>
    </>
  )
}

export default SsoRequest
