// Create this component for requirement for TOK-1151
// This will replace TenantServiceUsageTable.jsx

import React from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/react-hooks'
import { useTranslation } from 'react-i18next'
import {
  GET_TENANT_SERVICE_USAGE,
  GET_ADMIN_TENANT_SERVICE_USAGE,
} from '../../queriesAndMutations'
import { formatDate } from '../../utils'
import CustomLoader from '../Loader'

const TenantServiceUsageItem = ({
  tenantId,
  isAdmin,
}) => {
  const { t } = useTranslation()
  const {
    data: { [isAdmin ? 'getAdminTenantServiceUsage' : 'getTenantServiceUsage']: data } = {},
    loading,
    error,
  } = useQuery(isAdmin ? GET_ADMIN_TENANT_SERVICE_USAGE : GET_TENANT_SERVICE_USAGE, {
    variables: {
      id: tenantId,
    },
  })

  return (
    <>
      {loading && (
        <div className="activity__item change-log__item">
          <CustomLoader />
        </div>
      )}

      {error && (
        <div className="activity__item change-log__item">
          <div className="activity__details">
            <div className="activity__subtitle">
              {t('Error')}
            </div>

            <div className="activity__description">
              <em>{t('Cannot load data')}</em>
            </div>
          </div>
        </div>
      )}

      {data && data.map((service) => (
        <div className="activity__item change-log__item" key={service.id}>
          <div className="activity__details change-log__details">
            <div className="activity__description change-log__title">
              {t(service.label)}
            </div>

            <div className="flex justify-start items-center">
              <div className="activity__date change-log__details mr__8">
                <div className="field__label mt__8 mb__4">{t('Total usage')}</div>
                {t(service.usage)}
                {' '}
                {t(service.shortUnit)}
              </div>

              <div className="activity__date change-log__details mr__8">
                <div className="field__label mt__8 mb__4">{t('Usage not charge')}</div>
                {t(service.notCharge)}
                {' '}
                {t(service.shortUnit)}
              </div>

              <div className="activity__date change-log__details">
                <div className="field__label mt__8 mb__4">{t('Last use')}</div>
                {service.lastUse ? formatDate(+service.lastUse) : t('Never')}
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  )
}

TenantServiceUsageItem.propTypes = {
  tenantId: PropTypes.number.isRequired,
  isAdmin: PropTypes.bool,
}

export default TenantServiceUsageItem
