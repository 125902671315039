import React from 'react'
import { useTranslation } from 'react-i18next'
import RestorePasswordForm from '../components/RestorePasswordForm'

const RestorePassword = () => {
  const { t } = useTranslation()

  return (
    <>

      <div className="section-pt80 variants signinup">
        <div className="variants__center signinup__center center">
          <div className="variants__top signinup__top">
            <div className="variants__title h2 signinup__title">
              {t('Restore password')}
            </div>

            <div className="variants__info signinup__info">
              {t('We will send a password to your Email')}
            </div>
          </div>

          <RestorePasswordForm />
        </div>
      </div>
    </>
  )
}

export default RestorePassword
