import React, { useState } from 'react'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import { useCookies } from 'react-cookie'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import SlideToggle from "react-slide-toggle"

import config from '../../config'
import { logout } from '../../utils'
// import HeaderSearch from '../Header/SearchForm'
import SiteLogoDark from '../../media/images/dark-mode-investhub-logo.svg'
// import CompTranslationRwd from '../Header/TranslationRwd'
import TranslationToggle from '../SiteHeader/Translation/Translation'

const useStyles = makeStyles({
  header: {
    position: 'absolute',
    zIndex: '20',
    padding: '20px 0',
    top: '0',
    left: '0',
    width: '100%',
    borderBottom: '1px solid #353945',

    '@media only screen and (max-width: 1475px)': {
      padding: '24px 0',
    },
  },
  header__center: {
    display: 'flex',
    alignItems: 'center',
  },
  header__logo: {
    position: 'relative',
    zIndex: 12,
    display: 'inline-block',
    flexShrink: 0,
    width: '178px',
    marginRight: '32px',

    '@media only screen and (max-width: 1475px)': {
      marginRight: 'auto',
    },
  },
  header__logo_link: {
    display: 'block',
  },
  header__logo_img: {
    width: '100%',
  },
  header__wrapper: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,

    '@media only screen and (max-width: 1475px)': {
      flexGrow: 0,
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      flexDirection: 'column',
      alignItems: 'stretch',
      minHeight: 'calc(var(--vh, 1vh) * 100)',
      margin: 0,
      padding: '140px 32px 40px',
      boxShadow: '0px 64px 64px rgba(31, 47, 70, 0.2)',
      background: 'var(--color-bg-black)',
      visibility: 'hidden',
      opacity: 0,
      transition: 'all .2s',
    },
  },
  header__nav: {
    display: 'flex',
    marginRight: 'auto',

    '@media only screen and (max-width: 1475px)': {
      flexDirection: 'column',
      margin: '0 0 40px',
      border: 'none',
    },
  },
  header__list: {
    listStyle: 'none outside none',
    display: 'flex',
    flexFlow: 'row wrap',
  },
  header__item: {
    position: 'relative',
  },
  header__link: {
    marginLeft: '32px',
    fontFamily: 'var(--font-2)',
    fontWeight: 600,
    lineHeight: '40px',
    letterSpacing: '.5px',
    color: '#777E90',
    transition: 'color .2s',
    paddingBottom: '12px',

    '&:hover': {
      color: 'var(--color-blue)',
    },
    '&:focus': {
      color: 'var(--color-blue)',
    },
    '@media only screen and (max-width: 1475px)': {
      marginLeft: 0,
      fontSize: '24px',
      lineHeight: '64px',
      color: '#777E90',
    },
  },
  header__cta_link: {
    '&:not(:last-child)': {
      marginRight: '12px',

      '@media only screen and (max-width: 1475px)': {
        marginRight: 0,
      },
    },

    '@media only screen and (max-width: 1475px)': {
      marginTop: '16px',
      display: 'inline-flex',
      width: '100%',
    },
  },
  header__burger: {
    display: 'none',
    position: 'relative',
    width: '32px',
    height: '32px',
    background: 'none',
    '-webkit-tap-highlight-color': 'rgba(0, 0, 0, 0)',
    marginLeft: '24px',

    '@media only screen and (max-width: 1475px)': {
      display: 'block',
    },
  },
  header__burger_one: {
    content: '',
    position: 'absolute',
    top: '16px',
    left: '6px',
    width: '20px',
    height: '2px',
    background: '#777E90',
    borderRadius: '2px',
    transition: 'transform .2s',
    transform: 'translateY(-4px)',
  },
  header__burger_two: {
    content: '',
    position: 'absolute',
    top: '16px',
    left: '6px',
    width: '20px',
    height: '2px',
    background: '#777E90',
    borderRadius: '2px',
    transition: 'transform .2s',
    transform: 'translateY(3px)',
  },
  header__show_custom: {
    '@media only screen and (max-width: 1475px)': {
      display: 'block !important',
    },
  },
  header__hide_custom: {
    '@media only screen and (max-width: 1475px)': {
      display: 'none',
    },
  },
})

const AppAdminBar = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [,, removeCookie] = useCookies()
  const triggerLogout = () => {
    removeCookie(config.jwtCookie)

    const logoutFunc = logout()

    logoutFunc()
  }

  const [rwdVisibleValues, setRwdVisibleValues] = useState({
    isRwdVisibleActive: false,
  })
  const onRwdVisibleActive = () => {
    setRwdVisibleValues({ ...rwdVisibleValues, isRwdVisibleActive: !rwdVisibleValues.isRwdVisibleActive })
  }

  const menuList = [
    {
      label: 'Dashboard',
      url: '/admin/dashboard',
    },
    {
      label: 'Users',
      url: '/admin/users',
    },
    {
      label: 'Tenants',
      url: '/admin/tenants',
    },
    {
      label: 'Pages',
      url: '/admin/pages/projects',
    },
    {
      label: 'Prices',
      url: '/admin/prices',
    },
    {
      label: 'Blacklist',
      url: '/admin/blacklist',
    },
    {
      label: 'Settings',
      url: '/admin/settings',
    },
    {
      label: 'Storage',
      url: '/admin/storage',
    },
  ]

  return (
    <header className={classes.header}>
      <div className={classNames('header__center header-admin__center center center__admin', classes.header__center)}>
        <div className={classes.header__logo}>
          <NavLink to="/" className={classes.header__logo_link}>
            <img src={SiteLogoDark} alt="Investhub.io" className={classes.header__logo_img} />
          </NavLink>
        </div>

        <div className={classNames(classes.header__hide_custom, classes.header__wrapper)}>
          <nav className={classes.header__nav}>
            <ul className={classes.header__list}>
              {menuList.map((menuItem) => (
                <li key={menuItem.label} className={classes.header__item}>
                  <NavLink
                    key={menuItem.label}
                    className={classes.header__link}
                    to={menuItem.url}
                    exact
                    activeClassName="active"
                    onClick={onRwdVisibleActive}
                  >
                    {t(menuItem.label)}
                  </NavLink>
                </li>
              ))}
            </ul>
          </nav>

          <div>
            <NavLink to="/" className={classNames('button button-small', classes.header__cta_link)}>
              {t('Back to home dashboard')}
            </NavLink>

            <NavLink to="#" onClick={() => { triggerLogout() }} className={classNames('button-stroke button-small', classes.header__cta_link)}>
              {t('Log out')}
            </NavLink>
          </div>

          <TranslationToggle />
        </div>

        <SlideToggle
          duration={20}
          collapsed
          render={({
            toggle,
            setCollapsibleElement,
            toggleState,
          }) => (
            <>
              <div className={classNames(classes.header__show_custom, classes.header__hide_custom)}>
                <div className={classNames("header-wrapper__custom", classes.header__wrapper, (toggleState || "").toLowerCase())} ref={setCollapsibleElement}>

                  <nav className={classes.header__nav}>
                    {menuList.map((menuItem) => (
                      <NavLink
                        key={menuItem.label}
                        className={classes.header__link}
                        to={menuItem.url}
                        exact
                        activeClassName="active"
                        onClick={onRwdVisibleActive}
                      >
                        {t(menuItem.label)}
                      </NavLink>
                    ))}
                  </nav>

                  <NavLink to="/" className="button button-small">
                    {t('Back to home dashboard')}
                  </NavLink>
                  <br />
                  <NavLink to="#" onClick={() => { triggerLogout() }} className="button-stroke button-small">
                    {t('Log out')}
                  </NavLink>
                </div>
              </div>

              <button
                type="button"
                className={
                  classNames(
                    "header-burger__custom",
                    classes.header__burger,
                    (toggleState || "").toLowerCase(),
                  )
                }
                onClick={toggle}
              >
                <span className={classes.header__burger_one}>{' '}</span>
                <span className={classes.header__burger_two}>{' '}</span>
              </button>
            </>
          )}
        />
      </div>
    </header>
  )
}

export default AppAdminBar
