import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { useTranslation } from 'react-i18next'

import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  MenuItem,
  TextField,
} from '@material-ui/core'

import {
  GET_ADMIN_PRICE,
  SAVE_PRICE_SETTING,
} from '../queriesAndMutations'
import { toaster } from '../utils'
import ControlAdminBreadcrumbs from '../components/ControlAdminBreadcrumbs'

const AdminPrice = () => {
  const { t } = useTranslation()
  const { data: { getAdminPrice: data } = {}, loading } = useQuery(GET_ADMIN_PRICE)
  const [savePriceSetting, savePriceSettingStatus] = useMutation(SAVE_PRICE_SETTING)
  const [priceSetting, setPriceSetting] = useState(null)
  const changePlanPrice = (planId) => ({ target: { value } }) => {
    setPriceSetting({
      ...priceSetting,
      chargeByPlan: {
        ...priceSetting.chargeByPlan,
        [planId]: {
          ...priceSetting.chargeByPlan[planId],
          price: value,
        },
      },
    })
  }
  const changeCbuPrice = (serviceId) => ({ target: { value } }) => {
    setPriceSetting({
      ...priceSetting,
      chargeByUsage: {
        ...priceSetting.chargeByUsage,
        [serviceId]: {
          ...priceSetting.chargeByUsage[serviceId],
          price: value,
        },
      },
    })
  }
  const changePlanService = (planId, serviceId) => ({ target: { checked } }) => {
    const newPlan = priceSetting.chargeByPlan[planId]
    newPlan.services[serviceId] = checked

    setPriceSetting({
      ...priceSetting,
      chargeByPlan: {
        ...priceSetting.chargeByPlan,
        [planId]: newPlan,
      },
    })
  }
  const submitSavePrice = () => {
    savePriceSetting({
      variables: {
        input: priceSetting,
      },
    }).then(({ data: { savePriceSetting: success } }) => {
      if (success) {
        toaster.success('Price setting saved.')
      } else {
        toaster.error('Price setting has not been saved.')
      }
    }).catch(() => {})
  }

  useEffect(() => {
    if (data) {
      setPriceSetting(data.setting)
    }
  }, [data])

  return (
    <>
      <ControlAdminBreadcrumbs>
        <li className="breadcrumbs__item">
          <NavLink to="/admin/dashboard" className="breadcrumbs__link">{t('Admin Dashboard')}</NavLink>
        </li>
        <li className="breadcrumbs__item">
          {t('Price')}
        </li>
      </ControlAdminBreadcrumbs>

      <div className="section-pt80 faq admin-settings">
        <div className="faq__center admin-settings__center center">
          <div className="faq__top admin-settings__top">
            <div className="faq__title admin-settings__title h2">{t('Price Setting')}</div>
          </div>

          <div className="faq__row admin-settings__row">
            <div className="faq__col admin-settings__col">
              { loading && (
                <CircularProgress />
              )}

              {data && priceSetting && (
                <>
                  <div className="upload__item">
                    <div className="upload__category">{t('Subscription prices')}</div>

                    <div className="upload__fieldset details__fieldset">
                      <div className="investhub-table__wrapper">
                        <div className="investhub-table__box investhub-table__box-styled investhub-table__box-subscription">
                          <div className="investhub-table__row">
                            <div className="investhub-table__col">{t('Services')}</div>

                            {data.planList.map((plan) => (
                              <div className="investhub-table__col" key={plan.id}>
                                <strong>{t(plan.label)}</strong>
                              </div>
                            ))}
                          </div>

                          {data.serviceList.map((service) => (
                            <div className="investhub-table__row" key={service.id} hover="true">
                              <div className="investhub-table__col">
                                <div className="investhub-table__label">{t('Service')}</div>
                                <strong>{t(service.label)}</strong>
                              </div>
                              {data.planList.map((plan) => (
                                <div className="investhub-table__col" key={plan.id}>
                                  <div className="investhub-table__label">
                                    {t(plan.label)}
                                  </div>
                                  <FormControlLabel
                                    className="field field__checkbox no-margin__left"
                                    control={(
                                      <Checkbox
                                        values={service.id}
                                        checked={priceSetting.chargeByPlan[plan.id].services[service.id]}
                                        onChange={changePlanService(plan.id, service.id)}
                                        color="primary"
                                      />
                                    )}
                                  />
                                </div>
                              ))}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="upload__item">
                    <div className="upload__category">{t('Price')}</div>

                    <div className="upload__fieldset details__fieldset">
                      <div className="investhub-table__wrapper">
                        <div className="investhub-table__box investhub-table__box-styled">
                          <div className="investhub-table__row">{' '}</div>
                          <div className="investhub-table__row investhub-table__row-inline">
                            <div className="investhub-table__col">
                              {t('Plan')}
                            </div>

                            { data.planList.map((plan) => (
                              <div className="investhub-table__col" key={plan.id}>
                                <div className="field">
                                  <TextField
                                    select
                                    className="select investhub-select select-trans"
                                    value={priceSetting.chargeByPlan[plan.id].price}
                                    onChange={changePlanPrice(plan.id)}
                                  >
                                    {data.priceList.map((price) => (
                                      <MenuItem key={price.id} value={price.id}>
                                        {t(price.label)}
                                      </MenuItem>
                                    ))}
                                  </TextField>
                                </div>
                              </div>
                            ))}
                          </div>

                          {Object.values(data.chargeByUsageList).map((service) => (
                            <div className="investhub-table__row investhub-table__row-inline" key={service.id}>
                              <div className="investhub-table__col">
                                {t(service.label)}
                              </div>
                              {data.planList.map((plan) => (
                                <div className="investhub-table__col" key={plan.id}>
                                  <div className="field">
                                    <TextField
                                      select
                                      className="select investhub-select select-trans"
                                      value={priceSetting.chargeByUsage[`${plan.id}__${service.id}`].price}
                                      onChange={changeCbuPrice(`${plan.id}__${service.id}`)}
                                      SelectProps={{
                                        displayEmpty: true,
                                      }}
                                    >
                                      <MenuItem value="">
                                        {t('-- Empty --')}
                                      </MenuItem>
                                      {data.priceList.map((price) => (
                                        <MenuItem key={price.id} value={price.id}>
                                          {price.label}
                                        </MenuItem>
                                      ))}
                                    </TextField>
                                  </div>
                                </div>
                              ))}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="upload__foot details__foot">
                    <div className="details__btns details__btns_clear">
                      {
                        savePriceSettingStatus.loading
                          ? (
                            <CircularProgress />
                          ) : (
                            <button type="button" className="button details__button" onClick={submitSavePrice}>{t('Save')}</button>
                          )
                      }
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AdminPrice
