import gql from 'graphql-tag'

export const GET_ADMIN_PRICE = gql`
  query getAdminPrice {
    getAdminPrice {
      planList
      serviceList
      chargeByUsageList
      priceList
      setting
    }
  }
`;

export const GET_AVAILABLE_SUBSCRIPTION_PLAN = gql`
  query getAvailableSubscriptionPlan($id: ID) {
    getAvailableSubscriptionPlan(id: $id)
  }
`;

export const GET_SERVICE_PRICE_LIST = gql`
  query getServicePriceList {
    getServicePriceList
  }
`;
