import React from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import { ReactComponent as ArrowOne } from '../../../media/images/icons/long-acces-arrow-right-one.svg'
import { ReactComponent as ArrowTwo } from '../../../media/images/icons/long-acces-arrow-right-two.svg'
import { ReactComponent as ArrowThree } from '../../../media/images/icons/long-acces-arrow-right-three.svg'
import styles from './styles.module.scss'

const HowToGetStarted = () => {
  const { t } = useTranslation()

  return (
    <div className={classNames("section", styles.howtogetstarted)}>
      <div className={classNames("center", styles.howtogetstarted__center)}>
        <h2 className={classNames("h3 text-center", styles.howtogetstarted__title)}>
          {t('How to get started?')}
        </h2>

        <div className={styles.howtogetstarted__text}>
          {t('We can get you online within 7 days instead of 3 months.')}
        </div>

        <div className={styles.howtogetstarted__list}>
          <div className={styles.howtogetstarted__card}>
            <div className={styles.howtogetstarted__card_inner}>
              <div className={styles.howtogetstarted__card_icon}>
                1
              </div>
              <h3 className={classNames('h4', styles.howtogetstarted__card_title)}>
                {t('Setup')}
              </h3>
              <div className={styles.howtogetstarted__card_text}>
                {t('Setup your Tenant either yourself or with our help.')}
              </div>
            </div>

            <div className={classNames(styles.howtogetstarted__card_line, styles.line_one)}>
              <ArrowOne />
            </div>
          </div>

          <div className={styles.howtogetstarted__card}>
            <div className={styles.howtogetstarted__card_inner}>
              <div className={styles.howtogetstarted__card_icon}>
                2
              </div>
              <h3 className={classNames('h4', styles.howtogetstarted__card_title)}>
                {t('Configure')}
              </h3>
              <div className={styles.howtogetstarted__card_text}>
                {t('Configure your AML levels, other settings.')}
              </div>
            </div>

            <div className={classNames(styles.howtogetstarted__card_line, styles.line_two)}>
              <ArrowTwo />
            </div>
          </div>

          <div className={styles.howtogetstarted__card}>
            <div className={styles.howtogetstarted__card_inner}>
              <div className={styles.howtogetstarted__card_icon}>
                3
              </div>
              <h3 className={classNames('h4', styles.howtogetstarted__card_title)}>
                {t('Onboard')}
              </h3>
              <div className={styles.howtogetstarted__card_text}>
                {t('up to 10.000 Investors.')}
              </div>
            </div>

            <div className={classNames(styles.howtogetstarted__card_line, styles.line_three)}>
              <ArrowThree />
            </div>
          </div>

          <div className={styles.howtogetstarted__card}>
            <div className={styles.howtogetstarted__card_inner}>
              <div className={styles.howtogetstarted__card_icon}>
                4
              </div>
              <h3 className={classNames('h4', styles.howtogetstarted__card_title)}>
                {t('Payment + Tokenisation')}
              </h3>
              <div className={styles.howtogetstarted__card_text}>
                {t('Receive payments for the subscriptions and Issue token cross-chain.')}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HowToGetStarted
