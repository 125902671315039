import React, { useState } from 'react'
import uuid from 'react-uuid'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import { useQuery } from '@apollo/react-hooks'
import { useTranslation } from 'react-i18next'
import {
  CircularProgress,
} from '@material-ui/core'

import {
  GET_PROJECT_PAGE,
} from '../queriesAndMutations'
import Icon from '../components/Icon'

const Project = ({ match: { params: { projectUri } } }) => {
  const { t } = useTranslation()
  const [action, setActionActive] = useState({
    isActionActive: false,
  })

  const onActionClick = () => {
    setActionActive({ ...action, isActionActive: !action.isActionActive })
  }

  const onActionAwayClick = () => {
    setActionActive({ ...action, isActionActive: false })
  }

  const { data: { getProjectPage: data } = {}, loading } = useQuery(GET_PROJECT_PAGE, {
    variables: {
      uri: projectUri,
    },
  })
  const printData = (objectData) => {
    if (objectData === null) {
      return 'null'
    }
    if (typeof objectData === 'object') {
      return (
        <ul className="list-reset list-none list-investhub">
          {Object.keys(objectData).map((key) => (
            <>
              <li className="relative block text-sm">
                {key}
                {': '}
                {printData(objectData[key])}
              </li>
            </>
          ))}
        </ul>
      )
    }
    return objectData
  }

  const projectInformationNav = ['Info', 'Token', 'Services', 'Payment Method']
  const [activeInformationTab, setActiveInformationTab] = useState(projectInformationNav[0])

  const renderTabInfo = () => (
    <div className="popup__table investhub-table__horizontal">
      <div className="popup__row">
        <div className="popup__col">{t('Host')}</div>
        <div className="popup__col">
          {data.host}
        </div>
      </div>

      <div className="popup__row">
        <div className="popup__col">{t('SSO Link')}</div>
        <div className="popup__col">
          <a rel="noopener noreferrer" href={data.ssoLink} target="_blank" className="button-stroke button-small">{t('Click to open')}</a>
        </div>
      </div>

      <div className="popup__row">
        <div className="popup__col">{t('Deployment Method')}</div>
        <div className="popup__col">
          {t(data.deploymentMethod)}
        </div>
      </div>

      <div className="popup__row">
        <div className="popup__col">{t('Explanation PDF')}</div>
        <div className="popup__col">
          <a rel="noopener noreferrer" href={data.ssoLink} to={data.explanationPdf} target="_blank" className="button-stroke button-small">{t('Click to open')}</a>
        </div>
      </div>

      <div className="popup__row">
        <div className="popup__col">{t('Fatca Aia Compliance')}</div>
        <div className="popup__col">
          {data.fatcaAiaCompliance}
        </div>
      </div>

      <div className="popup__row">
        <div className="popup__col">{t('Data Center')}</div>
        <div className="popup__col">
          <a rel="noopener noreferrer" href={data.ssoLink} to={data.dataCenter} target="_blank" className="button-stroke button-small">{t('Click to open')}</a>
        </div>
      </div>
    </div>
  )

  const renderTabToken = () => (
    <div className="item__box js-tabs-item" style={{ display: 'block' }}>
      <div className="popup__table investhub-table__horizontal">
        <div className="popup__row">
          <div className="popup__col">{t('Name')}</div>
          <div className="popup__col">{data.token.tokenName}</div>
        </div>

        <div className="popup__row">
          <div className="popup__col">{t('Symbol')}</div>
          <div className="popup__col">{t(data.token.tokenSymbol)}</div>
        </div>

        <div className="popup__row">
          <div className="popup__col">{t('Type')}</div>
          <div className="popup__col">{data.token.tokenType}</div>
        </div>
      </div>
    </div>
  )

  const renderTabServices = () => (
    <div className="item__box js-tabs-item" style={{ display: 'block' }}>
      <div className="popup__table investhub-table__horizontal">
        <div className="popup__row">
          <div className="popup__col">{t('Acronis')}</div>
          <div className="popup__col">
            {data.services.acronis && (
              <span className="preview__clear project__checked">
                <Icon
                  name="check"
                  className={classNames('check')}
                  size="16"
                />
                {t('Checked')}
              </span>
            )}
          </div>
        </div>

        <div className="popup__row">
          <div className="popup__col">{t('Identification')}</div>
          <div className="popup__col">
            {t(data.services.identification)}
          </div>
        </div>

        <div className="popup__row">
          <div className="popup__col">{t('Intercom')}</div>
          <div className="popup__col">
            {data.services.intercom && (
              <span className="preview__clear project__checked">
                <Icon
                  name="check"
                  className={classNames('check')}
                  size="16"
                />
                {t('Checked')}
              </span>
            )}
          </div>
        </div>

        <div className="popup__row">
          <div className="popup__col">{t('Signnow')}</div>
          <div className="popup__col">
            {data.services.signnow && (
              <span className="preview__clear project__checked">
                <Icon
                  name="check"
                  className={classNames('check')}
                  size="16"
                />
                {t('Checked')}
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  )

  const renderTabPaymentMethod = () => (
    <div className="item__box js-tabs-item" style={{ display: 'block' }}>
      <div className="popup__table investhub-table__horizontal">
        <div className="popup__row">
          <div className="popup__col">{t('Bank Transfer')}</div>
          <div className="popup__col">
            {data.paymentMethod.bank_transfer && (
              <span className="preview__clear project__checked">
                <Icon
                  name="check"
                  className={classNames('check')}
                  size="16"
                />
                {t('Supported')}
              </span>
            )}
          </div>
        </div>

        <div className="popup__row">
          <div className="popup__col">{t('Crypto.com')}</div>
          <div className="popup__col">
            {data.paymentMethod.crypto_com && (
              <span className="preview__clear project__checked">
                <Icon
                  name="check"
                  className={classNames('check')}
                  size="16"
                />
                {t('Supported')}
              </span>
            )}
          </div>
        </div>

        <div className="popup__row">
          <div className="popup__col">{t('Sendwyre')}</div>
          <div className="popup__col">
            {data.paymentMethod.sendwyre && (
              <span className="preview__clear project__checked">
                <Icon
                  name="check"
                  className={classNames('check')}
                  size="16"
                />
                {t('Supported')}
              </span>
            )}
          </div>
        </div>

        <div className="popup__row">
          <div className="popup__col">{t('Volt.io')}</div>
          <div className="popup__col">
            {data.paymentMethod.volt_io && (
              <span className="preview__clear project__checked">
                <Icon
                  name="check"
                  className={classNames('check')}
                  size="16"
                />
                {t('Supported')}
              </span>
            )}
          </div>
        </div>

        <div className="popup__row">
          <div className="popup__col">{t('Wallet Engine')}</div>
          <div className="popup__col">
            {data.paymentMethod.wallet_engine && (
              <span className="preview__clear project__checked">
                <Icon
                  name="check"
                  className={classNames('check')}
                  size="16"
                />
                {t('Supported')}
              </span>
            )}
          </div>
        </div>

        <div className="popup__row">
          <div className="popup__col">{t('Wallet Transfer')}</div>
          <div className="popup__col">
            {data.paymentMethod.wallet_transfer && (
              <span className="preview__clear project__checked">
                <Icon
                  name="check"
                  className={classNames('check')}
                  size="16"
                />
                {t('Supported')}
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  )

  const renderDataCompany = () => (
    <div className="item__box js-tabs-item" style={{ display: 'block' }}>
      <div className="item__users">

        <div className="item__user">
          <div className="item__description">
            <div className="item__action">
              {t('Name')}
              :
            </div>
            <div className="item__name">
              {data.company.name}
            </div>
          </div>
        </div>

        <div className="item__user">
          <div className="item__description">
            <div className="item__action">
              {t('Email')}
              :
            </div>

            <div className="item__name">
              {data.company.email}
            </div>
          </div>
        </div>

        <div className="item__user">
          <div className="item__description">
            <div className="item__action">
              {t('Address')}
              :
            </div>

            <div className="item__name">
              {data.company.address}
            </div>
          </div>
        </div>

      </div>
    </div>
  )

  const renderDataAcceptedCurrencies = () => (
    <div className="item__categories project__categories">
      {data.acceptedCurrencies.map((acceptedCurrency, index) => (
        <>
          { index === 0 && (
            <div className="status-black item__category project__accepted-currency" key={acceptedCurrency}>
              {acceptedCurrency}
            </div>
          )}

          { index === 1 && (
            <div className="status-purple item__category project__accepted-currency" key={acceptedCurrency}>
              {acceptedCurrency}
            </div>
          )}

          { index === 2 && (
            <div className="status-blue item__category project__accepted-currency" key={acceptedCurrency}>
              {acceptedCurrency}
            </div>
          )}

          { index === 3 && (
            <div className="status-green item__category project__accepted-currency" key={acceptedCurrency}>
              {acceptedCurrency}
            </div>
          )}
        </>
      ))}
    </div>
  )

  const renderDataLogo = () => (
    <div className="item__logo project__logo">
      <img src={data.logo} alt={data.title} />
    </div>
  )

  const renderItemDataManagers = () => (
    <div className="project__item">
      <div className="project__header">
        <div className="user__name project__name">{t('Managers')}</div>
      </div>

      <div className="project__content">
        { loading && (
          <div className="item__user">
            <CircularProgress />
          </div>
        )}

        { data.managers.map((manager) => (
          <div className="item__user" key={uuid()}>
            <div className="item__avatar">
              <img src={manager.profile_image} alt={manager.first_name} />
            </div>

            <div className="item__description">
              <div className="item__name">
                {manager.first_name}
                {' '}
                {manager.last_name}
              </div>

              <div className="item__action">
                {manager.email}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )

  const renderItemDataLevels = () => (
    <div className="project__item">
      <div className="project__header">
        <div className="user__name project__name">{t('Levels')}</div>
      </div>

      <div className="project__content">
        <div className="levels__list">
          {Object.keys(data.levels).map((level, index) => (
            <>
              { index === 0 && (
                <div className="item__variants levels__item" key={uuid()}>
                  {t('Level 1')}
                  <span>
                    {t('Min')}
                    :
                    {' '}
                    {data.levels[level].min}
                  </span>
                  <span>
                    {t('Max')}
                    :
                    {' '}
                    {data.levels[level].max}
                  </span>
                </div>
              )}

              { index === 1 && (
                <div className="item__variants levels__item" key={uuid()}>
                  {t('Level 2')}
                  <span>
                    {t('Min')}
                    :
                    {' '}
                    {data.levels[level].min}
                  </span>
                  <span>
                    {t('Max')}
                    :
                    {' '}
                    {data.levels[level].max}
                  </span>
                </div>
              )}

              { index === 2 && (
                <div className="item__variants levels__item" key={uuid()}>
                  {t('Level 3')}
                  <span>
                    {t('Min')}
                    :
                    {' '}
                    {data.levels[level].min}
                  </span>
                  <span>
                    {t('Max')}
                    :
                    {' '}
                    {data.levels[level].max}
                  </span>
                </div>
              )}

              { index === 3 && (
                <div className="item__variants levels__item" key={uuid()}>
                  {t('Level 4')}
                  <span>
                    {t('Min')}
                    :
                    {' '}
                    {data.levels[level].min}
                  </span>
                  <span>
                    {t('Max')}
                    :
                    {' '}
                    {data.levels[level].max}
                  </span>
                </div>
              )}

              { index === 4 && (
                <div className="item__variants levels__item" key={uuid()}>
                  {t('Level 5')}
                  <span>
                    {t('Min')}
                    :
                    {' '}
                    {data.levels[level].min}
                  </span>
                  <span>
                    {t('Max')}
                    :
                    {' '}
                    {data.levels[level].max}
                  </span>
                </div>
              )}

              { index === 5 && (
                <div className="item__variants levels__item" key={uuid()}>
                  {t('Level 6')}
                  <span>
                    {t('Min')}
                    :
                    {' '}
                    {data.levels[level].min}
                  </span>
                  <span>
                    {t('Max')}
                    :
                    {' '}
                    {data.levels[level].max}
                  </span>
                </div>
              )}
            </>
          ))}
        </div>
      </div>
    </div>
  )

  const renderItemDataCountries = () => (
    <div className="project__item">
      <div className="project__header">
        <div className="user__name project__name">{t('Country')}</div>
      </div>

      <div className="project__content">
        <div className="country__list">
          <div className="country__inner">
            {Object.keys(data.country).map((item) => (
              <div className="country__item" key={uuid()}>
                <div className="faq__stage country__name">
                  {t(item)}
                </div>
                <div className="item__description country__description">
                  <div className="item__action country__acceptance">{t('Acceptance')}</div>
                  <div className="item__name country__checking">
                    {data.country[item].accept.passport && (
                      <div className="preview__clear project__checked">
                        <Icon
                          name="check"
                          className={classNames('check')}
                          size="16"
                        />
                        {t('Passport')}
                      </div>
                    )}

                    {!data.country[item].accept.passport && (
                      <div className="preview__clear project__unchecked">
                        <Icon
                          name="close"
                          className={classNames('close')}
                          size="16"
                        />
                        {t('Passport')}
                      </div>
                    )}

                    {data.country[item].accept.nationalId && (
                      <div className="preview__clear project__checked">
                        <Icon
                          name="check"
                          className={classNames('check')}
                          size="16"
                        />
                        {t('National ID')}
                      </div>
                    )}

                    {!data.country[item].accept.nationalId && (
                      <div className="preview__clear project__unchecked">
                        <Icon
                          name="close"
                          className={classNames('close')}
                          size="16"
                        />
                        {t('National ID')}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )

  const renderItemDataRoom = () => (
    <div className="project__item">
      <div className="project__header">
        <div className="user__name project__name">{t('Dataroom')}</div>
      </div>

      <div className="project__content datarooms__list">
        { loading && (
          <CircularProgress />
        )}

        { data.dataroom.map((item) => (
          <div className="variants__item datarooms__item" key={item.id}>
            <div className="variants__preview datarooms__preview">
              {(item.url).match(/\.pdf$/)
                ? t('pdf file')
                : <img src={item.url} alt="Dataroom" /> }
            </div>

            <div className="variants__description datarooms__description">
              <div className="item__name datarooms__name">
                {item.filename}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )

  return (
    <>
      <div className="section item project">
        {loading && (
          <div className="item__center center justify__center project__center">
            <CircularProgress />
          </div>
        )}

        {data && (
          <>
            <div className="item__center center project__center">
              <div className="item__bg project__bg">
                <div className="item__preview project__preview">

                  <div className="project__video-wrapper">
                    {data.videoUrl && (
                      <>
                        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/iframe-has-title */}
                        <iframe
                          id="ytplayer"
                          type="text/html"
                          width="640"
                          height="360"
                          src={data.videoUrl}
                          frameBorder="0"
                        />
                      </>
                    )}
                  </div>
                </div>

                <div className="options project__options">
                  <div className="options__list project__options-list">
                    <ClickAwayListener onClickAway={onActionAwayClick}>
                      <div className={`actions js-action ${action.isActionActive ? 'active' : ''}`}>
                        <button
                          className="button-circle-stroke actions__button js-actions-button"
                          type="button"
                          onClick={onActionClick}
                        >
                          <Icon
                            name="more"
                            className={classNames('more')}
                            size="20"
                          />
                        </button>

                        <div className="actions__body project__actions-body">
                          <button
                            type="button"
                            className="actions__item project__actions-item"
                          >
                            {t('Signup now')}
                          </button>

                          <button
                            type="button"
                            className="actions__item project__actions-item"
                          >
                            {t('Join Discord')}
                          </button>

                          <button
                            type="button"
                            className="actions__item project__actions-item error"
                            disabled
                          >
                            {t('Make an Offer (Q4 &#39;22)')}
                          </button>
                        </div>
                      </div>
                    </ClickAwayListener>
                  </div>
                </div>
              </div>

              <div className="item__details">
                {renderDataLogo()}

                <h1 className="item__title h3">
                  {data.title}
                </h1>

                {renderDataAcceptedCurrencies()}

                <div className="item__cost">
                  { data.sale.enable
                    ? <div className="status-stroke-green item__price">{t('Sale')}</div>
                    : <div className="status-stroke-black item__price">{t('Not for sale')}</div>}
                </div>

                <div className="faq__stage">{t('Company')}</div>

                {renderDataCompany()}

                <div className="item__cost">{' '}</div>
                <div className="item__tabs js-tabs">
                  <div className="item__nav">
                    {projectInformationNav.map((x) => (
                      <button
                        type="button"
                        className={`item__link js-tabs-link ${x === activeInformationTab ? 'active' : ''}`}
                        key={x}
                        onClick={() => setActiveInformationTab(x)}
                      >
                        {x}
                      </button>
                    ))}
                  </div>
                  <div className="item__container">
                    {activeInformationTab === projectInformationNav[0] && (
                      <div className="item__box js-tabs-item" style={{ display: 'block' }}>
                        {renderTabInfo()}
                      </div>
                    )}

                    {activeInformationTab === projectInformationNav[1] && renderTabToken()}

                    {activeInformationTab === projectInformationNav[2] && renderTabServices()}

                    {activeInformationTab === projectInformationNav[3] && renderTabPaymentMethod()}
                  </div>
                </div>
              </div>

              <div className="project__fullwidth">
                {renderItemDataLevels()}
                {renderItemDataManagers()}
                {renderItemDataRoom()}
                {renderItemDataCountries()}
              </div>
            </div>

            <div className="display-none">
              <p>{t('Check console window for details of the object')}</p>
              {/* eslint-disable-next-line */}
              {console.log(data)}
              {printData(data)}
            </div>
          </>
        )}
      </div>

    </>
  )
}

Project.propTypes = {
  match: PropTypes.object,
}

export default Project
