import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import uuid from 'react-uuid'
import PropTypes from 'prop-types'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { useTranslation } from 'react-i18next'

import {
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from '@material-ui/core'

import {
  GET_ADMIN_TENANT_DETAILS,
  DROP_TENANT,
  RENEW_SUBSCRIPTION,
} from '../queriesAndMutations'
import { formatDate, toaster } from '../utils'
import TenantStatusChip from '../components/common/TenantStatusChip'
import TenantLogItem from '../components/TenantDetails/TenantLogItem'
import TenantServiceUsageItem from '../components/TenantDetails/TenantServiceUsageItem'
import TenantInvoiceItem from '../components/TenantDetails/TenantInvoiceItem'
import ControlAdminBreadcrumbs from '../components/ControlAdminBreadcrumbs'
import CustomLoader from '../components/Loader'

const AdminTenantDetails = ({ match }) => {
  const { t } = useTranslation()
  const tenantId = +match.params.tenantId
  const [isDropTenantDialogOpen, setIsDropTenantDialogOpen] = useState(false)
  const [twoFaToken, setTwoFaToken] = useState('')
  const [dropTenantResult, setDropTenantResult] = useState({})
  const [isDropTenantStatusDialogOpen, setIsDropTenantStatusDialogOpen] = useState(false)
  const [dropTenant, dropTenantStatus] = useMutation(DROP_TENANT)
  const [renewSubscription, renewSubscriptionStatus] = useMutation(RENEW_SUBSCRIPTION)
  const { data: { getAdminTenantDetails: data } = {}, loading } = useQuery(GET_ADMIN_TENANT_DETAILS, {
    variables: {
      id: tenantId,
    },
  })
  const latestSubscription = data && data.Subscription
    ? [...data.Subscription].sort((subA, subB) => subB.id - subA.id)[0]
    : null
  const dropSelectedTenant = () => {
    setIsDropTenantDialogOpen(false)
    setIsDropTenantStatusDialogOpen(true)
    dropTenant({
      variables: {
        tenantId: data.id,
        twoFaToken,
      },
    }).then((response) => {
      setDropTenantResult(response.data.dropTenant)
    }).catch(() => {})
  }
  const renderDropTenantResult = () => (
    <ul>
      {dropTenantResult && dropTenantResult.status ? Object.keys(dropTenantResult.status).map((key) => (
        <li
          key={key}
          className={`text-${dropTenantResult.status[key].type}`}
        >
          {dropTenantResult.status[key].message}
        </li>
      )) : (
        <li>{t('Something went wrong. Please try again later.')}</li>
      )}
    </ul>
  )
  const closeDropTenantStatusDialog = () => {
    setIsDropTenantStatusDialogOpen(false)
    window.location.href = `${window.location.origin}/admin/tenants`
  }
  const renewTenantSubscription = () => {
    renewSubscription({
      variables: {
        tenantId,
      },
    }).then(({ data: { renewSubscription: isSuccess } }) => {
      if (isSuccess) {
        toaster.success(t('Subscription has been renewed'))
      } else {
        toaster.success(t('Failed to renew subscription of tenant'))
      }
    }).catch(() => {})
  }

  const tenantDetailsNav = ['Service Usage', 'Change Log', 'Billings']
  const [activeDetailNav, setActiveDetailNav] = useState(tenantDetailsNav[0])

  return (
    <>
      <ControlAdminBreadcrumbs>
        <li className="breadcrumbs__item">
          <NavLink to="/admin/dashboard" className="breadcrumbs__link">{t('Admin Dashboard')}</NavLink>
        </li>
        <li className="breadcrumbs__item">
          <NavLink to="/admin/tenants" className="breadcrumbs__link">{t('Tenants')}</NavLink>
        </li>
        <li className="breadcrumbs__item">
          {t('Tenant Details')}
        </li>
      </ControlAdminBreadcrumbs>

      <div className="section-pt80 faq tenant-details">
        <div className="faq__center tenant-details__center center">
          <div className="faq__top tenant-details__top tenant-details__top-full">
            <div className="faq__stage tenant-details__pretitle">
              {t('Tenant Details')}
            </div>
            {!loading && data && (
              <div className="faq__title h2 tenant-details__title">
                {t(data.host)}
              </div>
            )}
          </div>
        </div>

        <div className="activity__center tenant-activity__center center">
          <div className="activity__row tenant-activity__row">

            {loading && (
              <div className="activity__wrapper">
                <CustomLoader />
              </div>
            )}

            {!loading && data && (
              <>
                <div className="activity__wrapper no-padding__top">
                  <div className="block mt__32">{' '}</div>
                  <div className="activity__nav">
                    {tenantDetailsNav.map((x) => (
                      <button
                        key={uuid()}
                        type="button"
                        className={`activity__link ${x === activeDetailNav ? 'active' : ''}`}
                        onClick={() => setActiveDetailNav(x)}
                      >
                        {x}
                      </button>
                    ))}
                  </div>

                  <div className="activity__contents">
                    {activeDetailNav === tenantDetailsNav[0] && (
                      <>
                        <div className="upload__list no-margin__bottom no-padding__bottom border-none">
                          <div className="footer__info">{t('Service Usage')}</div>
                          <div className="mb__24">{' '}</div>
                          <TenantServiceUsageItem isAdmin tenantId={tenantId} />
                        </div>
                      </>
                    )}

                    {activeDetailNav === tenantDetailsNav[1] && (
                      <>
                        <div className="upload__list no-margin__bottom no-padding__bottom border-none">
                          <div className="footer__info">{t('Change Log')}</div>
                          <div className="mb__24">{' '}</div>

                          <TenantLogItem isAdmin tenantId={tenantId} />
                        </div>
                      </>
                    )}

                    {activeDetailNav === tenantDetailsNav[2] && (
                      <>
                        <div className="upload__list">
                          <div className="footer__info">{t('Subscription')}</div>
                          <div className="mb__24">{' '}</div>

                          {!latestSubscription && (
                            <div className="activity__item change-log__item">
                              <div className="activity__details">
                                <div className="activity__description">
                                  {t('No information')}
                                </div>
                              </div>
                            </div>
                          )}

                          {latestSubscription && (
                            <div className="activity__item change-log__item">
                              <div className="activity__details change-log__details">
                                <div className="activity__description change-log__title">
                                  <div className="field__label mt__8 mb__4">
                                    {t('License')}
                                  </div>
                                  {latestSubscription ? (latestSubscription?.SubscriptionPlan?.title || 'Deprecated license') : 'No license' }
                                </div>

                                <div className="activity__date change-log__details">
                                  <div className="field__label mt__8 mb__4">
                                    {t('Start from')}
                                  </div>
                                  {' '}
                                  {formatDate(+latestSubscription.createdAt)}
                                </div>

                                <div className="activity__date change-log__details">
                                  <div className="field__label mt__8 mb__4">
                                    {t('Next invoice')}
                                  </div>
                                  {' '}
                                  {formatDate(+latestSubscription.expiredDate)}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="upload__list">
                          <div className="footer__info">{t('Invoices')}</div>
                          <div className="mb__24">{' '}</div>

                          <TenantInvoiceItem isAdmin tenantId={tenantId} />
                        </div>
                      </>
                    )}
                  </div>
                </div>

                <div className="activity__filters">
                  <div className="upload__list">
                    <div className="field__label">{t('Tenant Info')}</div>
                    <div className="mb-32">{' '}</div>
                    <div className="item__head">
                      <div className="item__description">
                        <div className="item__info">
                          {t('Host')}
                          {' '}

                          <span>
                            {t(data.host)}
                          </span>
                        </div>
                        <div className="block mb__16">{' '}</div>

                        <div className="item__info">
                          {t('License')}
                          {' '}
                          <span>
                            {latestSubscription ? (latestSubscription?.SubscriptionPlan?.title || t('Deprecated license')) : t('No license') }
                          </span>
                        </div>
                        <div className="block mb__16">{' '}</div>

                        <div className="item__info">
                          {t('Status')}
                          {' '}
                          <TenantStatusChip status={data.status} />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="upload__list no-margin__bottom no-padding__bottom border-none">
                    <div className="field__label">{t('Administrator Action')}</div>
                    <div className="mb__32">{' '}</div>
                    {(window.location.hostname === 'localhost' || window.location.host === 'dev.investhub.io') && (
                      <div className="item__head">
                        <div className="item__description">
                          <div className="item__info">
                            {t('Renew subscription (for testing only)')}
                            {renewSubscriptionStatus.loading && (
                              <CircularProgress />
                            )}

                            {!renewSubscriptionStatus.loading && (
                              <>
                                <br />
                                <div className="block mb__8">{' '}</div>
                                <button
                                  type="button"
                                  disabled={data.isStatic}
                                  className="button button-small w-full"
                                  onClick={renewTenantSubscription}
                                >
                                  {t('Renew')}
                                </button>
                              </>
                            )}
                          </div>
                          <div className="block mb__16">{' '}</div>

                          <div className="item__info">
                            {t('Drop tenant')}
                            <br />
                            <div className="block mb__8">{' '}</div>
                            <button
                              disabled={data.isStatic}
                              className="button error button-small w-full"
                              onClick={() => setIsDropTenantDialogOpen(true)}
                              type="button"
                            >
                              {t('Drop')}
                            </button>
                            {data.isStatic && (
                              <>
                                {t('This is a static tenant. It cannot be dropped')}
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}

          </div>
        </div>
      </div>

      <Dialog
        open={isDropTenantDialogOpen}
        onClose={() => setIsDropTenantDialogOpen(false)}
      >
        <DialogTitle id="alert-dialog-title">{t('Drop tenant')}</DialogTitle>
        <DialogContent>
          {data && (
            <div>
              <p>
                {t('Do you really want to drop tenant')}
                {' '}
                <strong>{data.host}</strong>
                ?
              </p>
              <div className="text-error">
                <p>
                  <strong>
                    {t('Notice')}
                    :
                  </strong>
                </p>
                <p>
                  -
                  {' '}
                  {t('This action cannot be undone.')}
                </p>
                <p>
                  -
                  {' '}
                  {t('Dropping announcement will be sent to emails of all admins and compliance officers of the tenant.')}
                </p>
              </div>
              <hr />
              <TextField
                label={t('Enter 2FA code for confirmation')}
                required
                value={twoFaToken}
                onChange={({ target: { value } }) => setTwoFaToken(value)}
                className="investhub__field field w-full"
                margin="normal"
              />
            </div>
          )}
        </DialogContent>
        <DialogActions>
          {dropTenantStatus.loading ? (
            <CircularProgress />
          ) : (
            <>
              <Button variant="contained" onClick={() => setIsDropTenantDialogOpen(false)}>{t('Cancel')}</Button>
              <Button
                variant="contained"
                className="MuiButton-containedError"
                onClick={dropSelectedTenant}
              >
                {t('Drop')}
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>

      <Dialog
        open={isDropTenantStatusDialogOpen}
        onClose={closeDropTenantStatusDialog}
      >
        <DialogTitle id="alert-dialog-title">{t('Drop tenant status')}</DialogTitle>
        <DialogContent>
          {dropTenantStatus.loading ? (
            <div>
              <p>{t('Dropping tenant...')}</p>
              <CircularProgress />
            </div>
          ) : (
            renderDropTenantResult()
          )}
        </DialogContent>
        <DialogActions>
          {!dropTenantStatus.loading && (
            <>
              <Button
                variant="contained"
                color="primary"
                onClick={closeDropTenantStatusDialog}
              >
                {t('Close')}
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </>
  )
}

AdminTenantDetails.propTypes = {
  match: PropTypes.object,
}

export default AdminTenantDetails
