import axios from 'axios'

const api = axios.create({
  baseURL: 'https://investhub-cms.herokuapp.com',
  headers: {
    Authorization: 'Bearer 9a71b51045d525790fa2bde6ba6fe73ddf5c42545c7e73b31d5583ee25522e7f523bae9a9b6e247ad493b2e7ab0854ca60ccd205185d060840d04daa91acc9c428d350431dee45fbb37c690ef88ad28f9421843e7d38167cc7b56d3a5b69290ad20988ad65e8ef3fab98b83a6e802f5d33ac2d9f48d2404f6af7524a806a6762',
  },
})

export default api
