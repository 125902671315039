import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import {
  CircularProgress,
  TextField,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useMe } from '../../myHooks'
import Icon from '../Icon'

const MyProfileForm = () => {
  const { t } = useTranslation()

  const { data: { me } = {}, loading } = useMe({ fetchPolicy: 'network-only' })
  const [values, setValues] = useState({
    fullname: '',
    phone: '',
    companyName: '',
    companyAddress: '',
  })

  const setTextField = (field) => ({ target: { value } }) => {
    setValues({
      ...values,
      [field]: value,
    })
  }

  useEffect(() => {
    if (me) {
      setValues({
        ...values,
        fullname: me.fullname,
        phone: me.phone,
        companyName: me.companyName,
        companyAddress: me.companyAddress,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me])

  if (loading) {
    return (
      <div className="text-center">
        <CircularProgress />
      </div>
    )
  }
  return (
    <div className="details__list">
      <div className="details__item">
        <div className="details__subtitle text-white">{t('Update Profile')}</div>

        <div className="details__fieldset">
          <TextField
            className="xid-field has-icon"
            label={t('Full name')}
            value={values.fullname}
            margin="normal"
            variant="standard"
            onChange={setTextField('fullname')}
            InputProps={{
              startAdornment: (
                <Icon
                  name="user-svg"
                  className={classNames('user-svg')}
                  size={24}
                />
              ),
            }}
          />

          <TextField
            className="xid-field has-icon"
            label={t('Email')}
            margin="normal"
            variant="standard"
            value={me.email}
            onChange={() => {}}
            disabled
            InputProps={{
              startAdornment: (
                <Icon
                  name="mail"
                  className={classNames('mail')}
                  size={24}
                />
              ),
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default MyProfileForm
