import gql from 'graphql-tag'

// eslint-disable-next-line import/prefer-default-export
export const CHECK_VALID_TENANT = gql `
  query checkValidTenant($host: String!) {
    checkValidTenant(host: $host)
  }
`
export const LOGIN_VIA_TOKEN = gql`
  query loginViaToken ($token: String!, $host: String!) {
    loginViaToken (token: $token, host: $host) {
      jwt {
        accessToken
        refreshToken
      }
    }
  }
`;
