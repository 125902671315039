import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import {
  Dialog,
  CircularProgress,
} from "@material-ui/core"
import ModalInnerMaterialDialog from "../ModalInnerMaterialDialog"

export const PAYMENT_TYPES = {
  BANK_TRANSFER: "BANK_TRANSFER",
  PAYPAL: "PAYPAL",
  CRYPTO: "CRYPTO",
  WALLET: "WALLET",
  VOLT: "VOLT",
  SENDWYRE: "SENDWYRE",
  CRYPTODOTCOM: "CRYPTO.COM",
  STRIPE: "STRIPE",
}

const PaymentConfirmationDialog = ({
  values,
  setOpenModalConfirmation,
  openModalConfirmation,
  tenant,
  submitSsoTokenRequest,
  requestSsoTokenStatus,
  // submitPortKycData,
  portKycToTenantStatus,
  disabledBtn,
}) => {
  const { t } = useTranslation()

  const formatPaymentRef = (transaction) => (
    transaction.payment_ref != null
      ? `INV${transaction.id}-${transaction.payment_ref}`
      : `INV${transaction.id}`
  )

  const currencyText = (
    (values && values && values.currency && values.currency) ||
    ""
  ).toUpperCase()
  const currencyAmount =
    values && values && values.amount && (+values.amount).toFixed(2)

  const bankDetails = ((tenant || {}).bank || {})[values.currency] || {}

  return (
    <Dialog
      className="investhub-modal investhub-modal__l"
      open={openModalConfirmation}
      onClose={() => setOpenModalConfirmation(false)}
    >
      <ModalInnerMaterialDialog
        modalSize="popup__kyc-medium"
        modalOnClickClose={() => setOpenModalConfirmation(false)}
        modalTitleClassColor="title-primary"
        modalTitle={t("Confirmation Your Payment")}
      >
        <div className="details__fieldset outer">
          <div className="field">
            <div>
              {t("Your Order no.")}
              <strong>
                {formatPaymentRef(values.transaction)}
              </strong>
              {" "}
              {t("has been placed successfully.")}
            </div>
          </div>

          {values.selectedTransferType === PAYMENT_TYPES.BANK_TRANSFER ? (
            <div className="field">
              <div>
                {t("Please make your payment of")}
                {" "}
                <strong>{currencyAmount}</strong>
                {" "}
                <strong>{currencyText}</strong>
                {" "}
                {t("through bank to the below bank address. The token balance will appear in your account only after your transaction gets approved by our team.")}
              </div>
            </div>
          ) : values.selectedTransferType === PAYMENT_TYPES.CRYPTO ? (
            <div className="field">
              <div>
                {t("Please send")}
                <strong>{currencyAmount}</strong>
                {" "}
                <strong>{currencyText}</strong>
                {t("to the address below.")}
                <br />
                {/* <strong>{ t('Important') }: </strong>
                {" "}
                { t('Please add your ORDER NUMBER to the transaction memo field.') }
                <br /> */}
                {t("Your purchase transaction will appear in your account dashboard as soon as this has been confirmed by our team")}
              </div>
            </div>
          ) : null}

          {/* ) : values.selectedTransferType === PAYMENT_TYPES.VOLT ? (
            <div className="field">{renderVoltPaymentUrl()}</div>
          ) : values.selectedTransferType === PAYMENT_TYPES.CRYPTODOTCOM ? (
            <div className="field">{renderCryptoDotComPaymentUrl()}</div>
          ) : null} */}

          <div className="settings__row flex__row flex__wrap">
            <div className="field">
              {/* <NavLink to="/transactions" className="button button-small">
                {t("View Transaction")}
              </NavLink> */}
              {/* {publicSettings.enable_smart_contract &&
                publicSettings.smart_contract_type === "crowdsale_contract" &&
                values.transaction &&
                ["eth", "dai"].includes(values.transaction.currency) && (
                  <>
                    {" "}
                    {metamaskService.isMetamaskAvailable() &&
                      !loadingBuyToken &&
                      !isTransactionPaid && (
                        <button
                          className="button button-small"
                          onClick={onPayViaMetamask({
                            ...values.transaction,
                            user: me,
                          })}
                        >
                          Pay via Metamask
                        </button>
                      )}
                    {metamaskService.isMetamaskAvailable() &&
                      !loadingBuyToken &&
                      isTransactionPaid && (
                        <span className="text__green">Transaction paid</span>
                      )}
                    {metamaskService.isMetamaskAvailable() &&
                      loadingBuyToken && <CircularProgress />}
                    {metamaskService.isMetamaskNotAvailable() && (
                      <button className="button-stroke disabled">
                        You need to have metamask to do the transfer
                      </button>
                    )}
                  </>
                )} */}
            </div>
          </div>

          {values.selectedTransferType === PAYMENT_TYPES.BANK_TRANSFER ? (
            <div className="field">
              <div className="field__label">
                {t("Bank Details for Payment")}
              </div>

              <div className="field__wrap">
                <div className="transactions__row"> </div>
                <div className="transactions__row row__force">
                  <div className="transactions__col">
                    <div className="mr-3 inline-block field__label">
                      {t("Account Name")}
                    </div>

                    {bankDetails.account_name}
                  </div>

                  <div className="transactions__col">
                    <div className="mr-3 inline-block field__label">
                      {t("Account Number")}
                    </div>
                    {bankDetails.account_number}
                  </div>

                  <div className="transactions__col">
                    <div className="mr-3 inline-block field__label">{t("Bank Name")}</div>
                    {bankDetails.bank_name}
                  </div>

                  <div className="transactions__col">
                    <div className="mr-3 inline-block field__label">
                      {t("Bank Address")}
                    </div>
                    {bankDetails.bank_address}
                  </div>

                  <div className="transactions__col">
                    <div className="mr-3 inline-block field__label">
                      {t("Routing Number")}
                    </div>
                    {bankDetails.routing_number}
                  </div>

                  <div className="transactions__col">
                    <div className="mr-3 inline-block field__label">{t("IBAN")}</div>
                    {tenant && bankDetails.iban}
                  </div>

                  <div className="transactions__col">
                    <div className="mr-3 inline-block field__label">Swift/BIC</div>
                    {bankDetails["swift/bic"]}
                  </div>

                  <div className="transactions__col">
                    <div className="mr-3 inline-block field__label">
                      {t("Payment Reference")}
                    </div>
                    {formatPaymentRef(values.transaction)}
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          <br />

          <div className="refund__content">
            {values.paymentType === PAYMENT_TYPES.BANK_TRANSFER && (
              <p>
                {t("Use this transaction id")}
                {formatPaymentRef(values.transaction)}
                {" "}
                {t("as reference. Make your payment within 24 hours, If we will not received your payment within 24 hours, then we will cancel the transaction.")}
              </p>
            )}
          </div>

          <div className="settings__button settings__buttons d__flex flex__row flex__wrap flex__justify_between nospace__top">
            <button
              disabled={disabledBtn}
              onClick={submitSsoTokenRequest}
              type="button"
              className="button"
            >
              { t('Redirect to tenant and Sync data') }
            </button>

            {/* <button
              disabled={disabledBtn}
              onClick={submitPortKycData}
              type="button"
              className="button"
            >
              { t('Sync data and Close') }
            </button> */}
          </div>

          {requestSsoTokenStatus.loading || portKycToTenantStatus.loading ? (
            <CircularProgress />
          ) : (
            ""
          )}
        </div>
      </ModalInnerMaterialDialog>
    </Dialog>
  )
}

PaymentConfirmationDialog.propTypes = {
  values: PropTypes.object,
  setOpenModalConfirmation: PropTypes.func,
  openModalConfirmation: PropTypes.bool,
  tenant: PropTypes.object,
  submitSsoTokenRequest: PropTypes.func,
  requestSsoTokenStatus: PropTypes.object,
  // submitPortKycData: PropTypes.func,
  portKycToTenantStatus: PropTypes.object,
  disabledBtn: PropTypes.bool,
}

export default PaymentConfirmationDialog
