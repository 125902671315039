import gql from 'graphql-tag'

export const REGISTER_TENANT = gql`
  mutation registerTenant($input: RegisterTenantInput) {
    registerTenant(input: $input) {
      id
      host
      schema
      userId
      createdAt
    }
  }
`;

export const EASY_REGISTER_TENANT = gql`
  mutation easyRegisterTenant($input: EasyRegisterTenantInput) {
    easyRegisterTenant(input: $input) {
      id
      host
      schema
      userId
      createdAt
    }
  }
`;

export const CHECK_SUBDOMAIN_AVAILABILITY = gql`
  mutation checkSubdomainAvailability($host: String! ) {
    checkSubdomainAvailability(host: $host)
  }
`;

export const CHECK_DOMAIN_AVAILABILITY = gql`
  mutation checkDomainAvailability($host: String! ) {
    checkDomainAvailability(host: $host)
  }
`;

export const SUBSCRIBE_FOR_TENANT = gql`
  mutation subscribeForTenant($tenantId: ID!, $planId: String!, $voucherCode: String) {
    subscribeForTenant(tenantId: $tenantId, planId: $planId, voucherCode: $voucherCode)
  }
`;

export const MARK_AS_SETUP_COMPLETED = gql`
  mutation markTenantAsSetupCompleted($id: ID!) {
    markTenantAsSetupCompleted(id: $id)
  }
`;

export const DROP_TENANT = gql`
  mutation dropTenant($tenantId: ID!, $twoFaToken: String!) {
    dropTenant(tenantId: $tenantId, twoFaToken: $twoFaToken)
  }
`;

export const DROP_OWN_TENANT = gql`
  mutation dropOwnTenant($tenantId: ID!, $twoFaToken: String!) {
    dropOwnTenant(tenantId: $tenantId, twoFaToken: $twoFaToken)
  }
`;


export const SETUP_TENANT_SSL = gql`
  mutation setupTenantSsl($id: ID!) {
    setupTenantSsl(id: $id)
  }
`;
