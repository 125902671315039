import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { Tabs, Tab } from '@material-ui/core'

import ContentImage from '../../../media/images/explanationFeature/explanationFeature'
import styles from './styles.module.scss'

function TabPanel(props) {
  const {
    children,
    value,
    index,
    ...other
  } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`managemonitorregister-tabpanel-${index}`}
      aria-labelledby={`managemonitorregister-tab-${index}`}
      {...other}
    >
      {value === index && (
        <>
          {children}
        </>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

function a11yProps(index) {
  return {
    id: `managemonitorregister-tab-${index}`,
    'aria-controls': `managemonitorregister-tabpanel-${index}`,
  }
}

const Explanation = () => {
  const { t } = useTranslation()

  const [tabValue, setTabValue] = useState(0)

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue)
  }

  const tabContentPayment = () => {
    return (
      <div className={classNames(styles.explanation__content, styles.explanation__left)}>
        <div className={styles.explanation__image}>
          <img src={ContentImage.importantTools} alt={t('All important tools at your finger tips')} />
        </div>

        <div className={styles.explanation__description}>
          <h2 className={classNames("h3", styles.explanation__title)}>
            {t('All important tools at your finger tips')}
          </h2>

          <div className={styles.explanation__text}>
            <p>
              {t('We understand, that being open to integrations helped us satisfy every requirement form each issuers so far. Leverage it right away.')}
            </p>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <div className={classNames("section", styles.explanation)}>
        <div className={classNames("center", styles.explanation__center)}>
          <Tabs
            aria-label="explanation-tab"
            className={classNames('investhub-tab', styles.explanation__tab_header)}
            centered
            value={tabValue}
            onChange={handleTabChange}
          >
            <Tab label={t('Payment')} {...a11yProps(0)} />
            <Tab label={t('Tokenisation')} {...a11yProps(1)} />
            <Tab label={t('Compliance')} {...a11yProps(2)} />
            <Tab label={t('Data/Storage')} {...a11yProps(3)} />
            <Tab label={t('Messaging')} {...a11yProps(4)} />
            <Tab label={t('Legal/Trustee')} {...a11yProps(5)} />
            <Tab label={t('API')} {...a11yProps(6)} />
          </Tabs>

          <TabPanel value={tabValue} index={0}>
            {tabContentPayment()}
          </TabPanel>

          <TabPanel value={tabValue} index={1}>
            {tabContentPayment()}
          </TabPanel>

          <TabPanel value={tabValue} index={2}>
            {tabContentPayment()}
          </TabPanel>

          <TabPanel value={tabValue} index={3}>
            {tabContentPayment()}
          </TabPanel>

          <TabPanel value={tabValue} index={4}>
            {tabContentPayment()}
          </TabPanel>

          <TabPanel value={tabValue} index={5}>
            {tabContentPayment()}
          </TabPanel>

          <TabPanel value={tabValue} index={6}>
            {tabContentPayment()}
          </TabPanel>

        </div>
      </div>

    </>
  )
}

export default Explanation
