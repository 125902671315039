import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { TENANT_STATUS } from '../../constants/tenant'
import { capitalize } from '../../utils'

const TenantStatusChip = ({ status }) => {
  const { t } = useTranslation()
  let label = ''
  let className
  switch (status) {
    case TENANT_STATUS.AWAIT_PURCHASE:
      label = 'Need subscription'
      className = 'status-stroke-blue'
      break
    case TENANT_STATUS.PROCESSING:
      label = 'Processing'
      className = 'status-stroke-orange'
      break
    case TENANT_STATUS.RUNNING:
      label = 'Running'
      className = 'status-stroke-green'
      break
    case TENANT_STATUS.AWAIT_DNS_SETUP:
      label = 'Await DNS Setup'
      className = 'status-stroke-orange'
      break
    default:
      label = typeof status === 'string' ? capitalize(status.replace(/_/g, ' ').toLowerCase()) : status
  }

  return (
    <span className={className}>
      {t(label)}
    </span>
  )
}

TenantStatusChip.propTypes = {
  status: PropTypes.string.isRequired,
}

export default TenantStatusChip
