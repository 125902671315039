import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { Tabs, Tab } from '@material-ui/core'
import { ReactComponent as VerificationIcon } from '../../../media/images/icons/ongoingVerifications.svg'
import { ReactComponent as TopCountries } from '../../../media/images/icons/topCountries.svg'
import ImageTokenInvestmentAmount from '../../../media/images/issuerFeature/feature'

import styles from './styles.module.scss'

function TabPanel(props) {
  const {
    children,
    value,
    index,
    ...other
  } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`verification-tabpanel-${index}`}
      aria-labelledby={`verification-tab-${index}`}
      {...other}
    >
      {value === index && (
        <>
          {children}
        </>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

function a11yProps(index) {
  return {
    id: `verification-tab-${index}`,
    'aria-controls': `verification-tabpanel-${index}`,
  }
}

const OngoingVerifications = () => {
  const { t } = useTranslation()

  const [tabValue, setTabValue] = useState(0)

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue)
  }

  return (
    <div className={classNames("section", styles.ongoingverifications)}>
      <div className={classNames("center", styles.ongoingverifications__center)}>
        <Tabs aria-label="verification-tab" className="investhub-tab" centered value={tabValue} onChange={handleTabChange}>
          <Tab label={t('Onging')} {...a11yProps(0)} />
          <Tab label={t('Top Investors')} {...a11yProps(1)} />
          <Tab label={t('Monthly Statistics')} {...a11yProps(2)} />
        </Tabs>

        <TabPanel value={tabValue} index={0}>

          <div className={classNames(styles.ongoingverifications__content, styles.content__right)}>
            <div className={styles.ongoingverifications__svg}>
              <VerificationIcon />
            </div>

            <div className={styles.ongoingverifications__description}>
              <h2 className={classNames("h3", styles.ongoingverifications__title)}>
                {t('Ongoing Verifications')}
              </h2>

              <div className={styles.ongoingverifications__text}>
                {t('Stay always up to date with the latest verifications')}
              </div>
            </div>
          </div>

        </TabPanel>
        <TabPanel value={tabValue} index={1}>

          <div className={classNames(styles.ongoingverifications__content, styles.content__left)}>
            <div className={styles.ongoingverifications__svg}>
              <TopCountries />
            </div>

            <div className={styles.ongoingverifications__description}>
              <h2 className={classNames("h3", styles.ongoingverifications__title)}>
                {t('Top Investors')}
              </h2>

              <div className={styles.ongoingverifications__text}>
                {t('Based on Country, and Preferred Payment Method (FIAT/Crypto)')}
              </div>
            </div>
          </div>

        </TabPanel>
        <TabPanel value={tabValue} index={2}>

          <div className={classNames(styles.ongoingverifications__content, styles.ongoingverifications__full)}>
            <div className={styles.ongoingverifications__image}>
              <img src={ImageTokenInvestmentAmount.tokenInvestmentAmount} alt={t("Token and Investment Amounts")} />
            </div>

            <div className={styles.ongoingverifications__description}>
              <h2 className={classNames("h3", styles.ongoingverifications__title)}>
                {t('Token and Investment Amounts')}
              </h2>

              <div className={styles.ongoingverifications__text}>
                {t('Token amount or Investment amount based on Months.')}
              </div>
            </div>
          </div>

        </TabPanel>
      </div>
    </div>
  )
}

export default OngoingVerifications
