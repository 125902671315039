import gql from 'graphql-tag'

// eslint-disable-next-line import/prefer-default-export
export const GET_OPPORTUNITY = gql`
  query getOpportunity($id: String!) {
    getOpportunity(id: $id) {
      id
      user_id
      owner_id
      name
      slug
      video_embed_url
      currency
      owner_wallet
      location
      sector
      minimum_subscription
      total_expected_amount
      start_date
      end_date
      description
      owner
      allOpportunityCommitment
      tenant_url
    }
  }
`;

// eslint-disable-next-line import/prefer-default-export
export const GET_OPPORTUNITY_COMMITMENT = gql`
  query getOpportunityCommitment($id: Int!) {
    getOpportunityCommitment(id: $id) {
      id
      user_id
      opportunity_id
      email
      name
      wallet_address
      ens
      stripe_session_id
      status
      currency
      amount
      total_amount
      commited_at
    }
  }
`

// eslint-disable-next-line import/prefer-default-export
export const OPPORTUNITY_LIST = gql`
  query opportunityList($search: String, $page: Int!, $rowsPerPage: Int!) {
    opportunityList(search: $search, page: $page, rowsPerPage: $rowsPerPage) {
      opportunities {
        id
        user_id
        owner_id
        name
        slug
        video_embed_url
        currency
        owner_wallet
        location
        sector
        minimum_subscription
        total_expected_amount
        start_date
        end_date
        description
        owner
        allOpportunityCommitment
        tenant_url
      }
      count
    }
  }
`;
