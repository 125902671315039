import React from 'react'
import '../../styles/investhub/styles.scss'
import '../../styles/v2/style.scss'
import '../../styles/xid/app.scss'
import SiteFooter from '../SiteFooter/SiteFooter'
import AppBar from '../common/AppBar/AppBar'

const PageDashboard = ({ children }) => (
  <div className="outer">
    <AppBar />
    <div className="outer__inner">
      {children}
    </div>

    <SiteFooter />
  </div>
)

export default PageDashboard
