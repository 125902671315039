import React from 'react'
import { useTranslation } from 'react-i18next'
import MyProfileForm from '../components/MyProfile/MyProfileForm'
import TwoFactorAuth from '../components/MyProfile/TwoFactorAuth'

const MyProfile = () => {
  const { t } = useTranslation()

  return (
    <>
      <div className="section-pt80 details">
        <div className="center details__center">
          <div className="details__top">
            <div className="details__title h2">
              {t('My Profile')}
            </div>
          </div>

          <div className="details__row">
            <div className="details__col is-full">
              <MyProfileForm />
            </div>

            <div className="details__col is-full">
              <TwoFactorAuth />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MyProfile
