import React, { useState, useEffect } from 'react'
import { useQuery } from '@apollo/react-hooks'
import classNames from 'classnames'
import {
  TextField,
  CircularProgress,
  MenuItem,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import {
  GET_TENANT_META_INFO,
} from '../queriesAndMutations'
import TenantCatalog from '../components/common/TenantCatalog'
import Icon from '../components/Icon'
import RangeSliderContent from '../components/Explore/PriceRange'

const exploreSortingValues = [
  { value: 'newest', label: 'Newest' },
  { value: 'oldest', label: 'Oldest' },
]

const explorePricingValues = [
  { value: 'most_like', label: 'Most liked' },
  { value: 'least_like', label: 'Least liked' },
]

const Explore = () => {
  const { t } = useTranslation()
  const [page, setPage] = useState(0)
  const [rangeValues, setRangeValues] = useState([100])
  const [search, setSearch] = useState('')
  // const filter = {}
  const rowsPerPage = 12
  const [exploreStatus, setExploreStatus] = useState('all')
  const [explorePricing, setExplorePricing] = useState('most_like')
  const [exploreSorting, setExploreSorting] = useState('newest')

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search)

    if (queryParams.get('type')) {
      setExploreStatus(queryParams.get('type'))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const filterCategoryOptions = [
    { value: 'all', label: 'All' },
    { value: 'private', label: 'Private' },
    { value: 'public', label: 'Public' },
    { value: 'security', label: 'Security Token' },
    { value: 'utility', label: 'Utility Token' },
  ]
  const { data: { getTenantMetaInfo: data } = {}, loading } = useQuery(GET_TENANT_META_INFO, {
    variables: {
      input: {
        page,
        rowsPerPage,
        filter: {
          search,
          type: exploreStatus === 'all' ? '' : exploreStatus,
          price: rangeValues[0],
          priceCompare: explorePricing,
        },
        sort: exploreSorting,
      },
    },
  })

  const handleExploreSorting = (event) => {
    const {
      target: { value },
    } = event

    setExploreSorting(value)
  }

  const handleExplorePricing = (event) => {
    const {
      target: { value },
    } = event

    setExplorePricing(value)
  }

  const handleExploreStatus = (event) => {
    const {
      target: { value },
    } = event

    setExploreStatus(value)
  }

  return (
    <>
      <div className="section-pt80 catalog explore">
        <div className="catalog__center center">
          <div className="catalog__top">
            <div className="catalog__title">{t('Explore')}</div>

            <form className="catalog__search">
              <TextField
                placeholder={t('Search by title, tenant, uri...')}
                name="search"
                type="text"
                required
                className="investhub__field catalog__input"
                value={search}
                onChange={({ target: { value } }) => { setSearch(value) }}
              />
              <button className="catalog__result" type="submit">
                <Icon
                  name="searchh"
                  className={classNames('searchh')}
                  size="16"
                />
              </button>
            </form>
          </div>

          <div className="catalog__sorting">
            <TextField
              select
              className="select investhub-select"
              onChange={handleExploreSorting}
              value={exploreSorting}
            >
              {exploreSortingValues.map(({ label, value }) => (
                <MenuItem value={value} key={value}>
                  {t(label)}
                </MenuItem>
              ))}
            </TextField>

            <div className="catalog-nav">
              {filterCategoryOptions.map((option) => (
                <button
                  key={option.value}
                  type="button"
                  className={classNames(
                    exploreStatus === option.value
                      ? ['catalog__link', 'active']
                      : ['catalog__link'],
                  )}
                  onClick={() => setExploreStatus(option.value)}
                >
                  {t(option.label)}
                </button>
              ))}
            </div>
          </div>

          <div className="catalog__row">
            <div className="catalog__filters">
              <RangeSliderContent
                rangeValues={rangeValues}
                setRangeValues={setRangeValues}
                rangeMin={0}
              />

              <div className="catalog__group">
                <div className="field">
                  <div className="field__label">Price</div>
                  <TextField
                    select
                    className="select investhub-select"
                    onChange={handleExplorePricing}
                    value={explorePricing}
                  >
                    {explorePricingValues.map((option) => (
                      <MenuItem value={option.value} key={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>

                <div className="field">
                  <div className="field__label">Status</div>
                  <TextField
                    select
                    className="select investhub-select"
                    onChange={handleExploreStatus}
                    value={exploreStatus}
                  >
                    {filterCategoryOptions.map((option) => (
                      <MenuItem value={option.value} key={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
              </div>

              <button type="button" className="catalog__reset">
                <Icon
                  name="close-circle-fill"
                  className={classNames('close-circle-fill')}
                  size="20"
                />
                Reset Filter
              </button>
            </div>

            <div className="catalog__wrapper">
              {loading && (
                <div className="text-center">
                  <CircularProgress />
                </div>
              )}

              {data && data.tenants && (
                <>
                  <TenantCatalog
                    tenantList={data.tenants}
                    total={data.total}
                    navigation
                    page={page}
                    rowsPerPage={rowsPerPage}
                    setPage={setPage}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Explore
