// Create this component for requirement for TOK-1151
// This will replace TenantInvoiceItem.jsx

import React from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/react-hooks'
import { useTranslation } from 'react-i18next'
import {
  GET_INVOICES,
  GET_ADMIN_INVOICES,
} from '../../queriesAndMutations'
import { formatDate } from '../../utils'
import CustomLoader from '../Loader'

const TenantInvoiceItem = ({
  tenantId,
  isAdmin,
}) => {
  const { t } = useTranslation()
  const {
    data: {
      [isAdmin ? 'getAdminInvoices' : 'getInvoices']: data,
    } = {},
    loading,
    error,
  } = useQuery(isAdmin ? GET_ADMIN_INVOICES : GET_INVOICES, {
    variables: {
      tenantId,
    },
  })

  return (
    <>
      {loading && (
        <div className="activity__item change-log__item">
          <CustomLoader />
        </div>
      )}
      {error && (
        <div className="activity__item change-log__item">
          <div className="activity__details">
            <div className="activity__subtitle">
              {t('Error')}
            </div>

            <div className="activity__description">
              <em>{t('Cannot load data')}</em>
            </div>
          </div>
        </div>
      )}

      {data && data.map((invoice) => (
        <div className="activity__item change-log__item" key={invoice.invoiceId}>
          <div className="activity__details change-log__details">
            <div className="activity__description change-log__title">
              <div className="field__label mt__8 mb__4">{t('ID')}</div>
              {invoice.invoiceUrl && (
                <a href={invoice.invoiceUrl} target="_blank" rel="noopener noreferrer">
                  {invoice.invoiceId}
                </a>
              )}
              {!invoice.invoiceUrl && invoice.invoiceId}
            </div>

            <div className="flex justify-start items-center">
              <div className="activity__date change-log__details mr__8">
                <div className="field__label mt__8 mb__4">{t('Created At')}</div>
                {formatDate(+invoice.createdAt)}
              </div>

              <div className="activity__date change-log__details mr__8">
                <div className="field__label mt__8 mb__4">{t('Amount')}</div>
                {invoice.amount}
                {' '}
                {invoice.currency.toUpperCase()}
              </div>

              <div className="activity__date change-log__details mr__8">
                <div className="field__label mt__8 mb__4">{t('Status')}</div>
                {t(invoice.status.toUpperCase())}
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  )
}

TenantInvoiceItem.propTypes = {
  tenantId: PropTypes.number.isRequired,
  isAdmin: PropTypes.bool,
}

export default TenantInvoiceItem
