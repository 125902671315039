import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Icon from '../Icon'
import { generateUuid } from '../../utils'
import BreadcrumbConnector from '../common/BreadcrumbConnector'

const ControlBreadcrumbs = ({ children }) => {
  const { t } = useTranslation()
  let inner
  if (children.length) {
    inner = children.reduce((all, element) => [...all, element, <BreadcrumbConnector key={generateUuid()} />], [])
    inner.pop()
  } else {
    inner = children
  }

  return (
    <div className="control">
      <div className="control__center center">
        <div className="control__button">
          <NavLink to="/my-profile" className="button-stroke button-small">
            <Icon
              name="user"
              size="20"
            />
            <span>
              {t('Profile')}
            </span>
          </NavLink>
        </div>

        <div className="breadcrumbs">
          <ul className="breadcrumbs">
            {inner}
          </ul>
        </div>
      </div>
    </div>
  )
}

ControlBreadcrumbs.prototypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
}

export default ControlBreadcrumbs
