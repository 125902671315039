/* eslint-disable spaced-comment */
import React, { useState } from 'react'
import classNames from 'classnames'
import { useCookies } from 'react-cookie'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import SlideToggle from "react-slide-toggle"
import config from '../../../config'
import { useMe } from '../../../myHooks'
import { USER_RIGHT_TYPES } from '../../../constants/user'
import { hasUserEnoughRights, logout } from '../../../utils'

import XidLogo from '../../../media/xid/xid-logo.png'

// import TranslationRwd from '../../Header/TranslationToggleRwd/TranslationToggleRwd'
import Styles from './styles.module.scss'

const AppBar = () => {
  const { t } = useTranslation()
  const [,, removeCookie] = useCookies()
  const triggerLogout = () => {
    removeCookie(config.jwtCookie)

    const logoutFunc = logout()

    logoutFunc()
  }

  const { data: { me: user } = {} } = useMe()
  const [rwdVisibleValues, setRwdVisibleValues] = useState({
    isRwdVisibleActive: false,
  })
  const onRwdVisibleActive = () => {
    setRwdVisibleValues({ ...rwdVisibleValues, isRwdVisibleActive: !rwdVisibleValues.isRwdVisibleActive })
  }

  return (
    <>
      <header className="header">
        <div className="center header__center">
          {/* .header__logo */}
          <NavLink to="/" className="header__logo">
            <img src={XidLogo} alt="XID App" className="some-icon" />
          </NavLink>

          {/* .header__wrapper */}
          <div className="header__wrapper">{' '}</div>

          <div className="header__item header__item_notification">{' '}</div>

          <NavLink
            to="#"
            onClick={() => { triggerLogout(); onRwdVisibleActive() }}
            className="button-stroke button-small header__button header__connect"
          >
            {t('Log out')}
          </NavLink>

          <SlideToggle
            duration={20}
            collapsed
            render={({
              toggle,
              setCollapsibleElement,
              toggleState,
            }) => (
              <>
                <div className="desktop-hide tablet-show">
                  <div className={classNames("header__wrapper", (toggleState || "").toLowerCase())} ref={setCollapsibleElement}>
                    { user && hasUserEnoughRights(user.rights, USER_RIGHT_TYPES.ADMIN) && (
                      <NavLink to="/admin/dashboard" className="button-small header__button header__upload" onClick={onRwdVisibleActive}>{t('Admin')}</NavLink>
                    )}
                  </div>
                </div>

                <button
                  type="button"
                  className={
                    classNames(
                      "header__burger",
                      Styles.header__burger,
                      (toggleState || "").toLowerCase(),
                    )
                  }
                  onClick={toggle}
                >
                  &nbsp;
                </button>
              </>
            )}
          />
        </div>
      </header>
    </>
  )
}

export default AppBar
