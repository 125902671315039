import React, { useState } from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { omit } from 'lodash'
// import Stepper from '@material-ui/core/Stepper'
// import Step from '@material-ui/core/Step'
// import StepLabel from '@material-ui/core/StepLabel'
import { useTranslation } from 'react-i18next'
import {
  TextField,
  // Radio,
  // RadioGroup,
  // FormControlLabel,
  InputAdornment,
  // Checkbox,
  CircularProgress,
  // MenuItem,
} from '@material-ui/core'

import {
  CHECK_SUBDOMAIN_AVAILABILITY,
  CHECK_DOMAIN_AVAILABILITY,
  PUBLIC_SETTINGS,
  EASY_REGISTER_TENANT,
} from '../queriesAndMutations'
import { toaster } from '../utils'
import ValidationMessage from '../components/common/ValidationMessage'
import Upload from '../components/common/Upload'

const TenantEasySetup = () => {
  const { t } = useTranslation()
  const [checkSubdomain, checkSubdomainStatus] = useMutation(CHECK_SUBDOMAIN_AVAILABILITY)
  const [checkDomain, checkDomainStatus] = useMutation(CHECK_DOMAIN_AVAILABILITY)
  const [easyRegisterTenant] = useMutation(EASY_REGISTER_TENANT)
  const { data } = useQuery(PUBLIC_SETTINGS)
  const tenantBaseDomain = (data && data.publicSettings && data.publicSettings.cloudflare && data.publicSettings.cloudflare.baseDomain) || ''
  const prefixOwnDomain = window.location.host === 'dev.xidtech.io' ? 'test-' : ''

  const [stepEasySetup] = useState(1)
  const [values, setValues] = useState({
    subdomainType: 'xidtechSubdomain',
    ownSubdomain: '',
    customDomain: '',
    logo: '',
  })
  const [stepErrors] = useState({})

  const setTextField = (fieldName) => ({ target: { value } }) => {
    setValues({
      ...values,
      [fieldName]: value,
    })
  }

  const setFileField = (fieldName) => (value) => {
    setValues({
      ...values,
      [fieldName]: value,
    })
  }

  // const validateDomain = () => {
  //   const errors = {}
  //   if (!values.subdomainType) {
  //     errors.subdomainType = 'You need to select a type of domain.'
  //   } else if (values.subdomainType === 'ownSubdomain' && !values.ownSubdomain) {
  //     errors.ownSubdomain = 'You need to provide your subdomain.'
  //   } else if (values.subdomainType === 'xidtechSubdomain') {
  //     if (!values.customDomain) {
  //       errors.customDomain = 'You need to select a subdomain.'
  //     }
  //   }

  //   setStepErrors({
  //     ...stepErrors,
  //     domain: errors,
  //   })

  //   return Object.keys(errors).length === 0
  // }

  // const validateTokenType = () => {
  //   const errors = {}
  //   if (!values.tokenType) {
  //     errors.tokenType = 'You need to select a token type.'
  //   }

  //   setStepErrors({
  //     ...stepErrors,
  //     tokenType: errors,
  //   })

  //   return Object.keys(errors).length === 0
  // }

  // const validateCurrency = () => {
  //   const errors = {}
  //   if (!values.baseCurrency) {
  //     errors.baseCurrency = 'You need to select a base currency.'
  //   }

  //   if (+values.minimumInvestment <= 0) {
  //     errors.minimumInvestment = 'Minimum investment must be greater than 0.'
  //   }

  //   if (values.acceptedCurrencies.length === 0) {
  //     errors.acceptedCurrencies = 'You must select at least one accepted currency.'
  //   }

  //   setStepErrors({
  //     ...stepErrors,
  //     currency: errors,
  //   })

  //   return Object.keys(errors).length === 0
  // }

  // const validateBlockchain = () => {
  //   const errors = {}
  //   if (!values.tokenName) {
  //     errors.tokenName = 'Token name must not be empty.'
  //   }

  //   if (!values.tokenSymbol) {
  //     errors.tokenSymbol = 'Token symbol must not be empty.'
  //   }

  //   if (!values.blockchainType) {
  //     errors.blockchainType = 'You must select a blockchain type.'
  //   }

  //   if (Object.values(values.acceptedWallets).reduce((all, isAccepted) => all || isAccepted, false) === false) {
  //     errors.acceptedWallets = 'You must select at least one accepted wallet.'
  //   }

  //   setStepErrors({
  //     ...stepErrors,
  //     blockchain: errors,
  //   })

  //   return Object.keys(errors).length === 0
  // }

  // const validateStep = (step) => {
  //   switch (step) {
  //     case 1:
  //       return validateDomain()

  //     case 2:
  //       return validateTokenType()

  //     case 3:
  //       return validateCurrency()

  //     case 4:
  //       return validateBlockchain()

  //     default:
  //       return true
  //   }
  // }
  // const stepListEasySetup = [
  //   'Setup Domain',
  // ]

  const nextStepEasySetup = () => {
    const getUploadFile = (file) => (file ? file.file : null)

    const submitData = {
      ...omit(values, [
        'blockchainType',
      ]),
      logo: getUploadFile(values.logo),

    }
    easyRegisterTenant({
      variables: {
        input: submitData,
      },
    }).then(() => {
      window.location.href = `${window.location.origin}/dashboard`
    }).catch(() => {})
  }

  const getErrorText = (step, field) => (stepErrors && stepErrors[step] && stepErrors[step][field]) || ''

  const verifyDomain = () => {
    checkDomain({
      variables: {
        host: `${prefixOwnDomain}${values.ownSubdomain}`,
      },
    }).then((response) => {
      if (response.data.checkDomainAvailability) {
        toaster.success(t('Domain "OWNSUBDOMAIN" is available', { OWNSUBDOMAIN: prefixOwnDomain + values.ownSubdomain }))
      } else {
        toaster.error(t('Domain "OWNSUBDOMAIN" is not available', { OWNSUBDOMAIN: prefixOwnDomain + values.ownSubdomain }))
      }
    }).catch(() => {})
  }

  const checkSubdomainAvailable = () => {
    checkSubdomain({
      variables: {
        host: values.customDomain,
      },
    }).then((response) => {
      if (response.data.checkSubdomainAvailability) {
        toaster.success(t('Domain "OWNDOMAIN" is available', { OWNDOMAIN: values.customDomain + tenantBaseDomain }))
      } else {
        toaster.error(t('Domain "OWNDOMAIN" is not available', { OWNDOMAIN: values.customDomain + tenantBaseDomain }))
      }
    }).catch(() => {})
  }

  return (
    <>
      <div className="section-pt80 details">
        <div className="center details__center">
          <div className="details__top">
            <div className="detals__title h2">
              {t('Easy Tenant Setup')}
            </div>
          </div>

          <div className="details__row">
            {stepEasySetup === 1 && (
              <>
                <div className="details__col is-full">
                  <div className="details__list">
                    <div className="details__item">
                      <div className="details__subtitle text-white">
                        {t('Setup domain')}
                      </div>

                      <div className="details__fieldset">
                        {values.subdomainType === 'ownSubdomain' && (
                          <>
                            <TextField
                              value={values.ownSubdomain}
                              onChange={setTextField('ownSubdomain')}
                              className="xid-field has-adornment-end"
                              margin="normal"
                              variant="standard"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              label={t('Cname')}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    {checkDomainStatus.loading && (
                                      <CircularProgress />
                                    )}
                                    {!checkDomainStatus.loading && (
                                      <button
                                        type="button"
                                        className="button button-small"
                                        onClick={verifyDomain}
                                      >
                                        {t('Verify')}
                                      </button>
                                    )}
                                  </InputAdornment>
                                ),
                              }}
                            />
                            <ValidationMessage error={getErrorText('domain', 'ownSubdomain')} />
                          </>
                        )}

                        {values.subdomainType === 'xidtechSubdomain' && (
                          <>
                            <TextField
                              value={values.customDomain}
                              onChange={setTextField('customDomain')}
                              className="xid-field has-adornment-end"
                              margin="normal"
                              variant="standard"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              label={t('Cname')}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <span className="button-stroke button-small button-transparent">
                                      {tenantBaseDomain}
                                    </span>
                                  </InputAdornment>
                                ),
                              }}
                            />

                            <ValidationMessage error={getErrorText('domain', 'customDomain')} />

                            <div className="xid-field">
                              {checkSubdomainStatus.loading && (
                                <CircularProgress />
                              )}
                              {!checkSubdomainStatus.loading && (
                                <button
                                  className="button-stroke"
                                  type="button"
                                  onClick={checkSubdomainAvailable}
                                >
                                  {t('Check Availability')}
                                </button>
                              )}
                            </div>
                          </>
                        )}

                        <div className="xid-field">
                          <div className="field__wrapper">
                            <Upload
                              label={t('Logo')}
                              preview
                              accept="image/*"
                              state={values.logo}
                              setState={setFileField('logo')}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}

            <div className="details__col is-full">
              <div className="details__list">
                <div className="details__item">
                  <div className="details__fieldset">
                    <button type="button" className="button details__button" onClick={nextStepEasySetup}>
                      {t('Complete')}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default TenantEasySetup
