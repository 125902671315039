import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

const InformationCard = ({
  title,
  color,
  children,
}) => {
  return (
    <div className={classNames('card__information', color)}>
      {title && (
        <div className="h5 card__information_title">
          {title}
        </div>
      )}

      {children && (
        <div className="card__information_content">
          {children}
        </div>
      )}
    </div>
  )
}

InformationCard.propTypes = {
  title: PropTypes.string,
  color: PropTypes.string,
  children: PropTypes.element,
}

export default InformationCard
