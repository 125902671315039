import React, { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/react-hooks'
import {
  CircularProgress,
  MenuItem,
  TextField,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import {
  SETTINGS,
  SAVE_SETTINGS,
} from '../queriesAndMutations'
import { toaster } from '../utils'
import MailSetting from '../components/AdminSetting/MailSetting'
import StripeSetting from '../components/AdminSetting/StripeSetting'
import CloudflareSetting from '../components/AdminSetting/CloudflareSetting'
import BrandSetting from '../components/AdminSetting/BrandSetting'
import MediumSetting from '../components/AdminSetting/MediumSetting'
import MailchimpSetting from '../components/AdminSetting/MailchimpSetting'
import StatisticSetting from '../components/AdminSetting/StatisticSetting'
import OthersSetting from '../components/AdminSetting/OthersSetting'
import ControlAdminBreadcrumbs from '../components/ControlAdminBreadcrumbs'

const defaultSettings = {
  stripe: {
    publicKey: '',
    secretKey: '',
    webhookSecretKey: '',
  },
  mailgun: {
    host: '',
    port: '',
    user: '',
    pass: '',
    from: '',
    technicianEmail: '',
    bccEmail: '',
  },
  cloudflare: {
    enable: false,
    authKey: '',
    authEmail: '',
    zoneId: '',
    apiToken: '',
    baseDomain: '',
    loadbalancerHost: '',
  },
  brand: {
    siteTitle: 'Investhub',
    logo: null,
  },
  medium: {
    username: '',
  },
  mailchimp: {
    formUrl: '',
  },
  statisticModification: {
    allUser: 0,
    activeUser: 0,
    approvedIndividualCustomer: 0,
    approvedCompanyCustomer: 0,
    totalToken: 0,
    totalValue: 0,
  },
  others: {
    intercom_app_id: '',
    google_analytics_id: '',
    opportunity_fee: 0.0,
  },
}
const jsonSettings = [
  'mailgun',
  'stripe',
  'cloudflare',
  'brand',
  'medium',
  'mailchimp',
  'statisticModification',
  'others',
]

const AdminSetting = () => {
  const { t } = useTranslation()
  const [selectedSection, setSelectedSection] = useState('')
  const [saveSettings, saveSettingsStatus] = useMutation(SAVE_SETTINGS)
  const isSectionSelected = (section) => !selectedSection || section === selectedSection
  const sections = [
    { id: '', label: 'All' },
    { id: 'mail', label: 'Mail' },
    { id: 'stripe', label: 'Stripe' },
    { id: 'cloudflare', label: 'CloudFlare' },
    { id: 'brand', label: 'Brand' },
    { id: 'medium', label: 'Medium Blog' },
    { id: 'mailchimp', label: 'Mailchimp' },
    { id: 'statisticModification', label: 'Statistic' },
    { id: 'others', label: 'Others' },
  ]
  const [settings, setSettings] = useState(defaultSettings)
  const { data, loading, refetch } = useQuery(SETTINGS)

  const parseJsonData = (structure, response, parentKey) => Object.keys(structure[parentKey]).reduce((all, key) => ({
    ...all,
    [key]: (response[parentKey][key] === null || response[parentKey][key] === undefined)
      ? structure[parentKey][key]
      : response[parentKey][key],
  }), {})
  useEffect(() => {
    if (data && data.settings) {
      setSettings({
        ...settings,
        ...Object.keys(settings).reduce((all, key) => (
          data.settings[key] && key !== '__typename'
            ? {
              ...all,
              [key]: jsonSettings.includes(key)
                ? parseJsonData(settings, data.settings, key)
                : data.settings[key],
            }
            : all
          // eslint-disable-next-line comma-dangle
        ), {})
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, setSettings])
  const setSubSettingField = (field, subField) => ({ target: { value } }) => {
    setSettings({
      ...settings,
      [field]: {
        ...settings[field],
        [subField]: value,
      },
    })
  }
  const setSubSettingNumberField = (field, subField) => ({ target: { value } }) => {
    setSettings({
      ...settings,
      [field]: {
        ...settings[field],
        [subField]: +value,
      },
    })
  }
  const setSubSettingCheckbox = (field, subField) => ({ target: { checked } }) => {
    setSettings({
      ...settings,
      [field]: {
        ...settings[field],
        [subField]: checked,
      },
    })
  }
  const setSubSettingFile = (field, subField) => (file) => {
    setSettings({
      ...settings,
      [field]: {
        ...settings[field],
        [subField]: file,
      },
    })
  }
  const getUploadFile = (fileObject) => (fileObject ? fileObject.file : null)
  const triggerSaveSettings = () => {
    saveSettings({
      variables: {
        input: {
          ...settings,
          brand: {
            ...settings.brand,
            logo: settings.brand.logo?.file ? getUploadFile(settings.brand.logo) : settings.brand.logo,
          },
        },
      },
    }).then(() => {
      toaster.success('Settings saved.')
      refetch()
    }).catch(() => {})
  }

  return (
    <>
      <ControlAdminBreadcrumbs>
        <li className="breadcrumbs__item">
          <NavLink to="/admin/dashboard" className="breadcrumbs__link">{t('Admin Dashboard')}</NavLink>
        </li>
        <li className="breadcrumbs__item">
          {t('Settings')}
        </li>
      </ControlAdminBreadcrumbs>

      <div className="section-pt80 faq admin-settings">
        <div className="faq__center admin-settings__center center">
          <div className="faq__top admin-settings__top">
            <div className="faq__title admin-settings__title h2">{t('Settings')}</div>
          </div>

          <div className="faq__row admin-settings__row">
            <div className="faq__col admin-settings__col">
              {loading && (
                <CircularProgress />
              )}

              {!loading && (
                <>
                  <div className="catalog__nav admin-settings__nav">
                    {sections.map((section) => (
                      <button
                        type="button"
                        key={section.id}
                        className={`catalog__link ${section.id === selectedSection ? 'active' : ''}`}
                        onClick={() => setSelectedSection(section.id)}
                      >
                        {t(section.label)}
                      </button>
                    ))}
                  </div>

                  <TextField
                    select
                    className="select select__trans admin-settings__nav-rwd"
                    value={selectedSection}
                  >
                    {sections.map((section) => (
                      <MenuItem
                        key={section.id}
                        value={section.id}
                      >
                        {t(section.label)}
                      </MenuItem>
                    ))}

                  </TextField>

                  {/* Mail setting */}
                  { isSectionSelected('mail') && (
                    <MailSetting
                      settings={settings}
                      setSubSettingField={setSubSettingField}
                    />
                  )}

                  {/* Stripe setting */}
                  { isSectionSelected('stripe') && (
                    <StripeSetting
                      settings={settings}
                      setSubSettingField={setSubSettingField}
                    />
                  )}

                  {/* Cloudflare setting */}
                  { isSectionSelected('cloudflare') && (
                    <CloudflareSetting
                      settings={settings}
                      setSubSettingField={setSubSettingField}
                      setSubSettingCheckbox={setSubSettingCheckbox}
                    />
                  )}

                  {/* Brand setting */}
                  { isSectionSelected('brand') && (
                    <BrandSetting
                      settings={settings}
                      setSubSettingField={setSubSettingField}
                      setSubSettingFile={setSubSettingFile}
                    />
                  )}

                  {/* Medium setting */}
                  { isSectionSelected('medium') && (
                    <MediumSetting
                      settings={settings}
                      setSubSettingField={setSubSettingField}
                    />
                  )}

                  {/* Mailchimp setting */}
                  { isSectionSelected('mailchimp') && (
                    <MailchimpSetting
                      settings={settings}
                      setSubSettingField={setSubSettingField}
                    />
                  )}

                  {/* Statistic setting */}
                  { isSectionSelected('statisticModification') && (
                    <StatisticSetting
                      settings={settings}
                      setSubSettingNumberField={setSubSettingNumberField}
                    />
                  )}

                  {/* Other setting */}
                  { isSectionSelected('others') && (
                    <OthersSetting
                      settings={settings}
                      setSubSettingField={setSubSettingField}
                      setSubSettingNumberField={setSubSettingNumberField}
                    />
                  )}

                  <div className="upload__foot details__foot">
                    <div className="details__btns details__btns_clear">
                      {saveSettingsStatus.loading ? (
                        <CircularProgress />
                      ) : (
                        <button type="button" className="button details__button" onClick={triggerSaveSettings}>{t('Update')}</button>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AdminSetting
