import React from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import Background from '../../../media/images/background.png'
import styles from './styles.module.scss'

const ThingsLearn = () => {
  const { t } = useTranslation()

  return (
    <div className={classNames("section", styles.thingslearn)}>
      <img src={Background} alt="Thins you'll learn" className={styles.thingslearn__bg} />
      <div className={classNames("center", styles.thingslearn__center)}>
        <h2 className={classNames("h3 text-center", styles.thingslearn__title)}>
          {t('Things you’ll learn')}
        </h2>

        <div className={styles.thingslearn__text}>
          {t('We believe that you should never stop learning.')}
        </div>

        <div className={styles.thingslearn__list}>
          <span className={styles.thingslearn__item}>
            <span className={styles.thingslearn__item_icon}>
              &nbsp;
            </span>
            <span>
              {t('What is a party round?')}
            </span>
          </span>

          <span className={styles.thingslearn__item}>
            <span className={styles.thingslearn__item_icon}>
              &nbsp;
            </span>
            <span>
              {t('How to manage Compliance')}
            </span>
          </span>

          <span className={styles.thingslearn__item}>
            <span className={styles.thingslearn__item_icon}>
              &nbsp;
            </span>
            <span>
              {t('How to manage Legal')}
            </span>
          </span>

          <span className={styles.thingslearn__item}>
            <span className={styles.thingslearn__item_icon}>
              &nbsp;
            </span>
            <span>
              {t('How to issue a prospectus')}
            </span>
          </span>

          <span className={styles.thingslearn__item}>
            <span className={styles.thingslearn__item_icon}>
              &nbsp;
            </span>
            <span>
              {t('How to onboard 5000 investors')}
            </span>
          </span>

          <span className={styles.thingslearn__item}>
            <span className={styles.thingslearn__item_icon}>
              &nbsp;
            </span>
            <span>
              {t('How important a AML Policy is')}
            </span>
          </span>

          <span className={styles.thingslearn__item}>
            <span className={styles.thingslearn__item_icon}>
              &nbsp;
            </span>
            <span>
              {t('How to setup a Tenant')}
            </span>
          </span>

          <span className={styles.thingslearn__item}>
            <span className={styles.thingslearn__item_icon}>
              &nbsp;
            </span>
            <span>
              {t('How to Issue a Token')}
            </span>
          </span>

          <span className={styles.thingslearn__item}>
            <span className={styles.thingslearn__item_icon}>
              &nbsp;
            </span>
            <span>
              {t('How to distribute dividends')}
            </span>
          </span>

          <span className={styles.thingslearn__item}>
            <span className={styles.thingslearn__item_icon}>
              &nbsp;
            </span>
            <span>
              {t('Tokenize a LLC')}
            </span>
          </span>

          <span className={styles.thingslearn__item}>
            <span className={styles.thingslearn__item_icon}>
              &nbsp;
            </span>
            <span>
              {t('Onboard at Payment Providers')}
            </span>
          </span>

          <span className={styles.thingslearn__item}>
            <span className={styles.thingslearn__item_icon}>
              &nbsp;
            </span>
            <span>
              {t('Get registered as Token Issuer')}
            </span>
          </span>
        </div>
      </div>
    </div>
  )
}

export default ThingsLearn
