import React from 'react'
import { useTranslation } from 'react-i18next'
import LearnList from '../components/Learn/List'

const Learn = () => {
  const { t } = useTranslation()

  return (
    <div className="section-pt80 variants investhub-learn no-border__top">
      <div className="variants__center center investhub-learn__center">
        <div className="variants__top">
          <div className="variants__title h2">{t('Learn Center')}</div>
        </div>

        <LearnList />
      </div>
    </div>
  )
}

export default Learn
