import React from 'react'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ControlAdminBreadcrumbs from '../components/ControlAdminBreadcrumbs'
import AdminTenantTable from '../components/AdminDashboard/AdminTenantTable'

// eslint-disable-next-line arrow-body-style
const AdminTenant = () => {
  const { t } = useTranslation()

  return (
    <>
      <ControlAdminBreadcrumbs>
        <li className="breadcrumbs__item">
          <NavLink to="/dashboard" className="breadcrumbs__link">{t('Dashboard')}</NavLink>
        </li>
        <li className="breadcrumbs__item">
          {t('Tenant')}
        </li>
      </ControlAdminBreadcrumbs>

      <div className="section-pt80 faq admin-settings">
        <div className="faq__center admin-settings__center center">
          <div className="faq__top admin-settings__top">
            <div className="faq__title admin-settings__title h2">{t('Tenant Setting')}</div>
          </div>

          <div className="faq__row admin-settings__row">
            <div className="faq__col admin-settings__coo">
              <AdminTenantTable />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AdminTenant
