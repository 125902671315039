import React from 'react'

import Hero from '../components/InnerPageIssuer/Hero/Hero'
import TopUseCases from '../components/InnerPageIssuer/TopUseCases/TopUseCases'
import Explanation from '../components/InnerPageIssuer/Explanation/Explanation'
import ThingsLearn from '../components/InnerPageIssuer/ThingsLearn/ThingsLearn'
import ManageMonitorRegister from '../components/InnerPageIssuer/ManageMonitorRegister/ManageMonitorRegister'
import HowToGetStarted from '../components/InnerPageIssuer/HowToGetStarted/HowToGetStarted'
import ContentOne from '../components/InnerPageIssuer/ContentOne/ContentOne'
import ContentTwo from '../components/InnerPageIssuer/ContentTwo/ContentTwo'
import Cta from '../components/InnerPageIssuer/CTA/Cta'
import CustomerSay from '../components/InnerPageIssuer/CustomerSay/CustomerSay'
import OnGoingVerification from '../components/InnerPageIssuer/OngoingVerifications/OngoingVerifications'

const Issuer = () => (
  <>
    <Hero />
    <TopUseCases />
    <Explanation />
    <ContentOne />
    <OnGoingVerification />
    <Cta />
    <ManageMonitorRegister />
    <HowToGetStarted />
    <ContentTwo />
    <ThingsLearn />
    <CustomerSay />
  </>
)

export default Issuer
