import React from 'react'
import { useTranslation } from 'react-i18next'
import LoginForm from '../components/LoginForm'
import XIDLogo from '../media/xid/xid-logo.png'

const Login = () => {
  const { t } = useTranslation()

  return (
    <>
      <div className="entry__logo">
        <img src={XIDLogo} alt="XID App" />
      </div>
      <div className="entry__title h3">
        {t('Please Sign In')}
      </div>
      <div className="entry__top">
        <div className="entry__text">
          {t('Signin to manage your account')}
        </div>
      </div>

      <LoginForm onSuccess={() => { window.location.href = `${window.location.origin}/dashboard` }} />
    </>
  )
}

export default Login
