import React from 'react'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ControlBreadcrumbs from '../components/ControlBreadcrumbs'

const OnBoarding = () => {
  const { t } = useTranslation()

  return (
    <>
      <ControlBreadcrumbs>
        <li className="breadcrumbs__item">
          <NavLink to="/" className="breadcrumbs__link">{t('Home')}</NavLink>
        </li>
        <li className="breadcrumbs__item">
          {t('Create')}
        </li>
      </ControlBreadcrumbs>

      <div className="section-pt80 variants onboarding">
        <div className="variants__center center onboarding__center">
          <div className="variants__top onboarding__top">
            <div className="variants__title h2 onboarding__title">{t('Create')}</div>
          </div>

          <div className="variants__list onboarding__list">
            <div className="variants__item onboarding__item">
              <div className="variants__preview">
                <img src="https://via.placeholder.com/650/000000/FFFFFF/?text=investhub" alt="Onboarding 1" />
              </div>

              <a href="/login" className="button-stroke variants__button onboarding__button">{t('Create your Opportunity')}</a>
            </div>

            <div className="variants__item onboarding__item">
              <div className="variants__preview">
                <img src="https://via.placeholder.com/650/000000/FFFFFF/?text=investhub" alt="Onboarding 1" />
              </div>

              <a href="/" className="button-stroke variants__button onboarding__button">{t('I dont need investor onboarding')}</a>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default OnBoarding
