import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import CircularProgress from '@material-ui/core/CircularProgress'
import makeStyles from '@material-ui/core/styles/makeStyles'

import { useMe } from '../../myHooks'

const useStyles = makeStyles(() => ({
  circularProgressWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    minHeight: '100vh',
  },
}))

const AuthRoute = (props) => {
  const { t } = useTranslation()
  const { data, loading, error } = useMe()
  const classes = useStyles()

  if (loading) {
    return <div className={classes.circularProgressWrapper}><CircularProgress /></div>
  }

  if (error) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Route {...props} />
  }

  if (data && data.me) {
    setTimeout(() => {
      window.location.href = `${window.location.origin}/dashboard`
    }, 0)

    return (
      <div style={{ padding: '20px', textAlign: 'center' }}>
        <p><CircularProgress /></p>
        <p>{t('Redirecting...')}</p>
      </div>
    )
  }

  return <Redirect to="/login" />
}

export default AuthRoute
