import React from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import uuid from 'react-uuid'
import Customer from '../../../media/images/customersay/customersay'
import styles from './styles.module.scss'

const CustomerSay = () => {
  const { t } = useTranslation()

  const videoCards = [
    {
      quote: t('“Enables us to achieve our goal”'),
      name: t('Kaspars Zvaigzne'),
      title: t('Founder, Enercom AG'),
      // video: "https://www.youtube.com/embed/-QZ_CKHeU_0",
      thumbnail: Customer.Kaspars,
    },
    {
      quote: t('“Great technology”'),
      name: t('Peter Lindenmann'),
      title: t('Founder, Calidris Fintech AG'),
      // video: "https://www.youtube.com/embed/-QZ_CKHeU_0",
      thumbnail: Customer.Peter,
    },
  ]

  return (
    <div className={classNames("section", styles.customersay)}>
      <div className={classNames("center", styles.customersay__center)}>
        <h2 className={classNames("h3 text-center", styles.customersay__title)}>{t("What our customers say")}</h2>
        <div className={styles.customersay__text}>{t("Read why other entrepreneurs love our Engine was much as our contributors do.")}</div>

        <div className={styles.customersay__list}>
          {videoCards && videoCards.map((videoCard) => (
            <div className={styles.customersay__item} key={uuid()} style={{ textAlign: 'center' }}>
              <div className={styles.customersay__item_inner}>
                <h3 className={classNames("h4", styles.customersay__item_quote)}>
                  {videoCard.quote}
                </h3>

                <div className={styles.customersay__item_content} style={{ paddingTop: '0', flexFlow: 'column nowrap', alignItems: 'center' }}>
                  <h4 className={styles.customersay__item_name}>
                    {videoCard.name}
                  </h4>
                  <span className={styles.customersay__item_title}>
                    {videoCard.title}
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>

      </div>
    </div>
  )
}

export default CustomerSay
