import React from 'react'
import PropTypes from 'prop-types'
import {
  TextField,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const OthersSetting = ({
  settings,
  setSubSettingField,
  setSubSettingNumberField,
}) => {
  const { t } = useTranslation()

  return (
    <div id="setting-stripe" className="upload__item">
      <h2 className="upload__category">{t('Other Settings')}</h2>

      <div className="upload__fieldset details__fieldset">
        <div className="field__group">
          <TextField
            className="investhub__field field"
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            label={t('Intercom AppId Setting')}
            value={settings.others.intercom_app_id}
            onChange={setSubSettingField('others', 'intercom_app_id')}
          />

          <TextField
            className="investhub__field field"
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            label={t('Google analytics ID')}
            value={settings.others.google_analytics_id}
            onChange={setSubSettingField('others', 'google_analytics_id')}
          />

          <TextField
            className="investhub__field field"
            margin="normal"
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              inputMode: 'numeric',
            }}
            label={t('opportunity fee')}
            value={settings.others.opportunity_fee}
            onChange={setSubSettingNumberField('others', 'opportunity_fee')}
          />
        </div>
      </div>
    </div>
  )
}

OthersSetting.propTypes = {
  settings: PropTypes.object.isRequired,
  setSubSettingField: PropTypes.func.isRequired,
  setSubSettingNumberField: PropTypes.func.isRequired,
}

export default OthersSetting
