import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import axios from 'axios'
import { useMutation } from '@apollo/react-hooks'
import { useCookies } from 'react-cookie'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  FormControlLabel,
  Checkbox,
  TextField,
  CircularProgress,
} from '@material-ui/core'
import _ from "lodash"
import Icon from './Icon'

import config from '../config'
import {
  IS_2FA_NEEDED,
  LOGIN,
} from '../queriesAndMutations'

const LoginForm = ({
  onSuccess,
}) => {
  const { t } = useTranslation()
  const [, setCookie] = useCookies()
  const [require2Fa, setRequire2Fa] = useState(false)
  const [loading, setLoading] = useState(false)

  const [values, setValues] = useState({
    email: localStorage.getItem('email') || '',
    password: '',
    twoFaToken: '',
  })

  const setField = (fieldName) => ({ target: { value } }) => {
    setValues({
      ...values,
      [fieldName]: value,
    })
  }
  const setCheckBox = (fieldName) => ({ target: { checked } }) => {
    setValues({
      ...values,
      [fieldName]: checked,
    })
  }

  const [login] = useMutation(LOGIN)

  const getIs2FaNeeded = async () => {
    const response = await axios.post(config.endpoint, {
      query: IS_2FA_NEEDED.loc.source.body,
      variables: { email: values.email },
    })

    return response.data.data.is2FaNeeded
  }

  const submitLogin = async () => {
    try {
      const { data } = await login({
        variables: {
          input: _.omit(values, ['rememberMe']),
        },
      })

      setCookie(config.jwtCookie, data.login.accessToken, { path: '/' })
      localStorage.setItem('email', values.email)

      if (values.rememberMe) {
        localStorage.setItem(config.jwtCookie, data.login.accessToken)
      }

      onSuccess(data.login.accessToken)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
    }
  }

  return (
    <>
      <form
        onSubmit={async (event) => {
          event.preventDefault()
          setLoading(true)

          const is2FaNeeded = await getIs2FaNeeded()

          if (is2FaNeeded === require2Fa) {
            try {
              await submitLogin()
            } catch (error) {}
          } else {
            setRequire2Fa(is2FaNeeded)
          }
          setLoading(false)
        }}
        className="entry__fieldset"
      >
        <TextField
          required
          value={values.email}
          onChange={setField('email')}
          label={t('Email')}
          className="xid-field has-icon"
          placeholder={t('Enter Email Address')}
          margin="normal"
          variant="standard"
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            startAdornment: (
              <Icon
                name="mail"
                className={classNames('mail')}
                size={24}
              />
            ),
          }}
        />

        <TextField
          required
          type="password"
          label={t('Password')}
          value={values.password}
          onChange={setField('password')}
          className="xid-field has-icon"
          placeholder={t('Enter Password')}
          margin="normal"
          variant="standard"
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            startAdornment: (
              <Icon
                name="lock"
                className={classNames('lock')}
                size={24}
              />
            ),
          }}
        />

        {require2Fa && (
          <TextField
            required
            label={t('2FA code')}
            value={values.twoFaToken}
            onChange={setField('twoFaToken')}
            className="xid-field field"
            placeholder={t('Enter 2FA code')}
            InputLabelProps={{
              shrink: true,
            }}
          />
        )}

        <FormControlLabel
          className="xid-field field__checkbox"
          label={t('Remember me')}
          control={(
            <Checkbox
              values="yes"
              checked={values.rememberMe}
              onChange={setCheckBox('rememberMe')}
              color="primary"
            />
          )}
        />

        { loading
          ? (
            <CircularProgress />
          ) : (
            <button
              className="button-xid entry__button"
              type="submit"
            >
              {t('Sign in')}
            </button>
          )}
      </form>

      <div className="entry__note">
        <NavLink to="/restore-password">{t('Forgot Password')}</NavLink>
      </div>

      <div className="entry__info">
        {t('Do not have an account ?')}
        {' '}
        <NavLink to="/signup">{t('Signup')}</NavLink>
      </div>
    </>
  )
}

LoginForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
}

export default LoginForm
