import React from 'react'
import PropTypes from 'prop-types'
import {
  TextField,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const StatisticSetting = ({
  settings,
  setSubSettingNumberField,
}) => {
  const { t } = useTranslation()

  return (
    <div id="setting-medium" className="upload__item">
      <div className="upload__category">{t('Statistic modification')}</div>

      <div className="upload__fieldset details__fieldset">
        <div className="field__group field__group-3">
          <TextField
            className="investhub__field field"
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            type="number"
            label={t('Total Users')}
            value={settings.statisticModification.allUser}
            onChange={setSubSettingNumberField('statisticModification', 'allUser')}
            InputProps={{
              inputMode: 'numeric',
            }}
          />

          <TextField
            className="investhub__field field"
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            type="number"
            label={t('Active')}
            value={settings.statisticModification.activeUser}
            onChange={setSubSettingNumberField('statisticModification', 'activeUser')}
            InputProps={{
              inputMode: 'numeric',
            }}
          />

          <TextField
            className="investhub__field field"
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            type="number"
            label={t('Individuals')}
            value={settings.statisticModification.approvedIndividualCustomer}
            onChange={setSubSettingNumberField('statisticModification', 'approvedIndividualCustomer')}
            InputProps={{
              inputMode: 'numeric',
            }}
          />
        </div>

        <div className="field__group field__group-3">
          <TextField
            className="investhub__field field"
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            type="number"
            label={t('Institutions')}
            value={settings.statisticModification.approvedCompanyCustomer}
            onChange={setSubSettingNumberField('statisticModification', 'approvedCompanyCustomer')}
            InputProps={{
              inputMode: 'numeric',
            }}
          />

          <TextField
            className="investhub__field field"
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            type="number"
            label={t('Total Tokens')}
            value={settings.statisticModification.totalToken}
            onChange={setSubSettingNumberField('statisticModification', 'totalToken')}
            InputProps={{
              inputMode: 'numeric',
            }}
          />

          <TextField
            className="investhub__field field"
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            type="number"
            label={t('Total Allocated')}
            value={settings.statisticModification.totalValue}
            onChange={setSubSettingNumberField('statisticModification', 'totalValue')}
            InputProps={{
              inputMode: 'numeric',
            }}
          />
        </div>
      </div>
    </div>
  )
}

StatisticSetting.propTypes = {
  settings: PropTypes.object.isRequired,
  setSubSettingNumberField: PropTypes.func.isRequired,
}

export default StatisticSetting
