import gql from 'graphql-tag'

// eslint-disable-next-line import/prefer-default-export
export const SETTINGS = gql`
  query settings {
    settings {
      stripe
      mailgun
      cloudflare
      brand
      medium
      mailchimp
      statisticModification
      others
    }
  }
`;

export const PUBLIC_SETTINGS = gql`
  query publicSettings {
    publicSettings {
      cloudflare {
        baseDomain
      }
      brand
      medium
      mailchimp
      others
      siteLogo
    }
  }
`;
