import React from 'react'
import classNames from 'classnames'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import SlideToggle from "react-slide-toggle"

import TranslationToggle from './Translation/Translation'
import TranslationToggleRwd from './TranslationRwd/TranslationRwd'
import SiteLogoDark from '../../media/images/dark-mode-investhub-logo.svg'
import Styles from './styles.module.scss'

const SiteHeader = () => {
  const { t } = useTranslation()

  return (
    <header className={Styles.header}>
      <div className={classNames('center', Styles.header__center)}>
        <div className={Styles.header__logo}>
          <NavLink to="/">
            <img src={SiteLogoDark} alt="Investhub.io" />
          </NavLink>
        </div>
        {/* end header__logo */}

        <div className={classNames('tablet-hide', Styles.header__wrapper)}>
          <nav className={Styles.header__nav}>
            <ul className={Styles.header__lists}>
              <li className={classNames(Styles.header__item, 'site-header__megamenu-parent')}>
                <NavLink to="#" className={Styles.header__link}>
                  {t('Engine')}
                </NavLink>

                <div className={classNames('site-header__megamenu-child', Styles.header__subitem)}>
                  <div className={classNames(
                    'header__title h4',
                    Styles.header__subitem_title,
                  )}
                  >
                    {t('Platform')}
                  </div>

                  <ul className={Styles.header__subitems}>
                    <li className={Styles.header__subitem_item}>
                      <NavLink to="/signup" className={Styles.header__subitem_link}>
                        <span>
                          {t('Web3 Investor Onramp')}
                        </span>

                        <div>
                          {t('Simple onboarding, pay as')}
                          <br />
                          {' '}
                          {t('you go, deployed in 2 minutes')}
                        </div>
                      </NavLink>

                      <span className={Styles.header__subitem_nonlink}>
                        <span>
                          {t('Influencer')}
                          {' '}
                          (Q4 &#39;22)
                        </span>

                        <div>
                          {t('Enabling ethical Influencers to')}
                          <br />
                          {' '}
                          {t('share great opportunities')}
                          <br />
                          {' '}
                          {t('before everybody else.')}
                        </div>
                      </span>
                    </li>

                    <li className={Styles.header__subitem_item}>
                      <NavLink to="/signup" className={Styles.header__subitem_link}>
                        <span>
                          {t('Tokenize')}
                        </span>

                        <div>
                          {t('Deploy tokens to Ethereum,')}
                          <br />
                          {' '}
                          {t('Polygin, XDC Network,')}
                          {t('Cosmos and many more.')}
                        </div>
                      </NavLink>

                      <span className={Styles.header__subitem_nonlink}>
                        <span>
                          {t('Syndicates')}
                          {' '}
                          (Q4 &#39;22)
                        </span>

                        <div>
                          {t('Form Syndicates on and off-')}
                          <br />
                          {t('chain with a few simple clicks')}
                        </div>
                      </span>
                    </li>

                    <li className={Styles.header__subitem_item}>
                      <NavLink to="/signup" className={Styles.header__subitem_link}>
                        <span>
                          {t('Payments')}
                        </span>

                        <div>
                          {t('Receive subcription for')}
                          <br />
                          {' '}
                          {t('tokens via several payment')}
                          <br />
                          {' '}
                          {t('channels (SEPA, Direct Debit,')}
                          <br />
                          {' '}
                          {t('Credit Card)')}
                        </div>
                      </NavLink>
                      <span className={Styles.header__subitem_nonlink}>
                        <span>
                          {t('Scout')}
                          {' '}
                          (Q4 &#39;22)
                        </span>

                        <div>
                          {t('Enabling scouts to source new')}
                          <br />
                          {' '}
                          {t('deals for the community in')}
                          <br />
                          {t(('TradFi and DeFi'))}
                        </div>
                      </span>
                    </li>
                  </ul>
                </div>
              </li>
              <li className={Styles.header__item}>
                <NavLink to="/issuer" className={Styles.header__link}>
                  {t('Issuer')}
                </NavLink>
              </li>
              <li className={Styles.header__item}>
                <NavLink to="/pricing" className={Styles.header__link}>
                  {t('Pricing')}
                </NavLink>
              </li>
              <li className={Styles.header__item}>
                <NavLink to="/explore" className={Styles.header__link}>
                  {t('Explore')}
                </NavLink>
              </li>
              <li className={Styles.header__item}>
                <NavLink to="/learn" className={Styles.header__link}>
                  {t('Learn')}
                </NavLink>
              </li>
            </ul>
          </nav>
          {/* end header__nav */}

          <div className={classNames(Styles.header__cta)}>
            <NavLink to="/dashboard" className="button button-small">
              {t('My Account')}
            </NavLink>

            <NavLink to="/dashboard" className="button-stroke button-small">
              {t('Connect Wallet')}
            </NavLink>
          </div>
          {/* end header__cta */}

          <TranslationToggle />
        </div>

        <SlideToggle
          duration={20}
          collapsed
          render={({
            toggle,
            setCollapsibleElement,
            toggleState,
          }) => (
            <>
              <div className="desktop-hide tablet-show">
                <div className={classNames("header-wrapper__custom", Styles.header__wrapper, (toggleState || "").toLowerCase())} ref={setCollapsibleElement}>
                  <nav className={Styles.header__nav}>
                    <NavLink to="#" className={Styles.header__link}>
                      {t('Engine')}
                    </NavLink>

                    <NavLink to="/issuer" className={Styles.header__link}>
                      {t('Issuer')}
                    </NavLink>

                    <NavLink to="/explore" className={Styles.header__link}>
                      {t('Explore')}
                    </NavLink>

                    <NavLink to="/pricing" className={Styles.header__link}>
                      {t('Pricing')}
                    </NavLink>

                    <NavLink to="/signup" className={Styles.header__link}>
                      {t('Web3 Investor Onram')}
                    </NavLink>

                    <NavLink to="/signup" className={Styles.header__link}>
                      {t('Tokenize')}
                    </NavLink>

                    <NavLink to="/signup" className={Styles.header__link}>
                      {t('Payments')}
                    </NavLink>

                    <NavLink to="/learn" className={Styles.header__link}>
                      {t('Learn')}
                    </NavLink>
                  </nav>
                  {/* end header__nav */}

                  <TranslationToggleRwd />

                  <NavLink to="/dashboard" className="button button-small">
                    {t('My Account')}
                  </NavLink>
                  <br />
                  <NavLink to="/dashboard" className="button-stroke button-small">
                    {t('Connect Wallet')}
                  </NavLink>
                </div>
                {/* end header__wrapper */}
              </div>

              <button
                type="button"
                className={
                  classNames(
                    "header-burger__custom",
                    Styles.header__burger,
                    (toggleState || "").toLowerCase(),
                  )
                }
                onClick={toggle}
              >
                &nbsp;
              </button>
            </>
          )}
        />
      </div>
    </header>
  )
}

export default SiteHeader
