import React, { useEffect } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { Redirect, useHistory } from 'react-router-dom'
import {
  CircularProgress,
} from '@material-ui/core'
import { useCookies } from 'react-cookie'
import { useTranslation } from 'react-i18next'
import queryString from 'query-string'

import { REGISTRATION_CONFIRM } from '../queriesAndMutations'

const SignUpConfirmCheck = () => {
  const history = useHistory()
  const { t } = useTranslation()
  const query = queryString.parse(history.location.search)

  const [registerConfirm, registerConfirmStatus] = useMutation(REGISTRATION_CONFIRM)
  const token = (new URLSearchParams(query)).get('token')
  const [, setCookie] = useCookies(['jwt'])

  useEffect(() => {
    if (token) {
      registerConfirm({
        variables: {
          input: {
            token,
          },
        },
      }).then(({ data }) => {
        setCookie('jwt', data.registrationConfirm.accessToken, { path: '/' })
        localStorage.setItem('jwt', data.registrationConfirm.accessToken)

        setTimeout(() => {
          window.location.href = `${window.location.origin}/tenant-easy-setup`
        }, 1000)
      }).catch(() => {})
    }
  }, [registerConfirm, token, setCookie])

  if (!token) {
    return (
      <Redirect to="/" />
    )
  }

  return (
    <>
      { registerConfirmStatus.loading && (
        <CircularProgress />
      )}
      { registerConfirmStatus.error && (
        <Redirect to="/" />
      )}
      { registerConfirmStatus.data && (
        <>
          <div className="entry__title h3">
            {t('Account activated')}
          </div>
          <div className="entry__top">
            <div className="entry__text">
              {t('Your account is activated. You can now login to the site. Redirecting...')}
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default SignUpConfirmCheck
