import React from 'react'
import MaterialAccordion from '@material-ui/core/Accordion'
import MaterialAccordionSummary from '@material-ui/core/AccordionSummary'
import MaterialAccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { useTranslation, Trans } from 'react-i18next'

const Faq = () => {
  const { t } = useTranslation()

  return (
    <div className="section-bg faq section-faq__pricing">
      <div className="faq__center center">
        <div className="faq__top">
          <div className="faq__stage">{t('learn how to get started')}</div>
          <div className="faq__title h2">
            {t('Frequently asked questions')}
          </div>
          <div className="faq__info">
            {t('Join the Investhub community now and get onboarded for the future of web3.')}
          </div>
        </div>

        <div className="faq__row">
          <div className="faq__col">
            <MaterialAccordion className="investhub-accordion">
              <MaterialAccordionSummary
                className="investhub-accordion__summary"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="howdoesitworkbg-content"
                id="howdoesitwork-header"
              >
                <div className="faq__head">
                  {t('Can I start with the 39 CHF version and the upgrade or downgrade?')}
                </div>
              </MaterialAccordionSummary>

              <MaterialAccordionDetails className="investhub-accordion__details">
                <div className="faq__content">{t('Yes, upgrading and downgrading is easily possible.')}</div>
              </MaterialAccordionDetails>
            </MaterialAccordion>

            <MaterialAccordion className="investhub-accordion">
              <MaterialAccordionSummary
                className="investhub-accordion__summary"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="howtostartwithstacksbg-content"
                id="howtostartwithstacks-header"
              >
                <div className="faq__head">
                  {t('Can we get a cheaper pricing per check if the volume is higher?')}
                </div>
              </MaterialAccordionSummary>

              <MaterialAccordionDetails className="investhub-accordion__details">
                <div className="faq__content">{t('Yes, if you plan to onboard more than 100, 500, or 1000 the cost per KYC check can further be reduced to 1-2 CHF.')}</div>
              </MaterialAccordionDetails>
            </MaterialAccordion>

            <MaterialAccordion className="investhub-accordion">
              <MaterialAccordionSummary
                className="investhub-accordion__summary"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="doesitsuppordarkmodebg-content"
                id="doesitsupportdarkmode-header"
              >
                <div className="faq__head">
                  {t('How do I get charged?')}
                </div>
              </MaterialAccordionSummary>

              <MaterialAccordionDetails className="investhub-accordion__details">
                <div className="faq__content">
                  {t('The monthly fee is charged automatically via Stripe, alongside the daily usage of the System. If you onboard 50 Investors on the first day you will get charged at 10pm Vaduz time based on checks you made. The costs can also be seen just in time in your')}
                  <a href="http://investhub.io/dashboard" target="_blank" rel="noreferrer">{t('Investhub')}</a>
                  {' '}
                  {t('just click on the “detail” button at the right tenant.')}
                </div>
              </MaterialAccordionDetails>
            </MaterialAccordion>

            <MaterialAccordion className="investhub-accordion">
              <MaterialAccordionSummary
                className="investhub-accordion__summary"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="doesitsupportautolayoutbg-content"
                id="doesitsupportautolayout-header"
              >
                <div className="faq__head">
                  {t('How fast can I get a system to onboard my investors?')}
                </div>
              </MaterialAccordionSummary>

              <MaterialAccordionDetails className="investhub-accordion__details">
                <div className="faq__content">
                  <Trans
                    i18nKey={t('You can see in this video that it takes up to 2 minutes')}
                    t={t}
                    components={{
                      // eslint-disable-next-line
                      videoLink: <a href="https://www.youtube.com/watch?v=98y--uqMZIc" target="_blank" rel="noopener noreferrer" />
                    }}
                  />
                </div>
              </MaterialAccordionDetails>
            </MaterialAccordion>

            <MaterialAccordion className="investhub-accordion">
              <MaterialAccordionSummary
                className="investhub-accordion__summary"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="doesitsupportautolayoutbg-content"
                id="doesitsupportautolayout-header"
              >
                <div className="faq__head">
                  {t('Can I also use your system if I want to be registered under TVTG in Liechtenstein?')}
                </div>
              </MaterialAccordionSummary>

              <MaterialAccordionDetails className="investhub-accordion__details">
                <div className="faq__content">
                  <Trans
                    i18nKey={t('Yes, for example, one of our customers Calidris Fintech AG achieved exactly that. You can read about this here.')}
                    t={t}
                    components={{
                      // eslint-disable-next-line
                      calidrisLink: <a href="https://investhub.medium.com/congrats-to-calidris-as-token-issuer-in-liechtenstein-e52ca9a621b3" target="_blank" rel="noreferrer noopener" />
                    }}
                  />
                </div>
              </MaterialAccordionDetails>
            </MaterialAccordion>

            <MaterialAccordion className="investhub-accordion">
              <MaterialAccordionSummary
                className="investhub-accordion__summary"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="canialsobg-content"
                id="canialso-header"
              >
                <div className="faq__head">
                  {t('Can I also tokenize my financial instrument with your platform?')}
                </div>
              </MaterialAccordionSummary>

              <MaterialAccordionDetails className="investhub-accordion__details">
                <div className="faq__content">
                  {t('Yes, you can either choose Cashlink API, use the smart contracts from Equanimity AG as a token creator in Liechtenstein or bring your own smart contracts.')}
                </div>
              </MaterialAccordionDetails>
            </MaterialAccordion>

            <MaterialAccordion className="investhub-accordion">
              <MaterialAccordionSummary
                className="investhub-accordion__summary"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="receivepaymentsbg-content"
                id="receivepayments-header"
              >
                <div className="faq__head">
                  {t('Can I also receive payments for the tokens from my Investors?')}
                </div>
              </MaterialAccordionSummary>

              <MaterialAccordionDetails className="investhub-accordion__details">
                <div className="faq__content">
                  <Trans
                    i18nKey={t('Yes, either via SEPA VOLT | Open Banking Payments. Supercharged, Crypto.com, Wyre | APIs to launch your crypto/fintech app faster or via Stablecoin.')}
                    t={t}
                    components={{
                      // eslint-disable-next-line
                      voltLink: <a href="http://volt.io/" target="_blank" rel="noreferrer" />,
                      // eslint-disable-next-line
                      cryptoLink: <a href="http://crypto.com/" target="_blank" rel="noreferrer" />,
                      // eslint-disable-next-line
                      wyreLink: <a href="http://sendwyre.com/" target="_blank" rel="noreferrer" />
                    }}
                  />
                </div>
              </MaterialAccordionDetails>
            </MaterialAccordion>

          </div>
        </div>
      </div>
    </div>
  )
}

export default Faq
