import React from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import uuid from 'react-uuid'

import styles from './styles.module.scss'

const TopUseCases = () => {
  const { t } = useTranslation()

  const useCaseCards = [
    {
      icon: (
        <>
          <svg width="26" height="22" viewBox="0 0 26 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.0004 20.3939C14.3393 20.3939 15.4247 19.3085 15.4247 17.9697C15.4247 16.6308 14.3393 15.5454 13.0004 15.5454C11.6615 15.5454 10.5762 16.6308 10.5762 17.9697C10.5762 19.3085 11.6615 20.3939 13.0004 20.3939Z" stroke="#12141D" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M19.8493 11.1209C18.0917 9.36337 15.6675 8.27246 13.0008 8.27246C10.3342 8.27246 7.90992 9.36337 6.15234 11.1209" stroke="#12141D" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M25 5.9697C21.9091 2.87879 17.6667 1 13 1C8.33333 1 4.09091 2.87879 1 5.9697" stroke="#12141D" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </>
      ),
      title: t("Trustee"),
      description: t("Trustee needs to onboard 7000 Investors for several Crypto Projects"),
    },
    {
      icon: (
        <>
          <svg width="26" height="22" viewBox="0 0 26 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.0004 20.3939C14.3393 20.3939 15.4247 19.3085 15.4247 17.9697C15.4247 16.6308 14.3393 15.5454 13.0004 15.5454C11.6615 15.5454 10.5762 16.6308 10.5762 17.9697C10.5762 19.3085 11.6615 20.3939 13.0004 20.3939Z" stroke="#12141D" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M19.8493 11.1209C18.0917 9.36337 15.6675 8.27246 13.0008 8.27246C10.3342 8.27246 7.90992 9.36337 6.15234 11.1209" stroke="#12141D" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M25 5.9697C21.9091 2.87879 17.6667 1 13 1C8.33333 1 4.09091 2.87879 1 5.9697" stroke="#12141D" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </>
      ),
      title: t("Tokenized Equity Project"),
      description: t("Enabled 600 Investors to purchase tokens and send them via Bank Transfer"),
    },
    {
      icon: (
        <>
          <svg width="26" height="22" viewBox="0 0 26 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.0004 20.3939C14.3393 20.3939 15.4247 19.3085 15.4247 17.9697C15.4247 16.6308 14.3393 15.5454 13.0004 15.5454C11.6615 15.5454 10.5762 16.6308 10.5762 17.9697C10.5762 19.3085 11.6615 20.3939 13.0004 20.3939Z" stroke="#12141D" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M19.8493 11.1209C18.0917 9.36337 15.6675 8.27246 13.0008 8.27246C10.3342 8.27246 7.90992 9.36337 6.15234 11.1209" stroke="#12141D" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M25 5.9697C21.9091 2.87879 17.6667 1 13 1C8.33333 1 4.09091 2.87879 1 5.9697" stroke="#12141D" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </>
      ),
      title: t("Onboarding"),
      description: t("Just enable easy to use onboarding for individuals and corporates"),
    },
    {
      icon: (
        <>
          <svg width="26" height="22" viewBox="0 0 26 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.0004 20.3939C14.3393 20.3939 15.4247 19.3085 15.4247 17.9697C15.4247 16.6308 14.3393 15.5454 13.0004 15.5454C11.6615 15.5454 10.5762 16.6308 10.5762 17.9697C10.5762 19.3085 11.6615 20.3939 13.0004 20.3939Z" stroke="#12141D" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M19.8493 11.1209C18.0917 9.36337 15.6675 8.27246 13.0008 8.27246C10.3342 8.27246 7.90992 9.36337 6.15234 11.1209" stroke="#12141D" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M25 5.9697C21.9091 2.87879 17.6667 1 13 1C8.33333 1 4.09091 2.87879 1 5.9697" stroke="#12141D" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </>
      ),
      title: t("Bond issuance"),
      description: t("Enable the issuer to mint tokens for paid subscriptions from Investors on Ethereum, Polygon and other EVM compatible chains"),
    },
    {
      icon: (
        <>
          <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22.1053 0H1.89474C0.821053 0 0 0.821053 0 1.89474V14.5263C0 15.6 0.821053 16.4211 1.89474 16.4211H9.47368V20.2105H6C5.62105 20.2105 5.36842 20.4632 5.36842 20.8421C5.36842 21.2211 5.62105 21.4737 6 21.4737H10.1053H13.8947H18C18.3789 21.4737 18.6316 21.2211 18.6316 20.8421C18.6316 20.4632 18.3789 20.2105 18 20.2105H14.5263V16.4211H22.1053C23.1789 16.4211 24 15.6 24 14.5263V1.89474C24 0.821053 23.1789 0 22.1053 0ZM1.26316 1.89474C1.26316 1.51579 1.51579 1.26316 1.89474 1.26316H22.1053C22.4842 1.26316 22.7368 1.51579 22.7368 1.89474V11.3684H1.26316V1.89474ZM13.2632 20.2105H10.7368V16.4211H13.2632V20.2105ZM22.7368 14.5263C22.7368 14.9053 22.4842 15.1579 22.1053 15.1579H13.8947H10.1053H1.89474C1.51579 15.1579 1.26316 14.9053 1.26316 14.5263V12.6316H22.7368V14.5263Z" fill="#12141D" />
            <path d="M12.0001 15.7893C12.5233 15.7893 12.9475 15.3651 12.9475 14.8419C12.9475 14.3187 12.5233 13.8945 12.0001 13.8945C11.4769 13.8945 11.0527 14.3187 11.0527 14.8419C11.0527 15.3651 11.4769 15.7893 12.0001 15.7893Z" fill="#FFC947" />
          </svg>
        </>
      ),
      title: t("Utility Token Project"),
      description: t("Need to KYC about 100 investors with auto approval feature including the digital signature of a token purchase agreement including wallet proof of ownership to receive their USDC payment"),
    },
    {
      icon: (
        <>
          <svg width="26" height="22" viewBox="0 0 26 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.0004 20.3939C14.3393 20.3939 15.4247 19.3085 15.4247 17.9697C15.4247 16.6308 14.3393 15.5454 13.0004 15.5454C11.6615 15.5454 10.5762 16.6308 10.5762 17.9697C10.5762 19.3085 11.6615 20.3939 13.0004 20.3939Z" stroke="#12141D" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M19.8493 11.1209C18.0917 9.36337 15.6675 8.27246 13.0008 8.27246C10.3342 8.27246 7.90992 9.36337 6.15234 11.1209" stroke="#12141D" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M25 5.9697C21.9091 2.87879 17.6667 1 13 1C8.33333 1 4.09091 2.87879 1 5.9697" stroke="#12141D" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </>
      ),
      title: t("Liquidation/Bankruptcy"),
      description: t("Management of 6000 creditors, with automated payouts"),
    },
    {
      icon: (
        <>
          <svg width="26" height="22" viewBox="0 0 26 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.0004 20.3939C14.3393 20.3939 15.4247 19.3085 15.4247 17.9697C15.4247 16.6308 14.3393 15.5454 13.0004 15.5454C11.6615 15.5454 10.5762 16.6308 10.5762 17.9697C10.5762 19.3085 11.6615 20.3939 13.0004 20.3939Z" stroke="#12141D" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M19.8493 11.1209C18.0917 9.36337 15.6675 8.27246 13.0008 8.27246C10.3342 8.27246 7.90992 9.36337 6.15234 11.1209" stroke="#12141D" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M25 5.9697C21.9091 2.87879 17.6667 1 13 1C8.33333 1 4.09091 2.87879 1 5.9697" stroke="#12141D" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </>
      ),
      title: t("Tokenized Equity Project"),
      description: t("Enabled 250 Investors to swap a old token for a new token including digital signatures, generate wallet and Idnow checks"),
    },
    {
      icon: (
        <>
          <svg width="26" height="22" viewBox="0 0 26 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.0004 20.3939C14.3393 20.3939 15.4247 19.3085 15.4247 17.9697C15.4247 16.6308 14.3393 15.5454 13.0004 15.5454C11.6615 15.5454 10.5762 16.6308 10.5762 17.9697C10.5762 19.3085 11.6615 20.3939 13.0004 20.3939Z" stroke="#12141D" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M19.8493 11.1209C18.0917 9.36337 15.6675 8.27246 13.0008 8.27246C10.3342 8.27246 7.90992 9.36337 6.15234 11.1209" stroke="#12141D" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M25 5.9697C21.9091 2.87879 17.6667 1 13 1C8.33333 1 4.09091 2.87879 1 5.9697" stroke="#12141D" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </>
      ),
      title: t("NFT Project"),
      description: t("Enable access to an exclusive community for litigation financing with the minting of a NFT with easy FIAT onramp like Stripe, Bank transfer"),
    },
    {
      icon: (
        <>
          <svg width="26" height="20" viewBox="0 0 26 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.85742 11.2856H18.1431" stroke="#12141D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M4.42773 7V4.42857C4.42773 3.51926 4.78896 2.64719 5.43194 2.00421C6.07492 1.36122 6.94699 1 7.85631 1H18.142C19.0513 1 19.9234 1.36122 20.5664 2.00421C21.2094 2.64719 21.5706 3.51926 21.5706 4.42857V7" stroke="#12141D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M7.85714 14.7143V10.4286C7.85714 9.75047 7.65606 9.08759 7.27932 8.52376C6.90259 7.95994 6.36712 7.52049 5.74063 7.26099C5.11414 7.00149 4.42477 6.93359 3.75969 7.06588C3.09461 7.19817 2.4837 7.52471 2.00421 8.00421C1.52471 8.4837 1.19817 9.09461 1.06588 9.75969C0.933588 10.4248 1.00149 11.1141 1.26099 11.7406C1.52049 12.3671 1.95993 12.9026 2.52376 13.2793C3.08759 13.6561 3.75047 13.8571 4.42857 13.8571V18.1429C4.42857 18.3702 4.51888 18.5882 4.67962 18.7489C4.84037 18.9097 5.05839 19 5.28572 19H20.7143C20.9416 19 21.1596 18.9097 21.3204 18.7489C21.4811 18.5882 21.5714 18.3702 21.5714 18.1429V13.8571C22.2495 13.8571 22.9124 13.6561 23.4762 13.2793C24.0401 12.9026 24.4795 12.3671 24.739 11.7406C24.9985 11.1141 25.0664 10.4248 24.9341 9.75969C24.8018 9.09461 24.4753 8.4837 23.9958 8.00421C23.5163 7.52471 22.9054 7.19817 22.2403 7.06588C21.5752 6.93359 20.8859 7.00149 20.2594 7.26099C19.6329 7.52049 19.0974 7.95994 18.7207 8.52376C18.3439 9.08759 18.1429 9.75047 18.1429 10.4286V14.7143" stroke="#12141D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </>
      ),
      title: t("Series A Project (30x oversubscribed)"),
      description: t("Would like to KYC 30 Investors to onboard 8m in funds within 1 week"),
    },
    {
      icon: (
        <>
          <svg width="26" height="20" viewBox="0 0 26 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.85742 11.2856H18.1431" stroke="#12141D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M4.42773 7V4.42857C4.42773 3.51926 4.78896 2.64719 5.43194 2.00421C6.07492 1.36122 6.94699 1 7.85631 1H18.142C19.0513 1 19.9234 1.36122 20.5664 2.00421C21.2094 2.64719 21.5706 3.51926 21.5706 4.42857V7" stroke="#12141D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M7.85714 14.7143V10.4286C7.85714 9.75047 7.65606 9.08759 7.27932 8.52376C6.90259 7.95994 6.36712 7.52049 5.74063 7.26099C5.11414 7.00149 4.42477 6.93359 3.75969 7.06588C3.09461 7.19817 2.4837 7.52471 2.00421 8.00421C1.52471 8.4837 1.19817 9.09461 1.06588 9.75969C0.933588 10.4248 1.00149 11.1141 1.26099 11.7406C1.52049 12.3671 1.95993 12.9026 2.52376 13.2793C3.08759 13.6561 3.75047 13.8571 4.42857 13.8571V18.1429C4.42857 18.3702 4.51888 18.5882 4.67962 18.7489C4.84037 18.9097 5.05839 19 5.28572 19H20.7143C20.9416 19 21.1596 18.9097 21.3204 18.7489C21.4811 18.5882 21.5714 18.3702 21.5714 18.1429V13.8571C22.2495 13.8571 22.9124 13.6561 23.4762 13.2793C24.0401 12.9026 24.4795 12.3671 24.739 11.7406C24.9985 11.1141 25.0664 10.4248 24.9341 9.75969C24.8018 9.09461 24.4753 8.4837 23.9958 8.00421C23.5163 7.52471 22.9054 7.19817 22.2403 7.06588C21.5752 6.93359 20.8859 7.00149 20.2594 7.26099C19.6329 7.52049 19.0974 7.95994 18.7207 8.52376C18.3439 9.08759 18.1429 9.75047 18.1429 10.4286V14.7143" stroke="#12141D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </>
      ),
      title: t("Seed funding"),
      description: t("Tokenize AG, GmbH with share register for further fundraising"),
    },
    {
      icon: (
        <>
          <svg width="26" height="22" viewBox="0 0 26 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.0004 20.3939C14.3393 20.3939 15.4247 19.3085 15.4247 17.9697C15.4247 16.6308 14.3393 15.5454 13.0004 15.5454C11.6615 15.5454 10.5762 16.6308 10.5762 17.9697C10.5762 19.3085 11.6615 20.3939 13.0004 20.3939Z" stroke="#12141D" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M19.8493 11.1209C18.0917 9.36337 15.6675 8.27246 13.0008 8.27246C10.3342 8.27246 7.90992 9.36337 6.15234 11.1209" stroke="#12141D" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M25 5.9697C21.9091 2.87879 17.6667 1 13 1C8.33333 1 4.09091 2.87879 1 5.9697" stroke="#12141D" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </>
      ),
      title: t("Series B Project"),
      description: t("Would like to KYC 160 Investors (Individuals and Companies) to onboard 50m in funds within 2-3 months"),
    },
    {
      icon: (
        <>
          <svg width="26" height="22" viewBox="0 0 26 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.0004 20.3939C14.3393 20.3939 15.4247 19.3085 15.4247 17.9697C15.4247 16.6308 14.3393 15.5454 13.0004 15.5454C11.6615 15.5454 10.5762 16.6308 10.5762 17.9697C10.5762 19.3085 11.6615 20.3939 13.0004 20.3939Z" stroke="#12141D" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M19.8493 11.1209C18.0917 9.36337 15.6675 8.27246 13.0008 8.27246C10.3342 8.27246 7.90992 9.36337 6.15234 11.1209" stroke="#12141D" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M25 5.9697C21.9091 2.87879 17.6667 1 13 1C8.33333 1 4.09091 2.87879 1 5.9697" stroke="#12141D" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </>
      ),
      title: t("Bootstrapped Startup"),
      description: t("Tokenize SAFE including incorporation of SPV once conversion of the SAFE took place with a total of 5000 SAFE holders"),
    },
  ]

  return (
    <div className={classNames('section', styles.topusecases)}>
      <div className={classNames('center', styles.topusecases__center)}>
        <h2 className={classNames('h3 text-center', styles.topusecases__title)}>
          {t('Top usecases for the Investhub Engine')}
        </h2>

        <div className={styles.topusecases__list}>
          {useCaseCards.map((x) => (
            <div className={styles.topusecases__item} key={uuid()}>
              <div className={styles.topusecases__item_inner}>
                <div className={styles.topusecases__item_header}>
                  <div className={styles.topusecases__item_icon}>
                    {x.icon}
                  </div>
                  <div className={styles.topusecases__item_title}>
                    {x.title}
                  </div>
                </div>

                <div className={styles.topusecases__item_description}>
                  {x.description}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default TopUseCases
