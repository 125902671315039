import gql from 'graphql-tag'

// eslint-disable-next-line import/prefer-default-export
export const GET_KYC_INFO = gql`
  query getKycInfo {
    getKycInfo {
      firstName
      lastName
      middleName
      identificationNumber
      birthDate
      educationQualification
      occupation
      employerName
      annualPersonEarning
      totalAssets
      tokenPurpose
      sourceOfFunds
      placeOfBirth
      nationality
      countryOfResidence
      residentialAddress
      city
      postalCode
    }
  }
`;