import React from 'react'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const NotFound = () => {
  const { t } = useTranslation()

  return (
    <>
      <div className="section__result">
        <div className="result__center center">
          <div className="result__wrap">
            <h1
              style={{
                fontSize: "8rem",
                marginBottom: "2rem",
                lineHeight: "1.2",
                color: "#3772FF",
              }}
            >
              404
            </h1>
            <h2 className="result__title h2">
              {t('The page you are looking for not available!')}
            </h2>
            <div className="result__info">{' '}</div>
            <div className="subscription">
              <NavLink
                to="/"
                className="button"
                style={{ minWidth: "180px" }}
              >
                {t('Back to homepage')}
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default NotFound
