import React from 'react'
import { useTranslation } from 'react-i18next'
import RestorePasswordConfirmForm from '../components/RestorePasswordConfirmForm'

const RestorePasswordConfirm = () => {
  const { t } = useTranslation()
  return (
    <>
      <div className="section-pt80 variants signinup">
        <div className="variants__center signinup__center center">
          <div className="variants__top signinup__top">
            <div className="variants__title h2 signinup__title">
              {t('Reset password')}
            </div>
          </div>

          <RestorePasswordConfirmForm />
        </div>
      </div>
    </>
  )
}

export default RestorePasswordConfirm
