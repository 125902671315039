import twitter1 from './twitter-1.jpeg'
import twitter2 from './twitter-2.jpeg'
import twitter3 from './twitter-3.jpeg'
import twitter4 from './twitter-4.jpeg'
import twitter5 from './twitter-5.jpeg'
import twitter6 from './twitter-6.jpeg'
import twitter7 from './twitter-7.jpeg'
import twitter8 from './twitter-8.jpeg'
import Kaspars from './Kaspars-Zvaigzne.jpeg'
import Peter from './Peter-Lindenmann.jpeg'

export default {
  twitter1,
  twitter2,
  twitter3,
  twitter4,
  twitter5,
  twitter6,
  twitter7,
  twitter8,
  Kaspars,
  Peter,
}