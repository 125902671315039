import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { NavLink } from 'react-router-dom'
import { useMutation } from '@apollo/react-hooks'
import { omit } from 'lodash'
import {
  FormControlLabel,
  Checkbox,
  TextField,
  CircularProgress,
  // InputAdornment,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import Icon from './Icon'
import { toaster } from '../utils'
import {
  // PUBLIC_SETTINGS,
  REGISTRATION,
} from '../queriesAndMutations'

const SignUpForm = ({
  onRegistrationComplete,
}) => {
  const { t } = useTranslation()
  const [values, setValues] = useState({
    email: '',
    fullname: '',
    password: '',
    confirmPassword: '',
    companyName: '',
    agree: false,
  })

  const setField = (fieldName) => ({ target: { value } }) => {
    setValues({
      ...values,
      [fieldName]: value,
    })
  }
  const setCheckBox = (fieldName) => ({ target: { checked } }) => {
    setValues({
      ...values,
      [fieldName]: checked,
    })
  }

  // const { data: { publicSettings } = {} } = useQuery(PUBLIC_SETTINGS)
  const [register, registerStatus] = useMutation(REGISTRATION)

  const submitRegistration = async () => {
    if (values.password !== values.confirmPassword) {
      toaster.error(t('Password confirmation mismatched.'))
      return
    }

    try {
      await register({
        variables: {
          input: omit(values, ['agree', 'confirmPassword']),
        },
      })

      localStorage.setItem('companyName', values.companyName)

      if (onRegistrationComplete) {
        onRegistrationComplete()
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e)
    }
  }

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault()
          submitRegistration()
        }}
        className="entry__fieldset"
      >
        <TextField
          required
          label={t('Email')}
          className=" xid-field has-icon"
          placeholder={t('Enter Email Address')}
          margin="normal"
          variant="standard"
          value={values.email}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={setField('email')}
          InputProps={{
            startAdornment: (
              <Icon
                name="mail"
                className={classNames('mail')}
                size={24}
              />
            ),
          }}
        />

        <TextField
          required
          label={t('Name')}
          className="xid-field has-icon"
          placeholder={t('Enter Full Name')}
          margin="normal"
          variant="standard"
          InputLabelProps={{
            shrink: true,
          }}
          value={values.fullname}
          onChange={setField('fullname')}
          InputProps={{
            startAdornment: (
              <Icon
                name="user-svg"
                className={classNames('user-svg')}
                size={24}
              />
            ),
          }}
        />

        <TextField
          required
          type="password"
          label={t('Password')}
          className="xid-field has-icon"
          placeholder={t('Enter Password')}
          margin="normal"
          variant="standard"
          InputLabelProps={{
            shrink: true,
          }}
          value={values.password}
          onChange={setField('password')}
          InputProps={{
            startAdornment: (
              <Icon
                name="lock"
                className={classNames('lock')}
                size={24}
              />
            ),
          }}
        />

        <TextField
          required
          type="password"
          label={t('Confirm password')}
          className="xid-field has-icon"
          placeholder={t('Enter Confirm Password')}
          margin="normal"
          variant="standard"
          InputLabelProps={{
            shrink: true,
          }}
          value={values.confirmPassword}
          onChange={setField('confirmPassword')}
          InputProps={{
            startAdornment: (
              <Icon
                name="lock"
                className={classNames('lock')}
                size={24}
              />
            ),
          }}
        />

        <TextField
          required
          label={t('Company Name')}
          className="xid-field has-icon"
          placeholder={t('Enter Company Name')}
          margin="normal"
          variant="standard"
          InputLabelProps={{
            shrink: true,
          }}
          value={values.companyName}
          onChange={setField('companyName')}
          InputProps={{
            startAdornment: (
              <Icon
                name="store"
                className={classNames('store')}
                size={24}
              />
            ),
          }}
        />

        <FormControlLabel
          className="xid-field field__checkbox"
          label={(
            <>
              {t('I agree to XID\'s ')}
              <NavLink to="/privacy-policy" target="_blank">{t('Privacy Policy')}</NavLink>
              {t(' & ')}
              <NavLink to="/tos" target="_blank">{t('Terms')}</NavLink>
            </>
          )}
          control={(
            <Checkbox
              values="yes"
              checked={values.agree}
              onChange={setCheckBox('agree')}
              color="primary"
            />
          )}
        />

        { registerStatus.loading
          ? (
            <CircularProgress />
          ) : (
            <button
              className="button-xid entry__button"
              disabled={!values.agree}
              type="submit"
            >
              {t('Sign up Now')}
            </button>
          )}
      </form>

      <div className="entry__note">{' '}</div>
      <div className="entry__info">
        {t('Do you have an account already?')}
        {' '}
        <NavLink to="/login">{t('Login now')}</NavLink>
      </div>
    </>
  )
}

SignUpForm.propTypes = {
  onRegistrationComplete: PropTypes.func,
}

export default SignUpForm
