// eslint-disable-next-line import/prefer-default-export
export const formatPrintNumber = (x, precision = 2) => {
  const newNumber = Number.parseFloat(x).toFixed(precision).toString()
  const [decimal, fractal] = newNumber.toString().split('.')
  const trailing = Array(precision).fill(0).join('')

  return `${decimal.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}${(fractal || precision > 0) ? '.' : ''}${fractal || trailing}`
}

export const roundNumber = (num) => Math.round((num + Number.EPSILON) * 100) / 100
