import React from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { animated } from 'react-spring'
import { use3dEffect } from 'use-3d-effect'

import ContentImage from '../../../media/images/issuerFeature/feature'
import styles from './styles.module.scss'

const ContentTwo = () => {
  const { t } = useTranslation()

  const ref = React.useRef(null)
  const { style, ...mouseHandlers } = use3dEffect(ref)

  const ref2 = React.useRef(null)
  const { style2, ...mouseHandlers2 } = use3dEffect(ref2)

  const ref3 = React.useRef(null)
  const { style3, ...mouseHandlers3 } = use3dEffect(ref3)

  return (
    <>
      <div className={classNames('section', styles.content, styles.content__right, styles.content__space, styles.content__nospacetop)}>
        <div className={classNames('center', styles.content__center)}>

          <div className={styles.content__image}>
            <animated.div
              ref={ref2}
              style={{
                ...style2,
              }}
              {...mouseHandlers2}
            >
              <img src={ContentImage.kybOnboarding} alt={t('KYB Onboarding')} />
            </animated.div>
          </div>

          <div className={styles.content__description}>
            <h2 className={classNames("h3", styles.content__title)}>
              {t('KYB Onboarding')}
            </h2>

            <div className={styles.content__text}>
              <p>
                {t('We understand that Legal Entities are harder to onboard as Investor, we tried to make it as easy for the Legal Entities and seamless for Compliance to assess the Legal Entities.')}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className={classNames('section', styles.content, styles.content__left, styles.content__space)}>
        <div className={classNames('center', styles.content__center)}>

          <div className={styles.content__image}>
            <animated.div
              ref={ref}
              style={{
                ...style,
              }}
              {...mouseHandlers}
            >
              <img src={ContentImage.commitmentapi} alt={t('Commitment API')} />
            </animated.div>
          </div>

          <div className={styles.content__description}>
            <h2 className={classNames("h3", styles.content__title)}>
              {t('Commitment API')}
            </h2>

            <div className={styles.content__text}>
              <p>
                {t('Enable your existing Ecosystem to Invest and make Commitment for the Investhub Engine.')}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className={classNames('section', styles.content, styles.content__right)}>
        <div className={classNames('center', styles.content__center)}>

          <div className={styles.content__image}>
            <animated.div
              ref={ref3}
              style={{
                ...style3,
              }}
              {...mouseHandlers3}
            >
              <img src={ContentImage.circleContent} alt={t('IBAN to On-Chain Assets')} />
            </animated.div>
          </div>

          <div className={styles.content__description}>
            <h2 className={classNames("h3", styles.content__title)}>
              {t('IBAN to On-Chain Assets')}
            </h2>

            <div className={styles.content__text}>
              <p>
                {t('Enable your Investors to pay via SEPA to On-Chain EUR (EURe). Powered by Monerium.')}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ContentTwo
