import circleContent from './circle-content.png'
import commitmentapi from './commitment-API.png'
import kybOnboarding from './kybonboarding.png'
import tokenInvestmentAmount from './token-and-investment-amount.png'

export default {
  circleContent,
  commitmentapi,
  kybOnboarding,
  tokenInvestmentAmount,
}