import api from './index'

export default {
  find: () => api.get('/api/articles?populate=*').then((res) => res.data),
  detail: (id) => api.get(`/api/articles?id=${id}?populate=*`).then((res) => res.data),
  getBySlug: (slug, query = 'populate=*') => new Promise((resolve, reject) => {
    api.get(`/api/articles?filters[slug][$eq]=${slug}`).then((findResult) => {
      if (findResult.data.data.length > 0) {
        api.get(`/api/articles/${findResult.data.data[0].id}?${query}`)
          .then((res) => resolve(res))
          .catch((error) => reject(error))
      } else {
        reject(new Error('Article not found'))
      }
    }).catch((error) => reject(error))
  }),
}
