import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { useTranslation } from 'react-i18next'
import {
  CircularProgress,
} from '@material-ui/core'
import { Redirect } from 'react-router-dom'

import { toaster } from '../utils'
import {
  SUBSCRIBE_FOR_TENANT,
  GET_AVAILABLE_SUBSCRIPTION_PLAN,
  GET_TENANT,
} from '../queriesAndMutations'
import TenantPlanTable from '../components/TenantPlanTable'
// import '../styles/pages/TenantSubscribe.scss'

const TenantSubscribe = ({ match }) => {
  const { t } = useTranslation()
  const tenantId = +match.params.tenantId
  const [voucherCode, setVoucherCode] = useState('')
  const [hasValidVoucher, setHasValidVoucher] = useState(false)
  const [subscribeForTenant, subscribeForTenantStatus] = useMutation(SUBSCRIBE_FOR_TENANT)
  const {
    data: { getAvailableSubscriptionPlan: allPlans } = {},
    loading,
  } = useQuery(GET_AVAILABLE_SUBSCRIPTION_PLAN)
  const {
    data: { getTenant: tenant } = {},
    loading: getTenantLoading,
    error: getTenantError,
  } = useQuery(GET_TENANT, {
    variables: {
      id: tenantId,
    },
  })
  const [redirectToDashboard, setRedirectToDashboard] = useState(false)

  useEffect(() => {
    if (getTenantError) {
      setRedirectToDashboard(true)
    }
  }, [getTenantError])

  const checkout = (planId) => {
    if (!tenantId) {
      toaster.error('Select a tenant.')
      return
    }
    if (!planId) {
      toaster.error('Select a plan.')
      return
    }
    let variables = {
      tenantId,
      planId,
    }
    if (hasValidVoucher) {
      variables = {
        ...variables,
        voucherCode,
      }
    }
    subscribeForTenant({
      variables,
    }).then((response) => {
      const result = response.data.subscribeForTenant
      if (result.success) {
        window.location.href = result.data.redirectUrl
      } else {
        toaster.error(result.message)
      }
    }).catch(() => {})
  }

  if (redirectToDashboard) {
    return <Redirect to="/dashboard" />
  }

  // if (!loading && allPlans && Object.values(allPlans).length) {
  //   checkout(Object.values(allPlans)[0].id)
  //   return <></>
  // }

  return (
    <>
      <div className="section-pt80 variants pricings">
        <div className="variants__center pricings__center center center__admin">
          { loading && getTenantLoading && (
            <div className="variants__top text-center">
              <CircularProgress />
            </div>
          )}
          {!loading && !getTenantLoading && allPlans && (
            <>
              <div className="variants__top pricings__top">
                <div className="variants__title h2 pricings__title">
                  {t('Select plan')}
                  <br />
                  {tenant && `for ${tenant.host}`}
                </div>
              </div>

              <TenantPlanTable
                plans={allPlans}
                onSelectPlan={(selectedPlan) => checkout(selectedPlan)}
                loading={subscribeForTenantStatus.loading}
                voucherCode={voucherCode}
                setVoucherCode={setVoucherCode}
                setHasValidVoucher={setHasValidVoucher}
              />

            </>
          )}
        </div>
      </div>
    </>
  )
}

TenantSubscribe.propTypes = {
  match: PropTypes.object,
}

export default TenantSubscribe
