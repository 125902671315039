import React from 'react'
import { NavLink } from 'react-router-dom'
import {
  Dialog,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import MediaConnectWallet from '../media/images/walletconnect/index'
import ControlBreadcrumbs from '../components/ControlBreadcrumbs'

const ConnectWallet = () => {
  const { t } = useTranslation()

  return (
    <>
      <ControlBreadcrumbs>
        <li className="breadcrumbs__item">
          <NavLink to="/" className="breadcrumbs__link">{t('Home')}</NavLink>
        </li>
        <li className="breadcrumbs__item">
          {t('Wallet Connect')}
        </li>
      </ControlBreadcrumbs>

      <div className="section-pt80 faq discover connect-wallet">
        <div className="discover__center connect-wallet__center center">
          <div className="faq__top connect-wallet__top">
            <div className="faq__title connect-wallet__title h2">{t('Wallet Connect')}</div>
          </div>

          <div className="variants__list connect-wallet__list">
            <a href="/" className="variants__item connect-wallet__item">
              <div className="connect-wallet__img">
                <img src={MediaConnectWallet.metamask} alt="Metamask" />
              </div>
              <div className="card__title connect-wallet__title">{t('Metamask')}</div>
              <div className="upload__note connect-wallet__note">
                {t('Start exploring blockchain applications in seconds. Trusted by over 1 million users worldwide.')}
              </div>
            </a>

            <a href="/" className="variants__item connect-wallet__item">
              <div className="connect-wallet__img">
                <img src={MediaConnectWallet.authereum} alt="Authereum" />
              </div>
              <div className="card__title connect-wallet__title">{t('Authereum')}</div>
              <div className="upload__note connect-wallet__note">
                {t('Start exploring blockchain applications in seconds. Trusted by over 1 million users worldwide.')}
              </div>
            </a>

            <a href="/" className="variants__item connect-wallet__item">
              <div className="connect-wallet__img">
                <img src={MediaConnectWallet.walletconnect} alt="WalletConnect" />
              </div>
              <div className="card__title connect-wallet__title">{t('WalletConnect')}</div>
              <div className="upload__note connect-wallet__note">
                {t('Start exploring blockchain applications in seconds. Trusted by over 1 million users worldwide.')}
              </div>
            </a>

            <a href="/" className="variants__item connect-wallet__item">
              <div className="connect-wallet__img">
                <img src={MediaConnectWallet.dapper} alt="Dapper" />
              </div>
              <div className="card__title connect-wallet__title">{t('Dapper')}</div>
              <div className="upload__note connect-wallet__note">
                {t('Start exploring blockchain applications in seconds. Trusted by over 1 million users worldwide.')}
              </div>
            </a>

            <a href="/" className="variants__item connect-wallet__item">
              <div className="connect-wallet__img">
                <img src={MediaConnectWallet.kaikas} alt="Kaikas" />
              </div>
              <div className="card__title connect-wallet__title">{t('Kaikas')}</div>
              <div className="upload__note connect-wallet__note">
                {t('Start exploring blockchain applications in seconds. Trusted by over 1 million users worldwide.')}
              </div>
            </a>

            <a href="/" className="variants__item connect-wallet__item">
              <div className="connect-wallet__img">
                <img src={MediaConnectWallet.formatic} alt="Formatic" />
              </div>
              <div className="card__title connect-wallet__title">{t('Formatic')}</div>
              <div className="upload__note connect-wallet__note">
                {t('Start exploring blockchain applications in seconds. Trusted by over 1 million users worldwide.')}
              </div>
            </a>

            <a href="/" className="variants__item connect-wallet__item">
              <div className="connect-wallet__img">
                <img src={MediaConnectWallet.coinbasewallet} alt="Coinbase" />
              </div>
              <div className="card__title connect-wallet__title">{t('Coinbase')}</div>
              <div className="upload__note connect-wallet__note">
                {t('Start exploring blockchain applications in seconds. Trusted by over 1 million users worldwide.')}
              </div>
            </a>

            <a href="/" className="variants__item connect-wallet__item">
              <div className="connect-wallet__img">
                <img src={MediaConnectWallet.arkane} alt="Arkane" />
              </div>
              <div className="card__title connect-wallet__title">{t('Arkane')}</div>
              <div className="upload__note connect-wallet__note">
                {t('Start exploring blockchain applications in seconds. Trusted by over 1 million users worldwide.')}
              </div>
            </a>

          </div>
        </div>
      </div>

      <Dialog
        open
        className="investhub-modal"
      >
        <div className="popup js-popup investhub-popup">
          <div className="popup__wrap investhub-popup__wrap">
            <div className="popup__title h4 investhub-popup__title">{t('This will be soon available.')}</div>

            <div className="popup__content investhub-popup__content">
              <NavLink to="/" className="button-stroke button-small">{t('Back to home')}</NavLink>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  )
}

export default ConnectWallet
