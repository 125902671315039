import React from 'react'

const BreadcrumbConnector = () => (
  <div className="site-main__breadcrumb-sep">
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.36156 5.6194L3.35659 0.147039C3.15954 -0.0490131 2.83996 -0.0490131 2.64291 0.147039C2.44585 0.343077 2.44585 0.661169 2.64291 0.857222L8.28581 6.00001L2.64341 11.1428C2.44635 11.3388 2.44635 11.6569 2.64341 11.853C2.84046 12.049 3.16004 12.049 3.35707 11.853L9.36206 6.3806C9.46709 6.27609 9.51212 6.13753 9.5051 6.00051C9.51163 5.86297 9.46662 5.72443 9.36156 5.6194Z" fill="#808080" />
    </svg>
  </div>
)

export default BreadcrumbConnector
