import React from 'react'

import XIDLogo from '../media/xid/xid-logo.png'
import SignUpConfirmCheck from '../components/SignUpConfirmCheck'

const SignUpConfirm = ({ history }) => (
  <>
    <div className="entry__logo">
      <img src={XIDLogo} alt="XID App" />
    </div>

    <SignUpConfirmCheck
      history={history}
    />
  </>
)

export default SignUpConfirm
