import React from 'react'
import classNames from 'classnames'
import { NavLink } from 'react-router-dom'
import { EqualHeight, EqualHeightElement } from 'react-equal-height'
import { useTranslation } from 'react-i18next'
import Icon from '../../Icon'

const ListPricing = () => {
  const { t } = useTranslation()

  return (
    <div className="section-pt80 variants pricings">
      <div className="variants__center pricings__center center center__admin">
        <div className="variants__top">
          <div className="variants__title h2">
            {t('Pricing that scales with you')}
          </div>

          <div className="variants__info">
            {t('Flexible pricing for companies of all sizes. Simple, transparent and pay-as-you-go.')}
          </div>
        </div>

        <div className="variants__list pricings__list pricings__list_has_hot">
          <EqualHeight>
            <div className="variants__item pricings__item">
              <div className="item__title h3 pricings__item-title">
                {t('Starter')}
              </div>

              <div className="pricings__inner">
                <div className="item__cost pricings__cost">
                  <div className="status-stroke-green item__price">39chf</div>
                  <div className="item__counter">
                    /
                    {' '}
                    {t('month')}
                  </div>
                </div>

                <EqualHeightElement name="feature_general">
                  <ul className="pricings__feature">
                    <li>
                      {t('KYB Onboarding (Companies)')}
                    </li>
                    <li>
                      {t('Different Token Types')}
                    </li>
                    <li>
                      {t('Multi Currency (FIAT)')}
                    </li>
                    <li>
                      {t('Liveness Check / Video Ident Log')}
                    </li>
                    <li>
                      {t('Exchange Rates')}
                    </li>
                    <li>
                      {t('Enable Transaction Limit')}
                    </li>
                    <li>{t('Exact Investment Amount')}</li>
                    <li>{t('Mandatory KYC for Referrals to create transactions')}</li>
                    <li>{t('Mandatory KYC for Organic to create transactions')}</li>
                    <li>{t('Allow the Investor to add Wallet later')}</li>
                    <li>{t('Require referral Code for KYC')}</li>
                    <li>{t('Disable ERC-20 Check for receiving wallet')}</li>
                    <li>{t('Bank upload logo (allows you to use any bank)')}</li>
                    <li>{t('Download Due Dilligence Files as Zipfile')}</li>
                    <li>{t('Download All KYC Data')}</li>
                    <li>{t('Download Pythagoras formatted CSV')}</li>
                    <li>{t('Download Bank CSV')}</li>
                    <li>{t('Reopen KYC with Liveness Check')}</li>
                    <li>{t('Comment function')}</li>
                    <li>{t('Reopen KYC workflow')}</li>
                    <li>{t('Risk Level Assesment')}</li>
                    <li>{t('Wallet check Score')}</li>
                    <li>{t('Banking Data from your Investors (let investors enter their Banking Data)')}</li>
                    <li>{t('Approve transactions and confirm its business profile')}</li>
                    <li>{t('Datacenter to upload Data for your Investors (e.g Pitchdeck)')}</li>
                    <li>{t('User list (a list of all your Investors)')}</li>
                    <li>{t('Ethereum Address for approved KYCs export for manual whitelisting')}</li>
                    <li>{t('Compliance Role (Can only access KYC/KYB related data)')}</li>
                    <li>{t('Token Export File for approved transactions')}</li>
                    <li>{t('Split KYC process into two steps (gather personal data first, then allow liveness check)')}</li>
                    <li className="disabled">{t('General Blacklist derived from Investhub.io (Gesetze.li)')}</li>
                    <li className="disabled">{t('Pythagoras Export')}</li>
                    <li className="disabled">{t('Acronis Export (Datacenter in Liechtenstein)')}</li>
                    <li className="disabled">{t('Sales API (receive external Traffic via API with Single Sign on and send back investor related data)')}</li>
                    <li className="disabled">{t('Cashlink Integration')}</li>
                    <li className="disabled">{t('Volt.io Integration')}</li>
                    <li className="disabled">{t('Sendwyre Integration')}</li>
                    <li>{t('Walletengine.io (Beta)')}</li>
                    <li className="disabled">{t('Enable Crypto.com Payment Gateway')}</li>
                    <li className="disabled">{t('Signnow Integration')}</li>
                    <li className="disabled">{t('2FA (Mandatory yes/no)')}</li>
                    <li className="disabled">{t('Discount Levels')}</li>
                    <li className="disabled">{t('Referral Codes')}</li>
                    <li className="disabled">{t('Multi Language')}</li>
                    <li className="disabled">{t('Google Analytics')}</li>
                    <li className="disabled">{t('Google Tag Manager')}</li>
                    <li className="disabled">{t('Facebook Pixel')}</li>
                    <li className="disabled">{t('Hotjar Integration')}</li>
                    <li className="disabled">{t('YouTube Login Page')}</li>
                    <li className="disabled">{t('Auto Follow up (If no KYC, or if no Transaction)')}</li>
                    <li className="disabled">{t('Custodian Role (Custodian can see token owners at all times)')}</li>
                    <li className="disabled">{t('Allow non ERC-20 Receiving and Sending wallets')}</li>
                    <li className="disabled">{t('Use own Mailserver (Mailgun)')}</li>
                    <li className="disabled">{t('Change parameters for KYC Levels based on your AML policy')}</li>
                    <li className="disabled">{t('Use KYC level 5')}</li>
                    <li className="disabled">{t('Liveness check extract & compare data')}</li>
                    <li className="disabled">{t('Internal Blacklist (you can maintain an extra blacklist internally)')}</li>
                    <li className="disabled">{t('Custodian Role (Custodian can see token owners at all times)')}</li>
                    <li className="disabled">{t('Event Log (show all changes within the app for settings and KYC applications) perfect Audit Trail for yearly audit review')}</li>
                    <li className="disabled">{t('Auto approve KYC when matched several conditions')}</li>
                  </ul>
                </EqualHeightElement>

                <EqualHeightElement name="feature_charge_on_usage">
                  <div className="footer__category pricings__info">
                    {t('Charge on usage')}
                  </div>
                  <ul className="pricings__feature">
                    <li>
                      {t('7.00 chf / Onfido liveness check')}
                    </li>
                    <li>
                      {t('1.50 chf / Onfido enhanced watchlist')}
                    </li>
                    <li>
                      {t('25.00 chf / Datastorage (Liechtenstein)')}
                    </li>
                    <li>
                      {t('2.00 chf / Wallet check')}
                    </li>
                    <li>
                      {t('100.00 chf / Signnow')}
                    </li>
                  </ul>
                  <div className="footer__category pricings__info">
                    {' '}
                  </div>
                </EqualHeightElement>

                <EqualHeightElement name="feature_optional">
                  <>
                    <div className="footer__category pricings__info">
                      {t('Optional Package')}
                    </div>
                    <span className="pricings__not-included text-center">
                      {t('Not included')}
                    </span>
                  </>
                </EqualHeightElement>

                <EqualHeightElement name="feature_referal">
                  <>
                    <div className="footer__category pricings__info">
                      {t('Optional Referral')}
                    </div>
                    <ul className="pricings__feature">
                      <li>
                        {t('15% Commission for each Referral from Investhub.io')}
                      </li>
                    </ul>
                  </>
                </EqualHeightElement>

                <div className="footer__category pricings__info">
                  {' '}
                </div>
              </div>

              <NavLink to="/signup" className="button-stroke variants__button pricings__button">
                {t('Signup now')}
              </NavLink>
            </div>

            <div className="variants__item pricings__item pricings__hot">
              <div className="item__title h3 pricings__item-title">
                {t('Medium')}
              </div>

              <div className="pricings__inner">
                <div className="item__cost pricings__cost">
                  <div className="status-stroke-green item__price">150chf</div>
                  <div className="item__counter">
                    /
                    {' '}
                    {t('month')}
                  </div>
                </div>

                <EqualHeightElement name="feature_general">
                  <ul className="pricings__feature">
                    <li>
                      {t('KYB Onboarding (Companies)')}
                    </li>
                    <li>
                      {t('Different Token Types')}
                    </li>
                    <li>
                      {t('Multi Currency (FIAT)')}
                    </li>
                    <li>
                      {t('Liveness Check / Video Ident Log')}
                    </li>
                    <li>
                      {t('Exchange Rates')}
                    </li>
                    <li>
                      {t('Enable Transaction Limit')}
                    </li>
                    <li>
                      {t('Exact Investment Amount')}
                    </li>
                    <li>
                      {t('Mandatory KYC for Referrals to create transactions')}
                    </li>
                    <li>
                      {t('Mandatory KYC for Organic to create transactions')}
                    </li>
                    <li>
                      {t('Allow the Investor to add Wallet later')}
                    </li>
                    <li>
                      {t('Require referral Code for KYC')}
                    </li>
                    <li>
                      {t('Disable ERC-20 Check for receiving wallet')}
                    </li>
                    <li>
                      {t('Bank upload logo (allows you to use any bank)')}
                    </li>
                    <li>
                      {t('Download Due Dilligence Files as Zipfile')}
                    </li>
                    <li>
                      {t('Download All KYC Data')}
                    </li>
                    <li>
                      {t('Download Pythagoras formatted CSV')}
                    </li>
                    <li>
                      {t('Download Bank CSV')}
                    </li>
                    <li>
                      {t('Reopen KYC with Liveness Check')}
                    </li>
                    <li>
                      {t('Comment function')}
                    </li>
                    <li>
                      {t('Reopen KYC workflow')}
                    </li>
                    <li>
                      {t('Risk Level Assesment')}
                    </li>
                    <li>
                      {t('Wallet check Score')}
                    </li>
                    <li>
                      {t('Banking Data from your Investors (let investors enter their Banking Data)')}
                    </li>
                    <li>
                      {t('Approve transactions and confirm its business profile')}
                    </li>
                    <li>
                      {t('Datacenter to upload Data for your Investors (e.g Pitchdeck)')}
                    </li>
                    <li>
                      {t('User list (a list of all your Investors)')}
                    </li>
                    <li>
                      {t('Ethereum Address for approved KYCs export for manual whitelisting')}
                    </li>
                    <li>
                      {t('Compliance Role (Can only access KYC/KYB related data)')}
                    </li>
                    <li>
                      {t('Token Export File for approved transactions')}
                    </li>
                    <li>
                      {t('Split KYC process into two steps (gather personal data first, then allow liveness check)')}
                    </li>
                    <li>
                      {t('General Blacklist derived from Investhub.io (Gesetze.li)')}
                    </li>
                    <li className="disabled">
                      {t('Pythagoras Export')}
                    </li>
                    <li className="disabled">
                      {t('Acronis Export (Datacenter in Liechtenstein)')}
                    </li>
                    <li>
                      {t('Sales API (receive external Traffic via API with Single Sign on and send back investor related data)')}
                    </li>
                    <li className="disabled">
                      {t('Cashlink Integration')}
                    </li>
                    <li>
                      {t('Volt.io Integration')}
                    </li>
                    <li>
                      {t('Sendwyre Integration')}
                    </li>
                    <li className="disabled">
                      {t('Walletengine.io (Beta)')}
                    </li>
                    <li className="disabled">
                      {t('Enable Crypto.com Payment Gateway')}
                    </li>
                    <li className="disabled">
                      {t('Signnow Integration')}
                    </li>
                    <li>
                      {t('2FA (Mandatory yes/no)')}
                    </li>
                    <li>
                      {t('Discount Levels')}
                    </li>
                    <li>
                      {t('Referral Codes')}
                    </li>
                    <li>
                      {t('Multi Language')}
                    </li>
                    <li>
                      {t('Google Analytics')}
                    </li>
                    <li>
                      {t('Google Tag Manager')}
                    </li>
                    <li>
                      {t('Facebook Pixel')}
                    </li>
                    <li>
                      {t('Hotjar Integration')}
                    </li>
                    <li>
                      {t('YouTube Login Page')}
                    </li>
                    <li>
                      {t('Auto Follow up (If no KYC, or if no Transaction)')}
                    </li>
                    <li className="disabled">
                      {t('Custodian Role (Custodian can see token owners at all times)')}
                    </li>
                    <li>
                      {t('Allow non ERC-20 Receiving and Sending wallets')}
                    </li>
                    <li>
                      {t('Use own Mailserver (Mailgun)')}
                    </li>
                    <li className="disabled">
                      {t('Change parameters for KYC Levels based on your AML policy')}
                    </li>
                    <li className="disabled">
                      {t('Use KYC level 5')}
                    </li>
                    <li className="disabled">
                      {t('Liveness check extract & compare data')}
                    </li>
                    <li>
                      {t('Internal Blacklist (you can maintain an extra blacklist internally)')}
                    </li>
                    <li>
                      {t('Custodian Role (Custodian can see token owners at all times)')}
                    </li>
                    <li>
                      {t('Event Log (show all changes within the app for settings and KYC applications) perfect Audit Trail for yearly audit reviewz')}
                    </li>
                    <li className="disabled">
                      {t('Auto approve KYC when matched several conditions')}
                    </li>
                  </ul>
                </EqualHeightElement>

                <EqualHeightElement name="feature_charge_on_usage">
                  <>
                    <div className="footer__category pricings__info">
                      {t('Charge on usage')}
                    </div>
                    <ul className="pricings__feature">
                      <li>
                        {t('6.00 chf / Onfido liveness check')}
                      </li>
                      <li>
                        {t('1.20 chf / Onfido enhanced watchlist')}
                      </li>
                      <li>
                        {t('15.00 chf / Datastorage (Liechtenstein)')}
                      </li>
                      <li>
                        {t('1.50 chf / Wallet check')}
                      </li>
                      <li>
                        {t('50.00 chf / Signnow')}
                      </li>
                    </ul>
                  </>
                </EqualHeightElement>

                <EqualHeightElement name="feature_optional">
                  <>
                    <div className="footer__category pricings__info">
                      {t('Optional Package')}
                    </div>
                    <span className="pricings__not-included text-center">
                      {t('Not included')}
                    </span>
                  </>
                </EqualHeightElement>

                <EqualHeightElement name="feature_referal">
                  <>
                    <div className="footer__category pricings__info">
                      {t('Optional Referral')}
                    </div>
                    <ul className="pricings__feature">
                      <li>
                        {t('10% Commission for each Referral from Investhub.io')}
                      </li>
                    </ul>
                  </>
                </EqualHeightElement>
              </div>

              <NavLink to="/signup" className="button variants__button pricings__button">
                {t('Signup now')}
              </NavLink>
            </div>

            <div className="variants__item pricings__item">
              <div className="item__title h3 pricings__item-title">
                {t('Premium')}
              </div>

              <div className="pricings__inner">
                <div className="item__cost pricings__cost">
                  <div className="status-stroke-green item__price">499chf</div>
                  <div className="item__counter">
                    /
                    {' '}
                    {t('month')}
                  </div>
                </div>

                <EqualHeightElement name="feature_general">
                  <ul className="pricings__feature">
                    <li>
                      {t('KYB Onboarding (Companies)')}
                    </li>
                    <li>
                      {t('Different Token Types')}
                    </li>
                    <li>
                      {t('Multi Currency (FIAT)')}
                    </li>
                    <li>
                      {t('Liveness Check / Video Ident Log')}
                    </li>
                    <li>
                      {t('Exchange Rates')}
                    </li>
                    <li>
                      {t('Enable Transaction Limit')}
                    </li>
                    <li>{t('Exact Investment Amount')}</li>
                    <li>{t('Mandatory KYC for Referrals to create transactions')}</li>
                    <li>{t('Mandatory KYC for Organic to create transactions')}</li>
                    <li>{t('Allow the Investor to add Wallet later')}</li>
                    <li>{t('Require referral Code for KYC')}</li>
                    <li>{t('Disable ERC-20 Check for receiving wallet')}</li>
                    <li>{t('Bank upload logo (allows you to use any bank)')}</li>
                    <li>{t('Download Due Dilligence Files as Zipfile')}</li>
                    <li>{t('Download All KYC Data')}</li>
                    <li>{t('Download Pythagoras formatted CSV')}</li>
                    <li>{t('Download Bank CSV')}</li>
                    <li>{t('Reopen KYC with Liveness Check')}</li>
                    <li>{t('Comment function')}</li>
                    <li>{t('Reopen KYC workflow')}</li>
                    <li>{t('Risk Level Assesment')}</li>
                    <li>{t('Wallet check Score')}</li>
                    <li>{t('Banking Data from your Investors (let investors enter their Banking Data)')}</li>
                    <li>{t('Approve transactions and confirm its business profile')}</li>
                    <li>{t('Datacenter to upload Data for your Investors (e.g Pitchdeck)')}</li>
                    <li>{t('User list (a list of all your Investors)')}</li>
                    <li>{t('Ethereum Address for approved KYCs export for manual whitelisting')}</li>
                    <li>{t('Compliance Role (Can only access KYC/KYB related data)')}</li>
                    <li>{t('Token Export File for approved transactions')}</li>
                    <li>{t('Split KYC process into two steps (gather personal data first, then allow liveness check)')}</li>
                    <li>{t('General Blacklist derived from Investhub.io (Gesetze.li)')}</li>
                    <li className="">{t('Pythagoras Export')}</li>
                    <li className="">{t('Acronis Export (Datacenter in Liechtenstein)')}</li>
                    <li className="">{t('Sales API (receive external Traffic via API with Single Sign on and send back investor related data)')}</li>
                    <li className="">{t('Cashlink Integration')}</li>
                    <li className="">{t('Volt.io Integration')}</li>
                    <li className="">{t('Sendwyre Integration')}</li>
                    <li>{t('Walletengine.io (Beta)')}</li>
                    <li className="">{t('Enable Crypto.com Payment Gateway')}</li>
                    <li className="">{t('Signnow Integration')}</li>
                    <li className="">{t('2FA (Mandatory yes/no)')}</li>
                    <li className="">{t('Discount Levels')}</li>
                    <li className="">{t('Referral Codes')}</li>
                    <li className="">{t('Multi Language')}</li>
                    <li className="">{t('Google Analytics')}</li>
                    <li className="">{t('Google Tag Manager')}</li>
                    <li className="">{t('Facebook Pixel')}</li>
                    <li className="">{t('Hotjar Integration')}</li>
                    <li className="">{t('YouTube Login Page')}</li>
                    <li className="">{t('Auto Follow up (If no KYC, or if no Transaction)')}</li>
                    <li className="">{t('Custodian Role (Custodian can see token owners at all times)')}</li>
                    <li className="">{t('Allow non ERC-20 Receiving and Sending wallets')}</li>
                    <li className="">{t('Use own Mailserver (Mailgun)')}</li>
                    <li className="">{t('Change parameters for KYC Levels based on your AML policy')}</li>
                    <li className="">{t('Use KYC level 5')}</li>
                    <li className="">{t('Liveness check extract & compare data')}</li>
                    <li className="">{t('Internal Blacklist (you can maintain an extra blacklist internally)')}</li>
                    <li className="">{t('Custodian Role (Custodian can see token owners at all times)')}</li>
                    <li className="">{t('Event Log (show all changes within the app for settings and KYC applications) perfect Audit Trail for yearly audit review')}</li>
                    <li className="">{t('Auto approve KYC when matched several conditions')}</li>
                  </ul>
                </EqualHeightElement>

                <EqualHeightElement name="feature_charge_on_usage">
                  <div className="footer__category pricings__info">
                    {t('Charge on usage')}
                  </div>
                  <ul className="pricings__feature">
                    <li>
                      {t('5.00 chf / Onfido liveness check')}
                    </li>
                    <li>
                      {t('0.90 chf / Onfido enhanced watchlist')}
                    </li>
                    <li>
                      {t('1.00 chf / Datastorage (Liechtenstein)')}
                    </li>
                    <li>
                      {t('1.00 chf / Wallet check')}
                    </li>
                    <li>
                      {t('25.00 chf / Signnow')}
                    </li>
                  </ul>
                </EqualHeightElement>

                <EqualHeightElement name="feature_optional">
                  <>
                    <div className="footer__category pricings__info">
                      {t('Optional Package')}
                    </div>
                    <ul className="pricings__feature">
                      <li>
                        {t('150 CHF / Month IDnow')}
                      </li>
                      <li>
                        {t('150 CHF / Month Wallet as a Service (Tangany)')}
                      </li>
                      <li>
                        {t('150 CHF / Month Auto Approval')}
                      </li>
                    </ul>
                  </>
                </EqualHeightElement>

                <EqualHeightElement name="feature_referal">
                  <>
                    <div className="footer__category pricings__info">
                      {t('Optional Referral')}
                    </div>
                    <ul className="pricings__feature">
                      <li>
                        {t('5% Commission for each Referral from Investhub.io')}
                      </li>
                    </ul>
                  </>
                </EqualHeightElement>
                <div className="footer__category pricings__info">
                  {' '}
                </div>
              </div>

              <NavLink to="/signup" className="button-stroke variants__button pricings__button">
                {t('Signup now')}
              </NavLink>
            </div>

            <div className="variants__item pricings__item">
              <div className="item__title h3 pricings__item-title">
                {t('VIP')}
              </div>

              <div className="pricings__inner">
                <div className="item__cost pricings__cost">
                  <div className="status-stroke-green item__price">4999chf</div>
                  <div className="item__counter">
                    /
                    {' '}
                    {t('month')}
                  </div>
                </div>

                <EqualHeightElement name="feature_general">
                  <ul className="pricings__feature">
                    <li>
                      {t('KYB Onboarding (Companies)')}
                    </li>
                    <li>
                      {t('Different Token Types')}
                    </li>
                    <li>
                      {t('Multi Currency (FIAT)')}
                    </li>
                    <li>
                      {t('Liveness Check / Video Ident Log')}
                    </li>
                    <li>
                      {t('Exchange Rates')}
                    </li>
                    <li>
                      {t('Enable Transaction Limit')}
                    </li>
                    <li>{t('Exact Investment Amount')}</li>
                    <li>{t('Mandatory KYC for Referrals to create transactions')}</li>
                    <li>{t('Mandatory KYC for Organic to create transactions')}</li>
                    <li>{t('Allow the Investor to add Wallet later')}</li>
                    <li>{t('Require referral Code for KYC')}</li>
                    <li>{t('Disable ERC-20 Check for receiving wallet')}</li>
                    <li>{t('Bank upload logo (allows you to use any bank)')}</li>
                    <li>{t('Download Due Dilligence Files as Zipfile')}</li>
                    <li>{t('Download All KYC Data')}</li>
                    <li>{t('Download Pythagoras formatted CSV')}</li>
                    <li>{t('Download Bank CSV')}</li>
                    <li>{t('Reopen KYC with Liveness Check')}</li>
                    <li>{t('Comment function')}</li>
                    <li>{t('Reopen KYC workflow')}</li>
                    <li>{t('Risk Level Assesment')}</li>
                    <li>{t('Wallet check Score')}</li>
                    <li>{t('Banking Data from your Investors (let investors enter their Banking Data)')}</li>
                    <li>{t('Approve transactions and confirm its business profile')}</li>
                    <li>{t('Datacenter to upload Data for your Investors (e.g Pitchdeck)')}</li>
                    <li>{t('User list (a list of all your Investors)')}</li>
                    <li>{t('Ethereum Address for approved KYCs export for manual whitelisting')}</li>
                    <li>{t('Compliance Role (Can only access KYC/KYB related data)')}</li>
                    <li>{t('Token Export File for approved transactions')}</li>
                    <li>{t('Split KYC process into two steps (gather personal data first, then allow liveness check)')}</li>
                    <li>{t('General Blacklist derived from Investhub.io (Gesetze.li)')}</li>
                    <li className="">{t('Pythagoras Export')}</li>
                    <li className="">{t('Acronis Export (Datacenter in Liechtenstein)')}</li>
                    <li className="">{t('Sales API (receive external Traffic via API with Single Sign on and send back investor related data)')}</li>
                    <li className="">{t('Cashlink Integration')}</li>
                    <li className="">{t('Volt.io Integration')}</li>
                    <li className="">{t('Sendwyre Integration')}</li>
                    <li>{t('Walletengine.io (Beta)')}</li>
                    <li className="">{t('Enable Crypto.com Payment Gateway')}</li>
                    <li className="">{t('Signnow Integration')}</li>
                    <li className="">{t('2FA (Mandatory yes/no)')}</li>
                    <li className="">{t('Discount Levels')}</li>
                    <li className="">{t('Referral Codes')}</li>
                    <li className="">{t('Multi Language')}</li>
                    <li className="">{t('Google Analytics')}</li>
                    <li className="">{t('Google Tag Manager')}</li>
                    <li className="">{t('Facebook Pixel')}</li>
                    <li className="">{t('Hotjar Integration')}</li>
                    <li className="">{t('YouTube Login Page')}</li>
                    <li className="">{t('Auto Follow up (If no KYC, or if no Transaction)')}</li>
                    <li className="">{t('Custodian Role (Custodian can see token owners at all times)')}</li>
                    <li className="">{t('Allow non ERC-20 Receiving and Sending wallets')}</li>
                    <li className="">{t('Use own Mailserver (Mailgun)')}</li>
                    <li className="">{t('Change parameters for KYC Levels based on your AML policy')}</li>
                    <li className="">{t('Use KYC level 5')}</li>
                    <li className="">{t('Liveness check extract & compare data')}</li>
                    <li className="">{t('Internal Blacklist (you can maintain an extra blacklist internally)')}</li>
                    <li className="">{t('Custodian Role (Custodian can see token owners at all times)')}</li>
                    <li className="">{t('Event Log (show all changes within the app for settings and KYC applications) perfect Audit Trail for yearly audit review')}</li>
                    <li className="">{t('Auto approve KYC when matched several conditions')}</li>
                  </ul>
                </EqualHeightElement>

                <EqualHeightElement name="feature_charge_on_usage">
                  <>
                    <div className="footer__category pricings__info">
                      {t('Charge on usage')}
                    </div>
                    <ul className="pricings__feature">
                      <li>
                        {t('2.00 chf / Onfido liveness check')}
                      </li>
                      <li>
                        {t('0.90 chf / Onfido enhanced watchlist')}
                      </li>
                      <li>
                        {t('1.00 chf / Acronis')}
                      </li>
                      <li>
                        {t('1.00 chf / Wallet check')}
                      </li>
                      <li>
                        {t('3.00 chf / Signnow')}
                      </li>
                    </ul>
                  </>
                </EqualHeightElement>

                <EqualHeightElement name="feature_optional">
                  <>
                    <div className="footer__category pricings__info">
                      {t('Optional Package')}
                    </div>
                    <ul className="pricings__feature">
                      <li>
                        {t('150 CHF / Month IDnow')}
                      </li>
                      <li>
                        {t('150 CHF / Month Wallet as a Service (Tangany)')}
                      </li>
                      <li>
                        {t('150 CHF / Month Auto Approval')}
                      </li>
                    </ul>
                  </>
                </EqualHeightElement>

                <EqualHeightElement name="feature_referal">
                  <>
                    <div className="footer__category pricings__info">
                      {t('Optional Referral')}
                    </div>
                    <ul className="pricings__feature">
                      <li>
                        {t('1% Commission for each Referral from Investhub.io')}
                      </li>
                    </ul>
                  </>
                </EqualHeightElement>

                <EqualHeightElement name="feature_additional">
                  <>
                    <div className="footer__category pricings__info">
                      {t('Additional')}
                    </div>
                    <ul className="pricings__feature">
                      <li>
                        {t('2 Hour Legal included')}
                      </li>
                      <li>
                        {t('5 Hour from Investhub Contributor included')}
                      </li>
                    </ul>
                  </>
                </EqualHeightElement>
                <div className="footer__category pricings__info">
                  {' '}
                </div>
              </div>

              <NavLink to="/signup" className="button-stroke variants__button pricings__button">
                {t('Signup now')}
              </NavLink>
            </div>
          </EqualHeight>

          <div className="variants__item pricings__item pricings__item-full">
            <div className="pricings__item-inner center">
              <div className="item__title h3 pricings__item-title">
                {t('Pricing for Entrepreneurs')}
                :
              </div>
              <div className="pricings__inner">
                <p>
                  {t('We established a framework for Entrepreneurs which allows you to use all above for just 3% of your total raise.')}
                </p>
                <p>
                  {t('You will be charged every day at midnight from the total approved transactions during the day. This is the perfect way to scale up your idea, no big upfront costs nor hidden costs down the road.')}
                </p>
                <p>
                  {t('We provide Investor Onboarding support via')}
                  {' '}
                  <a href="http://intercom.io" target="_blank" rel="noreferrer nofollower">http://intercom.io</a>
                  .
                </p>
                <p>
                  {t('Please note that we would also ask for an allocation within your offering, with the same terms like any other investor.')}
                </p>
                <p>
                  {t('You can also settle the above fees with Crypto as well.')}
                </p>
              </div>

              <div className="description__btns">
                <NavLink
                  to="#"
                  onClick={(e) => {
                    window.location = 'mailto:marcel@investhub.io'
                    e.preventDefault()
                  }}
                  className="button descriptions__button"
                >
                  <span>{t('Contact Us')}</span>

                  <Icon
                    name="arrow-next"
                    className={classNames('arrow-next')}
                    size="20"
                  />
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ListPricing
